import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import { getUserLevel } from "./getUserLevel";

const DoctorRoutes = (props) => {
  const navigate = useNavigate();
  const authValue = useAuth();
  const isUserDoctor =
    authValue.currUser?.roles?.id == getUserLevel("IS_DOCTOR");

  return isUserDoctor ? (
    <Outlet />
  ) : (
    <>
      Not Authorized
      <button
        onClick={() => {
          navigate("/");
        }}
      >
        GO HOME
      </button>
    </>
  );
};

export default DoctorRoutes;
