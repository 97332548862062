export const planTypes = [
  {
    title: "Feature List",
    gp: {
      plan: "General Practitioner",
      price: "₹ 1,499",
      span: "per month billed yearly",
      yearly: "1 years+",
      off: "20%",
    },
    sp: {
      plan: "Single Speciality",
      price: "₹ 1,999",
      span: "per month billed yearly",
      yearly: "1 years+",
      off: "20%",
    },
    poly: {
      plan: "Polyclinic",
      price: "₹ 3,999",
      span: "per month billed yearly",
      yearly: "1 years+",
      off: "20%",
    },
    special: {
      plan: "Multi Speciality, Super Speciality OPD centers",
      price: "₹ 5,499",
      span: "per month billed yearly",
      yearly: "1 years+",
      off: "20%",
    },
  },
];

export const usersAndChannels = [
  {
    feature: "Rights for Owner",
    gp: "1 User Full Rights for Owner",
    gp_extra: "",
    ss: "1 User Full Rights for Owner",
    ss_extra: "",
    poly: "1 User Full Rights for Owner",
    poly_extra: "1000/- per extra Owners View only",
    special: "1 User Full Rights for Owner",
    special_extra: "1000/- per extra Owners View only",
    golden: false,
    premium: false,
  },
  {
    feature: "No of Doctors",
    gp: "1 Doctor",
    gp_extra: "1499/- per extra Doctor",
    ss: "1 Doctor",
    ss_extra: "1999/- per extra Doctor",
    poly: "1 General + 1 Speciality Practitioner",
    poly_extra: "1999/- per extra Doctor",
    special: "2 Doctors",
    special_extra: "1999/- per extra Doctor",
    golden: false,
    premium: false,
  },
  {
    feature: " No of Person In-charge Rights",
    gp: "1 Person In-charge",
    gp_extra: "",
    ss: "10 users",
    ss_extra: "",
    poly: "10 users",
    poly_extra: "",
    special: "10 users",
    special_extra: "",
    golden: true,
    premium: false,
  },
  {
    feature: "No of Receptionist",
    gp: "1 Receptionist",
    gp_extra: "300/- per extra Receptionist",
    ss: "10 Receptionist",
    ss_extra: "",
    poly: "10 Receptionist",
    poly_extra: "",
    special: "10 Receptionist",
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "WhatsApp Channel",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: true,
    premium: false,
  },
  {
    feature: "Teams",
    gp: false,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Smart team conversation assignment",
    gp: false,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
];

export const automationData = [
  {
    feature: "IPD Sequence",
    gp: "1 sequences",
    gp_extra: "",
    ss: "2 sequences",
    ss_extra: "",
    poly: "5 sequences",
    poly_extra: "",
    special: "10+ sequences",
    special_extra: "",
    golden: false,
    premium: true,
  },
  {
    feature: "Contact Segments",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: true,
    premium: false,
  },
  {
    feature: "Broadcast via Google Sheets",
    gp: false,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Broadcast via Excel",
    gp: false,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Schedule Broadcast",
    gp: false,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: true,
  },
  {
    feature: "Reply-to settings",
    gp: "Assign to agent, bot",
    gp_extra: "",
    ss: "Assign to agent, bot or team",
    ss_extra: "",
    poly: "Assign to agent, bot or team",
    poly_extra: "",
    special: "Assign to agent, bot or team",
    special_extra: "",
    golden: false,
    premium: true,
  },
  {
    feature: "Broadcast limit",
    gp: "1k messages/month",
    gp_extra: "",
    ss: "10k messages/month",
    ss_extra: "",
    poly: "10k messages/month",
    poly_extra: "",
    special: "10k messages/month",
    special_extra: "",
    golden: false,
    premium: true,
  },
  {
    feature: "Broadcast via API",
    gp: false,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: true,
  },
];

export const commerceData = [
  {
    feature: "Practice Profile & Appointments Orders",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Payments",
    gp: "Third party payment support",
    gp_extra: "",
    ss: "Third party payment support + WhatsApp payment",
    ss_extra: "",
    poly: "Third party payment support + WhatsApp payment",
    poly_extra: "",
    special: "Third party payment support + WhatsApp payment",
    special_extra: "",
    golden: true,
    premium: false,
    feature_extra: "(Unlimited transactions/ month @ 2%)",
  },
  {
    feature: "Professional billing",
    gp: false,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
];

export const supportData = [
  {
    feature: "Guide and tutorials",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Email support",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Whatsapp support",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Onboarding support",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Conversation Expert assistance",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Bot building assistance",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
];

export const integrationData = [
  {
    feature: "Global Practice Profile",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "e-Queue",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: true,
    premium: false,
  },
  {
    feature: "Feedback collection",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Share records with your patients",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "My Doctors",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "No Ads",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: true,
    premium: false,
  },
];

export const treatmentData = [
  {
    feature: "Patient Case Study",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: true,
    premium: false,
    feature_extra:
      "(Case file-Case note+ Prescription + Lab Reports + Diagnostic Reports etc. )",
  },
  {
    feature: "Appointment confirmation",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: true,
    premium: false,
  },
  {
    feature: "Private notes",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Mentions",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Follow-up",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: true,
    premium: false,
  },
  {
    feature: "Auto resolve",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Work timings",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Auto reply",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: true,
    premium: false,
  },
  {
    feature: "Welcome message",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: true,
    premium: false,
  },
  {
    feature: "Re-engagement message",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Conversation report",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Assignment rules",
    gp: "Assign to Staff, bot",
    gp_extra: "",
    ss: "Assign to Staff, bot",
    ss_extra: "",
    poly: "Assign to Staff, bot",
    poly_extra: "",
    special: "Assign to Staff, bot",
    special_extra: "",
    golden: false,
    premium: true,
  },
  {
    feature: "Reminder",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Bulk Action",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Message tracking",
    gp: "10",
    gp_extra: "",
    ss: "No Limits",
    ss_extra: "",
    poly: "No Limits",
    poly_extra: "",
    special: "No Limits",
    special_extra: "",
    golden: false,
    premium: true,
  },
  {
    feature: "Storage Limit",
    gp: "10 GB",
    gp_extra: "",
    ss: "10 GB",
    ss_extra: "",
    poly: "20 GB",
    poly_extra: "",
    special: "20 GB",
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Free SMS",
    gp: "4000",
    gp_extra: "",
    ss: "4000",
    ss_extra: "",
    poly: "8000",
    poly_extra: "",
    special: "8000",
    special_extra: "",
    golden: false,
    premium: true,
    feature_extra: "(Only System Communication)",
  },
  {
    feature: "Advanced reports",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: true,
  },
];

export const apiData = [
  {
    feature: "Status tracking webhooks",
    gp: true,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "Incoming message webhooks",
    gp: false,
    gp_extra: "",
    ss: true,
    ss_extra: "",
    poly: true,
    poly_extra: "",
    special: true,
    special_extra: "",
    golden: false,
    premium: false,
  },
  {
    feature: "ΑΡΙ",
    gp: "100 API calls per minute",
    gp_extra: "",
    ss: "200 API calls per minute",
    ss_extra: "",
    poly: "200 API calls per minute",
    poly_extra: "",
    special: "200 API calls per minute",
    special_extra: "",
    golden: false,
    premium: false,
  },
];

export const ownerShipData = [
  {
    feature: "Government/Public Sector",
    gp: "Same",
    gp_extra: "",
    ss: "Same",
    ss_extra: "",
    poly: "Same",
    poly_extra: "",
    special: "Same",
    special_extra: "",
    golden: false,
    premium: false,
    feature_extra:
      "Central Government, State Government, Local Government, Public Sector Undertaking, Others",
  },
  {
    feature: "Private Sector",
    gp: "Same",
    gp_extra: "",
    ss: "Same",
    ss_extra: "",
    poly: "Same",
    poly_extra: "",
    special: "Same",
    special_extra: "",
    golden: false,
    premium: false,
    feature_extra:
      "Individual Proprietorship, Registered Partnership, Registered Company, Co-operative Society, Trust/Charitable, Others",
  },
];
