import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import WorkOutlineRoundedIcon from "@mui/icons-material/WorkOutlineRounded";
import { useNavigate } from "react-router-dom";

const doctorList = [
  {
    id: 1,
    name: "Dr. Subhash V. Gupta",
    qualification: "M.S-Orthopaedics",
    doctorOf: "Orthopaedics",
    speciality: "MBBS, DNB-Orthopedics and Orthopedic Surgery",
    extra: "DS-0001",
    total_experience: "10 Y, 4M",
    practice_speciality__speciality: "Allopathy",
    registration_no: "123456",
    registration_date: "DD/MM/YYYY",
    registration_authority__name: "Maharashtra Medical Concil",
    valid_upto: "DD/MM/YYYY",
  },
];
const Doctor = ({ docList }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ p: 5 }}>
      <Box display="flex" justifyContent="space-between" sx={{ p: 3 }}>
        <Typography variant="h5">Doctors</Typography>
        {/* <Button variant="contained" color="primary">
          Invite Doctor
        </Button> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          mt: 1,
        }}
      >
        {docList?.map((each) => (
          <Box
            key={each?.id}
            sx={{
              p: 1,
              border: "1px solid lightgrey",
              borderRadius: 2,
              width: 370,
              mb: 1,
              cursor: "pointer",
            }}
            onClick={() => navigate(`/book-appointment/${each?.id}`)}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Avatar
                alt={each?.first_name}
                src={
                  each?.profile_picture?.length > 0
                    ? each?.profile_picture
                    : "https://thumbs.dreamstime.com/z/head-boy-blond-hair-green-eyes-cute-kids-avatar-portrait-male-white-background-vector-cartoon-illustration-s-258877623.jpg"
                }
                sx={{
                  mr: 1,
                  width: 56,
                  height: 56,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  display="flex"
                  sx={{ backgroundColor: "#FFC590", borderRadius: 1 }}
                >
                  <WorkOutlineRoundedIcon />
                  <Typography variant="caption">
                    {each?.total_experience}
                  </Typography>
                </Box>
                <Typography variant="caption">
                  {each?.practice_speciality__speciality}
                </Typography>
              </Box>
            </Box>
            <Typography variant="subtitle1">
              <b>{`${each?.first_name?.length > 0 ? each?.first_name : ""} ${
                each?.middle_name?.length > 0 ? each?.middle_name : ""
              } ${each?.last_name?.length > 0 ? each?.last_name : ""}`}</b>
            </Typography>
            <Typography variant="subtitle1">{each?.qualification}</Typography>
            <Typography variant="subtitle1">{each?.speciality}</Typography>
            <Divider sx={{ mt: 5, mb: 1 }} />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="caption">
                RMP Reg.no. <b>{each?.registration_no}</b>
              </Typography>
              <Typography variant="caption">
                Reg. date-<b>{each?.registration_date}</b>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="caption">
                <b>{each?.registration_authority__name}</b>
              </Typography>
              <Typography variant="caption">
                Valid Upto-
                <b>{each?.valid_upto}</b>
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Doctor;
