import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightArrow from "../component/UtilComponents/RightArrow";
import LeftArrow from "../component/UtilComponents/LeftArrow";
import Banner2 from "../assest/img/banner2.jpg";
import DocGroup from "../assest/img/docGroup.jpg";
import Services from "../assest/img/service.jpg";
import Services2 from "../assest/img/service2.jpg";
import Services3 from "../assest/img/service3.jpg";
import HomeBanner2 from "../assest/img/homebanner2.png";
import HomeBanner3 from "../assest/img/homebanner3.png";
// import HomeBanner3 from "../assest/img/homebanner3.png";
// import Banner2 from "../assest/img/banner2.jpg";
import { createSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useData } from "../context/data-context";

import HospitalAvatar from "../assest/img/hospitalAvatar.jpg";
import DocAvatar from "../assest/img/DocAvatar.png";
import MyDesktop from "../component/UtilComponents/MyDesktop";
import { getUserLevel } from "../utils/getUserLevel";
import { useAuth } from "../context/auth-context";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "100%",
    height: "11px", // set the height you want here
    background: "#ffffff",
  },
});

function HomePage2() {
  const dataValue = useData();
  const authValue = useAuth();

  const isUserDoctor =
    authValue.currUser?.roles?.id == getUserLevel("IS_DOCTOR");

  const navigate = useNavigate();

  const [hospitalData, setHospitalData] = useState([]);
  const [docData, setDocData] = useState([]);

  const [email, setEmail] = useState("");

  useEffect(() => {
    fetchHospitalData();
    fetchDocData();
  }, [dataValue?.dataState?.currentAddress?.state]);

  const reasonToChooseUs = [
    {
      id: 1,
      heading: "All Speciality",
      description:
        "Your one-stop destination for finding specialized healthcare professionals for every medical need.",
      img: Banner2,
    },
    {
      id: 2,
      heading: "No Long Queue",
      description:
        "Say goodbye to long queues and waiting times with our e-queue booking system",

      img: Banner2,
    },
    {
      id: 3,
      heading: "Easy Booking Process",
      description:
        "Schedule appointments effortlessly with our user-friendly interface, ensuring prompt access to quality healthcare.",
      img: Banner2,
    },
    {
      id: 4,
      heading: "Verified Credentials",
      description:
        "Rest assured of the credibility of our doctors, as we thoroughly verify their credentials and certifications.",
      img: Banner2,
    },
  ];

  const stateId = dataValue?.dataState?.currentAddress?.state;
  const fetchHospitalData = () => {
    if (stateId) {
      axios
        .get(`/apiV1/hospital-home-slider/?state=${stateId}`, {})
        .then((res) => {
          // console.log(res.data, "res of hos");
          setHospitalData(res?.data);
        })
        .catch((err) => {
          console.log(err, "err of hospital card");
        });
    }
  };
  const fetchDocData = () => {
    if (stateId) {
      axios
        .get(`/apiV1/doctor-home-slider/?state=${stateId}`, {})
        .then((res) => {
          // console.log(res.data, "res of doc");
          setDocData(res?.data);
        })
        .catch((err) => {
          console.log(err, "error of doc card");
        });
    }
  };

  // console.log("hosp data", hospitalData);
  const FirstSlider = () => {
    const settings = {
      slidesToShow: hospitalData?.length > 1 ? 3 : 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      infinite: true,
      nextArrow: <RightArrow to="next" />,
      prevArrow: <LeftArrow to="prev" />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: hospitalData?.length > 1 ? 2 : 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 535,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
          },
        },
      ],
    };
    return (
      <Slider
        {...settings}
        style={
          {
            // border: "1px solid blue"
          }
        }
      >
        {hospitalData?.map((each) => {
          return (
            <Box
              className="p-3 text-center mt-3"
              key={each?.id}
              onClick={() => {
                navigate(`/hospital-page/${each?.slug}`);
                console.log("first", each?.hospital);
              }}
            >
              <Box
                className="shadow-sm p-3 card"
                style={{
                  cursor: "pointer",
                  border: "1px solid lightgray",
                  borderRadius: 10,
                }}
              >
                <Box className="d-flex justify-content-center">
                  <div className="" style={{ marginTop: "-46px" }}>
                    {each?.page_setup__page_logo ? (
                      <img
                        src={each?.page_setup__page_logo}
                        alt="hos_img"
                        height="100px"
                        width="100px"
                        style={{
                          borderRadius: 48,
                        }}
                      />
                    ) : (
                      <img
                        src={HospitalAvatar}
                        alt="hos_img"
                        height="100px"
                        width="100px"
                        style={{
                          borderRadius: 48,
                        }}
                      />
                    )}
                  </div>
                </Box>
                <div className=" th-24 text-center pt-3">
                  <h6>{each?.establishment_name}</h6>
                </div>
                <Typography variant="caption" className="text-center">
                  {each?.state__name}
                </Typography>
                <br />
                <Typography
                  variant="caption"
                  color="text.secondary"
                  className="text-center"
                >
                  {each?.branch}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Slider>
    );
  };

  const SecondSlider = () => {
    const settings = {
      slidesToShow: docData?.length > 1 ? 3 : 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      infinite: true,
      arrows: true,
      nextArrow: <RightArrow to="next" />,
      prevArrow: <LeftArrow to="prev" />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: docData?.length > 1 ? 2 : 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 535,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        {docData?.map((each) => {
          return (
            <div
              className="p-3 text-center  mt-3"
              style={{ border: "1px solid red" }}
              key={each?.id}
              onClick={() => {
                navigate({
                  pathname: `/book-appointment`,
                  search: `?${createSearchParams({
                    doctor: each?.slug,
                    branch: each?.branch__slug,
                  })}`,
                });
              }}
            >
              <Box
                className="shadow-sm p-3 card"
                style={{
                  cursor: "pointer",
                  border: "1px solid lightgray",
                  borderRadius: 10,
                }}
              >
                <div className="d-flex justify-content-center">
                  <div className="rounded" style={{ marginTop: "-46px" }}>
                    {each?.profile_picture ? (
                      <img
                        src={each?.profile_picture}
                        alt="hos_img"
                        height="100px"
                        width="100px"
                        style={{ borderRadius: 48 }}
                      />
                    ) : (
                      <img
                        src={DocAvatar}
                        alt="hos_img"
                        height="100px"
                        width="100px"
                        style={{ borderRadius: 48 }}
                      />
                    )}
                  </div>
                </div>
                <div className=" th-24 text-center pt-3">
                  <h6>
                    {`${each?.first_name ?? ""} ${each?.middle_name ?? ""} ${
                      each?.last_name ?? ""
                    }`}
                  </h6>
                </div>
                <Typography variant="subtitle2" className="text-center">
                  {each?.branch__state__name}
                </Typography>
                <br />
                <Typography
                  variant="caption"
                  color="text.secondary"
                  className="text-center"
                >
                  {each?.branch__establishment_name}
                </Typography>
              </Box>
            </div>
          );
        })}
      </Slider>
    );
  };

  return (
    <>
      <div
        className="th-container-1 row justify-content-center align-items-center my-5 pt-5"
        style={{
          background:
            "linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
        }}
      >
        <div className="col-md-6">
          <h1
            className="th-text-gradient"
            style={{ fontWeight: 600, fontSize: "40px" }}
          >
            Welcome to
            <br /> WellDone Health,
          </h1>
          <p>
            Say goodbye to
            <span className="th-text-gradient" style={{}}>
              {" "}
              long queues
            </span>{" "}
            and waiting times with
            <br /> our{" "}
            <span className="th-text-gradient" style={{}}>
              "e-queue booking system"
            </span>
          </p>
          <div
            className="d-flex align-items-center"
            style={{
              alignItems: "center",
              // background: "#ffffff",
              // zIndex: 10,
            }}
          >
            {isUserDoctor ? (
              <>
                <button
                  className="th-18 th-btn-primary  px-5 py-2 th-br-5 border-0"
                  onClick={() => navigate("/register-doctor")}
                  // style={{ background: "#FE0000" }}
                >
                  JOIN US
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* <MyDesktop> */}
        <div
          className="col-md-6 th-bg-img w-100 "
          style={{
            backgroundImage: `url(${HomeBanner2})`,
            height: "500px",
            backgroundSize: "contain",
          }}
        ></div>
        {/* </MyDesktop> */}
        {/* <MyMobile>
          <div
            className="col-md-6 th-bg-img w-100 animated-image"
            style={{
              backgroundImage: `url(${HomeBanner3})`,
              height: "500px",
              backgroundSize: "contain",
            }}
          ></div>
        </MyMobile> */}
      </div>

      <div className="py-3 mt-3" style={{ background: "#f5f5f554" }}>
        {hospitalData?.length > 0 ? (
          <div
            className="th-container"
            // style={{ border: "1px solid red" }}
          >
            <h2
              className="th-responsive-header my-1 "
              style={{
                // textAlign: "center",
                marginTop: "10px",
                marginBottom: "10px",
                //  border: "1px solid green"
              }}
            >
              Hospital near you
            </h2>
            {/* </Box> */}
            <FirstSlider />
          </div>
        ) : (
          ""
        )}
        {docData?.length > 0 ? (
          <div className="th-container py-5">
            <div className="">
              <h2
                className="th-responsive-header"
                style={{
                  // textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                  //  border: "1px solid green"
                }}
              >
                Doctor near you
              </h2>
            </div>
            <SecondSlider />
            {/* Commented cause there are lots of card on homepage */}
            {/* <div>
              <h2
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Why you should trust us?
                <br /> Get know about us
              </h2>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ gap: 2 }}
              >
                {reasonToChooseUs?.map((each) => (
                  <div
                    className="d-flex justify-content-center align-items-center shadow-sm card"
                    key={each?.id}
                  >
                    <img
                      src={each?.img}
                      width="100px"
                      height="100px"
                      alt="img"
                      style={{ borderRadius: "50%" }}
                    />
                    <h5>{each?.heading}</h5>
                    <p
                      style={{
                        fontSize: "10px",
                        textAlign: "center",
                        color: "#a6a1a1",
                      }}
                    >
                      {each?.description}{" "}
                    </p>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        ) : (
          ""
        )}
        <hr />
        <div className="row my-5 th-container">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <img
                  src={DocGroup}
                  alt="img1"
                  width="100%"
                  height="160px"
                  style={{
                    // borderRadius: "25px 30px 40px",
                    borderTopLeftRadius: "30px",
                  }}
                />
              </div>
              <div className="col-md-6">
                <img
                  src={Services}
                  alt="img1"
                  width="100%"
                  height="160px"
                  style={{
                    // borderRadius: "25px 30px 40px",
                    borderTopRightRadius: "30px",
                  }}
                />{" "}
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-md-6">
                <img
                  src={Services2}
                  alt="img1"
                  width="100%"
                  height="160px"
                  style={{
                    // borderRadius: "25px 30px 40px",
                    borderBottomLeftRadius: "30px",
                  }}
                />
              </div>
              <div className="col-md-6">
                <img
                  src={Services3}
                  alt="img1"
                  width="100%"
                  height="160px"
                  style={{
                    // borderRadius: "25px 30px 40px",
                    borderBottomRightRadius: "30px",
                  }}
                />{" "}
              </div>
            </div>
          </div>
          <div
            className="col-md-6 p-3"
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <h4>
              Best{" "}
              <span className="th-text-gradient" style={{ textAlign: "left" }}>
                Service's
              </span>{" "}
              for you & your Family
            </h4>
            <br />
            <p style={{ color: "#00000099", fontSize: "14px" }}>
              Your one-stop destination for finding specialized healthcare
              professionals for every medical need.
            </p>
            <br />
            <p style={{ color: "#00000099", fontSize: "14px" }}>
              Say goodbye to long queues and waiting times with our e-queue
              booking system. Schedule appointments effortlessly with our
              user-friendly interface, ensuring prompt access to quality
              healthcare.
            </p>
          </div>
        </div>
        <div
          className="d-flex flex-column th-container justify-content-center align-items-center my-3 p-3 shadow-sm "
          style={{
            background: "#f5f9fc",
          }}
        >
          <p className="th-text-gradient" style={{ fontWeight: 600 }}>
            Newsletters
          </p>
          <h3>It's time to change your life Today</h3>

          <h5>
            To know more about{" "}
            <span className="th-text-gradient" style={{ fontWeight: 600 }}>
              Welldone
            </span>{" "}
            and regular update.
          </h5>
          <div
            className="d-flex align-items-center "
            style={{ gap: 10, marginTop: "10px" }}
          >
            <CustomTextField
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
            />
            <button
              className="w-80 th-18 th-btn-primary px-3 py-2 th-br-5 h-100  border-0"
              onClick={() => {
                setEmail("");
              }}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage2;
