import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, Typography } from "@mui/material";

import {
  Button,
  Layout,
  Row,
  Col,
  Divider,
  Input,
  Form,
  DatePicker,
  Radio,
  Tag,
  Spin,
  message,
} from "antd";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
const { Content } = Layout;

const DoctorRegFormThree = () => {
  const navigate = useNavigate();
  const location = useLocation();

  console.log("state", location?.state);

  const [formThreeData, setFormThreeData] = useState(null);

  const [educationDetail, setEducationDetail] = useState([]);
  const [trainingExpDetail, setTrainingExpDetail] = useState([]);
  const [achievementsDetail, setAchievementsDetail] = useState([]);
  const [membershipDetail, setMembershipDetail] = useState([]);
  const [accomplishmentDetail, setAccomplishmentDetail] = useState([]);

  // Achievements

  const educationFormRef = useRef();
  const trainingExpFormRef = useRef();
  const achievementsFormRef = useRef();
  const awardFormRef = useRef();
  const membershipFormRef = useRef();
  const accomplishmentFormRef = useRef();

  const specialityRef = useRef();

  const langRef = useRef();
  const skillRef = useRef();

  const [specialities, setSpecialities] = useState([]);

  const [langs, setLangs] = useState([]);

  const [skills, setSkills] = useState([]);

  const [specialityform] = useForm();

  const [langForm] = useForm();

  const [skillForm] = useForm();

  const [bio, setBio] = useState("");

  const [activity, setActivity] = useState("");

  const [videoConsultation, setVideoConsultation] = useState(false);

  console.log("educationDetail", educationDetail);
  console.log("trainingExpDetail", trainingExpDetail);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  console.log(location?.state, "location state");

  const submitForm = (payload) => {
    setSubmitLoading(true);
    axios
      .post(`apiV1/registration/`, payload)
      .then((res) => {
        console.log("res", res);
        navigate("/doctor-reg/four", {
          state: { registrationId: res?.data?.id },
        });
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  return (
    <Layout className="layout">
      {contextHolder}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              p: 3,
              backgroundColor: "#BFEBFB",
            }}
          >
            <Box>
              <img
                role={"button"}
                onClick={() => {
                  navigate("/home");
                }}
                alt="Welldone Logo"
                className="my-auto"
                src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                style={{
                  height: 40,
                  // width: 20,
                }}
              />
            </Box>
            <Typography variant="caption">
              Profile of Register Medical Practionar (RMP)
            </Typography>
          </Box>
        </AppBar>
      </Box>

      <Content
        style={{
          padding: "0 5px",
        }}
      >
        <Spin
          spinning={submitLoading}
          size="large"
          direction="vertical"
          style={{
            position: "fixed",
            height: "0vh",
            top: "50vh",
          }}
        >
          {/* <Box sx={{ p: 2 }}>
            <Steps
              size="small"
              current={0}
              items={[
                {
                  title: "Account Verification",
                },
                {
                  title: "Register Medical Practionar (RMP) Details",
                },
                {
                  title: "Practice Details",
                },
                {
                  title: "Dashboard",
                },
              ]}
            />
          </Box> */}
          <div
            className="site-layout-content"
            style={{
              // background: "orange",
              // height: "100vh",
              padding: "8px",
              paddingBottom: "16px",
            }}
          >
            <Divider />
            <Row>
              <Col span={8}>
                <Typography>Add Speciality</Typography>
              </Col>
              <Col span={6}>
                <Box>
                  <Typography>Speciality Treatment Areas *</Typography>
                  <Box>
                    <Form
                      form={specialityform}
                      style={{ display: "flex" }}
                      layout="vertical"
                    >
                      <Form.Item
                        name="specialityName"
                        // label="Speciality Treatment Areas"
                      >
                        <Input size="small" ref={specialityRef} />
                      </Form.Item>

                      <Button
                        type="primary"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          console.log(
                            "specialityRef.current",
                            specialityRef.current.input.value
                          );
                          if (
                            specialityRef.current.input.value.trim().length ===
                            0
                          ) {
                            return;
                          }
                          setSpecialities((p) => [
                            ...p,
                            {
                              value: specialityRef.current.input.value,
                              id: Math.random(),
                            },
                          ]);
                          specialityform.resetFields(["specialityName"]);
                        }}
                      >
                        Add
                      </Button>
                    </Form>
                  </Box>
                  <Box
                    sx={{
                      p: 1,
                      display: "flex",
                      // maxWidth: "500px",
                      flexWrap: "wrap",
                      gap: "0.5em",
                    }}
                  >
                    {/* <Space wrap direction="vertical" size="middle"> */}
                    {specialities?.map((item, index) => {
                      return (
                        <Tag
                          key={item?.id}
                          // key={index}
                          color="#7594D1"
                          closable
                          onClose={(e) => {
                            console.log(e);
                            setSpecialities((p) =>
                              p.filter((i) => i?.id !== item?.id)
                            );
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {item?.value}
                        </Tag>
                      );
                    })}
                    {/* </Space> */}
                  </Box>
                </Box>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    p: 1,
                    width: "100%",
                    height: "100%",
                    // border: "2px solid green",
                    // background: "#bada55",
                  }}
                >
                  {/* <Space direction="vertical" align="start"> */}
                  <Box sx={{ width: "95vw" }}>
                    <Typography>Education and Background*</Typography>
                    <Form
                      ref={educationFormRef}
                      layout="vertical"
                      onFinish={(value) => {
                        console.log("edu value", value);

                        if (
                          Object.values(value).some(
                            (a) => a == false || a == undefined || a == ""
                          )
                        ) {
                          return;
                        }
                        let v = value;
                        v.id = Math.random();
                        setEducationDetail([...educationDetail, v]);
                        educationFormRef.current.resetFields();
                      }}
                    >
                      <Row gutter={24} align="middle">
                        <Col md={4}>
                          <Form.Item
                            label="Qualification"
                            name="qualification"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 24 }}
                            colon={false}
                          >
                            <Input
                            // placeholder="Enter qualification"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Faculty"
                            name="faculity"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            label="Educational institute/ University"
                            name="institute"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="City"
                            name="city"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}trainingExpDetail
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Passing Year"
                            name="passing_year"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label=" "
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              htmlType="submit"
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Box>

                  <Box sx={{ width: "95vw" }}>
                    {educationDetail?.map((item, index) => {
                      return (
                        <Row
                          key={item?.id}
                          gutter={24}
                          align="middle"
                          style={{
                            border: "2px solid gray",
                            borderRadius: 10,
                            padding: 10,
                            margin: 5,
                            background: "white",
                          }}
                        >
                          <Col md={4}>
                            <div className="w-100">{item?.qualification}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.faculity}</div>
                          </Col>
                          <Col md={6}>
                            <div className="w-100">{item?.institute}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.city}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.passing_year}</div>
                          </Col>
                          <Col md={2}>
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              onClick={() => {
                                setEducationDetail(
                                  educationDetail?.filter(
                                    (e) => e.id !== item?.id
                                  )
                                );
                              }}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>

                  <hr />

                  {/* </Space> */}
                </Box>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    p: 1,
                    width: "100%",
                    height: "100%",
                    // border: "2px solid green",
                    // background: "#bada55",
                  }}
                >
                  {/* <Space direction="vertical" align="start"> */}
                  <Box sx={{ width: "95vw" }}>
                    <Typography>Training And Experience with Field*</Typography>
                    <Form
                      ref={trainingExpFormRef}
                      layout="vertical"
                      onFinish={(value) => {
                        if (
                          Object.values(value).some(
                            (a) => a == false || a == undefined || a == ""
                          )
                        ) {
                          return;
                        }
                        let v = value;
                        v.id = Math.random();
                        setTrainingExpDetail([...trainingExpDetail, v]);
                        trainingExpFormRef.current.resetFields();
                      }}
                    >
                      <Row gutter={24} align="middle">
                        <Col md={4}>
                          <Form.Item
                            label="Job Title"
                            name="job_title"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 24 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Practice Type"
                            name="practice_type"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Practice Speciality"
                            name="practice_spaciality"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Hospital Name"
                            name="hospital_name"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label="City"
                            name="city"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          {/* <div>Duration of Experience </div> */}
                          <Form.Item
                            label="Duration of Experience"
                            name="expDuration"
                          >
                            <DatePicker.RangePicker picker="month" />
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label=" "
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              htmlType="submit"
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Box>

                  <Box sx={{ width: "95vw" }}>
                    {trainingExpDetail?.map((item, index) => {
                      return (
                        <Row
                          gutter={24}
                          align="middle"
                          style={{
                            border: "2px solid gray",
                            borderRadius: 10,
                            padding: 10,
                            margin: 5,
                            background: "white",
                          }}
                        >
                          <Col md={4}>
                            <div className="w-100">{item?.job_title}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.practice_type}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">
                              {item?.practice_spaciality}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.hospital_name}</div>
                          </Col>
                          <Col md={2}>
                            <div className="w-100">{item?.city}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">
                              {moment(item?.expDuration[0]?.$d).format(
                                "MMM YYYY"
                              )}{" "}
                              -{" "}
                              {moment(item?.expDuration[1]?.$d).format(
                                "MMM YYYY"
                              )}
                            </div>
                          </Col>
                          <Col md={2}>
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              onClick={() => {
                                setTrainingExpDetail(
                                  trainingExpDetail?.filter(
                                    (e) => e.id !== item?.id
                                  )
                                );
                              }}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>

                  <hr />

                  {/* </Space> */}
                </Box>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    p: 1,
                    width: "100%",
                    height: "100%",
                    // border: "2px solid green",
                    // background: "#bada55",
                  }}
                >
                  {/* <Space direction="vertical" align="start"> */}
                  <Box sx={{ width: "95vw" }}>
                    <Typography>Honours and Achievements </Typography>
                    <Form
                      ref={achievementsFormRef}
                      layout="vertical"
                      onFinish={(value) => {
                        if (
                          Object.values(value).some(
                            (a) => a == false || a == undefined || a == ""
                          )
                        ) {
                          return;
                        }
                        let v = value;
                        v.id = Math.random();
                        setAchievementsDetail([...achievementsDetail, v]);
                        achievementsFormRef.current.resetFields();
                      }}
                    >
                      <Row gutter={24} align="middle">
                        <Col md={4}>
                          <Form.Item
                            label="Title"
                            name="title"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 24 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Type"
                            name="achievement_type"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Speciality"
                            name="speciality"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Name of Organization"
                            name="organisation"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label="City"
                            name="city"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          {/* <div>Duration of Experience </div> */}
                          <Form.Item
                            label="Duration of Experience"
                            name="expDuration"
                          >
                            <DatePicker.RangePicker picker="month" />
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label=" "
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              htmlType="submit"
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Box>

                  <Box sx={{ width: "95vw" }}>
                    {achievementsDetail?.map((item, index) => {
                      return (
                        <Row
                          gutter={24}
                          align="middle"
                          style={{
                            border: "2px solid gray",
                            borderRadius: 10,
                            padding: 10,
                            margin: 5,
                            background: "white",
                          }}
                        >
                          <Col md={4}>
                            <div className="w-100">{item?.title}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">
                              {item?.achievement_type}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.speciality}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.organisation}</div>
                          </Col>
                          <Col md={2}>
                            <div className="w-100">{item?.city}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">
                              {moment(item?.expDuration[0]?.$d).format(
                                "MMM YYYY"
                              )}{" "}
                              -{" "}
                              {moment(item?.expDuration[1]?.$d).format(
                                "MMM YYYY"
                              )}
                            </div>
                          </Col>
                          <Col md={2}>
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              onClick={() => {
                                setAchievementsDetail(
                                  achievementsDetail?.filter(
                                    (e) => e.id !== item?.id
                                  )
                                );
                              }}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>

                  <hr />

                  {/* </Space> */}
                </Box>
              </Col>
            </Row>
            <Divider />
            {/* <Space direction="vertical" align="start"> */}
            {/* <Row>
            <Col span={24}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  p: 1,
                  width: "100%",
                  height: "100%",
                  // border: "2px solid green",
                  // background: "#bada55",
                }}
              >
                <Box sx={{ width: "95vw" }}>
                  <Typography>Award</Typography>
                  <Form
                    ref={awardFormRef}
                    layout="vertical"
                    onFinish={(value) => {
                       if (
                        Object.values(value).some(
                          (a) => a == false || a == undefined || a == ""
                        )
                      ) {
                        return;
                      }
                      let v = value;
                      v.id = Math.random();
                      setAwardDetail([...awardDetail, v]);
                      awardFormRef.current.resetFields();
                    }}
                  >
                    <Row gutter={24} align="middle">
                      <Col md={4}>
                        <Form.Item
                          label="Title"
                          name="job_title"
                          // labelCol={{ span: 24 }}
                          // wrapperCol={{ span: 24 }}
                          colon={false}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Form.Item
                          label="Type"
                          name="practice_type"
                          // labelCol={{ span: 24 }}
                          // wrapperCol={{ span: 8 }}
                          colon={false}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Form.Item
                          label="Speciality"
                          name="practice_spaciality"
                          // labelCol={{ span: 24 }}
                          // wrapperCol={{ span: 8 }}
                          colon={false}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Form.Item
                          label="Name of Organization"
                          name="hospital_name"
                          // labelCol={{ span: 24 }}
                          // wrapperCol={{ span: 8 }}
                          colon={false}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col md={2}>
                        <Form.Item
                          label="City"
                          name="city"
                          // labelCol={{ span: 24 }}
                          // wrapperCol={{ span: 8 }}
                          colon={false}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                       <div>Duration of Experience </div>
                        <Form.Item
                          label="Duration of Experience"
                          name="expDuration"
                        >
                          <DatePicker.RangePicker picker="month" />
                        </Form.Item>
                      </Col>
                      <Col md={2}>
                        <Form.Item
                          label=" "
                          // labelCol={{ span: 24 }}
                          // wrapperCol={{ span: 8 }}
                          colon={false}
                        >
                          <Button
                            type="primary"
                            style={{ marginTop: -5 }}
                            htmlType="submit"
                          >
                            Add
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Box>

                <Box sx={{ width: "95vw" }}>
                  {awardDetail?.map((item, index) => {
                    return (
                      <Row
                        gutter={24}
                        align="middle"
                        style={{
                          border: "2px solid gray",
                          borderRadius: 10,
                          padding: 10,
                          margin: 5,
                          background: "white",
                        }}
                      >
                        <Col md={4}>
                          <div className="w-100">{item?.title}</div>
                        </Col>
                        <Col md={4}>
                          <div className="w-100">{item?.practice_type}</div>
                        </Col>
                        <Col md={4}>
                          <div className="w-100">
                            {item?.practice_spaciality}
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="w-100">{item?.hospital_name}</div>
                        </Col>
                        <Col md={2}>
                          <div className="w-100">{item?.city}</div>
                        </Col>
                        <Col md={4}>
                          <div className="w-100">
                            {moment(item?.expDuration[0]?.$d).format(
                              "MMM YYYY"
                            )}{" "}
                            -{" "}
                            {moment(item?.expDuration[1]?.$d).format(
                              "MMM YYYY"
                            )}
                          </div>
                        </Col>
                        <Col md={2}>
                          <Button
                            type="primary"
                            style={{ marginTop: -5 }}
                            onClick={() => {
                              setAwardDetail(
                                awardDetail?.filter((e) => e.id !== item?.id)
                              );
                            }}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                </Box>

                <hr />

              </Box>
            </Col>
          </Row> 
          */}
            {/* </Space> */}
            {/* <Divider /> */}
            <Row>
              <Col span={24}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    p: 1,
                    width: "100%",
                    height: "100%",
                    // border: "2px solid green",
                    // background: "#bada55",
                  }}
                >
                  {/* <Space direction="vertical" align="start"> */}
                  <Box sx={{ width: "95vw" }}>
                    <Typography>Membership</Typography>
                    <Form
                      ref={membershipFormRef}
                      layout="vertical"
                      onFinish={(value) => {
                        if (
                          Object.values(value).some(
                            (a) => a == false || a == undefined || a == ""
                          )
                        ) {
                          return;
                        }
                        let v = value;
                        v.id = Math.random();
                        setMembershipDetail([...membershipDetail, v]);
                        membershipFormRef.current.resetFields();
                      }}
                    >
                      <Row gutter={24} align="middle">
                        <Col md={4}>
                          <Form.Item
                            label="Title"
                            name="title"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 24 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Type"
                            name="membership_type"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Speciality"
                            name="speciality"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Name of Organization"
                            name="organisation"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label="City"
                            name="city"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          {/* <div>Duration of Experience </div> */}
                          <Form.Item
                            label="Duration of Experience"
                            name="expDuration"
                          >
                            <DatePicker.RangePicker picker="month" />
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label=" "
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              htmlType="submit"
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Box>

                  <Box sx={{ width: "95vw" }}>
                    {membershipDetail?.map((item, index) => {
                      return (
                        <Row
                          gutter={24}
                          align="middle"
                          style={{
                            border: "2px solid gray",
                            borderRadius: 10,
                            padding: 10,
                            margin: 5,
                            background: "white",
                          }}
                        >
                          <Col md={4}>
                            <div className="w-100">{item?.title}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.membership_type}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.speciality}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.organisation}</div>
                          </Col>
                          <Col md={2}>
                            <div className="w-100">{item?.city}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">
                              {moment(item?.expDuration[0]?.$d).format(
                                "MMM YYYY"
                              )}{" "}
                              -{" "}
                              {moment(item?.expDuration[1]?.$d).format(
                                "MMM YYYY"
                              )}
                            </div>
                          </Col>
                          <Col md={2}>
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              onClick={() => {
                                setMembershipDetail(
                                  membershipDetail?.filter(
                                    (e) => e.id !== item?.id
                                  )
                                );
                              }}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>

                  <hr />

                  {/* </Space> */}
                </Box>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    p: 1,
                    width: "100%",
                    height: "100%",
                    // border: "2px solid green",
                    // background: "#bada55",
                  }}
                >
                  <Box sx={{ width: "95vw" }}>
                    <Typography>
                      Literary Artistic & Scientific Accomplishments
                    </Typography>
                    <Form
                      ref={accomplishmentFormRef}
                      layout="vertical"
                      onFinish={(value) => {
                        if (
                          Object.values(value).some(
                            (a) => a == false || a == undefined || a == ""
                          )
                        ) {
                          return;
                        }
                        console.log("accomplishment Detail", value);
                        let v = value;
                        v.id = Math.random();
                        setAccomplishmentDetail([...accomplishmentDetail, v]);
                        accomplishmentFormRef.current.resetFields();
                      }}
                    >
                      <Row gutter={24} align="middle">
                        <Col md={4}>
                          <Form.Item
                            label="Title"
                            name="title"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 24 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Type"
                            name="achievement_type"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Speciality"
                            name="speciality"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Name of Organization"
                            name="organisation"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label="City"
                            name="city"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Form.Item
                            label="Duration of Experience"
                            name="expDuration"
                          >
                            <DatePicker.RangePicker picker="month" />
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label=" "
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              htmlType="submit"
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Box>

                  <Box sx={{ width: "95vw" }}>
                    {accomplishmentDetail?.map((item, index) => {
                      return (
                        <Row
                          gutter={24}
                          align="middle"
                          style={{
                            border: "2px solid gray",
                            borderRadius: 10,
                            padding: 10,
                            margin: 5,
                            background: "white",
                          }}
                        >
                          <Col md={4}>
                            <div className="w-100">{item?.title}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">
                              {item?.achievement_type}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.speciality}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">{item?.organisation}</div>
                          </Col>
                          <Col md={2}>
                            <div className="w-100">{item?.city}</div>
                          </Col>
                          <Col md={4}>
                            <div className="w-100">
                              {moment(item?.expDuration[0]?.$d).format(
                                "MMM YYYY"
                              )}{" "}
                              -{" "}
                              {moment(item?.expDuration[1]?.$d).format(
                                "MMM YYYY"
                              )}
                            </div>
                          </Col>
                          <Col md={2}>
                            <Button
                              type="primary"
                              style={{ marginTop: -5 }}
                              onClick={() => {
                                setAccomplishmentDetail(
                                  accomplishmentDetail?.filter(
                                    (e) => e.id !== item?.id
                                  )
                                );
                              }}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </Box>

                  <hr />
                </Box>
              </Col>
            </Row>
            {/* </Space> */}
            <Box
              sx={{
                width: "100%",
                diplay: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="body1">
                Any listed Special Achievements
              </Typography>
              <Form.Item>
                <Radio.Group>
                  <Radio value="yes">Yes </Radio>
                  <Radio value="no"> No</Radio>
                </Radio.Group>
              </Form.Item>

              <Row>
                <Col span={6}>
                  <Box>
                    <Typography>
                      What is your language proficiency level?
                    </Typography>
                    <Box>
                      <Form
                        form={langForm}
                        style={{ display: "flex" }}
                        layout="vertical"
                      >
                        <Form.Item
                          name="langName"
                          // label="Speciality Treatment Areas"
                        >
                          <Input size="small" ref={langRef} />
                        </Form.Item>

                        <Button
                          type="primary"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            if (
                              langRef.current.input.value.trim().length === 0
                            ) {
                              return;
                            }
                            console.log(
                              "langRef.current",
                              langRef.current.input.value
                            );
                            setLangs((p) => [
                              ...p,
                              {
                                value: langRef.current.input.value,
                                id: Math.random(),
                              },
                            ]);
                            langForm.resetFields(["langName"]);
                          }}
                        >
                          Add
                        </Button>
                      </Form>
                    </Box>
                    <Box
                      sx={{
                        p: 1,
                        display: "flex",
                        // maxWidth: "500px",
                        flexWrap: "wrap",
                        gap: "0.5em",
                      }}
                    >
                      {/* <Space wrap direction="vertical" size="middle"> */}
                      {langs?.map((item, index) => {
                        return (
                          <Tag
                            key={item?.id}
                            // key={index}
                            color="#7594D1"
                            closable
                            onClose={(e) => {
                              console.log(e);
                              setLangs((p) =>
                                p.filter((i) => i?.id !== item?.id)
                              );
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {item?.value}
                          </Tag>
                        );
                      })}
                      {/* </Space> */}
                    </Box>
                  </Box>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Typography variant="body1">
                    Are you comfortable working in a Video consultation /
                    Telemedicine setting?
                  </Typography>
                  <Form.Item>
                    <Radio.Group>
                      <Radio
                        value="yes"
                        onClick={() => {
                          setVideoConsultation(true);
                        }}
                      >
                        Yes{" "}
                      </Radio>
                      <Radio
                        value="no"
                        onClick={() => {
                          setVideoConsultation(false);
                        }}
                      >
                        {" "}
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Typography>Your Short Biography</Typography>
                  <TextArea
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    placeholder="write here"
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Typography>Social cultural Activites (if any)</Typography>
                  <TextArea
                    value={activity}
                    onChange={(e) => setActivity(e.target.value)}
                    name="activity"
                    placeholder="write here"
                    autoSize={{
                      minRows: 2,
                      maxRows: 3,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Box>
                    <Typography>Add Skill Areas</Typography>
                    <Typography variant="caption">
                      Add other areas and treatment keywords to make your
                      specialty more visible to the right patient (select up to
                      10)
                    </Typography>
                    <Box>
                      <Form
                        form={skillForm}
                        style={{ display: "flex" }}
                        layout="vertical"
                      >
                        <Form.Item
                          name="skillName"
                          // label="Speciality Treatment Areas"
                        >
                          <Input size="small" ref={skillRef} />
                        </Form.Item>
                        <Button
                          type="primary"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            if (
                              skillRef.current.input.value.trim().length === 0
                            ) {
                              return;
                            }
                            console.log(
                              "skillRef.current",
                              skillRef.current.input.value
                            );
                            setSkills((p) => [
                              ...p,
                              {
                                value: skillRef.current.input.value,
                                id: Math.random(),
                              },
                            ]);
                            skillForm.resetFields(["skillName"]);
                          }}
                        >
                          Add
                        </Button>
                      </Form>
                    </Box>

                    <Box
                      sx={{
                        pb: 1,
                        display: "flex",
                        // maxWidth: "500px",
                        flexWrap: "wrap",
                        gap: "0.5em",
                      }}
                    >
                      {/* <Space wrap direction="vertical" size="middle"> */}
                      {skills?.map((item, index) => {
                        return (
                          <Tag
                            key={item?.id}
                            // key={index}
                            color="#7594D1"
                            closable
                            onClose={(e) => {
                              console.log(e);
                              setSkills((p) =>
                                p.filter((i) => i?.id !== item?.id)
                              );
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {item?.value}
                          </Tag>
                        );
                      })}
                      {/* </Space> */}
                    </Box>
                  </Box>
                </Col>
              </Row>
              <Box
                sx={{
                  display: "flex",
                  width: "100vw",
                  justifyContent: "start",
                }}
              >
                <Button
                  type="primary"
                  size={"large"}
                  style={{ marginTop: 4 }}
                  onClick={() => {
                    if (specialities?.length <= 0) {
                      messageApi.open({
                        type: "error",
                        content: "Please enter atleast one speciality",
                      });

                      specialityform.setFields([
                        {
                          name: "specialityName",
                          errors: ["Please enter atleast one speciality"],
                        },
                      ]);

                      return;
                    }

                    if (educationDetail?.length <= 0) {
                      messageApi.open({
                        type: "error",
                        content: "Please add Education Detail",
                      });

                      educationFormRef.current.setFields([
                        {
                          name: "qualification",
                          errors: ["Please enter atleast one Education Detail"],
                        },
                      ]);

                      return;
                    }

                    if (trainingExpDetail?.length <= 0) {
                      messageApi.open({
                        type: "error",
                        content: "Please add Training or Experience Deatil",
                      });

                      trainingExpFormRef.current.setFields([
                        {
                          name: "job_title",
                          errors: [
                            "Please add atleast one Training or Experience Deatil",
                          ],
                        },
                      ]);

                      return;
                    }

                    let formThreeData = {
                      education_and_background: educationDetail,
                      experience: trainingExpDetail,
                      achievement: achievementsDetail,
                      membership: membershipDetail,
                      accomplishments: accomplishmentDetail,
                      language: langs?.map((i) => ({
                        language: i.value,
                      })),
                      skills: langs?.map((i) => ({
                        skill: i.value,
                      })),
                      speciality_treatment: specialities
                        ?.map((i) => i.value)
                        .toString(),
                    };
                    setFormThreeData(formThreeData);

                    submitForm({
                      ...location?.state?.formOneData,
                      ...location?.state?.formTwoData,
                      ...formThreeData,
                      branch: [location?.state?.branch?.id],
                    });
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </div>
        </Spin>
      </Content>
    </Layout>
  );
};

export default DoctorRegFormThree;
