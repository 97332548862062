import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Typography } from "@mui/material";

import { Button, Layout, Steps } from "antd";
const { Content } = Layout;

const DoctorRegFormFive = () => {
  const navigate = useNavigate();

  return (
    <Layout className="layout">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              p: 3,
              backgroundColor: "#BFEBFB",
            }}
          >
            <Box>
              <img
                role={"button"}
                onClick={() => {
                  navigate("/home");
                }}
                alt="Welldone Logo"
                className="my-auto"
                src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                style={{
                  height: 40,
                  // width: 20,
                }}
              />
            </Box>
            {/* <Typography variant="caption">
            Profile of Register Medical Practionar (RMP)
          </Typography> */}
          </Box>
        </AppBar>
      </Box>
      <Content
        style={{
          padding: "0 5px",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Steps
            size="small"
            current={0}
            items={[
              {
                title: "Account Verification",
              },
              {
                title: "Register Medical Practionar (RMP) Details",
              },
              {
                title: "Practice Details",
              },
              {
                title: "Dashboard",
              },
            ]}
          />
        </Box>
        <div
          className="site-layout-content"
          style={{
            // background: "orange",
            height: "80vh",
            padding: "2px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ p: 2, m: 2 }}>
              <Typography variant="h5" color="#28328C">
                Your account has been successfully setup!
              </Typography>
            </Box>

            <Box
              sx={{
                p: 2,
                m: 2,
                display: "flex",
                flexDirection: "row",
                gap: 1,
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  navigate("/");
                }}
              >
                Go Home
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  navigate("/branch-doctor");
                }}
              >
                See Branches
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Go to Dashboard
              </Button>
            </Box>
          </Box>
        </div>
      </Content>
    </Layout>
  );
};

export default DoctorRegFormFive;
