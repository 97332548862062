import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Typewriter from "./TypeWriter";
import { Button, FloatButton, Input, message } from "antd";
import Logo from "../../assest/img/logoW.png";
import MyDesktop from "../../component/UtilComponents/MyDesktop";
import MyMobile from "../../component/UtilComponents/MyMobile";
import { SearchOutlined } from "@ant-design/icons";
import Specialities from "./Specialities";
import About from "./About";
import Hospitals from "./Hospitals";
import axios from "axios";
import Doctors from "./Doctors";
import { DebounceSelect } from "../../component/Layout/Header";
import { useAuth } from "../../context/auth-context";
import { useData } from "../../context/data-context";
import { LocationOnOutlined } from "@mui/icons-material";
import AddressDialog from "../AddressDialog";
const NewHome = () => {
  const navigate = useNavigate();
  const [hospitalData, setHospitalData] = useState([]);
  const [docData, setDocData] = useState([]);
  const [value, setValue] = useState(null);
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [input, setInput] = useState("");
  const authValue = useAuth();
  const dataValue = useData();
  const address = dataValue?.dataState?.currentAddress;
  useEffect(() => {
    fetchHospitalData();
    fetchDocData();
  }, []);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const fetchHospitalData = () => {
    axios
      .get(`/apiV1/hospital-home-slider/`, {})
      .then((res) => {
        setHospitalData(
          res?.data?.sort((a, b) => b?.total_doctors - a?.total_doctors)
        );
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  };
  const fetchDocData = () => {
    axios
      .get(`/apiV1/doctor-home-slider/`, {})
      .then((res) => {
        setDocData(res?.data?.reverse());
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  };

  console.log(authValue, "shbuwh");

  async function fetchUserList(searchTest) {
    if (searchTest?.length > 0) {
      console.log("fetching user", searchTest);
      setInput(searchTest);
      return axios(`apiV1/home-search/?search=${searchTest}`).then((res) => {
        console.log(res, "res");
        return res.data?.map((each) => ({
          label:
            each?.establishment_name ||
            `${each?.first_name ?? ""} ${each?.middle_name ?? ""} ${
              each?.last_name ?? ""
            }`,
          value: each?.establishment_name || `${each?.first_name ?? ""}`,
        }));
      });
    }
  }

  return (
    <React.Fragment>
      {/* MAIN SECTION */}
      <div className=" th-bg-img w-100 th-image-main th-home">
        {/* <MyDesktop>
          <div className="th-container th-header-blur py-2 d-flex justify-content-between align-items-center w-100  w-100 ">
            <div className="">
              <img
                role={"button"}
                onClick={() => {
                  navigate("/home");
                }}
                alt="Welldone Logo"
                className="my-auto"
                src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                style={{
                  height: 50,
                }}
              />
            </div>
            <div className="" style={{ width: "35%", alignSelf: "center" }}>
              <DebounceSelect
                value={value}
                placeholder="Search for doctors nearby"
                className="mr-5 th-borderless-input w-100"
                fetchOptions={fetchUserList}
                onChange={(newValue, value) => {
                  console.log(newValue, "newValue");
                  navigate("/search-listing", {
                    state: value,
                  });
                  setValue(newValue);
                }}
              />
            </div>
            {authValue?.currUser ? (
              <>
                {authValue?.currUser?.roles?.id === 4 ? (
                  <>
                    <a
                      href="https://clinic.welldonehealth.in/"
                      target="__blank"
                    >
                      <div
                        className="th-secondary"
                        style={{
                          fontSize: "16px",
                          cursor: "pointer",
                          fontWeight: 600,
                        }}
                        // onClick={() => navigate("/dashboard")}
                      >
                        Dashboard
                      </div>
                    </a>

                    <div
                      className="th-secondary"
                      style={{
                        fontSize: "16px",
                        cursor: "pointer",
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        navigate("/history");
                      }}
                    >
                      My appointments
                    </div>
                    <div
                      className="th-secondary"
                      style={{
                        fontSize: "16px",
                        cursor: "pointer",
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        authValue?.token
                          ? authValue?.logoutHandler()
                          : navigate("/login");
                      }}
                    >
                      Logout<i className="fas fa-sign-out-alt pl-2"></i>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div
                className="th-pointer th-secondary"
                onClick={() => {
                  navigate("/login");
                }}
                style={{ fontSize: "18px" }}
              >
                Login <i className="fas fa-sign-in-alt pl-1"></i>
              </div>
            )}
          </div>
        </MyDesktop>
        <MyMobile>
          <div className=" py-4 d-flex align-items-center justify-content-between">
            <div className="">
              <img
                role={"button"}
                onClick={() => {
                  navigate("/home");
                }}
                alt="Welldone Logo"
                className="my-auto"
                src={Logo}
                style={{
                  height: 40,
                }}
              />
            </div>
            <DebounceSelect
              value={value}
              placeholder="Search for doctors nearby"
              className="th-borderless-input"
              fetchOptions={fetchUserList}
              onChange={(newValue, value) => {
                console.log(newValue, "newValue");
                navigate("/search-listing", {
                  state: value,
                });
                setValue(newValue);
              }}
              style={{ maxWidth: "65%" }}
            />
            <div className="">Login</div>
          </div>
        </MyMobile> */}

        <div className="d-flex flex-column justify-content-center h-75 th-container">
          <div className="" style={{ color: "#444" }}>
            <h1> Say goodbye to</h1>
            <Typewriter textArray={["long queues. ", "waiting times. "]} />
          </div>
          <div className="">
            <button
              className="th-btn-primary px-4 py-1 mt-2"
              style={{ backgroundColor: "#01549A", fontSize: "20px" }}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
      {/* MAIN SECTION */}
      <div className="th-container  bg-white">
        <Specialities />
      </div>
      <div className="th-container">
        <About />
      </div>
      <div className="th-container  bg-white">
        <Hospitals hospitalData={hospitalData} />
      </div>
      <div className="th-container pb-5">
        <Doctors docData={docData} />
      </div>
      <AddressDialog
        showAddressDialog={showAddressDialog}
        setShowAddressDialog={setShowAddressDialog}
      />
      {/* {showButton && (
        <FloatButton
          style={{
            height: "60px",
            width: "60px",
            background: "#01549a !important",
          }}
          type="primary"
          icon={<i className="fas fa-chevron-up"></i>}
          onClick={scrollToTop}
        />
      )} */}
    </React.Fragment>
  );
};

export default NewHome;
