import React from "react";

const ShippingAndReturnPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ padding: "20px" }}>
      <h4>Charges (Pricing), Shipping and Return Policy</h4>
      <ol type="A">
        <li>
          Shipping
          <ol>
            <li>
              Delivery charges
              <ol type="a">
                <li>Delivery charges vary from seller to seller.</li>
                <li>
                  Sellers incur relatively high shipping costs on low-priced
                  items. In such cases, charging a nominal delivery charge helps
                  them cover the logistics costs. Please check your order
                  summary to understand delivery charges for individual
                  products.
                </li>
                <li>
                  For products listed as Welldone, the order value is Rs. If
                  less than 500 a delivery charge of Rs 40 per item may be
                  applied. So Rs. Orders of 500 or more are delivered free of
                  charge.
                </li>
              </ol>
            </li>
            <li>
              Delivery timeline
              <p>
                It is possible that the seller or our courier partners will have
                a holiday between the day you place your order and the delivery
                date, based on the timeline shown on the product page. In this
                case, we add one day to the estimated date. Some courier
                partners and vendors do not work on Sundays and this depends on
                delivery dates.
              </p>
            </li>
            <li>
              Estimated delivery time
              <p>
                Sellers generally purchase and ship items within the time
                specified on the product page. Business days exclude public
                holidays and Sundays. Estimated delivery time depends factors
                <ul>
                  <li>The seller offers the product</li>
                  <li>Availability of the product at the seller</li>
                  <li>
                    The destination you want the order shipped to and the
                    seller's location.
                  </li>
                </ul>
              </p>
            </li>
            <li>
              Hidden costs
              <ol type="a">
                <li>
                  There are no hidden charges when you shop on Welldone. List
                  prices are final and inclusive. You will pay the price you see
                  on the product page.
                </li>
                <li>
                  Delivery charges are not hidden charges and are subject to
                  additional charges (if any) depending on the seller's shipping
                  policy.
                </li>
              </ol>
            </li>
            <li>
              Estimated delivery time varies for each seller
              <ol type="a">
                <li>
                  You may have noticed different estimated delivery times for
                  sellers of the product you are interested in. Delivery times
                  are affected by product availability, seller's geographic
                  location, your shipping destination and courier partner's
                  delivery time to your location.
                </li>
                <li>
                  Please enter your default pin code on the product page (you
                  don't need to enter it every time) to know more accurate
                  delivery times on the product page itself.
                </li>
              </ol>
            </li>
            <li>
              Shipping Area
              <ol type="a">
                <li>
                  Please enter your pincode on the product page to know if the
                  product can be delivered to your location (you don't need to
                  enter it every time).
                </li>
                <li>
                  If you have not provided your pincode up to the checkout
                  stage, the pincode from your shipping address will be used to
                  check serviceability.
                </li>
                <li>
                  It depends on whether your location can be serviced or not
                  <ul>
                    <li>Whether the seller ships to your location or not</li>
                    <li>
                      Legal restrictions, if any, on shipping certain products
                      to your location
                    </li>
                    <li>
                      Availability of reliable courier partners at your location
                    </li>
                  </ul>
                  <li>
                    Sometimes sellers choose not to ship to certain locations.
                    It totally depends on their decision.
                  </li>
                </li>
              </ol>
            </li>
            <li>
              Cash on Delivery (CoD)/ Pay on Delivery (PoD)
              <ol type="a">
                <li>
                  Availability of CoD/PoD depends on our courier partner's
                  ability to service your location to accept cash on delivery as
                  payment.
                </li>
                <li>
                  Our courier partners have limits on cash on delivery depending
                  on the destination and the value of your order may exceed this
                  limit. Please enter your Pin code on the product page to check
                  if CoD/PoD is available in your location.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Return
          <ol>
            <li>
              Return & pick-up
              <ol type="a">
                <li>
                  Returns are easy. Contact us to initiate a return. Once you
                  initiate a refund you will receive a call explaining the
                  process.
                </li>
                <li>
                  Logistics will facilitate pick up of the goods wherever
                  possible. If pick-up cannot be arranged by Welldone, you may
                  return the item via a third-party courier service. The return
                  fee is paid by the seller.
                </li>
              </ol>
            </li>
            <li>
              Delivery errors
              <p>
                If the product has not been delivered and you have received the
                delivery confirmation email/SMS, inform the seller to
                investigate the issue within 7 days from the date of delivery
                confirmation.
              </p>
            </li>
          </ol>
        </li>
        <li>
          Different tags
          <ol type="a">
            <li>
              'in stock'
              <p>
                For items listed as "in stock", sellers will quote a delivery
                time based on your location zip code (typically 2-3 business
                days, 4-5 business days or 4-6 business days in areas where
                standard courier service is available). For other areas, orders
                will be shipped by Indian Postal Service by registered post
                which may take 1-2 weeks depending on location.
              </p>
            </li>
            <li>
              'available'
              <p>
                The seller may not have the item in stock but can purchase it
                when an order is placed for the item. Delivery time will depend
                on estimated purchase time and estimated shipping time to your
                location.
              </p>
            </li>
            <li>
              'Pre Order' or 'Upcoming'
              <p>
                Such items are expected to be released soon and can be
                pre-booked for you. The item will be shipped to you on the
                official release launch day and will reach you within 2 to 6
                business days. Preorder period varies by item. Once known, the
                time and date of publication is mentioned.
              </p>
            </li>
            <li>
              'out of stock'
              <p>
                Currently, the item is not available for sale. Use the 'Notify
                Me' feature to be notified when it's available for purchase.
              </p>
            </li>
            <li>
              'Imported'
              <p>
                Sometimes goods have to be procured from vendors outside India.
                This item is mentioned as 'imported' on the product page and may
                take at least 10 days or more to be delivered to you.
              </p>
            </li>
            <li>
              'Will be back in stock soon'
              <p>
                The item is popular and sold. However, you can 'book' an order
                for the product and it will be shipped according to the timeline
                specified by the seller.
              </p>
            </li>
            <li>
              'temporarily unavailable'
              <p>
                The product is currently out of stock and not available for
                purchase. Product may be in stock soon. Use the 'Notify me'
                feature to know when it's available for purchase.
              </p>
            </li>
            <li>
              'Closed Forever'
              <p>
                This product is no longer available because it is obsolete
                and/or discontinued.
              </p>
            </li>
            <li>
              'Out of Print'
              <p>
                This product is no longer available because it is no longer
                published and has been permanently discontinued.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default ShippingAndReturnPolicy;
