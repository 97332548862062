import React from "react";
import {} from "@ant-design/icons";
import Stethoscope from "../../../assest/img/stethoscope.png";
import medicalCare from "../../../assest/img/medicalCare.png";
import Queue from "../../../assest/img/queue.png";
const specialities = [
  {
    image: Stethoscope,
    title: "Urgent Care Specialists",
    desc: `At our platform, we take pride in connecting patients with highly skilled Urgent Care Specialists.`,
  },
  {
    image: medicalCare,
    title: "Primary Care Physicians",
    desc: `Our platform enables you to easily schedule appointments with trusted Primary Care Physicians.`,
  },
  {
    image: Queue,
    title: "Efficient E-Queue Management",
    desc: `With our efficient E-Queue Management system, patients can bid farewell to long waiting times and enjoy a seamless experience.`,
  },
];
const Specialities = () => {
  return (
    <React.Fragment>
      <div className="py-5">
        <div className="mb-5 text-center">
          <h2>Our Speciality</h2>
        </div>
        <div className="row">
          {specialities?.map((each, index) => (
            <div className="col-12 col-md-4 col-lg-4 th-feature-panel">
              <div className="th-feature-image">
                <img src={each?.image} />
              </div>
              <div className="th-feature-content">
                <h5 className="mb-3">{each?.title}</h5>
                <p>{each?.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Specialities;
