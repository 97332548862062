import React, { useContext, useState } from "react";
import MyDesktop from "../../component/UtilComponents/MyDesktop";
import Logo from "../../assest/img/logo.jfif";

import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
// import { connect } from "react-redux";
// import { SnackbarContext } from "../../component/UtilComponents/SnackbarUtil/SnackbarContext";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { getUserLevel } from "../../utils/getUserLevel";
import { SnackbarContext } from "../../component/UtilComponents/SnackbarUtil/SnackbarContext";

// IS_SUPERADMIN = 1
//     IS_ADMIN = 2
//     IS_PATIENT = 3
//     IS_DOCTOR = 4

const Register = (props) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    name: "",
    contact: "",
  });

  const [errMsgPassword, setErrMsgPassword] = useState("");
  const [errMsgEmail, setErrMsgEmail] = useState("");
  const [errMsgContact, setErrMsgContact] = useState("");
  const [loading, setLoading] = useState(false);

  const [userType, setUserType] = React.useState("");

  const handleChangeUserType = (event) => {
    setUserType(event.target.value);
  };

  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const handlePassword = (e) => {
    // var passwordPattern =
    //   /^(?=.*[0-9])(?=.*[!@#$%^&*_])[a-zA-Z0-9!@#$%^&*_]{8,28}$/;
    var passwordPattern =
      /^(?=.*[0-9])(?=.*[!@#$%^&*_+=(){}/|:`~.,?<>₹])[a-zA-Z0-9!@#$%^&*_+=(){}/|:`~.,?<>₹]{8,28}$/;
    if (passwordPattern.test(e.target.value)) {
      setErrMsgPassword("");
    } else {
      setErrMsgPassword(
        "Minimum 8 characters, at least 1 letter, 1 number and 1 special character"
      );
    }
    setValues({ ...values, password: e.target.value });
  };

  const handleEmail = (e) => {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailPattern.test(e.target.value)) {
      setErrMsgEmail("");
    }
    setValues({ ...values, email: e.target.value });
  };

  const handleContact = (e) => {
    var numberPattern =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (numberPattern.test(e.target.value)) {
      setErrMsgContact("");
    } else {
      setErrMsgContact("Please Enter Valid Contact Number");
    }
    setValues({ ...values, contact: e.target.value });
  };

  const onFinish = (e) => {
    setLoading(true);
    axios
      .post(`/apiV1/user/`, { ...values, roles: getUserLevel(userType) })
      .then(() => {
        let value = {
          email: values.email,
          password: values.password,
          navigate: navigate,
        };
        openSnackbar("User Registered Successfully!", "success");
        navigate("/login");
      })
      .catch((err) => {
        console.log(err, "err");
        openSnackbar(
          `${err?.response?.data?.contact ?? "Something went wrong!"}`,
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log("user type", userType);

  if (props?.user?.authorized) {
    return <Navigate to={"/"} />;
  }
  return (
    <Box sx={{ mt: 5 }}>
      <div className="row">
        <MyDesktop>
          <div className="col-md-8" style={{ height: "100vh" }}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="text-center" onClick={() => navigate("/")}>
                <img
                  src={
                    "https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                  }
                  style={{ height: "100px" }}
                  alt="Welldone logo"
                />
                <h3 className="pt-4">One stop solution for your well being.</h3>
                <h4 className="th-primary">#getWelldone</h4>
              </div>
            </div>
          </div>
        </MyDesktop>
        <div className="col-md-4 col-12">
          <div
            className="d-flex  justify-content-center align-items-center"
            style={{ height: "100vh", backgroundColor: "#F6F4EE" }}
          >
            <div className="" style={{ width: "90%" }}>
              <div className="th-20 th-fw-700 px-3">Register</div>
              <form className="pt-3">
                <div className="">
                  <div className="col-md-12 px-3 py-3">
                    <input
                      placeholder="Enter Name"
                      className="shadow-none w-100 th-form-control-1"
                      name="name"
                      type="text"
                      onChange={(e) => {
                        setValues({ ...values, name: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-12 px-3 py-3">
                    <input
                      placeholder="Enter Email"
                      className="shadow-none w-100 th-form-control-1"
                      name="email"
                      type="email"
                      onChange={handleEmail}
                    />
                  </div>
                  <div className="th-12 px-3"> {errMsgEmail}</div>
                  <div className="col-md-12 px-3 py-3">
                    <input
                      placeholder="Enter Contact"
                      className="shadow-none w-100 th-form-control-1"
                      name="contact"
                      type="number"
                      onChange={handleContact}
                    />
                  </div>
                  <div className="th-12 px-3"> {errMsgContact}</div>
                  <div className="col-md-12 px-3 py-3">
                    <input
                      className="shadow-none w-100 th-form-control-1"
                      name="password"
                      placeholder="Enter password"
                      type="text"
                      onChange={handlePassword}
                    />
                  </div>
                  <div className="th-12 px-3">{errMsgPassword}</div>
                  <div className="col-md-12 px-3 py-3 py-0">
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        You are
                      </FormLabel>

                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={userType}
                        onChange={handleChangeUserType}
                      >
                        <FormControlLabel
                          value="IS_PATIENT"
                          control={<Radio />}
                          label="Patient"
                        />
                        <FormControlLabel
                          value="IS_DOCTOR"
                          control={<Radio />}
                          label="Doctor"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className="d-flex mt-2 justify-content-center ">
                      {errMsgContact == "" &&
                      errMsgEmail == "" &&
                      errMsgPassword == "" &&
                      // values.email != "" &&
                      values.password != "" &&
                      values.contact != "" ? (
                        <div
                          className="w-100 text-center th-pointer py-2 th-btn-primary"
                          type="submit"
                          onClick={(e) => onFinish(e)}
                        >
                          Register
                        </div>
                      ) : (
                        <div className="btn w-100 text-center th-pointer py-2 btn-outline-secondary">
                          Register
                        </div>
                      )}
                      {/* 
                      <div
                        role={"button"}
                        onClick={(e) => onFinish(e)}
                        className="w-100 text-center th-pointer py-2 th-btn-primary"
                        style={{ color: "gray" }}
                      >
                        Register
                        {loading && (
                          <i className="pl-2 fas fa-spinner fa-spin"></i>
                        )}
                      </div> */}
                    </div>

                    {/* {this.state.error ? (
                      <div
                        className="text-center th-14 pt-1 pb-3"
                        style={{ color: "red" }}
                      >
                        Either Person with this Email already exists or Contact
                        already exists
                      </div>
                    ) : null}
                    {this.state.successMsg ? (
                      <div
                        className="text-center th-14 pt-2"
                        style={{ color: "green" }}
                      >
                        Sucessfully Registered. Please Login
                      </div>
                    ) : null} */}
                  </div>
                </div>
                <div className="th-primary col-md-12 th-14 d-flex justify-content-between">
                  {/* <div className="">
                    <Link to="/auth/forgot-password">Forgot Password</Link>
                  </div> */}
                  <div className="">
                    <Link to="/login">Login</Link>
                  </div>
                </div>
                {/* {this.props.user.wrong ? (
                  <div className="th-14">
                    {" "}
                    Invalid Email or Password. Try Again
                  </div>
                ) : null} */}
              </form>
              {/* <div className="d-flex th-grey pt-2 justify-content-center th-14">
                <div className="pr-3">
                  <Link to="/terms">Terms & Condition</Link>
                </div>
                <div>
                  <Link to="/policy">Privacy Policy</Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Register;
