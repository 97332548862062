import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, Typography } from "@mui/material";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";

import {
  Button,
  Layout,
  Row,
  Col,
  Space,
  Input,
  Form,
  DatePicker,
  Select,
  Radio,
  Tooltip,
} from "antd";
import axios from "axios";
import moment from "moment/moment";
const { Header, Content, Footer } = Layout;

const DoctorRegFormTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formOneData, branch } = location?.state;
  console.log("formOneData", formOneData);

  const [regAuthData, setRegAuthData] = useState([]);
  const [regAuthLoading, setRegAuthLoading] = useState(false);
  const [specialityData, setSpecialityData] = useState([]);
  const [statesData, setStatesData] = useState([]);

  const fetchRegistrationAuthority = () => {
    setRegAuthLoading(true);
    axios
      .get("apiV1/registration-authority/")
      .then((res) => {
        console.log("registration-authority", res);
        setRegAuthData(res?.data);
      })
      .catch((err) => console.log("error", err))
      .finally(() => {
        setRegAuthLoading(false);
      });
  };

  const fetchPracticeSpeciality = () => {
    axios
      .get("apiV1/practice-speciality/")
      .then((res) => {
        console.log("practice-speciality/", res);
        setSpecialityData(res?.data);
      })
      .catch((err) => console.log("error", err));
  };

  const fetchStates = () => {
    axios
      .get("apiV1/state/")
      .then((res) => {
        console.log("states", res);
        setStatesData(res?.data);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    fetchRegistrationAuthority();
    fetchStates();
    fetchPracticeSpeciality();
  }, []);

  return (
    <Layout className="layout">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              p: 3,
              backgroundColor: "#BFEBFB",
            }}
          >
            <Box>
              <img
                role={"button"}
                onClick={() => {
                  navigate("/home");
                }}
                alt="Welldone Logo"
                className="my-auto"
                src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                style={{
                  height: 40,
                  // width: 20,
                }}
              />
            </Box>
            {/* <Typography variant="caption">
            Profile of Register Medical Practionar (RMP)
          </Typography> */}
          </Box>
        </AppBar>
      </Box>
      <Content
        style={{
          padding: "0 5px",
        }}
      >
        {/* <Box sx={{ p: 2 }}>
          <Steps
            size="small"
            current={0}
            items={[
              {
                title: "Account Verification",
              },
              {
                title: "Register Medical Practionar (RMP) Details",
              },
              {
                title: "Practice Details",
              },
              {
                title: "Dashboard",
              },
            ]}
          />
        </Box> */}
        <div
          className="site-layout-content"
          style={{
            // background: "orange",
            // height: "80vh",
            padding: "2px",
          }}
        >
          <Row style={{ height: "100%" }}>
            <Col>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  p: 1,
                  width: "100%",
                  height: "100%",
                  // border: "2px solid green",
                  // background: "#bada55",
                }}
              >
                <Space direction="vertical" align="start">
                  <Typography variant="h6">
                    Register Professional Profile
                  </Typography>
                  <Form
                    onFinish={(value) => {
                      navigate("/doctor-reg/three", {
                        state: {
                          branch,
                          formOneData,
                          formTwoData: {
                            ...value,
                            country: value?.country ?? "India",
                            registration_date: moment(
                              value?.registration_date
                            )?.format("YYYY-MM-DD"),
                            valid_upto: moment(value?.valid_upto)?.format(
                              "YYYY-MM-DD"
                            ),
                          },
                        },
                      });
                    }}
                  >
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={8}>
                        <Form.Item label="Country" name="country">
                          <Select defaultValue="india">
                            <Select.Option value="india">India</Select.Option>
                            <Select.Option value="not-india">
                              Other
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="State"
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: "Please enter State",
                            },
                          ]}
                        >
                          <Select>
                            {statesData?.map((item, index) => {
                              return (
                                <Select.Option value={item?.name} key={index}>
                                  {item?.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={16}>
                        <Form.Item
                          label="Registration Authority"
                          // extra="Maharashtra State Medical Concil, Mumbai"
                          name="registration_authority"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Registration Authority",
                            },
                          ]}
                        >
                          {/* <Input
                            placeholder="Enter Registration Authority
"
                          /> */}
                          <Select loading={regAuthLoading}>
                            {regAuthData?.map((item, index) => {
                              return (
                                <Select.Option value={item?.id} key={index}>
                                  {item?.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={16}>
                        <Form.Item
                          label="Registration No"
                          name="registration_no"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Registration No",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Registration No" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={12}>
                        <Form.Item
                          label="Registration Date "
                          required
                          name="registration_date"
                          rules={[
                            {
                              required: true,
                              message: "Please select Registration Date",
                            },
                          ]}
                        >
                          <DatePicker format="DD-MM-YYYY" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Valid Upto Date"
                          required
                          name="valid_upto"
                          rules={[
                            {
                              required: true,
                              message: "Please select valid upto date",
                            },
                          ]}
                        >
                          <DatePicker format="DD-MM-YYYY" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={18}>
                        <Form.Item
                          label="Qualification"
                          name="qualification"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Qualification",
                            },
                          ]}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={18}>
                        <Form.Item
                          label="Total Experience"
                          name="total_experience"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Total Experience",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder="Enter total Experience in years"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Typography>Name</Typography>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <b />
                      <Col span={8}>
                        <Form.Item
                          label="First Name"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Last Name"
                          name="last_name"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Middle Name"
                          name="middle_name"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>{" "}
                    </Row>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={18}>
                        <Form.Item
                          label="Contact address details"
                          extra="As per Concil Register"
                          name="contact_address"
                          rules={[
                            {
                              required: true,
                              message: "Please enter contact address",
                            },
                          ]}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={16}>
                        <Form.Item
                          label="Practice Specialty"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Practice Specialty",
                            },
                          ]}
                          name="practice_speciality"
                        >
                          <Select>
                            {specialityData?.map((item, index) => {
                              return (
                                <Select.Option value={item?.id} key={index}>
                                  {item?.speciality}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Tooltip
                          placement="right"
                          title={
                            <div>
                              Please enter one primary Speciality in which you
                              want to Add Practice. You can add more categories
                              later.
                            </div>
                          }
                        >
                          <InfoTwoToneIcon />
                        </Tooltip>
                      </Col>
                    </Row>

                    <Typography sx={{ my: 1 }}>
                      Individual Professional Charges from Patient
                    </Typography>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={12}>
                        <Form.Item
                          label="First Time"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter charge for patients coming for first time",
                            },
                          ]}
                          name="first_time_charge"
                        >
                          <Input
                            addonBefore={<span>&#8377;</span>}
                            type="number"
                            placeholder="Enter amount in number"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Returning"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter charge for returning patient",
                            },
                          ]}
                          name="returning_charge"
                        >
                          <Input
                            addonBefore={<span>&#8377;</span>}
                            type="number"
                            placeholder="Enter amount in number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* <Divider /> */}
                    <hr />
                    <Box sx={{ width: "100%" }}>
                      <Typography variant="body1">
                        <b>Associate with Any Practice office</b>
                      </Typography>
                      <Form.Item>
                        <Radio.Group>
                          <Radio value="yes">Yes </Radio>
                          <Radio value="no"> No</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Button type="primary" size={"large"} htmlType="submit">
                        Continue
                      </Button>
                    </Box>
                  </Form>
                </Space>
              </Box>
            </Col>
            {/* <Col span={8}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 1,
                  width: "100%",
                  height: "100%",
                  // border: "2px solid red",
                  // background: "wheat",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={writeImg} style={{ width: "100%" }} />
                </Box>
              </Box>
            </Col> */}
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default DoctorRegFormTwo;
