import React from "react";

const About = () => {
  return (
    <React.Fragment>
      <div className="py-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="mb-3">About Us</h2>
            <p style={{ color: "#999" }}>
              At Welldone , we are on a mission to revolutionize healthcare
              access. Our platform connects patients with nearby doctors, making
              appointment booking effortless and wait times a thing of the past.
              With a focus on efficiency and personalized care, we empower
              individuals to take control of their health journey. Join us as we
              reshape healthcare, making it seamless, convenient, and accessible
              to all.
            </p>
          </div>
          <div className="col-md-6">
            <div
              className="th-bg-img"
              style={{
                backgroundImage: `url(
                https://plus.unsplash.com/premium_photo-1661281397737-9b5d75b52beb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1469&q=80
            )`,
                height: "300px",
                borderRadius: "27% 73% 30% 70% / 66% 27% 73% 34% ",
              }}
            ></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
