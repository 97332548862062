import React, { useEffect, useState } from "react";
import MyDesktop1 from "../../UtilComponents/MyDesktop1";
import { useNavigate } from "react-router-dom";
import { DebounceSelect } from "../Header";
import { useAuth } from "../../../context/auth-context";
import { useData } from "../../../context/data-context";
import axios from "axios";
import MyMobile1 from "../../UtilComponents/MyMobile1";
import Logo from "../../../assest/img/logoW.png";
import { Avatar, Button, Dropdown } from "antd";
import { LocationOnOutlined, OutputOutlined } from "@mui/icons-material";
import AddressDialog from "../../../page/AddressDialog";
import { UserOutlined } from "@ant-design/icons";
const NewHeader = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [input, setInput] = useState("");
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const authValue = useAuth();
  const dataValue = useData();
  const address = dataValue?.dataState?.currentAddress;
  async function fetchUserList(searchTest) {
    if (searchTest?.length > 0) {
      console.log("fetching user", searchTest);
      setInput(searchTest);
      return axios(`apiV1/home-search/?search=${searchTest}`).then((res) => {
        console.log(res, "res");
        return res.data?.map((each) => ({
          label:
            each?.establishment_name ||
            `${each?.suffix ?? ""} ${each?.first_name ?? ""} ${
              each?.middle_name ?? ""
            } ${each?.last_name ?? ""}`,
          value: each?.establishment_name || `${each?.first_name ?? ""}`,
        }));
      });
    }
  }
  const itemsDoctor = [
    {
      label: (
        <div
          className="th-secondary th-home"
          onClick={() => navigate("/branch-doctor")}
          sx={{ cursor: "pointer" }}
        >
          Available Branch
        </div>
      ),
      key: "1",
    },
  ];
  const itemsDoctorMobile = [
    {
      label: (
        <a
          className="th-secondary th-home"
          href="https://clinic.welldonehealth.in/"
          target="__blank"
          sx={{ cursor: "pointer" }}
        >
          Dashboard
        </a>
      ),
      key: "0",
    },
    {
      label: (
        <div
          className="th-secondary th-home"
          onClick={() => navigate("/branch-doctor")}
          sx={{ cursor: "pointer" }}
        >
          Available Branch
        </div>
      ),
      key: "1",
    },
  ];
  const itemsPateint = [
    {
      label: <div sx={{}}>Patient</div>,
      key: "3",
    },
    {
      label: (
        <div
          className="th-secondary th-home"
          onClick={() => {
            navigate("/members");
          }}
        >
          My Family
        </div>
      ),
      key: "4",
    },
  ];
  const commonItems = [
    {
      label: (
        <div
          className="th-secondary th-home"
          onClick={() => {
            navigate("/history");
          }}
        >
          My Appointments
        </div>
      ),
      key: "5",
    },
    {
      label: (
        <div
          className="th-secondary th-home"
          onClick={() => setShowAddressDialog(true)}
        >
          {address?.id ? `Addresses` : "Add Address"}
        </div>
      ),
      key: "7",
    },
    {
      label: (
        <div
          className="th-secondary th-home"
          role="button"
          onClick={authValue?.logoutHandler}
        >
          {authValue?.token ? "Logout" : "Login"}
          <i className="fas fa-sign-in-alt pl-1"></i>
        </div>
      ),
      key: "6",
    },
  ];

  let list = authValue?.currUser?.roles?.id === 4 ? itemsDoctor : itemsPateint;
  let listMobile =
    authValue?.currUser?.roles?.id === 4 ? itemsDoctorMobile : itemsPateint;
  const menuMobile = [...listMobile, ...commonItems];
  const menu = [...list, ...commonItems];
  return (
    <React.Fragment>
      <AddressDialog
        showAddressDialog={showAddressDialog}
        setShowAddressDialog={setShowAddressDialog}
      />
      <MyDesktop1>
        <div className="w-100 " style={{ position: "fixed", zIndex: 100 }}>
          <div className="th-container th-home bg-white py-2 d-flex justify-content-between align-items-center w-100  w-100 shadow-sm">
            <div className="">
              <img
                role={"button"}
                onClick={() => {
                  navigate("/home");
                }}
                alt="Welldone Logo"
                className="my-auto"
                src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                style={{
                  height: 50,
                }}
              />
            </div>
            <div className="" style={{ width: "35%", alignSelf: "center" }}>
              {window.location.pathname !== "/search-listing" && (
                <DebounceSelect
                  value={value}
                  placeholder="Search for doctors nearby"
                  className="mr-5 th-borderless-input w-100"
                  fetchOptions={fetchUserList}
                  onChange={(newValue, value) => {
                    console.log(newValue, "newValue");
                    navigate("/search-listing", {
                      state: value,
                    });
                    setValue(newValue);
                  }}
                />
              )}
            </div>
            {authValue?.currUser ? (
              <>
                {authValue?.currUser?.roles?.id === 4 && (
                  <a href="https://clinic.welldonehealth.in/" target="__blank">
                    <div
                      className="th-secondary"
                      style={{
                        fontSize: "16px",
                        cursor: "pointer",
                        fontWeight: 600,
                      }}
                    >
                      Dashboard
                    </div>
                  </a>
                )}
                <div
                  className="th-secondary"
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    navigate("/history");
                  }}
                >
                  My appointments
                </div>
                <Dropdown
                  menu={{ items: menu }}
                  trigger={["click"]}
                  placement="bottomLeft"
                  className="th-secondary th-home"
                >
                  <div
                    style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                    className="d-flex align-items-center"
                  >
                    <UserOutlined />
                    <div className="pt-1 pl-2">{authValue?.currUser?.name}</div>
                  </div>
                </Dropdown>
              </>
            ) : (
              <div
                className="th-pointer th-secondary"
                onClick={() => {
                  navigate("/login");
                }}
                style={{ fontSize: "18px" }}
              >
                Login <i className="fas fa-sign-in-alt pl-1"></i>
              </div>
            )}
          </div>
        </div>
        <div className="" style={{ height: "60px" }}></div>
      </MyDesktop1>
      <MyMobile1>
        <div
          style={{ position: "fixed", zIndex: 100 }}
          className="py-2 px-2 w-100 bg-white d-flex align-items-center justify-content-between"
        >
          <div className="">
            <img
              role={"button"}
              onClick={() => {
                navigate("/home");
              }}
              alt="Welldone Logo"
              className="my-auto"
              src={Logo}
              style={{
                height: 40,
              }}
            />
          </div>
          <DebounceSelect
            value={value}
            placeholder="Search for doctors nearby"
            className="th-borderless-input"
            fetchOptions={fetchUserList}
            onChange={(newValue, value) => {
              console.log(newValue, "newValue");
              navigate("/search-listing", {
                state: value,
              });
              setValue(newValue);
            }}
            style={{ maxWidth: "65%" }}
          />
          {authValue?.token ? (
            <Dropdown
              menu={{ items: menuMobile }}
              trigger={["click"]}
              placement="bottomLeft"
              className="th-secondary th-home"
            >
              <div
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
                className="d-flex align-items-center"
              >
                <Button
                  type="primary"
                  size="large"
                  icon={<UserOutlined />}
                  shape="square"
                />
              </div>
            </Dropdown>
          ) : (
            <div
              onClick={() => {
                navigate(`/login`);
              }}
            >
              Login
            </div>
          )}
        </div>
        <div className="" style={{ height: "40px" }}></div>
      </MyMobile1>
    </React.Fragment>
  );
};

export default NewHeader;
