import React from "react";

const ProductAndCheckoutProcess = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ padding: "20px" }}>
      <h4>Product/Services and Checkout process</h4>
      <p>
        <b>welldonehealth.in</b> is the online gateway to your favorite doctor
        appointment at a click, welldone.
      </p>
      <h5>A. Product/Services</h5>
      <p>
        When it comes to doctor appointments, there are a few products and
        services involved. Here are some key aspects:
        <p></p>
        1. Online Appointment Booking Platforms: There are websites that
        facilitate the booking of doctor appointments. These platforms allow
        users to search for doctors by specialty, location, and availability,
        and book appointments conveniently.
        <p>
          2. Electronic Health Records (EHR) Systems: EHR systems are digital
          platforms used by healthcare providers to maintain patient records.
          These systems often include appointment scheduling features, allowing
          doctors and their staff to manage appointments, track patient history,
          and improve overall workflow efficiency.
        </p>
        <p>
          3. Telemedicine Services: Telemedicine involves virtual doctor
          appointments where patients can consult with healthcare professionals
          remotely, using video calls or secure messaging platforms.
          Telemedicine services enable convenient access to medical advice and
          consultations without the need for in-person visits.
        </p>
        <p>
          4. Reminder Services: Various reminder services, such as text
          messages, phone calls, or email notifications, help patients remember
          their upcoming doctor appointments. These services aim to reduce
          no-show rates and ensure that patients arrive on time for their
          scheduled appointments.
        </p>
        <p>
          5. Medical Practice Management Software: These software solutions
          assist healthcare providers in managing their practice efficiently.
          They often include features for appointment scheduling, patient
          management, billing, and administrative tasks, streamlining the
          overall operations of a medical practice.
        </p>
        <p>
          These are just a few examples of products and services related to
          doctor appointments.
        </p>
      </p>
      <h5>B. Checkout process</h5>
      <p>
        The checkout process for online appointments on the platform or service
        being used. Here's a general overview of how the checkout process
        typically works:
      </p>
      <p>
        1. Selection of Service: The user selects the type of appointment or
        service they require. This could include selecting a specific doctor,
        appointment duration, or a particular service or treatment.
      </p>
      <p>
        2. Availability and Scheduling: The user is presented with available
        time slots based on the doctor's availability or the service provider's
        schedule. The user selects a convenient date and time for their
        appointment.
      </p>
      <p>
        3. Patient Information: The user provides necessary information, such as
        their name, contact details, and any relevant medical history or
        symptoms. This information helps the healthcare provider prepare for the
        appointment.
      </p>
      <p>
        4. Confirmation and Payment: The user confirms the appointment details
        and proceeds to the payment stage. They may be required to provide
        payment information and authorize the transaction. Payment can be made
        through various methods, such as credit/debit cards, mobile wallets, or
        online payment platforms.
      </p>
      <p>
        5. Booking Confirmation: Once the payment is processed successfully, the
        user receives a booking confirmation. This confirmation typically
        includes details such as the appointment date, time, location (if
        applicable), and any additional instructions or requirements.
      </p>
      <p>
        6. Appointment Reminders: Prior to the appointment, the user may receive
        reminders via email, SMS, or in-app notifications. These reminders serve
        as prompts to ensure the user doesn't forget the appointment.
      </p>
      <p>
        Overall, the goal of the checkout process for online appointments is to
        provide a seamless and convenient experience for users to book and
        confirm their appointments with healthcare providers.
      </p>
    </div>
  );
};

export default ProductAndCheckoutProcess;
