import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Divider, Fab, IconButton, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { ReloadOutlined } from "@ant-design/icons";
import experienceIcon from "../../assest/img/experienceIcon.png";
import graduatedIcon from "../../assest/img/graduatedIcon.png";
import chargesIcon from "../../assest/img/chargesIcon.png";
import stethoscope from "../../assest/img/stethoscope.png";
import DocAvatar from "../../assest/img/DocAvatar.png";
import noMap from "../../assest/img/noMap.png";
import Footer from "../../component/Layout/Footer";

import "react-calendar/dist/Calendar.css";

import {
  Button,
  Layout,
  Menu,
  theme,
  Steps,
  Row,
  Col,
  Space,
  Input,
  Form,
  DatePicker,
  Select,
  Radio,
  Tooltip,
  Tag,
  Image,
  Rate,
  Modal,
  Tabs,
  Spin,
  message,
  Skeleton,
  Card,
  Avatar,
  Empty,
  FloatButton,
} from "antd";
import { useEffect, useState } from "react";
import Search from "antd/es/input/Search";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import Calendar from "react-calendar";
import {
  AccessTimeTwoTone,
  ApartmentTwoTone,
  BookOnlineTwoTone,
  Cached,
  CallOutlined,
  CheckCircleOutlined,
  GradeTwoTone,
  LocationOnTwoTone,
  Phone,
  SchoolOutlined,
  SchoolTwoTone,
} from "@mui/icons-material";
import { getRandomColor } from "../../component/UtilComponents/getRandomColor";
import MyDesktop from "../../component/UtilComponents/MyDesktop";
import MyMobile from "../../component/UtilComponents/MyMobile";
import { TimeIcon } from "@mui/x-date-pickers";
// import Authorize from "../../../global/Authorize";
const { Header, Content } = Layout;

const AppointmentForm = ({
  doctorData,
  equeueData,
  setSelectedEqueue,
  selectedEqueue,
  getEqueueData,
  equeueLoading,
  setEqueueData,
  branch,
  selectedBranch,
  setSelectedBranch,
}) => {
  const [bookAppointmentFrom] = useForm();

  const [firstVisit, setFirstVisit] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [slotsData, setSlotsData] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");

  console.log({ selectedSlot });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [calendarValue, setCalendarValue] = useState(new Date());

  const [slotLoading, setSlotLoading] = useState(false);

  const [isEqueue, setIsEqueue] = useState(true);

  console.log({ selectedBranch });

  const navigate = useNavigate();

  const tabItems = [
    {
      label: <b>Book by e-Queue</b>,
      key: 2,
      children: (
        <>
          <div>
            <div className="d-flex align-items-center">
              <Typography variant="subtitle1" sx={{ my: 1 }}>
                Book by e-Queue
              </Typography>
              <Tooltip placement="topLeft" title={"Reload"}>
                <Box
                  onClick={() => {
                    getEqueueData();
                    setEqueueData([]);
                    setSelectedEqueue(null);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "#09B5C8",
                    color: "#ffffff",
                    ml: 1,
                    cursor: "pointer",
                  }}
                >
                  <Cached />
                </Box>
              </Tooltip>
            </div>

            <div
              style={{ maxHeight: "300px", overflowY: "scroll", padding: 2 }}
            >
              {equeueLoading ? (
                <Skeleton />
              ) : (
                equeueData?.map((item, index) => (
                  <Box
                    sx={{
                      width: "100%",
                      background: item?.is_active ? "#fff" : "#e8ead8",
                      border: "2px solid lightgray",
                      my: 1,
                      border:
                        selectedEqueue == item?.id ? "3px solid #22c55e" : "",
                      borderRadius: "10px",
                      cursor: item?.is_active ? "pointer" : "not-allowed",
                    }}
                    key={item?.id}
                    onClick={() => {
                      if (item?.is_active) {
                        setSelectedEqueue(item?.id);
                      }
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <Box
                        sx={{
                          // background: "red",
                          background: "#dedede52",
                          borderRadius: "8px 0 0 8px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="#1d4ed8"
                          sx={{ px: 0.2 }}
                        >
                          Booking at
                        </Typography>
                        <Typography
                          variant="h6"
                          color="#e82d2d"
                          textAlign="center"
                        >
                          {item?.current_equeue + 1}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="caption" color="#1d4ed8">
                          Dr. for You
                        </Typography>

                        {moment().format("YYYY-MM-DD") === item?.date ? (
                          moment().isBefore(
                            moment(item?.expected_time, "HH:mm:ss")
                          ) ? (
                            <Typography
                              variant="caption"
                              color="#000000"
                              textAlign={"center"}
                            >
                              {moment(item?.expected_time, "HH:mm:ss")
                                .add(item?.average_time, "minutes")
                                .format("hh:mm A")}
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              color="#000000"
                              textAlign={"center"}
                            >
                              {moment()
                                ?.add(item?.average_time, "minutes")
                                .format("hh:mm A")}
                            </Typography>
                          )
                        ) : (
                          <Typography variant="caption">
                            {moment(item?.expected_time, "HH:mm:ss")
                              ?.add(item?.average_time, "minutes")
                              ?.format("hh:mm A")}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="caption" color="#1d4ed8">
                          On
                        </Typography>
                        {moment().format("YYYY-MM-DD") === item?.date ? (
                          <Typography
                            variant="subtitle2"
                            color="#000000"
                            textAlign="center"
                          >
                            Today
                          </Typography>
                        ) : (
                          <>
                            {console.log(
                              moment(item?.date).format("DD MMM"),
                              "sdfghjk",
                              item?.data
                            )}
                            <Typography
                              variant="subtitle2"
                              color="#000000"
                              textAlign={"center"}
                            >
                              {moment(item?.date).format("DD MMM")}
                            </Typography>
                          </>
                        )}
                      </Box>

                      <Box
                        sx={{
                          // background: "red",
                          background: "#dedede52",
                          borderRadius: "0 8px 8px 0",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="#1d4ed8"
                          sx={{ px: 0.2 }}
                        >
                          Ongoing
                        </Typography>
                        <Typography
                          variant="h6"
                          color="#09B5C8"
                          textAlign={"center"}
                        >
                          {item?.on_going_equeue}
                        </Typography>
                      </Box>
                    </div>
                    {!item?.is_active && (
                      <div className="py-1 px-2">
                        Currently paused due to {item?.reason}
                      </div>
                    )}
                  </Box>
                ))
              )}
            </div>
            {!equeueLoading && equeueData?.length <= 0 && (
              <Typography>E-queues not available</Typography>
            )}
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              size="large"
              type="primary"
              align="center"
              htmlType="submit"
              disabled={!selectedEqueue}
            >
              Continue Booking
            </Button>
          </Box>
        </>
      ),
    },
  ];
  const [messageApi, contextHolder] = message.useMessage();

  console.log({ firstVisit });
  const onFinish = (value) => {
    // if (!value.ReasonOfVisit) {
    //   messageApi.open({
    //     type: "error",
    //     content: "Please select Reason for Your Visit",
    //   });
    //   return;
    // }

    // if (!value.branch) {
    //   messageApi.open({
    //     type: "error",
    //     content: "Please select Branch",
    //   });
    //   return;
    // }
    navigate(`/patient-profile`, {
      state: {
        payload: {
          doctor: doctorData?.id,
          slot: isEqueue ? null : selectedSlot?.id,
          is_first_visit: firstVisit,
          is_equeue: isEqueue ? true : false,
          amount: firstVisit
            ? doctorData?.first_time_charge
            : doctorData?.returning_charge,
          branch: value?.branch ?? selectedBranch,
          payment_method: isEqueue ? "cash" : "online",
          date: isEqueue
            ? equeueData?.find((i) => i.id == selectedEqueue)?.date
            : null,
          appointment_date: isEqueue
            ? equeueData?.find((i) => i.id == selectedEqueue)?.date
            : moment(selectedSlot?.date).format("YYYY-MM-DD"),
        },
        doctorData: doctorData,
      },
    });
  };

  console.log("first");
  return (
    <>
      {contextHolder}
      <div className="w-100 d-flex flex-column m-1">
        <div className="text-center">
          <Typography variant="h5">Book Your Appointment </Typography>
        </div>
        <div className="w-100 mt-3">
          <Form
            form={bookAppointmentFrom}
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={24} align="middle">
              {/* <Col md={24}>
                <Form.Item
                  label="What’s The Reason for Your Visit"
                  name="ReasonOfVisit"
                  colon={false}
                >
                  <Select placeholder="Reason">
                    <Select.Option value="general_consultation">
                      General Consultation
                    </Select.Option>
                    <Select.Option value="follow_up">Follow Up</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={24} align="middle">
              <Col md={24}>
                <Form.Item
                  label="Have the patient seen these Doctors?"
                  colon={false}
                >
                  <Radio.Group defaultValue={true}>
                    <Radio
                      value={true}
                      onClick={() => {
                        setFirstVisit(true);
                      }}
                    >
                      First Time
                    </Radio>
                    <Radio
                      value={false}
                      onClick={() => {
                        setFirstVisit(false);
                      }}
                    >
                      Returning
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={24} align="middle"> */}
            {/* <Col> */}
            <Form.Item label="Select Branch" name="branch" colon={false}>
              <Select
                className="w-100"
                onChange={(value) => {
                  setSelectedBranch(value);
                }}
                placeholder={
                  doctorData?.establishment?.length > 0
                    ? `${doctorData?.establishment[0]?.establishment_name} - ${doctorData?.establishment[0]?.branch}`
                    : ""
                }
              >
                {console.log(doctorData, "doctorData")}
                {doctorData?.establishment?.map((item, index) => {
                  return (
                    <Select.Option
                      className="w-100"
                      key={index}
                      value={item?.id}
                    >
                      {item?.establishment_name} - {item?.branch}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* </Col> */}
            {/* </Row> */}

            <Tabs
              defaultActiveKey="1"
              type="card"
              size={"large"}
              items={tabItems}
              onChange={(tabKey) => {
                if (tabKey == 2) {
                  setIsEqueue(true);
                } else {
                  setIsEqueue(false);
                }
              }}
            />
          </Form>
        </div>
      </div>
      <Modal
        title={
          <div>
            {moment(calendarValue)?.format("dddd")} (
            {moment(calendarValue)?.format("MMM Do, yy")})
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {slotLoading ? (
          <Skeleton />
        ) : slotsData?.length > 0 ? (
          <div>
            <p>Select Slot</p>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {slotsData?.map((item, index) => {
                let thisIsSelected = false;
                if (item?.id === selectedSlot?.id) {
                  thisIsSelected = true;
                }
                return (
                  <Button
                    // style={{ flex: 1 }}
                    onClick={() => {
                      setSelectedSlot(item);
                    }}
                    type={thisIsSelected ? "primary" : "dashed"}
                    disabled={item?.is_booked}
                  >
                    {item?.slot}
                  </Button>
                );
              })}
            </Box>
          </div>
        ) : (
          <p>No Slots available for the day!</p>
        )}
      </Modal>
    </>
  );
};

// ProfileStatus

// AddBranchCard

const BookAppointment = () => {
  const [doctorData, setDoctorData] = useState([]);

  const [equeueData, setEqueueData] = useState([]);

  const [selectedEqueue, setSelectedEqueue] = useState(null);

  const [loading, setLoading] = useState(false);

  const [appointmentModal, setAppointmentModal] = useState(false);

  const [activeTabKey1, setActiveTabKey1] = useState(0);

  const [equeueLoading, setEqueueLoading] = useState(false);

  const [selectedBranch, setSelectedBranch] = useState(null);

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  useEffect(() => {
    getDoctorProfile();
    getEqueueData();
  }, []);

  const [searchParams] = useSearchParams();

  const doctor = searchParams.get("doctor");
  const branch = searchParams.get("branch");

  function getDoctorProfile() {
    setLoading(true);
    axios
      .get(`/apiV1/doctors-profile/?slug=${doctor}&branch=${branch}`)
      .then((res) => {
        console.log("doc profile", res?.data);
        setDoctorData(res?.data);
        if (res?.data?.establishment?.length > 0) {
          setSelectedBranch(res?.data?.establishment[0]?.id);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getEqueueData();
  }, [selectedBranch]);

  function getEqueueData() {
    let selectedBranchSlug = selectedBranch
      ? doctorData?.establishment?.find((i) => i?.id === selectedBranch)?.slug
      : branch;

    setEqueueLoading(true);
    axios
      .get(
        `/apiV1/e-queue/?doctor__slug=${doctor}&branch__slug=${selectedBranchSlug}`
      )
      .then((res) => {
        console.log("equeue res", res);
        setEqueueData(res?.data);
        if (res?.data[0]?.is_active) setSelectedEqueue(res?.data[0]?.id);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setEqueueLoading(false);
      });
  }
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  function copyUrl() {
    let text = window.location.href;
    const copyContent = async () => {
      try {
        await navigator.clipboard.writeText(text);
        console.log("Content copied to clipboard");
        messageApi.open({
          type: "success",
          content: "Content copied to clipboard",
        });
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };
    copyContent();
  }

  const tabList = doctorData?.establishment?.map((each, index) => ({
    key: index,
    tab: "Office " + (index + 1),
  }));
  let contentList = {};
  let establishment = doctorData?.establishment;
  for (let i = 0; i < establishment?.length; i++) {
    contentList = {
      ...contentList,
      [i]: (
        <div>
          <Space className="" align="center">
            <Typography
              variant="h5"
              className="pb-1 th-pointer"
              onClick={() => {
                navigate(`/hospital-page/${establishment[i]?.slug}`);
              }}
            >
              {establishment[i]?.establishment_name}
            </Typography>
            {/* <a href={`tel:${establishment[i]?.owner_details[0]?.phone}`}>
              <IconButton size="small" className=" text-white th-bg-secondary">
                <CallOutlined />
              </IconButton>
            </a> */}
          </Space>
          <div className="mb-3">
            <Space>
              <ApartmentIcon />
              <Typography style={{ fontSize: "14px" }}>
                {establishment[i]?.owner_details[0]?.address},{" "}
                {establishment[i]?.owner_details[0]?.pincode}
              </Typography>
            </Space>
          </div>
          <div className="py-2 mb-3">
            <Typography variant="h6">Timings </Typography>
            {establishment[i]?.timings?.length > 0 && (
              <div className="d-flex mt-1 p-1" style={{ overflowX: "scroll" }}>
                {establishment[i]?.timings?.map((timing, index) => (
                  <Card.Grid
                    style={{ minWidth: "140px" }}
                    className="mr-2 mb-2 p-1"
                  >
                    <div className="text-center font-weight-bold">
                      {timing?.day}
                    </div>
                    <div className="text-center" style={{ fontSize: "12px" }}>
                      {timing?.start_time} - {timing?.end_time}
                    </div>
                  </Card.Grid>
                ))}
              </div>
            )}
          </div>

          {establishment[i]?.map_setting[0]?.iframe ? (
            <div className="d-flex justify-content-center w-100 position-relative">
              <div
                className="w-100 th-iframe"
                dangerouslySetInnerHTML={{
                  __html: establishment[i]?.map_setting[0]?.iframe,
                }}
              />
            </div>
          ) : (
            <Empty
              image={noMap}
              description={"No Map Found"}
              imageStyle={{ height: 300 }}
            />
          )}
        </div>
      ),
    };
  }

  // useEffect(() => {
  //   if (doctorData?.establishment?.length > 0) {
  //     setSelectedBranch(doctorData?.establishment[0]);
  //   }
  // }, [doctorData]);

  console.log(selectedBranch, "selectedBranch");
  console.log(doctorData, "selectedBranch");
  return (
    // <Authorize>
    <div>
      {contextHolder}
      <Spin spinning={loading} size="large">
        {/* DOC PROFILE */}
        <div className="th-bg-secondary">
          <div className="w-100 th-container pt-5 d-flex flex-wrap align-items-center th-doc-profile">
            <img
              className="rounded-circle th-doc-img text-center "
              style={{}}
              src={
                doctorData?.image_gallery?.find((i) => i?.file_type === "image")
                  ?.image ?? DocAvatar
              }
            />
            {/* DESKTOP DOC DETAILS */}
            <div className="d-flex flex-column align-items-start justify-content-start gap-3 text-white pl-4 th-doc-details-desktop">
              <div>
                <Typography variant="h5" className="my-1 text-white">
                  {doctorData?.suffix ?? "Dr."} {doctorData?.first_name}{" "}
                  {doctorData?.middle_name} {doctorData?.last_name}
                </Typography>
                {doctorData?.education_and_background?.map((item, index) => {
                  return (
                    <>
                      <Tag key={index} className="mb-2 ">
                        <strong>{item?.qualification} </strong>
                      </Tag>
                    </>
                  );
                })}
                {doctorData?.total_experience && (
                  <Tag icon={<i class="fas fa-briefcase pr-2 "></i>}>
                    <strong className="">
                      {doctorData?.total_experience} Years Overall Experience
                    </strong>
                  </Tag>
                )}
              </div>
              {doctorData?.address?.length > 0 && (
                <div className="d-flex justify-content-center align-items-center">
                  <>
                    <LocationOnIcon color="error" />
                    <Typography className="pl-2 align-self-end">
                      {`${doctorData?.address[0]?.city},  ${doctorData?.address[0]?.state} `}{" "}
                    </Typography>
                  </>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* DOC LISTING DETAILS */}
        <div className="th-container-1  ">
          {/* MOBILE DOC DETAILS */}

          <div>
            <div style={{ height: "100%" }} className="row mb-3">
              <div className="col-md-8 th-doc-listing-left">
                <div className="d-flex flex-column align-items-start justify-content-start gap-3 th-doc-details-mobile">
                  <div>
                    <Typography variant="h5" className="my-1">
                      {doctorData?.suffix ?? "Dr."} {doctorData?.first_name}{" "}
                      {doctorData?.middle_name} {doctorData?.last_name}
                    </Typography>
                    {doctorData?.education_and_background?.map(
                      (item, index) => {
                        return (
                          <>
                            <Tag className="mb-2 ">
                              <strong>{item?.qualification} </strong>
                            </Tag>
                          </>
                        );
                      }
                    )}
                    {doctorData?.total_experience && (
                      <Tag icon={<i class="fas fa-briefcase pr-2"></i>}>
                        <strong className="">
                          {doctorData?.total_experience} Years Overall
                          Experience
                        </strong>
                      </Tag>
                    )}
                  </div>
                  {doctorData?.address?.length > 0 && (
                    <div className="d-flex justify-content-center align-items-center">
                      <>
                        <LocationOnIcon color="error" />
                        <Typography className="pl-2 align-self-end">
                          {`${doctorData?.address[0]?.city},  ${doctorData?.address[0]?.state} `}{" "}
                        </Typography>
                      </>
                    </div>
                  )}
                </div>
                <div className="my-2">
                  <MyMobile>
                    {!appointmentModal && (
                      <Button
                        className=" th-bg-secondary  w-100 text-white"
                        onClick={() => {
                          setAppointmentModal(true);
                        }}
                        icon={
                          <i className="far fa-calendar-plus text-white "></i>
                        }
                        style={{
                          borderRadius: "5px",
                        }}
                      >
                        Book Appointment
                      </Button>
                    )}
                  </MyMobile>
                </div>
                <Card
                  className="w-100 shadow-sm my-3"
                  title={<Typography variant="h6">Office Details</Typography>}
                  tabList={tabList}
                  activeTabKey={activeTabKey1}
                  onTabChange={onTab1Change}
                >
                  {contentList[activeTabKey1]}
                </Card>
              </div>
              <MyDesktop>
                <div span={8} className="col-md-4">
                  <Box
                    className="shadow-sm card"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 1,
                      backgroundColor: "#fff",
                      borderRadius: "20px",
                      mt: 2,
                      p: 2,
                    }}
                  >
                    <AppointmentForm
                      doctorData={doctorData}
                      equeueData={equeueData}
                      setSelectedEqueue={setSelectedEqueue}
                      selectedEqueue={selectedEqueue}
                      getEqueueData={getEqueueData}
                      equeueLoading={equeueLoading}
                      setEqueueData={setEqueueData}
                      messageApi={messageApi}
                      branch={branch}
                      selectedBranch={selectedBranch}
                      setSelectedBranch={setSelectedBranch}
                    />
                  </Box>
                </div>
              </MyDesktop>
            </div>
            <hr />
            {doctorData?.speciality_treatment?.split(",")?.length > 0 && (
              <div className="mb-3">
                <Typography variant="h5">
                  Specialties Treatment Areas
                </Typography>
                <div className="d-flex pt-2 flex-wrap gap-2">
                  {doctorData?.speciality_treatment
                    ?.split(",")
                    ?.map((item, index) => {
                      return (
                        <Tag className="mb-2" color={getRandomColor(index)}>
                          {item?.toUpperCase()}
                        </Tag>
                      );
                    })}
                </div>
              </div>
            )}

            {doctorData?.education_and_background?.length > 0 && (
              <div className="mb-3">
                <Typography variant="h5">Education and background</Typography>
                <div
                  className="d-flex mt-3 pb-2"
                  style={{ overflowX: "scroll" }}
                >
                  {doctorData?.education_and_background?.map((item, index) => {
                    return (
                      <div
                        className="mr-3 shadow-sm py-2 card"
                        style={{
                          minWidth: "350px",

                          borderRadius: "20px",
                        }}
                      >
                        <div className="card-body">
                          <Space align="center">
                            <Image
                              width="40px"
                              src={graduatedIcon}
                              preview={false}
                            />
                            <Typography
                              className="font-weight-bold"
                              style={{ fontSize: "18px" }}
                            >
                              {item?.qualification}
                            </Typography>
                            <Tag>
                              <Typography variant="caption">
                                {item?.faculity}
                              </Typography>
                            </Tag>
                          </Space>
                          <div className="pt-3">
                            {item?.institute && (
                              <Typography className="pb-2" variant="body2">
                                <ApartmentTwoTone /> {item?.institute}{" "}
                              </Typography>
                            )}
                            {item?.city && (
                              <Typography className="pb-2" variant="body2">
                                <LocationOnTwoTone /> {item?.city}
                              </Typography>
                            )}
                            {item?.passing_year && (
                              <Typography className="pb-2" variant="body2">
                                <SchoolTwoTone /> {item?.passing_year}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {doctorData?.experience?.length > 0 && (
              <div className="mb-3">
                <div>
                  <Typography variant="h5">
                    Training And Experience with Field
                  </Typography>
                  <div
                    className="d-flex mt-3 pb-2"
                    style={{ overflowX: "scroll" }}
                  >
                    {doctorData?.experience?.map((item, index) => {
                      return (
                        <div
                          className="mr-3 shadow-sm py-2 card"
                          style={{
                            minWidth: "350px",
                            borderRadius: "20px",
                          }}
                        >
                          <div className="card-body">
                            <Space align="center">
                              <Image
                                width="40px"
                                src={experienceIcon}
                                preview={false}
                              />
                              <Typography
                                className="font-weight-bold"
                                style={{ fontSize: "18px" }}
                              >
                                {item?.job_title}
                              </Typography>
                              {item?.practice_type && (
                                <Tag>
                                  <Typography variant="caption">
                                    {item?.practice_type}
                                  </Typography>
                                </Tag>
                              )}
                            </Space>
                            <div className="pt-3">
                              {item?.practice_spaciality && (
                                <Typography className="pb-2" variant="body2">
                                  <GradeTwoTone /> {item?.practice_spaciality}{" "}
                                </Typography>
                              )}
                              {item?.hospital_name && (
                                <Typography className="pb-2" variant="body2">
                                  <ApartmentTwoTone /> {item?.hospital_name}
                                </Typography>
                              )}
                              {item?.city && (
                                <Typography className="pb-2" variant="body2">
                                  <LocationOnTwoTone /> {item?.city}
                                </Typography>
                              )}
                              {item?.from_date && (
                                <Typography variant="h6">
                                  <AccessTimeTwoTone /> {item?.from_date}-
                                  {item?.to_date}
                                </Typography>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {doctorData?.language?.length > 0 && (
              <div className="mb-3">
                <Typography variant="h5">Languages Spoken</Typography>
                <div className="d-flex pt-2 flex-wrap gap-2">
                  {doctorData?.language?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        className="mb-2 text-capitalize"
                        color={getRandomColor(index)}
                        style={{ fontSize: "14px" }}
                      >
                        {item?.language}
                      </Tag>
                    );
                  })}
                </div>
              </div>
            )}

            {doctorData?.achievements?.length > 0 ? (
              <Row className="mb-3">
                <hr />
                <Col span={24}>
                  <Box>
                    <Typography variant="h5">
                      Honours and Achievements
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexWrap: "wrap",
                      }}
                    >
                      {doctorData?.achievements?.map((item, index) => {
                        return (
                          <Box
                            sx={{
                              width: "350px",
                              // backgroundColor: "#D6F3F6",
                              mt: 8,
                              borderRadius: "20px",
                            }}
                          >
                            <Box
                              sx={{
                                p: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                                textAlign: "center",
                                position: "relative",
                              }}
                            >
                              <Box>
                                <Image
                                  width="100px"
                                  src={stethoscope}
                                  preview={false}
                                  style={{
                                    top: "-30px",
                                    position: "relative",
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                position: "relative",
                                top: "-30px",
                                textAlign: "center",
                              }}
                            >
                              <Typography textAlign="center" variant="h6">
                                {item?.title}
                              </Typography>
                              <Typography variant="caption">
                                {item?.spaciality}
                              </Typography>
                              <Typography variant="body1">
                                {item?.organisation}
                              </Typography>
                              <Typography variant="body2">
                                {item?.city}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Col>
              </Row>
            ) : null}

            {doctorData?.service_charge?.length > 0 && (
              <div className="mb-3">
                <hr />
                <Typography variant="h5">Charge of Services Fees</Typography>
                <div
                  className="d-flex my-3 border-0 py-1 w-100"
                  style={{ overflowX: "scroll", padding: 0 }}
                >
                  {doctorData?.service_charge?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{ minWidth: "250px" }}
                        className="mr-3 mb-2 p-2 px-3 rounded card shadow-sm"
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <Typography
                              variant="body1"
                              className="font-weight-bold"
                            >
                              {item?.service}
                            </Typography>
                            <Typography variant="body2">
                              ₹ {item?.charge}
                            </Typography>
                          </div>
                          <Image
                            preview={false}
                            src={chargesIcon}
                            width={"30px"}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div
              className="w-100 my-2 py-2 text-center rounded shadow-sm"
              style={{
                backgroundColor: "#F3E2FF",
              }}
            >
              <Typography>
                {" "}
                These fees are communicated to you for information only by the
                practitioner. They may vary depending on the type of treatment
                finally performed in the office, the number of consultations and
                the additional procedures required. If the prices are exceeded,
                the practitioner must warn the patient beforehand.
              </Typography>
            </div>
          </div>
        </div>

        <Footer />
      </Spin>
      <Modal
        open={appointmentModal}
        onCancel={() => {
          setAppointmentModal(false);
        }}
        footer={null}
      >
        <AppointmentForm
          doctorData={doctorData}
          equeueData={equeueData}
          setSelectedEqueue={setSelectedEqueue}
          selectedEqueue={selectedEqueue}
          getEqueueData={getEqueueData}
          equeueLoading={equeueLoading}
          setEqueueData={setEqueueData}
          messageApi={messageApi}
          branch={branch}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
        />
      </Modal>
    </div>
  );
};
export default BookAppointment;
