import { Typography, Backdrop, Divider, CircularProgress } from "@mui/material";

import axios from "axios";
import React, { useEffect, useState } from "react";

import NoData from "../../assest/img/nodata.svg";
import PatientAvatar from "../../assest/img/patient.png";
import Pending from "../../assest/img/pending.png";
import Failed from "../../assest/img/failed.png";
import Paid from "../../assest/img/paid.png";
import { Badge, Button, Modal } from "antd";
import { Close } from "@mui/icons-material";

function HistoryPage() {
  useEffect(() => {
    fetchAppointmentsHistory();
  }, []);

  const [showData, setShowData] = useState(false);

  const [modal, setModal] = useState(false);
  const [prescriptionLink, setPrescriptionLink] = useState(null);

  const [historyData, setHistory] = useState([]);

  const fetchAppointmentsHistory = () => {
    setShowData(true);

    axios
      .get("apiV1/patient-appointment-history/")
      .then((res) => {
        setHistory(res?.data);
      })
      .catch((error) => console.log(error, "error"))
      .finally(() => setShowData(false));
  };

  return (
    <div className="th-container p-5">
      <h4>Appointments</h4>

      <div className="row  align-items-center ">
        {!historyData?.length > 0 ? (
          <div
            style={{
              display: "flex",
              height: "100vh",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={NoData} alt="no-data" height="50%" width="100%" />
            <Typography variant="subtitle1" sx={{ my: 2 }}>
              No Data
            </Typography>
          </div>
        ) : (
          historyData?.map((each, index) => (
            <div className=" col-lg-4 col-md-4 col-sm-6 col-12 h-100">
              <Badge.Ribbon text={"WELL" + each?.id}>
                <div
                  className="w-100 p-3  rounded my-3 shadow-sm"
                  style={{
                    m: 1,
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
                    border: each?.is_accepted
                      ? "2px solid #6AEB71"
                      : each?.is_delayed
                      ? "2px solid red"
                      : "2px solid yellow",
                    backgroundColor: each?.is_accepted ? "#dff1e1" : "#ffffff",
                  }}
                >
                  <div className="d-flex flex-wrap px-4 py-2 ">
                    <div className="d-flex w-100 align-items-center justify-content-between ">
                      <img
                        alt="pat_img"
                        src={
                          each?.patient_avatar
                            ? each?.patient_avatar
                            : PatientAvatar
                        }
                        width="100px"
                        height="100px"
                        className="th-br-10 bg-white shadow-sm"
                      />
                      {each?.payment_status === "pending" ? (
                        <img
                          alt="pat_img"
                          src={Pending}
                          width="50px"
                          height="50px"
                        />
                      ) : each?.payment_status === "success" ? (
                        <img
                          alt="pat_img"
                          src={Paid}
                          width="50px"
                          height="50px"
                        />
                      ) : (
                        <img
                          alt="pat_img"
                          src={Failed}
                          width="50px"
                          height="50px"
                        />
                      )}
                    </div>

                    <div
                      className=""
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="d-flex align-items-center ">
                        <h5 className="m-0 pr-1">{each?.patient_first_name}</h5>
                        <div>
                          ({each?.patient_age},
                          {each?.patient_gender?.substring(0, 1)?.toUpperCase()}
                          )
                        </div>
                      </div>
                      {each?.equeue_no && (
                        <div className="pt-1 d-flex align-items-center ">
                          <i class="fas fa-users pr-2"></i>
                          No. <b> {each?.equeue_no}</b>
                        </div>
                      )}
                      {each?.appointment_date && (
                        <div className="pt-1">
                          <i className="far fa-calendar-check pr-2"></i>
                          {each?.appointment_date}
                        </div>
                      )}
                      {each?.amount && (
                        <div className="pt-1">
                          <i className="far fa-money-bill-alt  pr-2"></i>
                          {each?.amount}
                        </div>
                      )}

                      {each?.reason && (
                        <div className="pt-1 d-flex align-items-center  text-capitalize">
                          <i className="far fa-question-circle pr-2"></i>
                          {each?.reason?.split("_")?.join(" ")}
                        </div>
                      )}

                      <div className="pt-1">
                        <i className="fas fa-user-md pr-2"></i>
                        {`${each?.doctor_suffix ?? each?.doctor_suffix} ${
                          each?.doctor_name ?? each?.doctor_name
                        } ${
                          each?.doctor_middle_name ?? each?.doctor_middle_name
                        } ${each?.doctor_last_name ?? each?.doctor_last_name}`}
                      </div>
                      {each?.prescription_link && (
                        <div className="mt-2">
                          <Button
                            onClick={() => {
                              setModal(true);
                              setPrescriptionLink(each?.prescription_link);
                            }}
                            type="primary"
                            size="small"
                          >
                            View Prescription
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Badge.Ribbon>
            </div>
          ))
        )}
      </div>
      <Modal
        open={modal}
        onCancel={() => {
          setModal(false);
        }}
        className={"th-prescription-modal"}
        closeIcon={null}
        footer={
          <Button
            onClick={() => {
              setModal(false);
            }}
            type="primary"
          >
            Close
          </Button>
        }
      >
        <iframe
          style={{
            width: "100%",
            height: "80vh",
          }}
          src={prescriptionLink}
        ></iframe>
      </Modal>
    </div>
  );
}

export default HistoryPage;
