const isDevMode = false;

// for api
export default function getBaseUrl() {
  if (isDevMode) {
    return "https://13.127.210.70/";
  } else {
    // return "https://api.welldonehealth.in/";
    // return "https://backend-side-production.up.railway.app/";
    // return "https://api-welldone.up.railway.app/";
    return "https://apidemo.welldonehealth.in/";
  }
}
