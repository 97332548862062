import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import CacheBuster from "./util/CacheBuster";

import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/auth-context";
import { SnackbarProvider } from "./component/UtilComponents/SnackbarUtil/SnackbarContext";
import { DataProvider } from "./context/data-context";
import { ErrorBoundary } from "react-error-boundary";
import { Box, Button, Typography } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary
    fallback={<div>Something went wrong,Please Try again later.</div>}
  >
    <SnackbarProvider>
      <AuthProvider>
        <DataProvider>
          <Router>
            <App />
          </Router>
        </DataProvider>
      </AuthProvider>
    </SnackbarProvider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
