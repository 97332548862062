import { LocationOnTwoTone } from "@mui/icons-material";
import { Button, Tag } from "antd";
import React from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getRandomColor } from "../../../../component/UtilComponents/getRandomColor";
import "./index.css";
const DoctorCard = ({ details }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="col-md-4 col-sm-6 p-3 mt-3" key={details?.id}>
        <div
          className="shadow-sm card p-3 bg-white"
          style={{
            cursor: "pointer",
            borderRadius: 10,
          }}
          onClick={() => {
            navigate({
              pathname: `/book-appointment`,
              search: `?${createSearchParams({
                doctor: details?.slug,
                branch: details?.branch__slug,
              })}`,
            });
          }}
        >
          <div className="d-flex">
            <div
              className="th-bg-img"
              style={{
                width: "100px",
                height: "100px",
                backgroundImage: `url(${
                  details?.profile_picture ??
                  `https://imgs.search.brave.com/RFGKyMz14GkCbAv4q4LKhDbqfcgsglmo7WLaBKHIlXM/rs:fit:500:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzAxLzUwLzE3Lzc2/LzM2MF9GXzE1MDE3/NzY3OF90VGlYRzFX/T3dWaW5tRDZJdnBG/ZnlTcURLeU5FVHl2/RC5qcGc`
                })`,
                borderRadius: "50%",
              }}
            />
            <div className="pl-3">
              <h5 className="m-0">
                Dr. {details?.first_name + " " + details?.last_name}
              </h5>
              {details?.branch__establishment_name && (
                <div className="pt-1 th-14">
                  <i class="fas fa-hospital th-secondary pr-1"></i>
                  {details?.branch__establishment_name}
                </div>
              )}
              {details?.branch__state__name && (
                <div className="pt-1 th-12">
                  <i className="fas fa-map-marker text-danger pr-1"></i>{" "}
                  {details?.branch__state__name}
                </div>
              )}
            </div>
          </div>
          <div className="pt-3">
            <div className="d-flex flex-wrap">
              {details?.education?.slice(0, 2)?.map((each, ind) => (
                <Tag key={ind} className="mb-2" color={getRandomColor(ind + 1)}>
                  <i className="fas fa-graduation-cap"></i> {each}
                </Tag>
              ))}
              <Tag className="mb-2">
                <i class="fas fa-briefcase text-primary"></i>{" "}
                {details?.total_experience} Years Overall Experience
              </Tag>
            </div>
          </div>
          <div className="pt-2">
            <Button
              className="w-100 "
              icon={<i class="fas fa-external-link-alt"></i>}
            >
              Consult
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DoctorCard;
