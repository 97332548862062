import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Backdrop,
  Select,
  Divider,
  Typography,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Chip,
  CircularProgress,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
// import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { SnackbarContext } from "../../../component/UtilComponents/SnackbarUtil/SnackbarContext";
import moment from "moment";
import dayjs from "dayjs";
import axios from "axios";
import { TimePicker } from "@mui/x-date-pickers";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "100%",
    height: "10px", // set the height you want here
  },
});

const CustomMobileTimePicker = styled(TimePicker)(({ theme }) => ({
  "& .MuiInputBase-input": {
    height: "10px", // Set the desired height here
    padding: "10px 12px", // Set the desired padding here
    marginLeft: "2px",
  },
}));

function DoctorInHospitalForm() {
  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const location = useLocation();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const { branch } = location?.state;
  // console.log(branch, "branchbranchbranch");
  const [page, setPage] = useState(1);

  const [mapLink, setMapLink] = useState("");

  const defaultFieldValues = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [hospitalTiming, setHospitalTiming] = useState(
    Array(7)
      .fill()
      .map((_, index) => ({
        branch: branch?.id,
        day: defaultFieldValues[index],
        start_time: null,
        end_time: null,
        is_lunch_break: true,
        lunch_start_time: null,
        lunch_end_time: null,
        is_open: true,
      }))
  );
  const [services, setServices] = useState([]);
  const [service, setService] = useState("");
  const [charge, setCharge] = useState("");

  const [clinicDetails, setClinicDetails] = useState({
    branch: branch?.id,
    pan_number: "",
    pan_holder_name: "",
    is_gst: true,
    gst_no: "",
    gst_holder_name: "",
    account_holder_name: "",
    account_number: "",
    bank_name: "",
    branch_name: "",
    ifsc_code: "",
    account_type: "",
    bank_address: "",
  });
  const [reConfirmAcc, setReConfirmAcc] = useState("");

  const handleInputChange = (value, lineIndex, fieldName) => {
    console.log(moment(value?.$d, "HH:mm:ss").format("HH:mm:ss"), "value");
    const updatedTodos = hospitalTiming.map((line, index) => {
      if (index === lineIndex) {
        return {
          ...line,
          [fieldName]: moment(value?.$d, "HH:mm:ss").format("HH:mm:ss"),
        };
      }
      return line;
    });
    setHospitalTiming(updatedTodos);
  };

  const handleCheckboxChange = (e, lineIndex) => {
    const { checked } = e.target;
    const updatedTodos = hospitalTiming.map((line, index) => {
      if (index === lineIndex) {
        return { ...line, selected: checked };
      }
      return line;
    });
    setHospitalTiming(updatedTodos);
  };

  const addServiceWithCharge = () => {
    if (service.trim() !== "" && charge.trim() !== "") {
      const newService = {
        service: service,
        charge: charge,
        branch: branch?.id,
      };
      setServices([...services, newService]);
      setService("");
      setCharge("");
    }
  };

  const removeservice = (index) => {
    const updatedTodos = services?.filter((_, i) => i !== index);
    setServices(updatedTodos);
  };

  const createClinicalSetup = () => {
    setShowBackdrop(true);
    const payload = {
      map_setting: {
        branch: branch?.id,
        iframe: mapLink,
      },
      timings: hospitalTiming.filter((item) => item?.selected === true),
      service_charge: services,
      clinic_details: clinicDetails,
    };
    axios
      .post("apiV1/clinic-setup/", payload)
      .then((res) => {
        if (res?.status === 200) {
          console.log(res, "res of clic");
          openSnackbar(
            res?.data?.message ?? "Clinic Information added",
            "success"
          );
          navigate("/branch-doctor");
        }
        console.log(res);
      })
      .catch((err) => {
        openSnackbar("Something went wrong", "error");
        console.log(err);
      })
      .finally(() => setShowBackdrop(false));
  };

  const accountTypes = [
    { id: 1, value: "Saving", name: "Savings Account" },
    { id: 2, value: "Current", name: "Current Account" },
  ];

  console.log(mapLink.includes("<iframe"), "mapLink");

  console.log(hospitalTiming, "hospitalTiming");

  console.log(services, "services");
  console.log(clinicDetails, "clinicDetails");

  const checkForm1Validation = () => {
    if (!mapLink.includes("<iframe")) {
      openSnackbar("Add correct Map Iframe", "error");
      return;
    }
    setPage(page + 1);
  };
  const checkForm2Validation = () => {};
  const checkForm3Validation = () => {
    if (!services?.length > 0) {
      openSnackbar("Add atleast one Service & Charge", "error");
      return;
    }
    setPage(page + 1);
  };
  const checkForm4Validation = () => {
    if (!clinicDetails?.pan_number?.length > 0) {
      openSnackbar("Add Pan Card Number", "error");
      return;
    }

    if (!clinicDetails?.pan_holder_name?.length > 0) {
      openSnackbar("Add Pan Holder Name", "error");
      return;
    }
    if (clinicDetails?.is_gst) {
      if (!clinicDetails?.gst_no?.length > 0) {
        openSnackbar("Add GST Number", "error");
        return;
      }
      if (!clinicDetails?.gst_holder_name?.length > 0) {
        openSnackbar("Add Legal Name on GST Certificate", "error");
        return;
      }
    }

    setPage(page + 1);
  };
  const checkForm5Validation = () => {
    if (!clinicDetails?.account_holder_name?.length > 0) {
      openSnackbar("Add Account Holder Name", "error");
      return;
    }
    if (!clinicDetails?.account_number?.length > 0) {
      openSnackbar("Add Account Number", "error");
      return;
    }
    if (!reConfirmAcc?.length > 0) {
      openSnackbar("Add Confirm Account Number", "error");
      return;
    }
    console.log(
      clinicDetails?.account_number != reConfirmAcc,
      "acc",
      reConfirmAcc,
      clinicDetails?.account_number
    );
    // console.log(reConfirmAcc, "reco");
    if (clinicDetails?.account_number != reConfirmAcc) {
      openSnackbar(
        "Account Number is not equal to Re_confirm Acc Number ",
        "error"
      );
      return;
    }

    if (!clinicDetails?.bank_name?.length > 0) {
      openSnackbar("Add Bank Name", "error");
      return;
    }
    if (!clinicDetails?.branch_name?.length > 0) {
      openSnackbar("Add Branch Name", "error");
      return;
    }
    if (!clinicDetails?.ifsc_code?.length > 0) {
      openSnackbar("Add Ifsc Code", "error");
      return;
    }
    if (!clinicDetails?.account_type?.length > 0) {
      openSnackbar("Add account type", "error");
      return;
    }
    if (!clinicDetails?.bank_address?.length > 0) {
      openSnackbar("Add bank Address", "error");
      return;
    }
    createClinicalSetup();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
        onClick={() => setShowBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: 40,
          p: 3,
          backgroundColor: "#BFEBFB",
        }}
      >
        <Box>
          <img
            role={"button"}
            onClick={() => {
              navigate("/home");
            }}
            alt="Welldone Logo"
            className="my-auto"
            src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
            style={{
              height: 40,
              // width: 20,
            }}
          />
        </Box>
        <Typography variant="caption">
          Profile of Register Medical Practionar (RMP)
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          // border: "1px solid red",
          display: "flex",
          justifyContent: "space-evenly",
          my: 2,
        }}
      >
        <Box
          sx={{
            width: "60%",
            border: page < 5 ? "1px solid grey" : "none",
            borderBottom: "none",
            boxShadow: page < 5 ? "0px 0px 4px rgba(0, 0, 0, 0.25)" : "none",
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {page === 1 ? (
            <>
              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                <b>Establishment Location*</b>
              </Typography>

              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                Where are you located?
              </Typography>
              <Typography variant="caption" sx={{ pb: 1 }}>
                Paste Your Embed Link of your address by following below
                instructions.
              </Typography>
              <CustomTextField
                type="text"
                name="map_link"
                value={mapLink}
                onChange={(e) => {
                  setMapLink(e.target.value);
                }}
                placeholder="Paste your embedded map link"
              />
              {mapLink?.length ? (
                <Box sx={{ mt: 1 }}>
                  <iframe
                    src={mapLink?.split(" ")[1]?.split(' " ')[0]?.slice(5, -1)}
                    width="400"
                    height="250"
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Box>
              ) : (
                <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                  <Typography>
                    <b>Instructions</b>
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption">
                      1) Click on the "Open Google Map" Button
                    </Typography>
                    <Chip
                      sx={{ cursor: "pointer", ml: 1 }}
                      variant="outlined"
                      size="small"
                      color="primary"
                      label="Open Google Map"
                      onClick={() => {
                        const url = "https://www.google.com/maps"; // Replace with your desired URL
                        window.open(url, "_blank");
                      }}
                    />
                  </Box>
                  <Typography variant="caption">
                    2)Select Your Hospital on the map
                  </Typography>
                  <Typography variant="caption">
                    3)After selecting your hospital from the map, click on 3
                    lines at top left upper corner of the sidebar.
                  </Typography>
                  <Typography variant="caption">
                    4)Select "Share or embed link option".
                  </Typography>
                  <Typography variant="caption">
                    5)Click on "Embed a map" tab on it.
                  </Typography>
                  <Typography variant="caption">
                    6)Click on "Copy HTML" option and paste in the above input
                    field.
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button variant="outlined" disabled>
                  Back
                </Button>
                <Button variant="contained" onClick={checkForm1Validation}>
                  Save & Next
                </Button>
              </Box>
            </>
          ) : page === 2 ? (
            <>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography>Choose the day for the open office</Typography>
                {hospitalTiming?.map((each, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid lightgrey",
                      borderRadius: 3,
                      my: 1,
                      pt: 1,
                      pl: 1,
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={each.selected || false}
                      onChange={(e) => handleCheckboxChange(e, index)}
                    />
                    <Typography variant="subtitle2">
                      <b>{each?.day}</b>
                    </Typography>
                    <Box
                      sx={
                        {
                          // border: "1px solid green",
                        }
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          ml: 2,
                          // border: "1px solid red",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="caption">Open at</Typography>
                            <CustomMobileTimePicker
                              sx={{ p: 0 }}
                              value={each.start_time || null}
                              name="start_time"
                              onChange={(e) =>
                                handleInputChange(e, index, "start_time")
                              }
                              disabled={!each.selected}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: 1,
                            }}
                          >
                            <Typography variant="caption">Close at</Typography>
                            <CustomMobileTimePicker
                              value={each.end_time || null}
                              name="end_time"
                              // defaultValue={dayjs("2022-04-17T15:30")}
                              onChange={(e) =>
                                handleInputChange(e, index, "end_time")
                              }
                              disabled={!each.selected}
                            />
                          </Box>
                        </LocalizationProvider>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          ml: 2,
                          my: 1,
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="caption">
                              Lunch Break
                            </Typography>
                            <CustomMobileTimePicker
                              value={each.lunch_start_time || null}
                              name="lunch_start_time"
                              // defaultValue={dayjs("2022-04-17T15:30")}
                              onChange={(e) =>
                                handleInputChange(e, index, "lunch_start_time")
                              }
                              disabled={!each.selected}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: 1,
                            }}
                          >
                            <Typography variant="caption">to</Typography>
                            <CustomMobileTimePicker
                              value={each.lunch_end_time || null}
                              name="lunch_end_time"
                              // defaultValue={dayjs("2022-04-17T15:30")}
                              onChange={(e) =>
                                handleInputChange(e, index, "lunch_end_time")
                              }
                              disabled={!each.selected}
                            />
                          </Box>
                        </LocalizationProvider>
                      </Box>
                    </Box>
                    {/* <Divider sx={{ border: "1px solid red" }} /> */}
                  </Box>
                ))}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button variant="outlined" onClick={() => setPage(page - 1)}>
                    Back
                  </Button>
                  <Button variant="contained" onClick={() => setPage(page + 1)}>
                    Save & Next
                  </Button>
                </Box>
              </Box>
            </>
          ) : page === 3 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  <b>Charge of Services Fees*</b>
                </Typography>
                <Box>
                  {services?.map((each, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          border: "1px solid lightgrey",
                          p: 1,
                          borderRadius: 2,

                          my: 1,
                          width: "95%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>{each?.service}</Typography>
                        <Typography>₹{each?.charge}</Typography>
                      </Box>
                      <CancelRoundedIcon
                        sx={{ color: "red" }}
                        onClick={() => removeservice(index)}
                      />
                    </Box>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      my: 2,
                    }}
                  >
                    <CustomTextField
                      type="text"
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                      placeholder="Enter Service"
                    />
                    <CustomTextField
                      type="number"
                      value={charge}
                      onChange={(e) => setCharge(e.target.value)}
                      placeholder="Enter Charge"
                    />
                    <Button
                      variant="contained"
                      onClick={addServiceWithCharge}
                      sx={{ p: 1, background: "#FFCD1E", color: "#000000" }}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ p: 1, my: 1, background: "#F3E2FF" }}>
                  <Typography variant="caption">
                    These charges are reported by the practitioner for
                    informational purposes only. Finally, this may vary
                    depending on the type of treatment performed in the office,
                    the number of consultations, and the additional procedures
                    required. If prices are exceeded, the practitioner should
                    already have a cautionary element for the patient.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button variant="outlined" onClick={() => setPage(page - 1)}>
                    Back
                  </Button>
                  <Button variant="contained" onClick={checkForm3Validation}>
                    Save & Next
                  </Button>
                </Box>
              </Box>
            </>
          ) : page === 4 ? (
            <>
              <Typography sx={{ my: 1 }}>
                <b>ORGANIZATIONAL DETAILS*</b>
              </Typography>
              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                Pan Card Number
              </Typography>
              <CustomTextField
                type="text"
                name="pan_number"
                value={clinicDetails?.pan_number}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setClinicDetails({
                    ...clinicDetails,
                    [name]: value,
                  });
                }}
                placeholder="Enter Pan Number"
              />

              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                Name of Pan Card Holder
              </Typography>
              <CustomTextField
                type="text"
                name="pan_holder_name"
                value={clinicDetails?.pan_holder_name}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setClinicDetails({
                    ...clinicDetails,
                    [name]: value,
                  });
                }}
                placeholder="Enter Name"
              />

              <Typography variant="subtitle2">Is the GST number</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="is_gst"
                  defaultValue="Yes"
                  value={clinicDetails?.is_gst}
                  onChange={(e) => {
                    setClinicDetails({
                      ...clinicDetails,
                      is_gst: e.target.value === "true",
                    });
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />

                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>

              {clinicDetails?.is_gst ? (
                <>
                  <Typography variant="subtitle2" sx={{ pb: 1 }}>
                    Is this business registered for GST?
                  </Typography>
                  <CustomTextField
                    type="text"
                    name="gst_no"
                    value={clinicDetails?.gst_no}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setClinicDetails({
                        ...clinicDetails,
                        [name]: value,
                      });
                    }}
                    placeholder="Enter your GSTIN *"
                  />
                  <Typography variant="subtitle2" sx={{ pb: 1 }}>
                    Legal Name on GST Certificate
                  </Typography>
                  <CustomTextField
                    type="text"
                    name="gst_holder_name"
                    value={clinicDetails?.gst_holder_name}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setClinicDetails({
                        ...clinicDetails,
                        [name]: value,
                      });
                    }}
                    placeholder="Enter Name"
                  />
                </>
              ) : (
                ""
              )}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Button variant="outlined" onClick={() => setPage(page - 1)}>
                  Back
                </Button>
                <Button variant="contained" onClick={checkForm4Validation}>
                  Save & Next
                </Button>
              </Box>
            </>
          ) : page === 5 ? (
            <>
              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                Account Holder Name
              </Typography>
              <CustomTextField
                type="text"
                name="account_holder_name"
                value={clinicDetails?.account_holder_name}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setClinicDetails({
                    ...clinicDetails,
                    [name]: value,
                  });
                }}
                placeholder="Enter Account Holder Name"
              />
              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                Account Number
              </Typography>
              <CustomTextField
                type="number"
                name="account_number"
                value={clinicDetails?.account_number}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setClinicDetails({
                    ...clinicDetails,
                    [name]: value,
                  });
                }}
                placeholder="Enter Account Number"
              />
              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                Re-Confirm Account Number
              </Typography>
              <CustomTextField
                type="number"
                value={reConfirmAcc}
                onChange={(e) => {
                  setReConfirmAcc(e.target.value);
                }}
                placeholder="Enter Account Number"
              />
              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                Bank Name
              </Typography>
              <CustomTextField
                type="text"
                name="bank_name"
                value={clinicDetails?.bank_name}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setClinicDetails({
                    ...clinicDetails,
                    [name]: value,
                  });
                }}
                placeholder="Enter Bank Name"
              />

              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                Branch Name
              </Typography>
              <CustomTextField
                type="text"
                name="branch_name"
                value={clinicDetails?.branch_name}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setClinicDetails({
                    ...clinicDetails,
                    [name]: value,
                  });
                }}
                placeholder="Enter Bank Name"
              />

              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                IFSC Code
              </Typography>
              <CustomTextField
                type="text"
                name="ifsc_code"
                value={clinicDetails?.ifsc_code}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setClinicDetails({
                    ...clinicDetails,
                    [name]: value,
                  });
                }}
                placeholder="Enter 11 Digit IFSC code"
              />

              <Box>
                <Typography variant="subtitle2">Account Type</Typography>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    value={clinicDetails?.account_type}
                    onChange={(e) => {
                      setClinicDetails({
                        ...clinicDetails,
                        account_type: e.target.value,
                      });
                    }}
                    placeholder="select"
                    fullWidth
                    style={{ width: "100%", height: "40px" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={""} disabled>
                      Select
                    </MenuItem>
                    {accountTypes?.map((each) => (
                      <MenuItem value={each?.value}>{each?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Typography variant="subtitle2" sx={{ pb: 1 }}>
                Branch Address
              </Typography>
              <CustomTextField
                type="text"
                name="bank_address"
                value={clinicDetails?.bank_address}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setClinicDetails({
                    ...clinicDetails,
                    [name]: value,
                  });
                }}
                placeholder="Enter Address"
              />

              <Typography variant="caption" sx={{ my: 1 }}>
                By submitting these bank details, I agree to accept online
                payments from patients after consulting through
                weldonehealth.in. Fees received from patients will be credited
                to this account.
              </Typography>
              <Typography variant="caption" sx={{ my: 1 }}>
                <b>Note :</b>
                Upon submission of details, ₹1 will be credited to your account.
                This is to ensure that all your future settlements, including
                Weldon's, are seamless. For any queries or changes in the
                submitted details, please mail us at support@welldonehealth.in
                or contact us at +91-9999999999.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button variant="outlined" onClick={() => setPage(page - 1)}>
                  Back
                </Button>
                <Button variant="contained" onClick={checkForm5Validation}>
                  Submit
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    </>
  );
}

export default DoctorInHospitalForm;
