export const getUserLevel = (userType) => {
  switch (userType) {
    case "IS_SUPERADMIN":
      return 1;
      break;
    case "IS_ADMIN":
      return 2;
      break;
    case "IS_PATIENT":
      return 3;
      break;
    case "IS_DOCTOR":
      return 4;
      break;
    default:
      return -1;
  }
};
