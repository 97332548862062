import React, { useEffect, useState } from "react";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import PatientAvatar from "../../assest/img/patient.png";

import { Badge, Button, message, Popconfirm, Spin, Tag } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Members = () => {
  const navigate = useNavigate();
  const [patientsData, setPatientsData] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log("patientsData", patientsData);
  const location = useLocation();
  console.log("locn state", location?.state);
  const getPatientsData = () => {
    setLoading(true);
    axios
      .get("/apiV1/patient/")
      .then((res) => {
        setPatientsData(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getPatientsData();
  }, []);

  const [htmlContent, setHtmlContent] = useState("");

  const handlePayment = (appId, amount) => {
    setLoading(true);
    axios
      .post(`apiV1/payment/`, {
        appointment: appId,
        amount,
      })
      .then((res) => {
        console.log("res", res?.data);
        setHtmlContent(res?.data);
        // document.innerHTML = <p>hiiii</p>;
        // const bodyEl = document.querySelector("body");
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log("htmlContent", htmlContent);

  const deleteMember = (memberId) => {
    axios
      .delete(`/apiV1/patient/${memberId}/`)
      .then(() => {
        message.success("member deleted successfully");
      })
      .catch(() => {
        message.error("member deletion failed");
      });
  };

  return (
    <>
      {htmlContent && (
        <iframe
          style={{ position: "fixed", zIndex: 1000 }}
          srcDoc={htmlContent}
          width="100%"
          height="100%"
          frameBorder="0"
          title="HTML Content"
        />
      )}

      <div
        style={{
          minHeight: "60vh",
          p: 1,
          my: 2,
        }}
      >
        <Spin size="large" spinning={loading}>
          <div className="th-container p-5">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 th-16">
                {" "}
                Here you can see the list of all members and also add new
                member.
              </p>
              <Button
                onClick={() => {
                  navigate("/patient-reg");
                }}
                icon={<i class="fas fa-plus-circle"></i>}
                type="primary"
              >
                Add New Patient
              </Button>
            </div>
            <div className="row   ">
              {patientsData?.map((item, index) => {
                return (
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                    <div className=" card  th-br-10 w-100 p-3 rounded  shadow-sm h-100 d-flex flex-column justify-content-between">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-wrap justify-content-between py-2 ">
                          <img
                            alt="pat_img"
                            src={item?.avatar ? item?.avatar : PatientAvatar}
                            width="50px"
                            height="50px"
                            className="th-br-10 bg-white shadow-sm rounded-circle"
                          />
                          <div
                            className="pl-3"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div className="d-flex align-items-center ">
                              <h5 className="m-0 pr-1">
                                {[
                                  item?.first_name ?? "",
                                  item?.last_name ?? "",
                                ]?.join(" ")}
                              </h5>
                              <div>
                                ({item?.age},
                                {item?.gender?.substring(0, 1)?.toUpperCase()})
                              </div>
                              <Badge
                                style={{
                                  backgroundColor: "#01549a",
                                }}
                                className="ml-2"
                                count={item?.relation}
                              />
                            </div>
                            <div className="d-flex flex-wrap mt-2">
                              {item?.contact && (
                                <Tag
                                  icon={<i class="fas fa-phone-alt mr-1"></i>}
                                >
                                  {item?.contact}
                                </Tag>
                              )}
                              {item?.address && (
                                <Tag
                                  icon={
                                    <i class="fas fa-map-marker-alt mr-1"></i>
                                  }
                                >
                                  {item?.address}
                                </Tag>
                              )}
                            </div>
                          </div>
                        </div>
                        <Popconfirm
                          title={`Delete ${item?.first_name} as member!`}
                          description="Are you sure to delete this member?"
                          onConfirm={() => {
                            deleteMember(item?.id);
                          }}
                          onCancel={() => {
                            message.error("Cancelled");
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteTwoToneIcon sx={{ color: "red" }} />
                        </Popconfirm>
                      </div>
                      <div className="">
                        <div className="d-flex flex-wrap  mt-2">
                          {item?.weight && (
                            <Tag className="m-1">
                              <span>
                                <i className="th-grey fas fa-weight mr-2"></i>
                                {item?.weight}kg
                              </span>
                            </Tag>
                          )}
                          {item?.temperature && (
                            <Tag className="m-1">
                              <span>
                                <i className="th-grey fas fa-temperature-high mr-2"></i>
                                {item?.temperature}° C
                              </span>
                            </Tag>
                          )}
                          {item?.menstrual && (
                            <Tag className="m-1">
                              <span>
                                <img
                                  src="https://cdn-icons-png.flaticon.com/128/4080/4080345.png"
                                  height={16}
                                  className="mr-2"
                                  alt="periods"
                                />
                                {item?.menstrual}M/c
                              </span>
                            </Tag>
                          )}
                          {item?.low_bp && item?.high_bp && (
                            <Tag className="m-1">
                              <span>
                                <img
                                  src="https://cdn-icons-png.flaticon.com/128/811/811932.png"
                                  height={16}
                                  className="mr-2"
                                  alt="periods"
                                />
                                {item?.low_bp}/{item?.high_bp} mm/hg
                              </span>
                            </Tag>
                          )}
                          {item?.respiratory_rate && (
                            <Tag className="m-1">
                              <span>
                                <img
                                  src="https://cdn-icons-png.flaticon.com/128/2833/2833035.png"
                                  height={16}
                                  className="mr-2"
                                  alt="periods"
                                />
                                {item?.respiratory_rate}/min
                              </span>
                            </Tag>
                          )}
                          {item?.glucose_level && (
                            <Tag className="m-1">
                              <span>
                                <img
                                  src="https://cdn-icons-png.flaticon.com/128/3209/3209174.png"
                                  height={16}
                                  className="mr-2"
                                  alt="periods"
                                />
                                {item?.glucose_level} mg/DL
                              </span>
                            </Tag>
                          )}
                          {item?.pulse && (
                            <Tag className="m-1">
                              <span>
                                <img
                                  src="https://cdn-icons-png.flaticon.com/128/8226/8226674.png"
                                  height={16}
                                  className="mr-2"
                                  alt="periods"
                                />
                                {item?.pulse}/min
                              </span>
                            </Tag>
                          )}
                          {item?.height && item?.height_unit && (
                            <Tag className="m-1">
                              <span>
                                <img
                                  src="https://cdn-icons-png.flaticon.com/128/2330/2330995.png"
                                  height={16}
                                  className="mr-2"
                                  alt="periods"
                                />
                                {item?.height}
                                {item?.height_unit}
                              </span>
                            </Tag>
                          )}
                          {item?.blood_group && (
                            <Tag
                              icon={
                                <img
                                  src="https://cdn-icons-png.flaticon.com/128/8879/8879089.png"
                                  height={14}
                                  className="mr-1"
                                  alt="periods"
                                />
                              }
                              className=""
                            >
                              <span style={{ textTransform: "uppercase" }}>
                                {item?.blood_group}
                              </span>
                            </Tag>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default Members;
