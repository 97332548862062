import React, { useEffect, useState, useRef } from "react";

const Typewriter = ({ textArray }) => {
  const typewriterRef = useRef(null);
  const txtRef = useRef(null);
  const [txt, setTxt] = useState("");
  const period = 2000;

  useEffect(() => {
    const typewriter = new TypewriterEffect(
      typewriterRef.current,
      textArray,
      period
    );
    typewriter.start();

    // INJECT CSS
    const css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #01549A}";
    document.body.appendChild(css);

    return () => {
      typewriter.stop();
    };
  }, []);

  class TypewriterEffect {
    constructor(el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = "";
      this.isDeleting = false;
    }

    start() {
      this.tick();
    }

    stop() {
      clearTimeout(this.timeout);
    }

    tick() {
      const i = this.loopNum % this.toRotate.length;
      const fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      txtRef.current.innerHTML = this.txt;

      let delta = 200 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      this.timeout = setTimeout(() => this.tick(), delta);
    }
  }

  return (
    <h3>
      <a
        ref={typewriterRef}
        href=""
        className="typewrite "
        data-period="2000"
        data-type={JSON.stringify(textArray)}
        role="article"
        style={{ cursor: "default", color: "black" }}
      >
        {" "}
        <span ref={txtRef} className="wrap  th-text-gradient"></span>
      </a>
    </h3>
  );
};

export default Typewriter;
