const TypeofClinicalEstablishment = [
  {
    type: { id: 1, name: "Inpatient", value: "inpatient" },
    subType: [
      { id: 1, name: "Hospital", value: "hospital" },
      { id: 2, name: "Nursing Home", value: "nursing_home" },
      { id: 3, name: "Maternity Home", value: "maternity_home" },
      { id: 4, name: "Primary Health Centre", value: "primary_health_center" },
      {
        id: 5,
        name: "Community Health Centre",
        value: "community_health_center",
      },
      { id: 6, name: "Sanatorium", value: "sanaatorium" },
      { id: 7, name: "Palliative Care", value: "palliative_care" },
      // { id: 8, name: "Any other, please specify", value: "others" },
    ],
  },
  {
    type: { id: 2, name: "Outpatient", value: "outpatient" },
    subType: [
      { id: 1, name: "Single Practitioner", value: "single_practioner" },
      { id: 2, name: "Polyclinic", value: "poly_clinic" },
      { id: 3, name: "Sub-Centre", value: "sub_center" },
      {
        id: 4,
        name: "Physiotherapy / Occupational Therapy Clinic",
        value: "physiotherapy-occupational_tharipy_clinic",
      },
      { id: 5, name: "Infertility Clinic", value: "infertility_clinic" },
      { id: 6, name: "Dental Clinic", value: "dental_clinic" },
      { id: 7, name: "Dialysis Centre", value: "dialysis_center" },
      { id: 8, name: "Day Care Centre", value: "day_care_center" },
      { id: 9, name: "Mobile Clinic", value: "mobile_clinic" },
      // { id: 10, name: "Any other, please specify", value: "others" },
    ],
  },
  {
    type: { id: 3, name: "Laboratory", value: "laboratory" },
    subType: [
      { id: 1, name: "Pathology", value: "pathology" },
      { id: 2, name: "Haematology", value: "haematology" },
      { id: 3, name: "Biochemistry", value: "biochemistry" },
      { id: 4, name: "Microbiology", value: "microbiology" },
      { id: 5, name: "Genetics", value: "genetics" },
      { id: 6, name: "Collection Centre", value: "collection_center" },
      // { id: 7, name: "Any other, please specify", value: "others" },
    ],
  },
  {
    type: { id: 4, name: "Imaging Centre", value: "imaging_center" },
    subType: [
      { id: 1, name: "X-Ray", value: "x_ray" },
      { id: 2, name: "Electro Cardio Graph (ECG)", value: "ecg" },
      { id: 3, name: "Ultra Sound", value: "ultrasound" },
      { id: 4, name: "CT Scan", value: "ct_scan" },
      { id: 5, name: "Magnetic Resonance Imaging (MRI)", value: "mri" },
      // { id: 6, name: "Any other, please specify", value: "others" },
    ],
  },
];

export default TypeofClinicalEstablishment;
