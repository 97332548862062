import React, { useEffect } from "react";

const TermsAndCondtions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ padding: "20px" }}>
      <h4>Terms and Conditions</h4>
      <p>
        This document ("Terms") is an electronic record in terms of the
        Information Technology Act, 2000 and the rules thereunder, as well as
        the amended provisions relating to electronic records in various Acts
        amended by Information Technology from time to time. Act, 2000. This
        electronic record is generated by computer system and does not require
        any physical or digital signature.
      </p>
      <p>
        This document is published in accordance with the provisions of Rule
        3(1) of the Information Technology (Mediator Guidelines and Digital
        Media Code of Conduct) Rules, 2021, as amended from time to time, for
        which rules and regulations, privacy policy and website
        www.welldonehealth.in and TERMS OF ACCESS OR USE OF THE MOBILE
        APPLICATION.
      </p>
      <p>1. General</p>
      <p>
        We, at Welldone Healthcare Private Limited, a company duly incorporated
        under the provisions of the Companies Act, 2013, having our registered
        office at G.NO.91/1, PRASAD NAGAR, BHADANE TAL.SAKRI, Dhule, Dhule,
        Maharashtra, India, 424304 (“welldone”, “we”, “us”, “our” “Company”)
        (collectively the “Platform”). ) for any reason (“You”, “Your”, “User”)
        subject to the notices, terms and conditions set forth in these Terms
        and Conditions (“Terms and Conditions”, “Agreement”, “Terms”), read
        together with the Privacy Policy available here
        https://www.welldonehealth.in/privacy. The domain name
        www.welldonehealth.in, an internet-based portal are owned, operated and
        maintained by Welldone. Welldone may assign, transfer and subcontract
        its rights and/or obligations under these Terms and Conditions to any
        third party it deems appropriate and you shall be bound by these Terms
        and Conditions in the event of such assignment, transfer. , or
        subcontract. Our Platform is operated, and the Services are provided in
        compliance with the laws of India and Welldone shall not be liable for
        the provision of any Services by you in locations outside India. If you
        access our Services from locations outside India, you do so at your own
        risk and are solely responsible for compliance with applicable local
        laws. The User agrees to use the Service to authorize an individual and
        obtain Services from a third party on his/her behalf. Where you use any
        third party website or any third party services, you will be subject to
        the applicable third party's alternative or additional terms and
        conditions and privacy policies. aCCESSING OR BROWSING THE PLATFORM AND
        USING THE SERVICES (AS DEFINED IN THESE TERMS AND CONDITIONS) INDICATE
        YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT. If you
        disagree with any part of the Terms and Conditions, you may discontinue
        accessing or using the Platform.
      </p>
      <p>ELIGIBILITY</p>
      <p>
        When you use the Platform, you represent that you meet the following
        primary eligibility criteria:
      </p>
      <p>
        a. You are at least 18 years of age or are accessing the Platform under
        the supervision of a parent or guardian, who in such circumstances shall
        be deemed to be the recipient/end user of the Services (as defined in
        these Terms and Conditions) and these Terms and Conditions.
      </p>
      <p>
        b. If you are under 18 years of age, your parent or legal guardian may
        transact on your behalf if they are registered users. You are prohibited
        from purchasing any content that is prohibited for sale or purchase by
        minors and is intended for use by adults only.
      </p>
      <p>
        c. You are legally able to contract and otherwise receive the Services
        (as defined in these Terms and Conditions). Persons who are "incapable
        of contracting" within the meaning of the Indian Contract Act, 1872,
        including undischarged insolvents, etc. are not eligible to use the
        Platform.
      </p>
      <p>
        d. You have not been previously suspended or fired by Welldone or any
        other Welldone Group entity or disqualified from availing the Services
        for any other reason.
      </p>
      <p>
        e. Welldone reserves the right to terminate your membership and/or deny
        you access to the Platform if Welldone learns that you are under 18
        years of age.
      </p>
      <p>
        f. Your Registered ID can only be used by you in which your details have
        been provided and we do not allow more than one person to share a single
        login/registration ID.
      </p>
      <p>
        g.We reserve our right to deny access to new users to use the services
        offered on the Platform or to terminate the access granted to existing
        users at any time without assigning any reason and you have no right to
        object thereto.
      </p>
      <p>
        h. Organizations, companies and businesses cannot become registered
        users on the Platform or use the Platform by individual users.
      </p>
      <p>
        i. You agree and acknowledge that you will (i) create only 1 (one)
        Account; (ii) provide accurate, true, current and complete information
        when creating your account and in all of your transactions through the
        Platform; (iii) maintain and promptly update your account information;
        (iv) maintain the security of your account by not sharing your password
        with others and by restricting access to your account and your computer;
        (v) promptly notify Welldone if you discover or otherwise suspect any
        security breach related to the Platform; and (vi) take responsibility
        for all activities that occur under your account and accept the risk of
        unauthorized access.
      </p>
      <p>
        j. We reserve the right, in our sole discretion, to permanently or
        temporarily suspend users, restrict use and access to the Platform, when
        we investigate complaints or alleged violations of these Terms of Use or
        any Services, or for any other reason.
      </p>
      <p>3. Our Services</p>
      <p>
        Through the Platform, we provide you with the following services
        ("Services"):
      </p>
      <p>a. Creating and maintaining user accounts:</p>
      <p>
        Users must register with the Platform in order to use the Platform or
        services provided by the Platform. To register, you need to provide some
        personal details including your name, phone number, email address, date
        of birth, gender, etc.
      </p>
      <p>b. Scheduling an appointment for medical consultation:</p>
      <p>
        1. You may book an appointment for a medical consultation with a
        healthcare provider/doctor/physician (“HSP”) listed on the Platform.
        Medical consultation will be available on the platform for various
        specialties.
      </p>
      <p>
        2. You will receive appointment confirmation for medical consultation
        with HSP on the Platform and/or through SMS and/or email/online
        communication messaging services. We reserve the right to reschedule or
        cancel appointments without prior notice. The time allotted to you for
        consultation is indicative and the actual consultation time may vary at
        the discretion of Consultant HSP or other circumstances, for which you
        will be communicated accordingly. You agree that there may be
        technical/logistical/unforeseen circumstances which may lead to delay or
        cancellation of the consultation for which appropriate resolution shall
        be provided as either rescheduling/refund. For any support related to
        issues you face before/during/after the consultation, you can reach us
        through various channels as mentioned on the Platform or as mentioned in
        Clause 11.8 of these Terms and our team will assist you. for the same. A
        consultation can be booked, rescheduled or canceled up to 15 (fifteen)
        minutes prior to the scheduled/booked appointment time and up to 30
        (thirty) minutes for audio/video/chat-based consultation (“online
        medical consultation”). ) a few minutes before the scheduled/booked
        appointment time for face-to-face consultation. You are allowed to
        reschedule a free online medical consultation a maximum of 3 (three)
        times and a free physical consultation once.
      </p>
      <p>
        3. Your consultant HSP reserves the right to provide free review,
        post-medical advice.
      </p>
      <p>c. Seeking medical advice:</p>
      <p>
        You can seek medical advice online through audio call or video call or
        chat with the HSP you have selected/allocated on the platform:
      </p>
      <p>
        Please ensure that you are equipped with appropriate video conferencing
        equipment and internet connectivity for the service you wish to avail.
        In case of inadequate video conference equipment, technical glitches or
        faulty internet connectivity, we reserve the right to reschedule or
        cancel your appointment without any notice.
      </p>
      <p>
        You understand and acknowledge that there will be no physical
        examination and online medical consultation services will be provided
        remotely in accordance with these terms and conditions. The opinion
        delivered by the HSP will be based solely on the verbal communication
        between the concerned HSP and the patient and any test reports and other
        information provided/uploaded by you on the platform.
      </p>
      <p>
        Your medical records may be viewed by HSP on the chat facility provided
        on the Platform during a medical consultation and will remain accessible
        to you for a certain number of days after the end of a particular
        consultation, as determined by HSP.
      </p>
      1. The Service is by no means intended for emergency and life-threatening
      situations. In such cases it is advised to take the patient to the nearest
      hospital or as per the advice of HSP.
      <p>
        2. If HSP does not respond then you are advised to contact our customer
        care.
      </p>
      <p>3. Obtaining e-prescription from HSP based on medical advice:</p>
      <p>
        You will get an e-prescription on the platform after consultation with
        the concerned HSP
      </p>
      <p>e. Accessing your medical records on the Platform:</p>
      <p>You can access your medical records on the platform.</p>
      <p>f. To book diagnostic tests:</p>
      <p>
        You can book an appointment to find diagnostic tests and packages
        offered by HSP on the platform. You can book diagnostic tests and
        packages on the platform and visit HSP's respective diagnostic
        center/lab or schedule sample pickup from home through HSP. g Any other
        services
      </p>
      <p>
        It is made available on the Platform from time to time subject to the
        scope of services specified under Section 4 below.
      </p>
      <p>4. YOUR USE OF THE PLATFORM</p>
      <p>
        As an end user and recipient of the Services, when you use the Platform,
        you agree to the following Terms of Use:
      </p>
      <p>A. Due Diligence Conditions:</p>
      <p>
        a. You are solely responsible for the medical, health and personal
        information you provide on the Platform and you are requested to use
        your discretion in providing such information.
      </p>
      <p>
        b. Any advice or service provided by HSP will depend on the information
        you provide on the Platform. You will provide accurate and complete
        information throughout the Platform, based on which you will receive the
        Services.
      </p>
      <p>
        c. You are solely responsible for all access to and use of this Site
        whether or not access and use of this Site is authorized by you using
        the password and identification originally assigned to you, including,
        without limitation, all communications and transmissions. AND ALL
        LIABILITIES (INCLUDING, WITHOUT LIMITATION, FINANCIAL LIABILITIES)
        INCURRED BY SUCH ACCESS OR USE. You are solely responsible for
        protecting the security and confidentiality of passwords and identities
        assigned to you.
      </p>
      <p>
        d. The information provided by you may be used by us for the purpose of
        services including analysis, research, training and disclosure (where
        necessary) to our affiliates, group companies, agents and government
        authorities etc. as stated in our Privacy Policy (our Privacy Policy).
        Available at https://www.welldonehealth.in/privacy). Please review
        separately and consent to our Privacy Policy accordingly.
      </p>
      <p>
        e. In accordance with our Privacy Policy available at
        https://www.welldonehealth.in/privacy, the information you provide may
        be retained by us and used without disclosing your identity.
      </p>
      <p>
        f. We reserve our right to refuse service or terminate accounts in our
        sole discretion if we believe so
      </p>
      <p>
        You have violated or are likely to violate any applicable law or these
        Terms and Conditions.
      </p>
      <p>B. Scope of Services:</p>
      <p>
        a. The services you receive from HSP through the Platform are an
        arrangement between you and the HSP you select. The Platform only
        facilitates the connection between you and HSP and assumes no
        responsibility for the results of any such medical consultation or other
        services obtained by you.
      </p>
      <p>
        b. We shall not be responsible for
        service/misdiagnosis/misdiagnosis/error in interpretation/perception
        error/adverse event/prescribed treatment or advice or examination
        report/validity of advice or ineffectiveness of prescription or
        examination reports provided by HSP. Non-availability of recommended or
        prescribed treatment or medication under any condition or circumstances.
        Users are advised to use their discretion to follow the advice received
        through the Platform or post medical consultation with HSP.
      </p>
      <p>
        c. We only establish the connection between you and HSP through the
        Platform and in no way facilitate, encourage, permit or require you to
        contact HSP outside of the Platform. Any contact between you and HSP
        through the Platform shall be subject to these Terms and Conditions.
      </p>
      <p>
        d. If you are redirected from the Platform to any other website or app
        for the purpose of browsing or placing and/or completing an order (or
        any matter related thereto) to a different website/platform, we may
        receive or receive information from you on the other website or
        platform. We shall not be responsible for the products or services
        purchased or any aspect of your experience on such websites or any
        representations and particulars provided on such websites/platforms.
        Products/services being displayed or sold through such
        websites/platforms. In particular, you may visit or be redirected to the
        Welldone Pharmacy website Pharmacy interface(collectively the “Welldone
        Platform”) at https://www.welldonehealth.in/pharmacy for browsing and
        purchasing. . Medicines, approved medical devices and other products
        (including all aspects relating to the placement of orders and payments
        in respect thereof), which is exclusively supported, owned, controlled
        and operated by a separate and independent entity. Welldone has no
        control over the Welldone Platform (including the information and
        content displayed therein, the list of products sold and its pricing and
        its presentation/display) and shall have no responsibility or liability
        for your experience. The Welldone Platform, including products sold to
        you and delivered to you through the Welldone Platform, and any customer
        service support you may receive in connection with such products. The
        entire user experience and data related to your use of the Welldone
        Platform will be stored exclusively by Welldone on its own servers. By
        visiting the Welldone Platform, you agree to and accept the Welldone
        Platform Terms of Use. Welldone will not be involved in any interaction
        between you and Welldone with respect to products purchased on the
        Welldone Platform, including any payment made by you on the Welldone
        Platform, and all products purchased by you on the Welldone Platform
        will be sold, packaged, delivered. , delivered and completed. . Only
        through Welldone. For avoidance of doubt, it is expressly clarified that
        – (i) neither Welldone nor we act as a 'marketplace' in relation to the
        display, display, distribution, fulfillment, purchase or sale of any
        products on the Welldone Platform; (ii) Welldone is not a seller on the
        Platform and by merely providing a redirect link, Welldone does not
        authorize or permit Welldone to become a seller on the Platform. You
        also acknowledge and agree that through the Platform Welldone: (i)
        provides a link to the Welldone Platform for redirection only; (ii) only
        provides Welldone with relevant technical connectors to help seamlessly
        integrate the Welldone Platforms with the Application with minimal
        disruption to your experience; and (iii) act solely as a communication
        link with respect to any complaints relating to products listed on the
        Welldone Platform (at the behest of Welldone), the actual resolution of
        complaints made by you on the Platform being handled solely by Welldone.
        You also agree and acknowledge that Welldone is in no way, in any way
        involved and (i) does not facilitate the sale of products on the
        Welldone platform, Welldone is not privy to the contractual
        relationship/arrangement between Welldone and your purchase/sale.
        Products on the Welldone Platform (ii) Payments for Products sold on the
        Welldone Platform are solely facilitated by Welldone and/or any third
        party entities (including payment aggregators) involved. Welldone for
        such purposes; (iii) currency for products sold on the Welldone
        Platform;
      </p>
      <p>
        e. You may view and access the Content available on the Platform solely
        to avail the Services and only in accordance with these Terms and
        Conditions. You may not modify any Content on the Platform or reproduce,
        display, publicly display, distribute or otherwise use such Content for
        any public or commercial purpose or for personal gain.
      </p>
      <p>C. Restrictions:</p>
      <p>
        a. You may not reproduce, distribute, display, sell, lease, transmit,
        create derivative works, translate, modify, reverse-engineer,
        disassemble, decompile the Platform or any part thereof except as
        expressly permitted in writing by Welldone. cannot or otherwise exploit.
      </p>
      <p>
        b. You may not make commercial use of any information you provide on the
        Platform.
      </p>
      <p>
        c. You may not impersonate any person or entity or make false statements
        or otherwise misrepresent your identity, age or affiliation with any
        person or entity.
      </p>
      <p>
        d. You may not upload any content prohibited by applicable law and/or
        designated as "Prohibited Content" under Section 5.
      </p>
      <p>
        e. You may not contact or make any attempt to contact the respective HSP
        through email, SMS, messaging service or any other authorized channel
        for consultation, pre-medical consultation or any other purpose of
        communication outside the Platform.
      </p>
      <p>
        f. You may not assign, transfer or sub-contract any of your rights or
        obligations under these Terms or related orders for Products to any
        third party, except as agreed in writing by Welldone.
      </p>
      <p>5. Prohibited Content</p>
      <p>
        You must not upload, distribute or otherwise publish through the
        Platform the following prohibited content, including any content,
        information or other content:
      </p>
      <p>a. belongs to another person and for which you do not have rights;</p>
      <p>
        b. Harmful, harassing, defamatory, libelous, obscene, obscene,
        pedophilic, invasive of another's privacy, sexually offensive or
        harassing, abusive, racially or ethnically offensive, or otherwise
        objectionable or unlawful, including physical privacy. strength
      </p>
      <p>
        c. hateful, racially or ethnically offensive, offensive to any person;
      </p>
      <p>d. relates to or appears to promote money laundering or gambling,</p>
      <p>e. Harm minors in any way;</p>
      <p>
        f. infringes any patent, trademark, copyright or other proprietary
        right;
      </p>
      <p>g. Violates any law in India for the time being;</p>
      <p>
        h. deceives or misleads the addressee as to the origin of your message
        and knowingly communicates any information that is patently false or
        misleading but may be construed as fact;
      </p>
      <p>
        i. communicates any information of a highly offensive or harmful nature;
      </p>
      <p>j. impersonates another person;</p>
      <p>
        k. contains software viruses or any other computer code and malicious
        programs;
      </p>
      <p>
        l. file or program designed to interfere with, destroy or limit the
        functionality of any computer resource;
      </p>
      <p>
        m.endangering the unity, integrity, defence, security or sovereignty of
        India, friendly relations with foreign states or public order, creating
        enmity between different groups on the basis of religion or caste with
        the intention of inciting violence;
      </p>
      <p>
        n.is patently false and untrue and is written or published for the
        financial benefit of any person, organization or agency or with intent
        to injure, mislead or harass any person;
      </p>
      <p>
        o.Incites any crime or obstructs the investigation of any crime or
        insults any other nation;
      </p>
      <p>
        p. is insulting or inappropriate to any employee, consultant or
        technician of Welldone or any other Welldone Group company or related
        person with whom you may interact to obtain the Services; And
      </p>
      <p>
        q. Not related to medical consultation or any services you availed from
        us.
      </p>
      <p>
        You also understand and acknowledge that if you fail to comply with the
        above, we have the right to remove such information and/or immediately
        terminate your access to the Services and/or the Platform.
      </p>
      <p>6. Indemnification</p>
      <p>
        You agree and undertake to indemnify and hold harmless Welldone, its
        affiliates and any Welldone Group companies, relevant HSPs,
        representatives and each of their respective directors, officers or
        employees (“Indemnified Persons”) and us for any damages, costs and
        expenses. Fees, including reasonable attorneys' fees, and expenses which
        the indemnified persons concerned may suffer:
      </p>
      <p>
        Deficiencies or deficiencies in services/misdiagnosis/faulty
        judgments/errors in interpretation/misunderstandings arise from:
      </p>
      <p>
        a. Your failure to provide correct and/or complete patient medical
        information/history in a timely and medically appropriate manner; Or
      </p>
      <p>
        b. suppression of material facts; or your failure to provide relevant
        clinical information about the patient; Or
      </p>
      <p>
        c. Misinterpretation of advice/prescription/diagnosis/examination report
        by you; Or
      </p>
      <p>d. failure to follow doctor's advice/prescription by you; Or</p>
      <p>
        e. Failure by you to comply with HSP's instructions regarding services
        or medical procedures prescribed by HSP;
      </p>
      <p>
        Incorrect or inaccurate credit/debit card details provided by you; Or
      </p>
      <p>
        Using a credit/debit card that does not legitimately belong to you; Or
      </p>
      <p>
        You are allowing a third party to use your password or other means to
        access your account.
      </p>
      <p>7. Limitation of Liability</p>
      <p>
        By using our Services, you confirm that you understand and agree to the
        following:
      </p>
      <p>
        A. The services you receive from HSP through the Platform are provided
        to you by the HSP you choose, not by Welldone. THE LIMITATION OF
        LIABILITY SPECIFIED IN THIS SECTION ALSO APPLIES TO ANY SERVICES YOU
        OBTAIN FROM ANY THIRD-PARTY SELLER OF SERVICES LISTED ON THE PLATFORM.
      </p>
      <p>
        B. The Platform only facilitates communication between you and the HSP,
        and Welldone assumes no responsibility for the quality and results of
        any such services obtained by you from the relevant HSP, to the extent
        permitted by applicable law.
      </p>
      <p>
        C. Welldone itself does not provide any medical advice or diagnostic
        services. If you receive any medical advice/investigation report from an
        HSP contacted by you through the Platform, you are responsible for
        evaluating such advice, and reporting the consequences of acting on such
        advice or reports and all follow-up actions taken after the
        consultation. Following HSP's instructions.
      </p>
      <p>
        D. If Welldone markets or promotes any services to you, please note that
        such services will be provided by the relevant HSP and you are
        responsible for the relevant assessment.
      </p>
      <p>
        SUITABILITY OF SUCH SERVICES AND SUCH HSP FOR YOUR PURPOSES. Marketing
        or promotion of the Services should be considered for informational
        purposes only and does not constitute expert advice regarding the
        suitability of such Services for your particular healthcare needs.
      </p>
      <p>
        E. The services provided through the Platform are in no way a substitute
        for a face-to-face consultation with a physician. Welldone advises users
        to make independent evaluations regarding its accuracy or usefulness and
        usefulness before making any decisions in reliance thereon.
      </p>
      <p>
        F. To the extent permitted by applicable law, neither Welldone nor its
        affiliates or any Welldone group companies shall be liable for any
        special, indirect, incidental, consequential, punitive, consequential or
        exemplary damages for: (i) these Terms and Conditions and the Privacy
        Policy; (ii) your use of or inability to use the Platform; (iii) your
        use or inability to use AI Assistant (iv) your use of any third party
        services, including services provided by any HSP you contact through the
        Platform.
      </p>
      <p>
        This Section shall survive termination of this Agreement and termination
        of your use of our Services or Platform.
      </p>
      <p>8. Data and Information Policy</p>
      <p>
        We respect your right to privacy with respect to any personal
        information you provide to us for the purpose of availing our services.
        To see how we collect and use your personal information, please see our
        Privacy Policy available at https://www.welldonehealth.in/privacy.
      </p>
      <p>9. Intellectual Property and Ownership</p>
      <p>
        A. All Confidential Information, including underlying intellectual
        property (“IP”) developed by Welldone or its affiliates or developed by
        third parties under contract by Welldone or purchased or licensed by
        Welldone; , remains the sole and exclusive property of Welldone.
      </p>
      <p>
        B. You shall be bound by the following obligations in relation to
        ownership of Intellectual Property:
      </p>
      <p>
        All works created, developed, conceived or distributed as part of or in
        connection with materials, software, documentation, methods, equipment,
        systems and services, and all embodiments thereof, shall be deemed IP
        for any use of our Platform. This agreement.
      </p>
      <p>
        Welldone shall have exclusive title and ownership rights, including all
        worldwide intellectual property rights, in all Services. To the extent
        that exclusive title and/or ownership rights as contemplated herein are
        not originally vested in Welldone, you hereby irrevocably assign all
        right, title and interest in and to the Services, Medical Records and
        Information, including intellectual property and proprietary rights.
        Welldone, and your representatives shall irrevocably assign to Welldone
        all such rights in the IP.
      </p>
      <p>
        All use of, and goodwill associated with, any trademarks, service marks
        and trade names in the Services or in the performance of the Services,
        by you or third parties, is and will be for the benefit of Welldone.
      </p>
      <p>
        You agree not to circumvent, disable or otherwise interfere with the
        security-related features of the Platform or features that prevent or
        restrict the use or copying of any Content or otherwise limit the use of
        the Platform or its Content. Content on the Platform or otherwise may
        not be modified, copied, reproduced, distributed, republished,
        downloaded, displayed, sold, compiled, posted or transmitted in any form
        or by any means, including but not limited to electronic, mechanical,
        photocopying. , recording or other media.
      </p>
      <p>
        It may not be used without the prior written permission of Welldone.
      </p>
      <p>10. Other Terms</p>
      <p>A. Payment and Refunds:</p>
      <p>
        Please note that your payments are processed in accordance with
        applicable laws and for details you may refer to our Payment and Refund
        Policy and such policies are available at
        https://www.welldonehealth.in/refund-policy.
      </p>
      <p>B. AI Assistant</p>
      <p>
        We use AI assistants to guide patients seeking medical help or treatment
        from the platform. The primary purpose of this AI assistant is to allow
        users to book appointments by entering their symptoms, medical condition
        or treatment for which they wish to consult an HSP.
      </p>
      <p>You understand that:</p>
      <p>
        i. You should never use an AI assistant in a medical or psychiatric
        emergency;
      </p>
      <p>ii. In case of emergency, you dial 112;</p>
      <p>
        iii You may use this Platform on behalf of other users (third parties)
        only if you are the legal guardian of such persons, i.e. you have the
        legal right to look after the personal and property interests of such
        persons; And
      </p>
      <p>
        iv None of the Content on the Platform is intended or intended as a
        substitute for medical advice, care, diagnosis or treatment.
      </p>
      <p>
        The user may be directed to chat or call our patient support team, in
        certain cases including but not limited to:
      </p>
      <p>i. Uncertain results from an AI assistant;</p>
      <p>ii. changes in visitation schedule;</p>
      <p>
        iii. Appointment booking incomplete; iv Any other matter deemed
        appropriate by Welldone.
      </p>
      <p>C. Accuracy of information displayed</p>
      <p>
        We have made every effort to display information provided by third
        parties associated with HSPs as accurately as possible. However, we do
        not accept any liability in respect of such information or any other
        information in respect of which you are able to exercise your own due
        diligence to verify its accuracy.
      </p>
      <p>11. Miscellaneous:</p>
      <p>A. Third-party links and resources</p>
      <p>
        Where the Platform contains links to other sites and resources provided
        by third parties (including our social media sharing plug-in that
        includes links to third-party sites), these links are provided for your
        information only. We have no control over the content (including the
        Weldon Platform) or resources on those websites/platforms and accept no
        responsibility for them or for any harm or damage that may arise from
        their use.
      </p>
      <p>
        Welldone does not make any warranties or representations about the goods
        or services available or sold by such third parties. Welldone does not
        make any guarantees or recommendations about the products and/or
        services made available to you by such third parties as to the term,
        service, usefulness, business dealings. RELIABILITY, AVAILABILITY OR
        QUALITY OF PRODUCTS AND/OR SERVICES.
      </p>
      <p>
        YOU WILL INDEMNIFY WELDONe, ITS GROUP SUBSIDIARIES, AFFILIATES OR THEIR
        RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS AND/OR VENDORS FROM
        ANY ACTIONS, CLAIMS, DEMANDS, INDEMNITIES, FROM THE ES AND OR ACCESS TO
        THIRD PARTY WEBSITES/PLATFORMS. Expenses incurred, sustained or claimed
        by you or claimed to have been incurred, sustained or incurred directly
        or indirectly.
      </p>
      <p>
        Welldone is not a party to any contractual arrangement entered into
        between you and any third party website/platform. We are not agents of
        third parties and such third-party websites/platforms are governed
        solely by their respective policies over which Weldon has no control.
      </p>
      <p>
        Use of such links to visit third-party websites/platforms implies full
        acceptance of these terms and conditions. WELDONe SHALL NOT BE
        RESPONSIBLE OR LIABLE DIRECTLY OR INDIRECTLY FOR ANY DAMAGES OR LOSSES
        RESULTING FROM THE USE OF ANY CONTENT ON OR AVAILABLE THROUGH ANY
        THIRD-PARTY LINKED WEBSITE, INCLUDING TRANSMISSIONS RECEIVED FROM ANY
        THIRD PARTY. A PARTY'S WEBSITE (INCLUDING WITHOUT LIMITATION WELDONE) OR
        ITS SERVERS.
      </p>
      <p>B. improvement</p>
      <p>
        We may update or modify these Terms and Conditions from time to time.
        Each time you plan to use the Platform, please check the relevant Terms
        and Conditions and Privacy Policy to ensure you understand the terms
        applicable at that time.
      </p>
      <p>C. Forced affairs</p>
      <p>
        We shall not be liable for any failure or delay in the performance of
        any obligation assumed by us under any contract arising from events
        beyond our reasonable control (“Force Majeure”). Force majeure shall
        include any act, event, failure to exercise, omission or accident beyond
        our reasonable control, including:
      </p>
      <p>a. Strikes, lockouts or other forms of protest</p>
      <p>
        b. Civil unrest, insurrection, invasion, terrorist attack or threat of
        terrorism, war (whether or not declared) or threat or preparation for
        war.
      </p>
      <p>
        c. Fire, explosion, storm, flood, earthquake, collapse, epidemic or any
        other natural disaster.
      </p>
      <p>
        d. Inability to use public or private transportation and
        telecommunication systems.
      </p>
      <p>
        e. Any laws, orders, statutes, regulations or restrictions of any
        governmental or public authority, including any judicial determination.
      </p>
      <p>
        Our obligations under any contract will be deemed to be suspended during
        the period during which the force majeure is in effect and we will be
        granted an extension of time to fulfill these obligations during the
        period during which we communicate with you. The force majeure situation
        persists.
      </p>
      <p>D. termination</p>
      <p>
        We may terminate this arrangement without cause. If you wish to
        terminate this arrangement, you may do so at any time by discontinuing
        your access to or use of the Platform.
      </p>
      <p>
        We reserve the right to immediately refuse your use of the Services if
        your conduct is deemed by us to be in violation of applicable laws,
        statutes, rules and regulations or these terms and conditions or to be
        unethical/unethical; And
      </p>
      <p>
        In particular for a change in law, we reserve our right to indefinitely
        suspend our obligations under any contract and/or to provide services
        under revised terms and conditions.
      </p>
      <p>E. Applicable Laws, Jurisdiction and Dispute Resolution</p>
      <p>
        Use of our Platform shall be governed by the laws applicable in India,
        without reference to conflict of law principles;
      </p>
      <p>
        Any dispute relating to the use of our Services shall be subject to the
        exclusive jurisdiction of the courts located in Pune, India; And
      </p>
      <p>
        If any dispute, disagreement or claim arises between us and you with
        respect to this Agreement or the validity, interpretation, enforcement
        or alleged breach of this Agreement or anything excluded pursuant to
        these Terms and Conditions, you shall first attempt to resolve it
        amicably. NEGOTIATION However, if the dispute is not resolved through
        amicable and negotiated settlement within 30 days from the commencement
        of such conciliation or such period as may be mutually agreed in
        writing, we may refer the dispute to arbitration under the Indian
        Arbitration and Conciliation Act, 1996. by a sole arbitrator mutually
        appointed by us from time to time (the "Law"). If the parties do not
        agree on a sole arbitrator, the sole arbitrator shall be appointed in
        accordance with law. The seat and venue of the arbitration shall be at
        pune. The arbitration shall be conducted in the English language.
      </p>
      <p>F. variation</p>
      <p>
        If, for any reason, a court of competent jurisdiction finds any
        provision of these Terms, or any part thereof, to be unenforceable, that
        provision shall be enforced to the maximum extent permissible to give
        effect to the intent of the parties. That provision and the remainder of
        the Terms shall continue in full and in full force and effect.
      </p>
      <p>G. apology</p>
      <p>
        No provision of these Terms of Use shall be waived, and no breach
        waived, unless such waiver or consent is in writing and signed by
        Welldone. No consent by Welldone, or waiver by Welldone of any breach by
        other parties, whether express or implied, shall constitute a consent,
        waiver or waiver of any other different or subsequent breach.
      </p>
    </div>
  );
};

export default TermsAndCondtions;
