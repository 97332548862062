import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import {
  Button,
  Layout,
  Row,
  Col,
  Space,
  Input,
  Form,
  DatePicker,
  Select,
  Radio,
} from "antd";
import axios from "axios";
import moment from "moment/moment";
const { Content } = Layout;

const DoctorRegFormOne = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { branch } = location?.state;

  const [professionData, setProfessionData] = useState([]);

  const fetchProfession = () => {
    axios
      .get("apiV1/profession/")
      .then((res) => {
        console.log("profession//", res);
        setProfessionData(res?.data);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    fetchProfession();
  }, []);

  return (
    <Layout className="layout">
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 40,
            p: 3,
            backgroundColor: "#BFEBFB",
          }}
        >
          <Box>
            <img
              role={"button"}
              onClick={() => {
                navigate("/home");
              }}
              alt="Welldone Logo"
              className="my-auto"
              src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
              style={{
                height: 40,
                // width: 20,
              }}
            />
          </Box>
          <Typography variant="caption">
            Profile of Register Medical Practionar (RMP)
          </Typography>
        </Box>
      </Box>
      <Content
        style={{
          padding: "0 5px",
        }}
      >
        <div
          className="site-layout-content"
          style={{
            background: "",
            // height: "80vh",
            padding: "2px",
          }}
        >
          <Row style={{ height: "100%" }}>
            <Col>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  p: 1,
                  width: "100%",
                  height: "100%",
                  // border: "2px solid green",
                  background: "",
                }}
              >
                <Space direction="vertical" align="start">
                  <Typography variant="h6">
                    Hello Lets build your dedicated Professional profile.{" "}
                  </Typography>
                  <Form
                    onFinish={(value) => {
                      console.log("form finish ", value);
                      navigate("/doctor-reg/two", {
                        state: {
                          branch,
                          formOneData: {
                            ...value,
                            dob: moment(value?.dob).format("YYYY-MM-DD"),
                          },
                        },
                      });
                    }}
                  >
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={4}>
                        <Form.Item
                          label="Suffix"
                          name="suffix"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Suffix",
                            },
                          ]}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="First Name"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter First Name",
                            },
                          ]}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Last Name"
                          name="last_name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Last Name",
                            },
                          ]}
                        >
                          <Input placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Col span={8}>
                      <Form.Item
                        label="Middle Name (optional)"
                        name="middle_name"
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Col>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={8}>
                        <Form.Item
                          label="Date of Birth"
                          name="dob"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Date of Birth",
                            },
                          ]}
                        >
                          <DatePicker
                            popupPlacement="bottomLeft"
                            format="DD-MM-YYYY"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Gender"
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Gender",
                            },
                          ]}
                        >
                          <Select>
                            <Select.Option value="male">Male</Select.Option>
                            <Select.Option value="female">Female</Select.Option>
                            <Select.Option value="notsay">
                              Prefer not to say
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Blood Group"
                          name="blood_group"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Blood Group",
                            },
                          ]}
                        >
                          <Select>
                            <Select.Option value="a+">A+</Select.Option>
                            <Select.Option value="a-">A-</Select.Option>
                            <Select.Option value="b+">B+</Select.Option>
                            <Select.Option value="b-">B-</Select.Option>
                            <Select.Option value="ab+">AB+</Select.Option>
                            <Select.Option value="ab-">AB-</Select.Option>
                            <Select.Option value="o+">O+</Select.Option>
                            <Select.Option value="o-">O-</Select.Option>
                            {/* <Select.Option value="a">A</Select.Option>
                            <Select.Option value="b">B</Select.Option>
                            <Select.Option value="ab">AB</Select.Option>
                            <Select.Option value="o">O</Select.Option> */}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                      }}
                    >
                      <Col span={12}>
                        <Form.Item
                          label="E-mail"
                          name="email"
                          extra="Post verification, your email will be used for sending
                          practice related communications"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Email",
                            },
                          ]}
                        >
                          <Input type="email" placeholder="" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="contact"
                          label="Mobile No."
                          extra="Post verification, your mobile number will be used for sending
                          practice related communications"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Mobile Number",
                            },
                          ]}
                        >
                          <Input type="mobile" placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Box sx={{ p: 2, width: "70%", height: "100%" }}>
                      <Typography variant="body1">
                        <b>Enter your address</b>
                      </Typography>
                      {/* <Form.Item>
                        <Radio.Group>
                          <Radio value="a">
                            {" "}
                            House no, AREA, CITY, STATE, Pin code, IN{" "}
                          </Radio>
                          <br />
                          <Radio value="b"> Add New Address </Radio>
                        </Radio.Group>
                      </Form.Item> */}
                      <Form.Item
                        label="House No/ building/ apartment"
                        // name="address.apartment"
                        name={["address", "appartment"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item
                        label="Area/ Colony/ Street/ Sector/ Village"
                        name={["address", "area"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item
                        label="Landmark"
                        name={["address", "landmark"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item
                        label="Town/City"
                        name={["address", "city"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item
                        label="PIN Code"
                        name={["address", "pincode"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item
                        label="Country"
                        name={["address", "country"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item
                        label="State / province /region"
                        name={["address", "state"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input placeholder="" />
                      </Form.Item>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <Typography variant="body1">
                        <b>Select Your Profession</b>
                      </Typography>
                      <Form.Item>
                        <Radio.Group>
                          <Radio value="a">Register </Radio>
                          <Radio value="b"> Provisional/ student</Radio>
                          <Radio value="c"> Other </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label="Select Your Profession">
                        <Select>
                          {professionData?.map((item, index) => {
                            return (
                              <Select.Option value={item?.profession}>
                                {item?.profession}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Button type="primary" htmlType="submit" size={"large"}>
                        Continue
                      </Button>
                    </Box>
                  </Form>
                  {/* <Divider /> */}
                </Space>
              </Box>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default DoctorRegFormOne;
