import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Avatar,
  Select,
  Divider,
  Typography,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextareaAutosize,
  FormGroup,
  Chip,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { InfoOutlined, UploadFileRounded } from "@mui/icons-material/";
import { useNavigate } from "react-router-dom";

// import { SnackbarContext } from "../../../component/UtilComponents/";
import { SnackbarContext } from "../../../component/UtilComponents/SnackbarUtil/SnackbarContext";

import TypeofClinicalEstablishment from "./TypeOfClinicalEstablishment";
import BannerDummy from "../../../assest/img/bannerDummy.svg";
import axios from "axios";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

const filter = createFilterOptions();

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "100%",
    height: "10px", // set the height you want here
  },
});

function HospitalRegPage() {
  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [showData, setShowData] = useState(false);
  const [stateList, setStateList] = useState([]);

  const [newSpeciality, setNewSpeciality] = useState("");

  const [hospitalImg, setHospitalImg] = useState(null);

  const [hospitalCoverImg, setHospitalCoverImg] = useState(null);

  const [hospitalInfo, setHospitalInfo] = useState({
    state: null,
    establishment_name: "",
    certificate_number: "",
    branch: "",
  });

  const [goverment, setGoverment] = useState({
    certificate_registration_number: null,
    registration_type: "",
    muncipal_corporation: true,
    name: "",
    address: "",
    state: null,
    district: "",
    city: "",
    pincode: null,
    std_code: null,
    telephone: null,
    phone: null,
    email: "",
  });

  const [govtExpiryDate, setGovtExpiryDate] = useState(dayjs());

  const [owner_details, setOwner_details] = useState({
    name: "",
    address: "",
    state: null,
    district: "",
    city: "",
    pincode: null,
    std_code: null,
    telephone: null,
    phone: null,
    email: "",
    id_proff: "",
    id_number: "",
  });

  const [person_in_charge, setPerson_in_charge] = useState({
    name: "",
    qualification: "",
    registration_number: "",
    name_of_counsil: "",
    std_code: null,
    telephone: null,
    phone: null,
    email: "",
  });

  const [type_of_owner_ship, setType_of_owner_ship] = useState({
    type_of_owner_ship: "",
    goverment_public: "",
    goverment_public_others: "",
    private_sector: "",
    private_sector_others: "",
  });

  const [system_of_medicine, setSystem_of_medicine] = useState({
    allopathy: false,
    ayurveda: false,
    homeopathy: false,
    unani: false,
    siddha: false,
    yoga: false,
    naturopathy: false,
    sowa_rigpa: false,
  });

  const [clinical_service, setClinical_service] = useState({
    service: "",
    specility: [],
    service_others: "",
  });

  const [type_of_establishment, setType_of_establishment] = useState({
    inpatient: true,
    inpatient_type: [],
    inpatient_other: "",

    outpatient: true,
    outpatient_type: [],
    outpatient_other: "",

    laboratory: true,
    laboratory_type: [],
    laboratory_other: "",

    imaging_center: true,
    imaging_center_type: [],
    imaging_center_other: "",

    others: "",
  });

  const [page_setup, setPage_setup] = useState({
    page_logo: "",
    banner_image: "",
    public_url: "",
    contact: "",
    tagline: "",
    display_message: "",
    statement: "",
    website: "",
    instagram: "",
    google_business: "",
    twitter: "",
    facebook: "",
    youtube: "",
  });

  console.log(person_in_charge, "person_in_charge");
  console.log(system_of_medicine, "system_of_medicine");
  console.log(type_of_owner_ship, "type_of_owner_ship");
  console.log(clinical_service, "clinical_service");
  console.log(owner_details, "owner_details");
  console.log(goverment, "goverment");
  console.log(
    moment(goverment?.expiry_date?._d).format("YYYY-MM-DD"),
    "govermentyyyy"
  );
  console.log(dayjs(govtExpiryDate?.$d).format("YYYY-MM-DD"), "govtExpiryDate");
  console.log(page_setup, "page_setup");

  console.log(hospitalImg, "hospitalImg");

  useEffect(() => {
    fetchStateList();
  }, []);

  const fetchStateList = () => {
    console.log("first");
    axios
      .get("apiV1/state/")
      .then((res) => {
        setStateList(res?.data);
        console.log(res, "resof state");
      })
      .catch((err) => {
        console.log(err, "err of state");
      });
  };

  const hospitalImgUpload = (e) => {
    let allowedExtension = ["image/jpeg", "image/jpg", "image/png"];
    let imgType = e.target?.files[0]?.type;
    const file = e.currentTarget?.files[0];
    if (allowedExtension.indexOf(imgType) === -1) {
      openSnackbar("Only image(.jpeg, .jpg, .png) is acceptable", "error");
      return;
    }
    if (file?.size < 6000000) {
      setHospitalImg(null);
      setShowData(true);
      setHospitalImg(file);
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("apiV1/upload-to-aws/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res?.data);

          setPage_setup({
            ...page_setup,
            page_logo: res?.data,
          });
        })
        .catch((err) => console.log(err))
        .finally(() => setShowData(false));
    } else {
      openSnackbar("Image size should be less than 5MB!", "error");
    }
  };
  const hospitalCoverUpload = (e) => {
    let allowedExtension = ["image/jpeg", "image/jpg", "image/png"];
    let imgType = e.target?.files[0]?.type;
    const file = e.currentTarget?.files[0];
    if (allowedExtension.indexOf(imgType) === -1) {
      openSnackbar("Only image(.jpeg, .jpg, .png) is acceptable", "error");
      return;
    }
    if (file?.size < 6000000) {
      setHospitalCoverImg(null);
      setShowData(true);
      setHospitalCoverImg(file);
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("apiV1/upload-to-aws/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res?.data);
          // setHospitalCoverLink(res?.data);
          setPage_setup({
            ...page_setup,
            banner_image: res?.data,
          });
        })
        .catch((err) => console.log(err))
        .finally(() => setShowData(false));
    } else {
      openSnackbar("Image size should be less than 5MB!", "error");
    }
  };
  const handleSubmit = () => {
    setShowData(true);
    const payload = {
      establishment_name: hospitalInfo?.establishment_name,
      certificate_number: hospitalInfo?.certificate_number,
      branch: hospitalInfo?.branch,
      state: hospitalInfo?.state,
      government: {
        ...goverment,
        expiry_date: dayjs(govtExpiryDate?.$d).format("YYYY-MM-DD"),
      },
      registration_type: {},
      owner_details: { ...owner_details },
      person_in_charge: { ...person_in_charge },
      type_of_owner_ship: { ...type_of_owner_ship },
      system_of_medicine: { ...system_of_medicine },
      clinical_service: {
        ...clinical_service,
        specility: clinical_service.specility.join(","),
      },
      type_of_establishment: {
        ...type_of_establishment,
        inpatient_type: type_of_establishment.inpatient_type,
        outpatient_type: type_of_establishment.outpatient_type,
        laboratory_type: type_of_establishment.laboratory_type,
        imaging_center_type: type_of_establishment.imaging_center_type,
      },
      page_setup: { ...page_setup },
    };
    console.log(payload, "payload");

    axios
      .post("apiV1/establishment-basic/", payload)
      .then((res) => {
        console.log(res);
        if (res?.status === 201) {
          openSnackbar("Form submited successfully", "success");
          navigate("/branch-doctor");
        } else {
          openSnackbar("Something went wrong", "error");
        }
      })
      .catch((err) => {
        console.log(err, "form submited failed");
        openSnackbar(err, "error");
      })
      .finally(() => setShowData(false));
  };

  let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var numberPattern =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  const clearButton = <div style={{ width: 24 }} />;
  const registrationType = [
    { id: 1, name: "Provisional" },
    { id: 2, name: "Permanent" },
  ];

  const idProffChoice = [
    { id: 1, name: "Aadhar Card", value: "aadhar_card" },
    { id: 2, name: "Pan Card", value: "pan_card" },
    { id: 3, name: "Voter Id", value: "voter_id" },
    { id: 4, name: "Passport", value: "Passport" },
    { id: 5, name: "Driving License", value: "driving_license" },
  ];

  const typeOfGovtSector = [
    { id: 1, value: "central_goverment", name: "Central Government" },
    { id: 2, value: "state_goverment", name: "State Government" },
    { id: 3, value: "local_goverment", name: "Local Government" },
    { id: 4, value: "public_sector", name: "Public Sector Undertaking" },
  ];
  const typeOfPrivateSector = [
    {
      id: 1,
      value: "individual_proprietorship",
      name: "Individual Proprietorship",
    },
    { id: 2, value: "registered_partnership", name: "Registered Partnership" },
    { id: 3, value: "registered_company", name: "Registered Company" },
    { id: 4, value: "co_operative_society", name: "Co-operative Society" },
    { id: 5, value: "trust_charitable", name: "Trust / Charitable" },
  ];

  const systemOfMedicine = [
    { id: 1, value: "allopathy", name: "Allopathy", value1: false },
    { id: 1, value: "ayurveda", name: "Ayurveda", value1: false },
    { id: 1, value: "homeopathy", name: "Homeopathy", value1: false },
    { id: 1, value: "unani", name: "Unani", value1: false },
    { id: 1, value: "siddha", name: "Sidda", value1: false },
    { id: 1, value: "naturopathy", name: "Naturopathy", value1: false },
    { id: 1, value: "sowa_rigpa", name: "SowaRigpa", value1: false },
    { id: 1, value: "yoga", name: "Yoga", value1: false },
  ];

  const typesOfClinicalServices = [
    { id: 1, value: "general", name: "General" },
    { id: 2, value: "single_speciality", name: "Single Speciality" },
    { id: 3, value: "multi_speciality", name: "Multi Speciality" },
    { id: 4, value: "super_speciality", name: "Super Speciality" },
  ];

  console.log(hospitalInfo, "hospitalInfo");

  console.log(type_of_establishment, "type_of_establishment");

  const checkForm1Validation = () => {
    if (!hospitalInfo?.state) {
      console.log("inside state");
      openSnackbar("State field is required", "error");
      return;
    }
    if (!hospitalInfo?.establishment_name?.length > 0) {
      openSnackbar("Establishment Name is required", "error");
      return;
    }
    if (!hospitalInfo?.certificate_number?.length > 0) {
      openSnackbar("certificate Number is required", "error");
      return;
    }
    if (!hospitalInfo?.branch?.length > 0) {
      openSnackbar("Branch field is required", "error");
      return;
    }
    setPage(page + 1);
  };

  const checkForm2Validation = () => {
    if (!goverment?.certificate_registration_number?.length > 0) {
      openSnackbar("Certificate of Registration Number is required", "error");
      return;
    }
    if (!goverment?.registration_type?.length > 0) {
      openSnackbar("Registration Type is required", "error");
      return;
    }

    if (!goverment?.name.length > 0) {
      openSnackbar("Name field is required", "error");
      return;
    }
    if (!goverment?.address.length > 0) {
      openSnackbar("Address field is required", "error");
      return;
    }
    if (!goverment?.state) {
      openSnackbar("Choose State", "error");
      return;
    }
    if (!goverment?.district.length > 0) {
      openSnackbar("District field is required", "error");
      return;
    }
    if (!goverment?.city.length > 0) {
      openSnackbar("City field is required", "error");
      return;
    }
    if (!goverment?.pincode) {
      openSnackbar("Correct Pincode is required", "error");
      return;
    }
    // if (!goverment?.std_code) {
    //   openSnackbar("Std Code field is required", "error");
    //   return;
    // }
    // if (!goverment?.telephone) {
    //   openSnackbar("Telephone No. is required", "error");
    //   return;
    // }
    if (!numberPattern.test(goverment?.phone)) {
      openSnackbar("Correct Phone Number is required", "error");
      return;
    }
    if (
      8 < goverment?.email?.length &&
      goverment?.email?.length < 35 &&
      emailPattern.test(goverment?.email)
    ) {
    } else {
      openSnackbar(
        "🚫 Please Enter valid Email with character between 8 to 35 only",
        "error"
      );
      return;
    }
    setPage(page + 1);
  };

  const checkForm3Validation = () => {
    if (!owner_details?.name?.length > 0) {
      openSnackbar("Name of Owner is required", "error");
      return;
    }
    if (!owner_details?.address?.length > 0) {
      openSnackbar("Address is required", "error");
      return;
    }
    if (!owner_details?.state) {
      openSnackbar("Choose State", "error");
      return;
    }
    if (!owner_details?.district.length > 0) {
      openSnackbar("District field is required", "error");
      return;
    }
    if (!owner_details?.city.length > 0) {
      openSnackbar("City field is required", "error");
      return;
    }
    if (!owner_details?.pincode) {
      openSnackbar("Correct Pincode is required", "error");
      return;
    }

    if (!numberPattern.test(owner_details?.phone)) {
      openSnackbar("Correct Owner Phone No. is required", "error");
      return;
    }

    if (!owner_details?.id_proff?.length > 0) {
      openSnackbar("Id Proff field is required", "error");
      return;
    }
    if (!owner_details?.id_number.length > 0) {
      openSnackbar("Id Number field is required", "error");
      return;
    }

    // Person Incharge
    if (!person_in_charge?.name?.length > 0) {
      openSnackbar("Name of the Person In-charge is required", "error");
      return;
    }
    if (!person_in_charge?.qualification?.length > 0) {
      openSnackbar("Qualification is required", "error");
      return;
    }
    if (!person_in_charge?.registration_number?.length > 0) {
      openSnackbar("Registration Number is required", "error");
      return;
    }
    if (!person_in_charge?.name_of_counsil?.length > 0) {
      openSnackbar("Name of central/state council is required", "error");
      return;
    }
    // if (!person_in_charge?.std_code) {
    //   openSnackbar("Person In Charge Std Code field is required", "error");
    //   return;
    // }
    // if (!person_in_charge?.telephone) {
    //   openSnackbar("Person In Charge Telephone No. is required", "error");
    //   return;
    // }
    if (!numberPattern.test(person_in_charge?.phone)) {
      openSnackbar(
        "Person In Charge Correct Phone Number is required",
        "error"
      );
      return;
    }
    if (
      8 < person_in_charge?.email?.length &&
      person_in_charge?.email?.length < 35 &&
      emailPattern.test(person_in_charge?.email)
    ) {
    } else {
      openSnackbar(
        "Please Enter valid Email with character between 8 to 35 only",
        "error"
      );
      return;
    }
    setPage(page + 1);
  };

  const checkForm4Validation = () => {
    if (!type_of_owner_ship?.type_of_owner_ship?.length > 0) {
      openSnackbar("Type of Owner is required", "error");
      return;
    }
    if (type_of_owner_ship?.type_of_owner_ship === "goverment_public") {
      if (!type_of_owner_ship?.goverment_public?.length > 0) {
        openSnackbar("Govt. Sector field is required", "error");
        return;
      }
    } else {
      if (!type_of_owner_ship?.private_sector?.length > 0) {
        openSnackbar("Private Sector field is required", "error");
        return;
      }
    }
    if (!Object.values(system_of_medicine).some((each) => each === true)) {
      openSnackbar("Choose Any one System Of Medicine", "error");
      return;
    }
    if (!clinical_service.service?.length > 0) {
      openSnackbar("Choose Types Of ClinicalServices", "error");
      return;
    }
    if (!Object.values(clinical_service)[1]?.length > 0) {
      openSnackbar("Atleast add one Speciality", "error");
      return;
    }
    setPage(page + 1);
  };
  console.log(page_setup, "page_setup");

  const checkForm5Validation = () => {
    if (!type_of_establishment?.inpatient_type?.length > 0) {
      openSnackbar("Select atleast one", "error");
      return;
    }

    setPage(page + 1);
  };

  const checkForm6Validation = () => {
    if (!page_setup?.page_logo?.length > 0) {
      openSnackbar("Add Page Logo or Replace It", "error");
      return;
    }
    if (!page_setup?.banner_image?.length > 0) {
      openSnackbar("Add Cover Photo or Replace It", "error");
      return;
    }
    if (!page_setup?.tagline?.length > 0) {
      openSnackbar("Type Tagline", "error");
      return;
    }
    if (!page_setup?.contact?.length > 0) {
      openSnackbar("Type Contact Number", "error");
      return;
    }
    if (!page_setup?.display_message?.length > 0) {
      openSnackbar("Type Display Message", "error");
      return;
    }
    if (!page_setup?.statement?.length > 0) {
      openSnackbar("Type statement", "error");
      return;
    }
    handleSubmit();
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showData}
        onClick={() => setShowData(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          //   border: "1px solid red",
          display: "flex",
          justifyContent: "space-evenly",
          my: 2,
        }}
      >
        <Box
          sx={{
            //  border: "1px solid green",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              border: page < 5 ? "1px solid grey" : "none",
              borderBottom: "none",
              boxShadow: page < 5 ? "0px 0px 4px rgba(0, 0, 0, 0.25)" : "none",
              //   width: "60%",
              p: 3,
              display: "flex",
              flexDirection: "column",
              //   justifyContent: "center",
              //   alignItems: "center",
            }}
          >
            {page === 1 ? (
              <>
                <Typography variant="subtitle1" sx={{ pb: 1 }}>
                  <b>Establishment basic details</b>
                </Typography>
                <Typography variant="subtitle2">
                  State / Province / Region
                </Typography>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    value={hospitalInfo?.establishmentBasicdetails?.state}
                    onChange={(e) => {
                      setHospitalInfo({
                        ...hospitalInfo,
                        state: e.target.value,
                      });
                    }}
                    placeholder="select"
                    fullWidth
                    style={{ width: "100%", height: "40px" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={""} disabled>
                      Select
                    </MenuItem>
                    {stateList?.map((each) => (
                      <MenuItem value={each?.id}>{each?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="subtitle2">Establishment name</Typography>
                <CustomTextField
                  name="establishment_name"
                  value={hospitalInfo?.establishment_name}
                  onChange={(e) => {
                    console.log(e.target);
                    const { name, value } = e.target;
                    setHospitalInfo({
                      ...hospitalInfo,
                      [name]: value,
                    });
                  }}
                  placeholder="Enter Name"
                />
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ pb: 0.5 }}
                >
                  eg. Sai Hospital, Sai Clinic, Sai Nursing Home etc
                </Typography>
                <Typography variant="subtitle2">
                  Certificate of Establishments number
                </Typography>
                <CustomTextField
                  type="text"
                  name="certificate_number"
                  value={hospitalInfo?.certificate_number}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setHospitalInfo({
                      ...hospitalInfo,
                      [name]: value,
                    });
                  }}
                  placeholder="Certificate Number"
                />
                <Typography variant="subtitle2">Branch</Typography>
                <CustomTextField
                  name="branch"
                  value={hospitalInfo?.branch}
                  onChange={(e) => {
                    console.log(e.target);
                    const { name, value } = e.target;
                    setHospitalInfo({
                      ...hospitalInfo,
                      [name]: value,
                    });
                  }}
                  placeholder="Enter branch with City"
                />
                <Typography variant="subtitle1">
                  <b>Documents required</b>
                </Typography>
                <Box>
                  <Typography variant="subtitle2">
                    1) Proof of establishment of the firm or company
                    <InfoOutlined sx={{ color: "#0997FF", p: 0.5 }} />
                  </Typography>
                  <Typography variant="subtitle2">
                    2) Proof of address of firm's premises
                    <InfoOutlined sx={{ color: "#0997FF", p: 0.5 }} />
                  </Typography>
                  <Typography variant="subtitle2">
                    3) Proof of Identity of the signatory
                    <InfoOutlined sx={{ color: "#0997FF", p: 0.5 }} />
                  </Typography>
                  <Typography variant="subtitle2">
                    4) Map indicating location of premises from nearest land
                    mark
                    <InfoOutlined sx={{ color: "#0997FF", p: 0.5 }} />
                  </Typography>
                  <Typography variant="subtitle2">
                    5) Map indicating location of premises from nearest land
                    mark
                    <InfoOutlined sx={{ color: "#0997FF", p: 0.5 }} />
                  </Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 3,
                    }}
                  >
                    <button
                      className="th-18 px-3 py-2 th-br-5 h-100  border-0"
                      onClick={() => {
                        setPage(page - 1);
                      }}
                      disabled={page === 1}
                      sx={{ border: "none" }}
                    >
                      Back
                    </button>
                    <button
                      className="th-18 th-btn-primary px-3 py-2 th-br-5 h-100  border-0"
                      onClick={checkForm1Validation}
                      // disabled={page === 7}
                    >
                      Next
                    </button>
                  </Box>
                  {page < 5 && (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", pb: 1 }}
                    >
                      <Typography variant="caption">
                        <b>Note:</b>
                        You can change these details later in Settings, if
                        needed.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            ) : page === 2 ? (
              <>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="subtitle2">
                    Clinical Establishments (Registration and Regulation) Act,
                    2010
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    (Ministry of Health and Family Welfare, Government of India)
                  </Typography>
                  <Typography variant="subtitle2">And</Typography>
                  <Typography variant="subtitle2">
                    Act under Clause (1) of Article 252 of the Constitution
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    (All states of India)
                  </Typography>
                  <br />
                  <Typography variant="caption" color="text.secondary">
                    (follow this)
                  </Typography>
                </Box>
                <Typography variant="subtitle2">
                  Certificate of Registration Number.
                </Typography>
                <CustomTextField
                  type="text"
                  name="certificate_registration_number"
                  value={goverment?.certificate_registration_number}
                  onChange={(e) => {
                    // const { name, value } = e.target;
                    setGoverment({
                      ...goverment,
                      certificate_registration_number: e.target.value,
                    });
                  }}
                  placeholder="Certificate No."
                />
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Types of Registration
                </Typography>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    value={goverment?.registration_type}
                    onChange={(e) => {
                      setGoverment({
                        ...goverment,
                        registration_type: e.target.value,
                      });
                    }}
                    placeholder="select"
                    fullWidth
                    style={{ width: "100%", height: "40px" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={""} disabled>
                      Select
                    </MenuItem>
                    {registrationType?.map((each) => (
                      <MenuItem key={each?.id} value={each?.name}>
                        {each?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Expiry Date of Registration
                </Typography>
                {/* <CustomTextField
                  type="string"
                  name="expiry_date"
                  value={goverment?.expiry_date}
                  onChange={(e) => {
                    // const { name, value } = e.target;
                    setGoverment({
                      ...goverment,
                      expiry_date: e.target.value,
                    });
                  }}
                  placeholder="DD-MM-YYYY"
                /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      // label="Controlled picker"
                      value={govtExpiryDate}
                      onChange={(newValue) => setGovtExpiryDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <Divider sx={{ my: 2, backgroundColor: "#000" }} />
                <Typography variant="subtitle2">
                  <b>working Area</b>
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="muncipal_corporation"
                    defaultValue="Municipal corporation"
                    value={goverment?.muncipal_corporation}
                    onChange={(e) => {
                      setGoverment({
                        ...goverment,
                        muncipal_corporation: e.target.value === "true",
                      });
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid grey",
                        px: 0.5,
                        borderRadius: 1,
                        mr: 1,
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Municipal corporation"
                      />
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid grey",
                        px: 0.5,
                        borderRadius: 1,
                      }}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Non-Municipal Corporation"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
                <Typography variant="subtitle1" sx={{ py: 2 }}>
                  <b>Details of Clinical Estabilishmet</b>
                </Typography>

                <Typography variant="subtitle2">
                  Name of the Clinical Estabilishmet
                </Typography>
                <CustomTextField
                  name="name"
                  value={goverment?.name}
                  onChange={(e) => {
                    setGoverment({
                      ...goverment,
                      name: e.target.value,
                    });
                  }}
                  placeholder="Enter Name"
                />
                <Typography variant="subtitle2">Address</Typography>
                <CustomTextField
                  name="address"
                  value={goverment?.address}
                  onChange={(e) => {
                    setGoverment({
                      ...goverment,
                      address: e.target.value,
                    });
                  }}
                  placeholder="Enter Address"
                />

                {/* State and Distric  */}

                <>
                  <>
                    <Typography variant="subtitle2">State</Typography>
                    <FormControl sx={{ minWidth: 120 }}>
                      <Select
                        value={goverment?.state}
                        onChange={(e) => {
                          setGoverment({
                            ...goverment,
                            state: e.target.value,
                          });
                        }}
                        placeholder="select"
                        fullWidth
                        style={{ width: "100%", height: "40px" }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={""} disabled>
                          Select
                        </MenuItem>
                        {stateList?.map((each) => (
                          <MenuItem key={each?.id} value={each?.id}>
                            {each?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                  <>
                    <Typography variant="subtitle2">District</Typography>
                    <CustomTextField
                      name="district"
                      value={goverment?.district}
                      onChange={(e) => {
                        setGoverment({
                          ...goverment,
                          district: e.target.value,
                        });
                      }}
                      placeholder="select"
                      fullWidth
                      style={{ width: "100%", height: "40px" }}
                      inputProps={{ "aria-label": "Without label" }}
                    ></CustomTextField>
                  </>
                </>

                {/* Village and Pincode */}

                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    justifyContent: "space-around",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">
                      Village/ Towen/ City
                    </Typography>
                    <CustomTextField
                      name="city"
                      value={goverment?.city}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setGoverment({
                          ...goverment,
                          city: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Pin Code</Typography>
                    <CustomTextField
                      type="number"
                      name="pincode"
                      value={goverment?.pincode}
                      onChange={(e) => {
                        setGoverment({
                          ...goverment,
                          pincode: Number(e.target.value),
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                </Box>

                {/* STD And Telephone */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">STD Code</Typography>
                    <CustomTextField
                      type="number"
                      name="std_code"
                      value={goverment?.std_code}
                      onChange={(e) => {
                        setGoverment({
                          ...goverment,
                          std_code: Number(e.target.value),
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Telephone</Typography>
                    <CustomTextField
                      type="number"
                      name="telephone"
                      value={goverment?.telephone}
                      onChange={(e) => {
                        setGoverment({
                          ...goverment,
                          telephone: Number(e.target.value),
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                </Box>

                {/* Mobile and Email */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">Mobile</Typography>
                    <CustomTextField
                      type="number"
                      name="phone"
                      value={goverment?.phone}
                      onChange={(e) => {
                        setGoverment({
                          ...goverment,
                          phone: Number(e.target.value),
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Email ID</Typography>
                    <CustomTextField
                      type="email"
                      name="email"
                      value={goverment?.email}
                      onChange={(e) => {
                        setGoverment({
                          ...goverment,
                          email: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 3,
                    }}
                  >
                    <button
                      className="th-18 px-3 py-2 th-br-5 h-100  border-0"
                      onClick={() => {
                        setPage(page - 1);
                      }}
                      sx={{ border: "none" }}
                    >
                      Back
                    </button>
                    <button
                      className="th-18 th-btn-primary px-3 py-2 th-br-5 h-100  border-0"
                      onClick={checkForm2Validation}
                      // disabled={page === 7}
                    >
                      Next
                    </button>
                  </Box>
                  {page < 5 && (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", pb: 1 }}
                    >
                      <Typography variant="caption">
                        <b>Note:</b>
                        You can change these details later in Settings, if
                        needed.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            ) : page === 3 ? (
              <>
                <Typography variant="subtitle2" sx={{ py: 2 }}>
                  <b>Owner Details</b>
                </Typography>

                <Typography variant="subtitle2">Name of the owner</Typography>
                <CustomTextField
                  type="text"
                  name="name"
                  value={owner_details?.name}
                  onChange={(e) => {
                    setOwner_details({
                      ...owner_details,
                      name: e.target.value,
                    });
                  }}
                  placeholder="Enter Name"
                />
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Checkbox {...label} defaultChecked />
                  <Typography variant="caption">
                    Same as Clinical Establishmet
                  </Typography>
                </Box> */}
                <Typography>Address</Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  value={owner_details?.address}
                  minRows={1}
                  placeholder="Enter Address Line"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setOwner_details({
                      ...owner_details,
                      address: e.target.value,
                    });
                  }}
                />

                {/* State and Distric  */}

                <>
                  <>
                    <Typography variant="subtitle2">State</Typography>
                    <FormControl sx={{ minWidth: 120 }}>
                      <Select
                        value={owner_details?.state}
                        onChange={(e) => {
                          setOwner_details({
                            ...owner_details,
                            state: e.target.value,
                          });
                        }}
                        placeholder="select"
                        fullWidth
                        style={{ width: "100%", height: "40px" }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={""} disabled>
                          Select
                        </MenuItem>
                        {stateList?.map((each) => (
                          <MenuItem value={each?.id}>{each?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                  <>
                    <Typography variant="subtitle2">District</Typography>
                    <CustomTextField
                      name="district"
                      value={owner_details?.district}
                      onChange={(e) => {
                        setOwner_details({
                          ...owner_details,
                          district: e.target.value,
                        });
                      }}
                      fullWidth
                      style={{ width: "100%", height: "40px" }}
                      inputProps={{ "aria-label": "Without label" }}
                    />
                  </>
                </>

                {/* Village and Pincode */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      mr: 1,
                    }}
                  >
                    <Typography variant="subtitle2">
                      Village/ Towen/ City
                    </Typography>
                    <CustomTextField
                      name="city"
                      value={owner_details.city}
                      onChange={(e) => {
                        setOwner_details({
                          ...owner_details,
                          city: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Pin Code</Typography>
                    <CustomTextField
                      type="number"
                      name="pincode"
                      value={owner_details.pincode}
                      onChange={(e) => {
                        setOwner_details({
                          ...owner_details,
                          pincode: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                </Box>

                {/* STD And Telephone */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box sx={{ mr: 1 }}>
                    <Typography variant="subtitle2">STD Code</Typography>
                    <CustomTextField
                      type="number"
                      name="std_code"
                      value={owner_details.std_code}
                      onChange={(e) => {
                        setOwner_details({
                          ...owner_details,
                          std_code: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Telephone</Typography>
                    <CustomTextField
                      type="number"
                      name="telephone"
                      value={owner_details.telephone}
                      onChange={(e) => {
                        setOwner_details({
                          ...owner_details,
                          telephone: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                </Box>

                {/* Mobile and Email */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      mr: 1,
                    }}
                  >
                    <Typography variant="subtitle2">Mobile</Typography>
                    <CustomTextField
                      type="number"
                      name="phone"
                      value={owner_details.phone}
                      onChange={(e) => {
                        setOwner_details({
                          ...owner_details,
                          phone: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Email ID</Typography>
                    <CustomTextField
                      type="email"
                      name="email"
                      value={owner_details.email}
                      onChange={(e) => {
                        setOwner_details({
                          ...owner_details,
                          email: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                </Box>

                {/* Id proof */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">Id Proof</Typography>
                    <FormControl sx={{ minWidth: 120 }}>
                      <Select
                        value={owner_details?.id_proff}
                        onChange={(e) => {
                          setOwner_details({
                            ...owner_details,
                            id_proff: e.target.value,
                          });
                        }}
                        placeholder="select"
                        fullWidth
                        style={{ width: "100%", height: "40px" }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={""} disabled>
                          Select
                        </MenuItem>
                        {idProffChoice?.map((each) => (
                          <MenuItem value={each?.value}>{each?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    <Box sx={{ mt: 1 }} />
                    <CustomTextField
                      type="text"
                      name="id_number"
                      value={owner_details.id_number}
                      onChange={(e) => {
                        setOwner_details({
                          ...owner_details,
                          id_number: e.target.value,
                        });
                      }}
                      placeholder="Enter No"
                    />
                  </Box>
                </Box>

                {/*  Person In-charge Details */}

                <Typography variant="subtitle1" sx={{ py: 2 }}>
                  <b> Person In-charge Details</b>
                </Typography>

                <Typography variant="subtitle2">
                  Name of Person In-charge*
                </Typography>
                <CustomTextField
                  type="text"
                  value={person_in_charge?.name}
                  onChange={(e) => {
                    setPerson_in_charge({
                      ...person_in_charge,
                      name: e.target.value,
                    });
                  }}
                  placeholder="Enter Number"
                />
                <Typography variant="subtitle2">Qualification*</Typography>
                <CustomTextField
                  type="text"
                  value={person_in_charge?.qualification}
                  onChange={(e) => {
                    setPerson_in_charge({
                      ...person_in_charge,
                      qualification: e.target.value,
                    });
                  }}
                  placeholder="Enter Number"
                />
                <Typography variant="subtitle2">
                  Registration Number*
                </Typography>
                <CustomTextField
                  type="text"
                  value={person_in_charge?.registration_number}
                  onChange={(e) => {
                    setPerson_in_charge({
                      ...person_in_charge,
                      registration_number: e.target.value,
                    });
                  }}
                  placeholder="ENter Reg. Number"
                />
                <Typography variant="subtitle2">
                  Name of central/state council (with which registered)*
                </Typography>
                <CustomTextField
                  type="text"
                  value={person_in_charge?.name_of_counsil}
                  onChange={(e) => {
                    setPerson_in_charge({
                      ...person_in_charge,
                      name_of_counsil: e.target.value,
                    });
                  }}
                  placeholder="Enter Number"
                />
                {/* STD And Telephone */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box sx={{ mr: 1 }}>
                    <Typography variant="subtitle2">STD Code</Typography>
                    <CustomTextField
                      type="number"
                      value={person_in_charge.std_code}
                      onChange={(e) => {
                        setPerson_in_charge({
                          ...person_in_charge,
                          std_code: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Telephone</Typography>
                    <CustomTextField
                      type="number"
                      value={person_in_charge.telephone}
                      onChange={(e) => {
                        setPerson_in_charge({
                          ...person_in_charge,
                          telephone: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                </Box>

                {/* Mobile and Email */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      mr: 1,
                    }}
                  >
                    <Typography variant="subtitle2">Mobile</Typography>
                    <CustomTextField
                      type="number"
                      name="phone"
                      value={person_in_charge.phone}
                      onChange={(e) => {
                        setPerson_in_charge({
                          ...person_in_charge,
                          phone: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Email ID</Typography>
                    <CustomTextField
                      type="email"
                      name="email"
                      value={person_in_charge.email}
                      onChange={(e) => {
                        setPerson_in_charge({
                          ...person_in_charge,
                          email: e.target.value,
                        });
                      }}
                      placeholder=""
                    />
                  </Box>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 3,
                    }}
                  >
                    <button
                      className="th-18 px-3 py-2 th-br-5 h-100  border-0"
                      onClick={() => {
                        setPage(page - 1);
                      }}
                      disabled={page === 1}
                      sx={{ border: "none" }}
                    >
                      Back
                    </button>
                    <button
                      className="th-18 th-btn-primary px-3 py-2 th-br-5 h-100  border-0"
                      onClick={checkForm3Validation}
                    >
                      Next
                    </button>
                  </Box>
                  {page < 5 && (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", pb: 1 }}
                    >
                      <Typography variant="caption">
                        <b>Note:</b>
                        You can change these details later in Settings, if
                        needed.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            ) : page === 4 ? (
              <>
                <Typography variant="subtitle1">
                  <b>Types of Ownership -</b>
                </Typography>

                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    value={type_of_owner_ship?.type_of_owner_ship}
                    onChange={(e) => {
                      setType_of_owner_ship({
                        ...type_of_owner_ship,
                        type_of_owner_ship: e.target.value,
                      });
                    }}
                    placeholder="select"
                    // fullWidth
                    // style={{ width: "100%", height: "40px" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={""} disabled>
                      Select
                    </MenuItem>

                    <MenuItem value={"goverment_public"}>
                      Government/Public Sector
                    </MenuItem>
                    <MenuItem value={"private"}>Private Sector</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="subtitle1">
                  Select{" "}
                  {type_of_owner_ship?.type_of_owner_ship === "goverment_public"
                    ? "Government/Public"
                    : "Private"}{" "}
                  Sector
                </Typography>
                <Autocomplete
                  value={
                    type_of_owner_ship?.type_of_owner_ship ===
                    "goverment_public"
                      ? type_of_owner_ship?.goverment_public
                      : type_of_owner_ship?.private_sector
                  }
                  // fullWidth
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                    } else if (newValue && newValue?.inputValue) {
                      type_of_owner_ship?.type_of_owner_ship ===
                      "goverment_public"
                        ? setType_of_owner_ship({
                            ...type_of_owner_ship,
                            private_sector: "",
                            goverment_public: newValue?.inputValue,
                          })
                        : setType_of_owner_ship({
                            ...type_of_owner_ship,
                            private_sector: newValue?.inputValue,
                            goverment_public: "",
                          });
                    } else {
                      // setValue(newValue);
                      type_of_owner_ship?.type_of_owner_ship ===
                      "goverment_public"
                        ? setType_of_owner_ship({
                            ...type_of_owner_ship,
                            private_sector: "",
                            goverment_public: newValue?.value,
                          })
                        : setType_of_owner_ship({
                            ...type_of_owner_ship,
                            private_sector: newValue?.value,
                            goverment_public: "",
                          });
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option?.name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        name: `Add Other "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={
                    type_of_owner_ship?.type_of_owner_ship ===
                    "goverment_public"
                      ? typeOfGovtSector
                      : typeOfPrivateSector
                  }
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name}</li>
                  )}
                  sx={{ padding: "0px" }}
                  // clearIcon={<ClearIcon style={{ visibility: "hidden" }} />} // Hide the default clear icon
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        padding: 0,
                        //  border: "1px solid red"
                      }}
                      {...params}
                      label=""
                    />
                  )}
                />

                <Typography variant="subtitle1" sx={{ py: 2 }}>
                  <b>System of Medicine </b>
                </Typography>
                <FormGroup>
                  <Box>
                    {Object?.keys(system_of_medicine).map((each) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={system_of_medicine[String(each)]}
                            onChange={(e) => {
                              const { name } = e.target;
                              console.log(name, "namesystem_of_medicine");
                              setSystem_of_medicine({
                                ...system_of_medicine,
                                [name]: !system_of_medicine[String(each)],
                              });
                            }}
                            name={each}
                          />
                        }
                        label={
                          <Typography textTransform="capitalize">
                            {each}
                          </Typography>
                        }
                      />
                    ))}
                  </Box>
                </FormGroup>

                <Typography variant="subtitle1" sx={{ pt: 2 }}>
                  <b>Types of clinical services</b>
                </Typography>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    value={clinical_service?.service}
                    onChange={(e) => {
                      setClinical_service({
                        ...clinical_service,
                        service: e.target.value,
                      });
                    }}
                    placeholder="select"
                    fullWidth
                    style={{ width: "100%", height: "40px" }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={""} disabled>
                      Select
                    </MenuItem>
                    {typesOfClinicalServices.map((each) => (
                      <MenuItem key={each?.id} value={each?.value}>
                        {each?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Typography variant="subtitle1" sx={{ pt: 2 }}>
                  <b>Speciality</b>
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <CustomTextField
                    name="establishmentName"
                    value={newSpeciality}
                    onChange={(e) => {
                      console.log(e.target);
                      setNewSpeciality(e.target.value);
                    }}
                    placeholder="Type and select"
                  />
                  <button
                    className="th-18 th-btn-primary ml-1 px-5 py-2 th-br-5 h-100  border-0"
                    onClick={() => {
                      if (newSpeciality !== "") {
                        const special = {
                          id: Math.random(),
                          name: newSpeciality,
                        };
                        // setSpeciality([...speciality, special]);
                        setClinical_service((prevState) => ({
                          ...prevState,
                          specility: [...prevState?.specility, special?.name],
                        }));
                      }
                      setNewSpeciality("");
                    }}
                  >
                    Add
                  </button>
                </Box>
                <Box sx={{ mt: 1 }}>
                  {Object.values(clinical_service)[1]?.length > 0 &&
                    Object.values(clinical_service)[1]?.map((each) => (
                      <Chip
                        label={each}
                        size="small"
                        onDelete={() => {
                          console.log(each?.id, "idspeciality");
                          const updatedSpecialityList = Object.values(
                            clinical_service
                          )[1]?.filter((item) => item !== each);
                          setClinical_service((prevState) => ({
                            ...prevState,
                            specility: [...updatedSpecialityList],
                          }));
                        }}
                      />
                    ))}
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 3,
                    }}
                  >
                    <button
                      className="th-18 px-3 py-2 th-br-5 h-100  border-0"
                      onClick={() => {
                        setPage(page - 1);
                      }}
                      sx={{ border: "none" }}
                    >
                      Back
                    </button>
                    <button
                      className="th-18 th-btn-primary px-3 py-2 th-br-5 h-100  border-0"
                      onClick={checkForm4Validation}
                    >
                      Next
                    </button>
                  </Box>
                  {page < 5 && (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", pb: 1 }}
                    >
                      <Typography variant="caption">
                        <b>Note:</b>
                        You can change these details later in Settings, if
                        needed.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            ) : page === 5 ? (
              <>
                <Box display="flex" sx={{ alignItems: "center" }}>
                  <Typography variant="subtitle1">
                    <b>Type of clinical Establishment* -</b>
                  </Typography>
                  <Typography variant="caption">
                    (please tick whichever is applicable)-You can select more
                    than one option
                  </Typography>
                </Box>
                <Box sx={{ border: "1px solid black", borderRadius: 2 }}>
                  <FormGroup>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 2,
                        flexWrap: "wrap",
                      }}
                    >
                      {TypeofClinicalEstablishment?.map((each) => (
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{ textDecoration: "underline" }}
                          >
                            <b>{each?.type?.name}</b>
                          </Typography>
                          <Box>
                            <FormGroup>
                              {each?.subType?.map((item) => {
                                return (
                                  <FormControlLabel
                                    control={<Checkbox color="primary" />}
                                    onChange={(e) => {
                                      switch (each?.type?.value) {
                                        case "inpatient":
                                          if (
                                            !type_of_establishment?.inpatient_type.includes(
                                              e.target.name
                                            )
                                          ) {
                                            setType_of_establishment({
                                              ...type_of_establishment,
                                              inpatient_type: [
                                                ...type_of_establishment?.inpatient_type,
                                                e.target.name,
                                              ],
                                            });
                                          } else {
                                            const establishmentType =
                                              type_of_establishment;
                                            establishmentType.inpatient_type =
                                              establishmentType.inpatient_type.filter(
                                                (a) => a !== e.target.name
                                              );
                                            setType_of_establishment({
                                              ...establishmentType,
                                            });
                                          }
                                          break;

                                        case "outpatient":
                                          if (
                                            !type_of_establishment?.outpatient_type.includes(
                                              e.target.name
                                            )
                                          ) {
                                            setType_of_establishment({
                                              ...type_of_establishment,
                                              outpatient_type: [
                                                ...type_of_establishment?.outpatient_type,
                                                e.target.name,
                                              ],
                                            });
                                          } else {
                                            const establishmentType =
                                              type_of_establishment;
                                            establishmentType.outpatient_type =
                                              establishmentType.outpatient_type.filter(
                                                (a) => a !== e.target.name
                                              );
                                            setType_of_establishment({
                                              ...establishmentType,
                                            });
                                          }
                                          break;

                                        case "laboratory":
                                          if (
                                            !type_of_establishment?.laboratory_type.includes(
                                              e.target.name
                                            )
                                          ) {
                                            setType_of_establishment({
                                              ...type_of_establishment,
                                              laboratory_type: [
                                                ...type_of_establishment?.laboratory_type,
                                                e.target.name,
                                              ],
                                            });
                                          } else {
                                            const establishmentType =
                                              type_of_establishment;
                                            establishmentType.laboratory_type =
                                              establishmentType.laboratory_type.filter(
                                                (a) => a !== e.target.name
                                              );
                                            setType_of_establishment({
                                              ...establishmentType,
                                            });
                                          }
                                          break;

                                        case "imaging_center":
                                          if (
                                            !type_of_establishment?.imaging_center_type.includes(
                                              e.target.name
                                            )
                                          ) {
                                            setType_of_establishment({
                                              ...type_of_establishment,
                                              imaging_center_type: [
                                                ...type_of_establishment?.imaging_center_type,
                                                e.target.name,
                                              ],
                                            });
                                          } else {
                                            const establishmentType =
                                              type_of_establishment;
                                            establishmentType.imaging_center_type =
                                              establishmentType.imaging_center_type.filter(
                                                (a) => a !== e.target.name
                                              );
                                            setType_of_establishment({
                                              ...establishmentType,
                                            });
                                          }
                                        default:
                                          break;
                                      }
                                    }}
                                    color="primary"
                                    label={item?.name}
                                    name={item?.name}
                                  />
                                );
                              })}
                            </FormGroup>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </FormGroup>
                </Box>
                <Divider sx={{ my: 2 }} />

                <Box>
                  <Typography variant="subtitle1">
                    <b>Declaration*</b>
                  </Typography>
                  <Box sx={{ backgroundColor: "#E5F0F9", p: 1 }}>
                    <Typography variant="caption">
                      I hereby declare that the above statement is true and
                      correct to the best of my knowledge and complies with all
                      the rules and declarations made under the Clinical
                      Establishment (Registration and Regulation) Act, 2010. I
                      promise that if any of the above features change I will
                      suggest for proper registration.
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 3,
                    }}
                  >
                    <button
                      className="th-18 px-3 py-2 th-br-5 h-100  border-0"
                      onClick={() => {
                        setPage(page - 1);
                      }}
                      disabled={page === 1}
                      sx={{ border: "none" }}
                    >
                      Back
                    </button>
                    <button
                      className="th-18 th-btn-primary px-3 py-2 th-br-5 h-100 border-0"
                      onClick={() => {
                        if (
                          type_of_establishment?.inpatient_type?.length > 0 ||
                          type_of_establishment?.outpatient_type?.length > 0 ||
                          type_of_establishment?.laboratory_type?.length > 0 ||
                          type_of_establishment?.imaging_center_type?.length > 0
                        ) {
                          setPage(page + 1);
                        } else {
                          openSnackbar(
                            "Add one Establishment atleast",
                            "error"
                          );
                        }
                      }}
                      disabled={page === 7}
                    >
                      SAVE & NEXT
                    </button>
                  </Box>
                  {page < 5 && (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", pb: 1 }}
                    >
                      <Typography variant="caption">
                        <b>Note:</b>
                        You can change these details later in Settings, if
                        needed.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            ) : page === 6 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    border: "1px solid lightgrey",
                    p: 2,
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    <Typography variant="subtitle1">
                      <b>Page Logo*</b>
                    </Typography>
                    <Paper
                      sx={{
                        // display: "flex",
                        m: 2,
                        borderRadius: 2,
                        p: 0.5,
                      }}
                    >
                      <Avatar
                        sx={{ width: 80, height: 80, borderRadius: 2 }}
                        alt="Hospital Img"
                        src={
                          hospitalImg ? URL.createObjectURL(hospitalImg) : null
                        }
                      />
                      <Button
                        // variant="outlined"
                        color="primary"
                        component="label"
                        size="small"
                        startIcon={<UploadFileRounded />}
                      >
                        {hospitalImg ? "Replace Image" : "Upload Image"}
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                          onChange={(e) => {
                            hospitalImgUpload(e);
                          }}
                        />
                      </Button>
                    </Paper>

                    <Typography variant="caption">
                      Your profile picture will appear where your practice will
                      be presented on <b>Welldone Health</b>, as well as next to
                      your prescription, invoice, pictures, videos, blogs and
                      comments.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "75%",
                    }}
                  >
                    <Typography variant="subtitle2">
                      <b>Banner Image*</b>
                    </Typography>
                    <Box
                      sx={{
                        border: "0.2px solid grey",
                        p: 1,
                        borderRadius: 2,
                        height: "80%",
                        width: "90%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        // alignContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        {hospitalCoverImg ? (
                          <img
                            src={
                              hospitalCoverImg
                                ? URL.createObjectURL(hospitalCoverImg)
                                : BannerDummy
                            }
                            width="150px"
                            height="90px"
                            alt="bannerImg"
                          />
                        ) : null}
                        <Paper
                          sx={{
                            display: "flex",
                            m: 2,
                            borderRadius: 2,
                            p: 0.5,
                          }}
                        >
                          <Box p={0.25} />
                          <Box p={1.5} sx={{ my: "auto" }}>
                            <Button
                              // variant="outlined"
                              color="primary"
                              component="label"
                              size="small"
                              startIcon={<UploadFileRounded />}
                            >
                              {hospitalCoverImg
                                ? "Replace Image"
                                : "Upload Image"}
                              <input
                                type="file"
                                style={{ display: "none" }}
                                id="image"
                                accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                                onChange={(e) => {
                                  hospitalCoverUpload(e);
                                }}
                              />
                            </Button>
                          </Box>
                        </Paper>
                      </Box>
                      <Typography variant="caption">
                        For the best results on all devices, use an image that’s
                        at least 2048 x 1152 pixels and 6MB or less.
                      </Typography>
                    </Box>
                    <Typography variant="subtitle2">
                      <b>
                        This image will appear across the top of your Practice
                        Profile
                      </b>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className="row"
                  sx={{
                    my: 1,
                  }}
                >
                  <Box className="col-md-6">
                    <Typography>Tagline</Typography>
                    <CustomTextField
                      name="name"
                      fullWidth
                      value={page_setup?.tagline}
                      onChange={(e) => {
                        setPage_setup({
                          ...page_setup,
                          tagline: e.target.value,
                        });
                      }}
                      placeholder="Enter Tagline"
                    />
                  </Box>
                  <Box className="col-md-6">
                    <Typography>Contact Number</Typography>
                    <CustomTextField
                      name="contact"
                      fullWidth
                      value={page_setup?.contact}
                      onChange={(e) => {
                        setPage_setup({
                          ...page_setup,
                          contact: e.target.value,
                        });
                      }}
                      placeholder="Enter Contact Number"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    my: 1,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>Display Message</Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ pl: 1 }}
                    >
                      ! Provide details to display on your page
                    </Typography>
                  </Box>
                  <CustomTextField
                    name="name"
                    fullWidth
                    value={page_setup?.display_message}
                    onChange={(e) => {
                      setPage_setup({
                        ...page_setup,
                        display_message: e.target.value,
                      });
                    }}
                    placeholder="Enter Display Message"
                  />

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>Statement</Typography>
                  </Box>
                  <CustomTextField
                    name="name"
                    fullWidth
                    value={page_setup?.statement}
                    onChange={(e) => {
                      setPage_setup({
                        ...page_setup,
                        statement: e.target.value,
                      });
                    }}
                    placeholder="Enter Statement"
                  />
                </Box>

                <Box
                  className="row"
                  sx={{
                    my: 1,
                  }}
                >
                  <Box className="col-md-6">
                    <Typography>Website URL</Typography>
                    <CustomTextField
                      name="name"
                      fullWidth
                      value={page_setup?.website}
                      onChange={(e) => {
                        setPage_setup({
                          ...page_setup,
                          website: e.target.value,
                        });
                      }}
                      placeholder="Enter website"
                    />
                  </Box>
                  <Box className="col-md-6">
                    <Typography>Instagram</Typography>
                    <CustomTextField
                      name="name"
                      fullWidth
                      value={page_setup?.instagram}
                      onChange={(e) => {
                        setPage_setup({
                          ...page_setup,
                          instagram: e.target.value,
                        });
                      }}
                      placeholder="Enter instagram"
                    />
                  </Box>
                  <Box className="col-md-6">
                    <Typography>Google Business</Typography>
                    <CustomTextField
                      name="name"
                      fullWidth
                      value={page_setup?.google_business}
                      onChange={(e) => {
                        setPage_setup({
                          ...page_setup,
                          google_business: e.target.value,
                        });
                      }}
                      placeholder="Enter google Business"
                    />
                  </Box>
                  <Box className="col-md-6">
                    <Typography>Twitter</Typography>
                    <CustomTextField
                      name="name"
                      fullWidth
                      value={page_setup?.twitter}
                      onChange={(e) => {
                        setPage_setup({
                          ...page_setup,
                          twitter: e.target.value,
                        });
                      }}
                      placeholder="Enter twitter"
                    />
                  </Box>
                  <Box className="col-md-6">
                    <Typography>Facebook</Typography>
                    <CustomTextField
                      name="name"
                      fullWidth
                      value={page_setup?.facebook}
                      onChange={(e) => {
                        setPage_setup({
                          ...page_setup,
                          facebook: e.target.value,
                        });
                      }}
                      placeholder="Enter facebook"
                    />
                  </Box>
                  <Box className="col-md-6">
                    <Typography>Youtube</Typography>
                    <CustomTextField
                      name="name"
                      fullWidth
                      value={page_setup?.youtube}
                      onChange={(e) => {
                        setPage_setup({
                          ...page_setup,
                          youtube: e.target.value,
                        });
                      }}
                      placeholder="Enter youtube"
                    />
                  </Box>
                </Box>
                {/* Button Section  */}

                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 3,
                    }}
                  >
                    <button
                      className="th-18 px-3 py-2 th-br-5 h-100  border-0"
                      onClick={() => {
                        setPage(page - 1);
                      }}
                      disabled={page === 1}
                      sx={{ border: "none" }}
                    >
                      Back
                    </button>
                    <button
                      className="th-18 th-btn-primary px-3 py-2 th-br-5 h-100  border-0"
                      onClick={checkForm6Validation}
                      disabled={page === 7}
                    >
                      Submit
                    </button>
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
        {/* {page < 5 && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={HospitalImg} width="100%" />
          </Box>
        )} */}
      </Box>
    </>
  );
}

export default HospitalRegPage;
