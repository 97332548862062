import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
  Badge,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Backdrop,
  Switch,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import NoData from "../../../../assest/img/nodata.svg";

import {
  FullscreenExit,
  Fullscreen,
  RefreshRounded,
} from "@mui/icons-material";

function EQueueList({ allPatients, fetchEqueueInfo }) {
  const [fullScreen, setFullScreen] = useState(false);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      // children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  function enterFullScreen(element) {
    setFullScreen(true);
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen(); // Firefox
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(); // Safari
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen(); // IE/Edge
    }
  }

  function exitFullscreen() {
    setFullScreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }

  return (
    <Box>
      {!allPatients?.equeue?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={NoData} alt="no-data" height="150px" width="100%" />
          <Typography variant="subtitle1">No Data</Typography>
        </Box>
      ) : (
        allPatients?.equeue?.map((each) => (
          <Box>
            <Box
              sx={{
                display: "flex",
                lineHeight: 0,
                justifyContent: "center",
                alignItems: "center",
                py: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // border: "1px solid red",
                  // alignItems: "center",
                  width: "35%",
                }}
              >
                <Badge
                  badgeContent={each?.equeue_no}
                  color="primary"
                  overlap="circular"
                >
                  <Avatar
                    sx={{ width: 28, height: 10 }}
                    {...stringAvatar(`${each?.patient__first_name ?? ""}`)}
                  />
                </Badge>

                <Box sx={{ ml: 1 }}>
                  <Typography variant="h4">
                    <b>{`${each?.patient__first_name ?? ""} ${
                      each?.patient__middle_name ?? ""
                    }`}</b>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
          </Box>
        ))
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          // m: 1,
        }}
      >
        <RefreshRounded
          sx={{
            cursor: "pointer",
            backgroundColor: "#1976D2",
            borderRadius: "50%",
            m: 1,
            p: 0.4,
            color: "#fff",
          }}
          onClick={() => fetchEqueueInfo()}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {!fullScreen ? (
          <Fullscreen
            sx={{ m: 2, cursor: "pointer" }}
            onClick={() => enterFullScreen(document.body)}
          />
        ) : (
          <FullscreenExit
            sx={{ m: 2, cursor: "pointer" }}
            onClick={() => exitFullscreen(document.body)}
          />
        )}
      </Box>
    </Box>
  );
}

export default EQueueList;
