import React from "react";
import Phone from "../../assest/img/doctor_mobile.png";
import Consult from "../../assest/img/consult.png";
import DownloadApp from "../../assest/img/download_app.png";
function ForDoctors() {
  return (
    <React.Fragment>
      <div className="th-bg-secondary th-container py-5 ">
        <div className="py-5 text-center">
          <h2 className="text-white">Welldone for Doctors</h2>
          <h4 className="text-white pt-3">
            Simple yet powerful solutions that are built to grow and manage your
            practice.
          </h4>
          <p className="text-white pt-3">
            Manage your practice efficiently, engage with patients to offer them
            a premium experience, or increase your visibility online.
          </p>
        </div>
      </div>
      <div className="bg-white th-container py-5 ">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6">
            <img src={Phone} className="img-fluid" alt="" />
          </div>
          <div className="col-md-6">
            <h2 className="">Doctor's Profile</h2>
            <h4 className=" pt-3">
              Simple yet powerful solutions that are built to grow and manage
              your practice.
            </h4>
            <p className=" pt-3">
              Create a free Practo Profile to help patients find you
              <ul className="tick-ul">
                <li>Manage your profile with advanced profile editor</li>
                <li>Respond to patient stories for your practice</li>
                <li>Provide online consultation to patients</li>
                <li>Manage your clinic with a Ray by Practo subscription</li>
                <li>See patient records from anywhere</li>
                <li>Track your clinic’s performance on the go</li>
                <li>Manage and track your Prime subscription</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white th-container py-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6">
            <h2 className="">Welldone Consult</h2>
            <h4 className=" pt-3">
              Consult online and grow your practice. Reach new patients and
              connect with your patients online.
            </h4>
            <p className=" pt-3">
              With Practo Consult you can
              <ul className="tick-ul">
                <li>Answer medical queries & showcase your expertise</li>
                <li>Maximise your earnings with paid online consultations</li>
                <li>Offer online follow-ups to your clinic patients</li>
              </ul>
            </p>
          </div>
          <div className="col-md-6">
            <img src={Consult} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <div className="th-bg-secondary th-container py-5 ">
        <div className="py-5 row justify-content-center align-items-center">
          <div className="col-md-6">
            <img src={DownloadApp} className="img-fluid" alt="" />
          </div>
          <div className="col-md-6">
            <h2 className="text-white">Download Welldone Health app</h2>
            <h4 className="text-white pt-3">
              A powerful app that lets you manage and grow your practice.
            </h4>
            <div className="d-flex pt-4 align-items-center">
              <span className="text-white th-20 mr-3">Get it on </span>
              <a
                href="https://play.google.com/store/apps/details?id=com.welldone.welldone_app"
                target="__blank"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/128/888/888857.png"
                  alt="gplay"
                  height={40}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForDoctors;
