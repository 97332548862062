import React, { useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { CheckCircleOutlined, Phone } from "@mui/icons-material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Tooltip,
} from "antd";

import { ReloadOutlined } from "@ant-design/icons";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/auth-context";
import { useForm } from "antd/es/form/Form";
import moment from "moment";

const NewAppointemet = ({ selectedDoc, selectedBranch, setShow }) => {
  console.log(selectedDoc, selectedBranch, "sjskksks");
  const authValue = useAuth();
  const [patientLoading, setPatientLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const doctorData = JSON.parse(localStorage.getItem("current_doctor_details"));
  const userId = authValue?.currUser?.id;

  const [medicalHistory, setMedicalHistory] = useState([]);
  const [medicalHistoryInput, setMedicalHistoryInput] = useState("");

  const [imgInput, setImgInput] = useState(null);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };

  const navigate = useNavigate();

  const [patientAdded, setPatientAdded] = useState(false);

  const [patientData, setPatientData] = useState([]);

  const item = patientData;
  console.log(patientData, selectedDoc, "sihidsiuhd");
  return (
    <Box
      sx={{
        p: 2,
        pt: 5,
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-evenly",
        gap: 2,
      }}
    >
      {contextHolder}
      <Box
        sx={{
          border: "2px solid gray",
          width: "40%",
          minWidth: "380px",
          height: "100%",
        }}
      >
        {!patientAdded ? (
          <>
            <Typography variant="h6" textAlign="center">
              {" "}
              Patient Details
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  width: "100%",
                  flexWrap: "wrap",
                  // border: "2px solid red",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    // border: "2px solid green",
                    flexBasis: "20vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 5,
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Typography> Add Display picture</Typography>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      setImgInput(e.target.files[0]);
                    }}
                  ></input>
                </Box>
                <Box
                  className="box-shadow"
                  sx={{
                    // border: "2px solid blue",
                    flexBasis: "75vw",
                    // height: "1000px",
                    p: 2,
                  }}
                >
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Typography variant="h6">
                      New Patient Registration
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap", p: 1 }}>
                    <Form
                      layout="vertical"
                      onFinish={(value) => {
                        setPatientLoading(true);
                        const payload = {
                          ...value,
                          medical_history: medicalHistory,
                          branch: selectedBranch,
                        };
                        var form_data = new FormData();

                        for (var key in payload) {
                          if (!!payload[key]) {
                            form_data.append(key, payload[key]);
                          }
                        }
                        if (!!imgInput) {
                          form_data.append("avatar", imgInput);
                        }
                        form_data.append("user", userId);
                        console.log("value", {
                          ...value,
                          medical_history: medicalHistory,
                        });
                        console.log("img inp", imgInput);
                        axios
                          .post("/apiV1/patient/", form_data, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          })
                          .then((res) => {
                            // navigate(-1);
                            setPatientAdded(true);
                            setPatientData(res?.data?.response);
                          })
                          .catch((err) => {
                            console.log("error", err);
                          })
                          .finally(() => {
                            setPatientLoading(false);
                          });
                      }}
                    >
                      <Row gutter={24} align="middle">
                        <Col md={6}>
                          <Form.Item
                            label="First Name"
                            name="first_name"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                            rules={[
                              {
                                required: true,
                                message: "Please enter First Name",
                              },
                            ]}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col md={6}>
                          <Form.Item
                            label="Last Name"
                            name="last_name"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please enter Last Name",
                            //   },
                            // ]}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col md={6}>
                          <Form.Item
                            label="Middle Name(Optional)"
                            name="middle_name"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24} align="middle">
                        <Col md={6}>
                          <Form.Item
                            label="Referred By"
                            name="refered_by"
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col md={6} style={{ marginBottom: "1em" }}>
                          <Form.Item
                            label="Whatsapp Number"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Mobile Number",
                              },
                            ]}
                            name="contact"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                            style={{ margin: 0, padding: 0 }}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col md={6} style={{ marginBottom: "1em" }}>
                          <Form.Item
                            label="Email Address"
                            name="email"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                            style={{ margin: 0, padding: 0 }}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24} align="middle">
                        <Col md={6}>
                          <Form.Item
                            label="Age"
                            name="age"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Age",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col md={6}>
                          <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select Gender",
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="male">Male</Select.Option>
                              <Select.Option value="female">
                                Female
                              </Select.Option>
                              <Select.Option value="others">
                                Prefer not to say
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col md={6}>
                          <Form.Item
                            label="Blood Group"
                            name="blood_group"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select a Blood Group",
                            //   },
                            // ]}
                          >
                            <Select>
                              <Select.Option value="a+">A+</Select.Option>
                              <Select.Option value="a-">A-</Select.Option>
                              <Select.Option value="b+">B+</Select.Option>
                              <Select.Option value="b-">B-</Select.Option>
                              <Select.Option value="ab+">AB+</Select.Option>
                              <Select.Option value="ab-">AB-</Select.Option>
                              <Select.Option value="o+">O+</Select.Option>
                              <Select.Option value="o-">O-</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col md={6}>
                          <Form.Item label="Primary Language">
                            <Select>
                              <Select.Option value="hindi">Hindi</Select.Option>
                              <Select.Option value="english">
                                English
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24} align="middle">
                        <Col md={8}>
                          <Form.Item
                            label="Address of Patient"
                            name="address"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col md={6}>
                          <Form.Item
                            label="City"
                            name="city"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                            rules={[
                              {
                                required: true,
                                message: "Please enter Pincode",
                              },
                            ]}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24} align="middle">
                        {/* <Col md={8}>
                          <Form.Item
                            label="Your Relationship"
                            name="relation"
                            colon={false}
                          >
                            <Select>
                              {[
                                "Myself",
                                "Daughter",
                                "Son",
                                "Sister",
                                "Brother",
                                "Relative",
                                "Friend",
                              ]?.map((item, index) => {
                                return (
                                  <Select.Option value={item}>
                                    {item}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col> */}

                        <Col md={6}>
                          <Form.Item
                            label="Member Name"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please enter Member Name",
                            //   },
                            // ]}
                            name="memberName"
                            // labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 8 }}
                            colon={false}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* <Box sx={{ width: "100%" }}>
                        <Form.Item
                          label={<b>Insurance Holder</b>}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please enter Insurance Holder",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="yes">Yes </Radio>
                            <Radio value="no"> No </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Box> */}
                      <Box sx={{ width: "100%" }}>
                        <Typography variant="body1">
                          <b>Medical Profile</b>
                        </Typography>

                        <Box
                          sx={{
                            p: 2,
                            display: "flex",
                            justifyContent: "space-around",
                            flexWrap: "wrap",
                          }}
                        >
                          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            <Typography>Medical History</Typography>
                            <Box sx={{ display: "flex" }}>
                              {/* <Form.Item name="medicalHistory"> */}
                              <Input
                                size="small"
                                value={medicalHistoryInput}
                                onChange={(e) => {
                                  setMedicalHistoryInput(e.target.value);
                                }}
                              />
                              {/* </Form.Item> */}
                              <Button
                                type="primary"
                                style={{ marginLeft: 5 }}
                                onClick={() => {
                                  if (!medicalHistoryInput) {
                                    return;
                                  }
                                  setMedicalHistory((p) => [
                                    ...p,
                                    medicalHistoryInput,
                                  ]);
                                  setMedicalHistoryInput("");
                                }}
                              >
                                Add
                              </Button>
                            </Box>
                            <Box
                              sx={{
                                p: 1,
                                display: "flex",
                                maxWidth: "20vw",
                                flexWrap: "wrap",
                                gap: 1,
                              }}
                            >
                              {medicalHistory?.map((item, index) => {
                                return (
                                  <Tag
                                    key={item}
                                    color="#7594D1"
                                    closable
                                    onClose={(e) => {
                                      console.log(e);
                                      setMedicalHistory((a) =>
                                        a.filter((i) => i !== item)
                                      );
                                    }}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item}
                                  </Tag>
                                );
                              })}
                            </Box>
                          </Box>

                          {/* <Box> */}

                          {/* </Box> */}

                          <Box>
                            <Typography>Paient is allergic ?</Typography>
                            <Form.Item
                              name="allrgy"
                              // labelCol={{ span: 24 }}
                              // wrapperCol={{ span: 8 }}
                              colon={false}
                            >
                              <Input placeholder="Type of Allergy" />
                            </Form.Item>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          loading={patientLoading}
                          size="large"
                          type="primary"
                          htmlType="submit"
                        >
                          Add Member
                        </Button>
                      </Box>
                    </Form>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography>
              Patient Added! Please proceed filling Appointment Form.
            </Typography>
            <Box
              sx={{
                // border: "2px solid gray",
                flexGrow: 1,
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minWidth: "350px",
                maxWidth: "40vw",
                p: 1,
                px: 1.5,
                position: "relative",
                gap: 1,
                m: 1,
                my: 2,
                boxShadow:
                  "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}
            >
              {item?.relation && (
                <Box
                  sx={{
                    mx: "auto",
                    position: "relative",
                    top: -25,
                    background: "#FFC289",
                    px: 5,
                    py: 1,
                    mb: -2,
                    borderRadius: 2,
                    boxShadow:
                      "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                  }}
                >
                  {" "}
                  {item?.relation}
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <Avatar
                    size={50}
                    src={
                      item?.avatar ??
                      "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    }
                  />
                  <Box sx={{ mx: 2 }}>
                    <Typography variant="h6">
                      {item?.first_name} {item?.middle_name} {item?.last_name}{" "}
                    </Typography>
                    <Typography color="gray">
                      {/* 26 Years, 4 Month Old */}
                      Age: {item?.age}
                    </Typography>
                  </Box>
                </Box>
                {/* <EditIcon /> */}
              </Box>
              <Box sx={{ border: "1px solid lightgray" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{item?.gender}</Typography>
                <Typography>{item?.contact}</Typography>
              </Box>
              <Box sx={{ border: "1px solid lightgray" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  //
                }}
              >
                <Typography>Body Weight - {item?.weight} Kgs</Typography>
                <Typography>Blood Groups - {item?.blood_group}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  //
                  background: "lightgray",
                  padding: 1,
                  px: 2,
                  alignItems: "center",
                  borderRadius: 2,
                }}
              >
                <HomeRoundedIcon sx={{ mr: 2, fontSize: 35 }} />
                <Typography>{item?.address}</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          border: "2px solid gray",
          width: "40%",
          minWidth: "380px",
          height: "100%",
        }}
      >
        <Typography variant="h6" textAlign={"center"}>
          Appointment Details
        </Typography>
        <AppointmentForm
          messageApi={messageApi}
          selectedBranch={selectedBranch}
          selectedDoc={selectedDoc}
          patientData={patientData}
          setShow={setShow}
        />
      </Box>
    </Box>
  );
};

const AppointmentForm = ({
  setShow,
  selectedBranch,
  selectedDoc,
  patientData,
}) => {
  const doctorData = JSON.parse(localStorage.getItem("current_doctor_details"));
  const [bookAppointmentFrom] = useForm();
  const [timingData, setTimingData] = useState();

  const [firstVisit, setFirstVisit] = useState(true);

  const doctor = "dsad";

  // useEffect(() => {
  //   console.log(getTimeSlots(timingData?.start_time, timingData?.end_time));
  // }, [timingData]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [slotsData, setSlotsData] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");

  const [equeueData, setEqueueData] = useState([]);
  const [equeueLoading, setEqueueLoading] = useState(false);

  const [selectedEqueue, setSelectedEqueue] = useState(null);

  function getEqueueData() {
    setEqueueLoading(true);
    axios
      .get(`/apiV1/e-queue/?doctor=${selectedDoc}&branch=${selectedBranch}`)
      .then((res) => {
        console.log("equeue res", res);
        setEqueueData(res?.data);
        setSelectedEqueue(res?.data[0]?.id);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setEqueueLoading(false);
      });
  }

  useEffect(() => {
    getEqueueData();
  }, []);

  console.log({ selectedSlot });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [calendarValue, setCalendarValue] = useState(new Date());

  let branchId;

  const [slotLoading, setSlotLoading] = useState(false);

  const [isEqueue, setIsEqueue] = useState(true);

  console.log({ selectedBranch });

  const createTimeSlot = (dateObj) => {
    branchId = bookAppointmentFrom.getFieldValue("branch");
    setSlotLoading(true);
    axios
      // .get(`/create-time-slot/?date=2023-05-31&day=Sunday&branch=1`)
      .get(
        `/apiV1/create-time-slot/?date=${moment(dateObj)?.format(
          "YYYY-MM-DD"
        )}&day=${moment(dateObj)?.format("dddd")}&branch=${selectedBranch}`
      )
      .then((res) => {
        console.log("resss", res);
        setSlotsData(res?.data);
      })
      .catch((err) => {
        console.log("time slot create error", err);
        setSlotsData([]);
      })
      .finally(() => {
        setSlotLoading(false);
      });
  };

  const navigate = useNavigate();
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const tabItems = [
    {
      label: <b>Book by e-Queue</b>,
      key: 2,
      children: (
        <>
          <Box>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Typography variant="h6" sx={{ my: 1 }}>
                Book by e-Queue
              </Typography>
              <Button
                onClick={() => {
                  getEqueueData();
                  setEqueueData([]);
                  setSelectedEqueue(null);
                }}
                type="primary"
                icon={
                  <Tooltip placement="topLeft" title={"Reload"}>
                    <ReloadOutlined />
                  </Tooltip>
                }
              />
            </Box>
            {/* <Typography>Select E-queue card </Typography> */}
            {equeueLoading && <Skeleton />}
            <div
              style={{ maxHeight: "300px", overflowY: "scroll", padding: 2 }}
            >
              {equeueData?.map((item, index) => {
                return (
                  <Box
                    sx={{
                      width: "100%",
                      // background: "#f0f9ff",
                      background: "#fff",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      border: "2px solid lightgray",
                      my: 1,
                      border:
                        selectedEqueue == item?.id ? "3px solid #22c55e" : "",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectedEqueue(item?.id);
                    }}
                  >
                    <Box>
                      <Typography variant="" color="#1d4ed8">
                        Booking at
                      </Typography>
                      <Typography
                        variant="h5"
                        // color="green"
                        textAlign={"center"}
                      >
                        {item?.current_equeue + 1}
                      </Typography>
                    </Box>
                    <Box>
                      <div style={{ position: "absolute", right: -15 }}>
                        {selectedEqueue == item?.id && (
                          <CheckCircleOutlined style={{ color: "#DCFCE7" }} />
                        )}
                      </div>
                      {/* <Typography variant="caption">Today</Typography> */}
                      {/* <br /> */}
                      <Typography variant="body1" color="#1d4ed8">
                        {moment(item?.date).format("DD MMM YY")}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="" color="#1d4ed8">
                        Ongoing
                      </Typography>
                      <Typography variant="h6" textAlign={"center"}>
                        {item?.on_going_equeue}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </div>
            {!equeueLoading && equeueData?.length <= 0 && (
              <Typography>E-queues not available</Typography>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              size="large"
              type="primary"
              align="center"
              htmlType="submit"
              loading={appointmentLoading}
              // onClick={() => {
              //   navigate(`/patient-profile`, {
              //     state: {
              //       doctor: doctorData?.id,
              //       slot: selectedSlot?.id,
              //       is_first_visit: false,
              //       reason: "follow_up",
              //       is_equeue: false,
              //       amount: 700,
              //     },
              //   });
              // }}
              disabled={!selectedEqueue}
            >
              Continue Booking
            </Button>
          </Box>
        </>
      ),
    },
  ];
  const [messageApi, contextHolder] = message.useMessage();

  console.log({ firstVisit });

  const bookAppointment = (payload) => {
    setAppointmentLoading(true);
    axios
      .post(`apiV1/appointment/`, payload)
      .then(() => {
        message.success("Appointment Booked Successfully!");
        navigate(`/dashboard`);
        setShow(0);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setAppointmentLoading(false);
      });
  };

  return (
    <>
      {contextHolder}
      <Box
        sx={{
          width: "100%",
          height: "70vh",
          display: "flex",
          flexDirection: "column",
          m: 1,
          p: 0,
          px: 1,
          // my: 10,
          display: "flow-root",
        }}
      >
        <Space
          direction="vertical"
          align="start"
          size="large"
          style={{ display: "flow-root" }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography variant="h5">Book Your Appointment </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Form
              form={bookAppointmentFrom}
              layout="vertical"
              onFinish={(value) => {
                let amount = 0;

                console.log({ value });

                if (!value.ReasonOfVisit) {
                  messageApi.open({
                    type: "error",
                    content: "Please select Reason for Your Visit",
                  });
                  return;
                }

                if (!selectedBranch) {
                  messageApi.open({
                    type: "error",
                    content: "Please select Branch",
                  });
                  return;
                }
                let payload = {
                  doctor: doctorData?.id,
                  slot: isEqueue ? null : selectedSlot?.id,
                  is_first_visit: firstVisit == "true" ? true : false,
                  reason: value?.ReasonOfVisit,
                  is_equeue: isEqueue ? true : false,
                  amount: firstVisit
                    ? doctorData?.first_time_charge
                    : doctorData?.returning_charge,
                  branch: selectedBranch,
                  date: isEqueue
                    ? equeueData?.find((i) => i.id == selectedEqueue)?.date
                    : null,
                  appointment_date: isEqueue
                    ? equeueData?.find((i) => i.id == selectedEqueue)?.date
                    : moment(selectedSlot?.date).format("YYYY-MM-DD"),
                  patient: patientData?.id,
                };
                bookAppointment(payload);
                // navigate(`/patient-profile`, {
                //   state: {
                //     payload: {
                //       doctor: doctorData?.id,
                //       slot: isEqueue ? null : selectedSlot?.id,
                //       is_first_visit: firstVisit == "true" ? true : false,
                //       reason: value?.ReasonOfVisit,
                //       is_equeue: isEqueue ? true : false,
                //       amount: firstVisit
                //         ? doctorData?.first_time_charge
                //         : doctorData?.returning_charge,
                //       branch: value?.branch,
                //       payment_method: isEqueue ? "cash" : "online",
                //       date: isEqueue
                //         ? equeueData?.find((i) => i.id == selectedEqueue)?.date
                //         : null,
                //       appointment_date: isEqueue
                //         ? equeueData?.find((i) => i.id == selectedEqueue)?.date
                //         : moment(selectedSlot?.date).format("YYYY-MM-DD"),
                //     },
                //     doctorData: doctorData,
                //   },
                // });
              }}
            >
              {/* <Row align="middle">
                <Col>
                  <Form.Item
                    label="Select Pay Provider"
                    name="PayProvider"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 24 }}
                    colon={false}
                    extra="Choose another, Out of service for Dr. Andrew Fagelman"
                  >
                    <Select></Select>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row align="middle">
                <Col>
                  <Form.Item
                    label="What’s The Reason for Your Visit"
                    name="ReasonOfVisit"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 24 }}
                    colon={false}
                  >
                    {/* ("general_consultation", "general_consultation"),
          ("follow_up", "follow_up"), */}
                    <Select>
                      <Select.Option value="general_consultation">
                        General Consultation
                      </Select.Option>
                      <Select.Option value="follow_up">Follow Up</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col>
                  <Form.Item
                    label="Have the patient seen these Doctors?"
                    // name="firstVisit"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 24 }}
                    colon={false}
                    // onChange={(e) => {
                    //   setFirstVisit(e.target.value);
                    // }}
                  >
                    <Radio.Group defaultValue={true}>
                      <Radio
                        value={true}
                        onClick={() => {
                          setFirstVisit(true);
                        }}
                      >
                        First Time
                      </Radio>
                      <Radio
                        value={false}
                        onClick={() => {
                          setFirstVisit(false);
                        }}
                      >
                        Returning
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Tabs
                defaultActiveKey="1"
                type="card"
                size={"large"}
                items={tabItems}
                onChange={(tabKey) => {
                  if (tabKey == 2) {
                    setIsEqueue(true);
                  } else {
                    setIsEqueue(false);
                  }
                }}
              />
            </Form>
          </Box>
        </Space>
      </Box>
      <Modal
        title={
          <div>
            {moment(calendarValue)?.format("dddd")} (
            {moment(calendarValue)?.format("MMM Do, yy")})
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {slotLoading ? (
          <Skeleton />
        ) : slotsData?.length > 0 ? (
          <div>
            <p>Select Slot</p>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {slotsData?.map((item, index) => {
                let thisIsSelected = false;
                if (item?.id === selectedSlot?.id) {
                  thisIsSelected = true;
                }
                return (
                  <Button
                    // style={{ flex: 1 }}
                    onClick={() => {
                      setSelectedSlot(item);
                    }}
                    type={thisIsSelected ? "primary" : "dashed"}
                    disabled={item?.is_booked}
                  >
                    {item?.slot}
                  </Button>
                );
              })}
            </Box>
          </div>
        ) : (
          <p>No Slots available for the day!</p>
        )}
      </Modal>
    </>
  );
};

export default NewAppointemet;
