import axios from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useContext, useState } from "react";
import { SnackbarContext } from "../component/UtilComponents/SnackbarUtil/SnackbarContext";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { openSnackbar } = useContext(SnackbarContext);

  const localAuthToken = localStorage.getItem("token");
  const localCurrentUser = JSON.parse(localStorage.getItem("user"));

  const [token, setToken] = useState(localAuthToken);
  const [currUser, setCurrUser] = useState(localCurrentUser);

  const [loginLoading, setLoginLoading] = useState(false);

  const getCurrentUser = async (authToken) => {
    try {
      const currentUserRes = await axios.get(`/apiV1/current-user/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authToken ?? localStorage?.getItem("token")}`,
        },
      });
      console.log(currentUserRes, "currentUserRes");
      return currentUserRes;
    } catch (error) {
      console.log("getCurrentUser error", error);
    }
  };

  const loginHandler = async (value) => {
    setLoginLoading(true);
    try {
      const loginResponse = await axios.post(`/token/`, value);
      const currUserResponse = await getCurrentUser(
        loginResponse?.data?.access
      );
      setToken(loginResponse?.data?.access);
      setCurrUser(currUserResponse?.data);

      openSnackbar("Logged in successfully!", "success");
      if (loginResponse?.data?.access) {
        localStorage.setItem("token", loginResponse?.data?.access);
      }
      if (currUserResponse?.data) {
        localStorage.setItem("user", JSON.stringify(currUserResponse?.data));
      }
    } catch (error) {
      console.log("login error", error);
      openSnackbar(
        `${
          error?.response?.data?.detail ??
          "Something went wrong! Try again later"
        }`,
        "error"
      );
    } finally {
      setLoginLoading(false);
      window.location.reload();
    }
  };

  const otpHandler = async (value) => {
    setLoginLoading(true);
    try {
      // const loginResponse = await axios.post(`/token/`, value);
      const currUserResponse = await getCurrentUser(value);

      console.log(currUserResponse, "currUserResponse");
      setToken(value);
      setCurrUser(currUserResponse?.data);

      openSnackbar("Logged in successfully!", "success");
      // if (currUserResponse?.data) {
      localStorage.setItem("token", value);
      // }
      // if (currUserResponse?.data) {
      localStorage.setItem("user", JSON.stringify(currUserResponse?.data));
      // }
    } catch (error) {
      console.log("login error", error);
      openSnackbar(
        `${
          error?.response?.data?.detail ??
          "Something went wrong! Try again later"
        }`,
        "error"
      );
    } finally {
      setLoginLoading(false);
      // window.location.reload();
    }
  };

  const logoutHandler = () => {
    setToken("");
    setCurrUser(null);
    localStorage.clear();
    window.location.replace("/login");
    openSnackbar("Logged Out successfully!", "success");
  };

  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      logoutHandler();
    }
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        currUser,
        loginHandler,
        logoutHandler,
        otpHandler,
        loginLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
