import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Backdrop,
  Switch,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "100%",
    height: "10px", // set the height you want here
  },
});

function EQueueThird({ selectedDoc, selectedBranch }) {
  console.log(selectedDoc, "selectedDoc");
  const [allDateEqueueInfo, setAllDateEqueueInfo] = useState([]);
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    fetchEQueueInfo();
  }, []);

  const fetchEQueueInfo = () => {
    setShowData(true);

    axios
      .get(`/apiV1/e-queue/?doctor=${selectedDoc}`)
      .then((res) => {
        console.log(res?.data, "res of get equeue");
        setAllDateEqueueInfo(res?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setShowData(false));
  };

  const editEQueue = (each) => {
    setShowData(true);

    const payload = {
      is_active: !each?.is_active,
    };
    axios
      .put(`apiV1/e-queue/${each?.id}/`, payload)
      .then((res) => {
        console.log(res?.data, "res of edit equeue");
        fetchEQueueInfo();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box sx={{ p: 5 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showData}
        onClick={() => setShowData(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h5" sx={{ my: 2 }}>
        E-Queue
      </Typography>
      <Box display="flex" justifyContent="space-between" sx={{ px: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Count</TableCell>
                <TableCell>Pause</TableCell>
                <TableCell>Limit</TableCell>
                {/* <TableCell>New Limit</TableCell> */}
                {/* <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {!allDateEqueueInfo?.length > 0 ? (
                <Box
                  sx={{
                    height: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>No E-Queue created for any date</Typography>
                </Box>
              ) : (
                allDateEqueueInfo?.map((row, index) => (
                  <TableRow key={row?.id}>
                    <TableCell></TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row?.current_equeue}</TableCell>
                    <TableCell>
                      <Switch
                        key={row?.id}
                        checked={row?.is_active}
                        onChange={() => editEQueue(row)}
                      />
                    </TableCell>
                    <TableCell>{row?.limit}</TableCell>
                    {/* <TableCell>
                      <CustomTextField
                        type="number"
                        placeholder="Limit"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    </TableCell> */}
                    {/* <TableCell>
                      <Button onClick={() => editEQueue(row.id)}>Save</Button>
                    </TableCell> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default EQueueThird;
