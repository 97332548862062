import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Divider,
  Typography,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
} from "@mui/material";
import { DeleteRounded } from "@mui/icons-material/";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { SnackbarContext } from "../component/UtilComponents/SnackbarUtil/SnackbarContext";
import AddressHome from "../assest/img/addressHome.svg";
import { useData } from "../context/data-context";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "100%",
    height: "10px", // set the height you want here
  },
});

function AddressDialog({ showAddressDialog, setShowAddressDialog }) {
  const { openSnackbar } = useContext(SnackbarContext);

  const dataValue = useData();
  const [showData, setShowData] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [addressInfo, setAddressInfo] = useState({
    country: "India",
    state: null,
    email: "",
    house_no: "",
    area: "",
    town: "",
    landmark: "",
    pincode: null,
    address_type: null,
    is_current: true,
  });

  useEffect(() => {
    fetchStateList();
    fetchAddressList();
  }, []);

  const fetchStateList = () => {
    axios
      .get("apiV1/state/")
      .then((res) => {
        setStateList(res?.data);
      })
      .catch((err) => {
        console.log(err, "err of state");
      });
  };

  const fetchAddressList = () => {
    axios
      .get("/apiV1/address/")
      .then((res) => {
        setAddressList(res?.data);

        const currentAddress = res?.data?.filter(
          (each) => each?.is_current === true
        );
        console.log(currentAddress, "currentAddress");
        if (currentAddress?.length > 0) {
          dataValue.dispatch({
            type: "addressUpdate",
            payload: currentAddress[0],
          });
        }
      })
      .catch((err) => {
        console.log(err, "err of AddressList");
      })
      .finally(() => setShowData(false));
  };

  const onSelectAddress = (each) => {
    setShowData(true);
    axios
      .put(`apiV1/address/${each?.id}/`, { is_current: true })
      .then((res) => {
        openSnackbar("New Address Selected successfully", "success");
        fetchAddressList();
      })
      .catch((err) => {
        console.log(err, "error of address");
      });
    dataValue.dispatch({ type: "addressUpdate", payload: each });
  };

  const deleteAddress = (e, each) => {
    e.stopPropagation();
    setShowData(true);
    if (each?.is_current) {
      localStorage?.removeItem("current_address");
    }
    axios
      .delete(`apiV1/address/${each?.id}/`)
      .then(() => {
        fetchAddressList();
      })
      .catch((err) => {
        console.log(err, "error of address");
      });
  };

  const createAddress = () => {
    if (!addressInfo?.state > 0) {
      openSnackbar("State is required", "error");
      return;
    }

    if (!addressInfo?.area > 0) {
      openSnackbar("Area is required", "error");
      return;
    }
    if (!addressInfo?.town > 0) {
      openSnackbar("Town is required", "error");
      return;
    }
    if (!addressInfo?.pincode > 0) {
      openSnackbar("Pincode is required", "error");
      return;
    }

    if (addressList?.length > 0) {
      addressInfo["is_current"] = false;
    }
    setShowData(true);
    axios
      .post("apiV1/address/", addressInfo)
      .then((res) => {
        console.log(res, "res of address");
        fetchAddressList();
        setShowAddressDialog(false);
        openSnackbar("Address Added successfully", "success");
      })
      .catch((err) => {
        console.log(err, "error of address");
      })
      .finally(() =>
        setAddressInfo({
          country: "",
          state: null,
          email: "",
          house_no: "",
          area: "",
          town: "",
          landmark: "",
          pincode: "",
          address_type: null,
        })
      );
  };

  return (
    <Dialog
      open={showAddressDialog}
      onClose={() => setShowAddressDialog(false)}
      fullWidth
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showData}
        onClick={() => setShowData(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>Add Address</DialogTitle>
      <Box className="row px-4">
        <Box className="col-md-8">
          <TextField
            autoFocus
            margin="dense"
            value={addressInfo?.country}
            label="Country"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setAddressInfo({
                ...addressInfo,
                country: e.target.value,
              });
            }}
          />
          <Typography variant="subtitle2" sx={{ pt: 1 }}>
            State*
          </Typography>
          <FormControl sx={{ m: 0, mt: 1, minWidth: "100%" }}>
            <Select
              value={addressInfo?.state}
              onChange={(e) => {
                setAddressInfo({
                  ...addressInfo,
                  state: e.target.value,
                });
              }}
              placeholder="select"
              fullWidth
              style={{ width: "100%", height: "40px" }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={""} disabled>
                Select
              </MenuItem>
              {stateList?.map((each) => (
                <MenuItem key={each?.id} value={each?.id}>
                  {each?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            value={addressInfo?.email}
            label="Email Address"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setAddressInfo({
                ...addressInfo,
                email: e.target.value,
              });
            }}
          />

          <TextField
            autoFocus
            margin="dense"
            value={addressInfo?.house_no}
            label="House No/ building/ apartment"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setAddressInfo({
                ...addressInfo,
                house_no: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            value={addressInfo?.area}
            label="Area / colony/ street/ sector/ village *​"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setAddressInfo({
                ...addressInfo,
                area: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            value={addressInfo?.town}
            label="Town/city*​"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setAddressInfo({
                ...addressInfo,
                town: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            value={addressInfo?.landmark}
            label="Landmark ​​"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setAddressInfo({
                ...addressInfo,
                landmark: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            value={addressInfo?.pincode}
            label="Pin Code *​​"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setAddressInfo({
                ...addressInfo,
                pincode: e.target.value,
              });
            }}
          />
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Type of Address
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={addressInfo?.address_type}
              onChange={(e) => {
                setAddressInfo({
                  ...addressInfo,
                  address_type: e.target.value,
                });
              }}
            >
              <FormControlLabel value="home" control={<Radio />} label="Home" />
              <FormControlLabel value="work" control={<Radio />} label="Work" />
            </RadioGroup>
          </FormControl>
          <DialogActions>
            <Button variant="contained" onClick={createAddress}>
              Submit
            </Button>
          </DialogActions>
        </Box>
        <Box className="col-md-4">
          {addressList?.map((each) => (
            <Box
              key={each?.id}
              className="scale-on-hover"
              sx={{
                display: "flex",
                flexDirection: "column",
                my: 2,
                p: 1,
                borderRadius: 2,
                border: "1px solid grey",
                backgroundColor: each?.is_current ? "#CEF5AC" : "",
                cursor: "pointer",
                transform: "translate",
              }}
              onClick={() => onSelectAddress(each)}
            >
              <Box
                sx={{
                  my: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={AddressHome}
                  alt="addressHomeImg"
                  height="30"
                  weight="30"
                  align="left"
                />
                <DeleteRounded
                  onClick={(e) => deleteAddress(e, each)}
                  sx={{ color: "red", p: 0.2 }}
                />
              </Box>
              <Typography variant="caption">
                {`${each?.house_no} (${each?.landmark}) ${each?.area} ${each?.town} ${each?.pincode}`}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* <DialogContent></DialogContent> */}
    </Dialog>
  );
}

export default AddressDialog;
