import React, { useContext, useState } from "react";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { styled } from "@mui/material/styles";
import axios from "axios";
import moment from "moment";
import { SnackbarContext } from "../../../component/UtilComponents/SnackbarUtil/SnackbarContext";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    width: "100%",
    height: "10px",
  },
});

function EQueueInfoForm({
  showEQueueDialog,
  setShowEQueueDialog,
  setShow,
  selectedDoc,
  selectedBranch,
}) {
  const { openSnackbar } = useContext(SnackbarContext);

  const [date, setDate] = useState(moment());
  const [limit, setLimit] = useState(null);
  const [averageTime, setAverageTime] = useState(null);
  const [visitingTime, setVisitingTime] = useState(dayjs());

  const createEQueue = () => {
    if (averageTime > 0 && limit > 0) {
      const payload = {
        limit: limit,
        is_active: true,
        average_time: averageTime,
        date: moment(date?.$d).format("YYYY-MM-DD"),
        branch: selectedBranch,
        doctor: selectedDoc,
        doctor_visiting_time: dayjs(visitingTime?.$d).format("HH:mm"),
      };

      // ........................hard code need to be fix.......................................
      // doctor_visiting_time: dayjs(visitingTime?.$d).format(
      //   "YYYY-MM-DDThh:mm:ss.SSSZ"
      // ),
      axios
        .post("apiV1/e-queue/", payload)
        .then((res) => {
          console.log(res, "res os create equeue");
          if (res?.status === 201) {
            setShow(2);
            return;
          }
        })
        .catch((err) => {
          console.log(err?.response?.data);
          openSnackbar(err?.response?.data, "error");
        })
        .finally(() => setShowEQueueDialog(false));
    }
  };

  // console.log(date, "dateee");
  // console.log(moment(date?.$d).format("YYYY-MM-DD"), "dateee moment");
  // console.log(limit, "limit");
  // console.log(averageTime, "averageTime");
  // console.log(selectedDoc, "selectedDoc");
  // console.log(selectedBranch, "selectedBranch");
  console.log(dayjs(visitingTime?.$d).format("hh:mm:ss"), "visitingTime");
  console.log(
    dayjs(visitingTime?.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    "visitingTime"
  );
  console.log(
    dayjs(visitingTime?.$d)
      .add(5, "hours")
      .add(30, "minutes")
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    "  badiya wala"
  );

  return (
    <Dialog open={showEQueueDialog} onClose={() => setShowEQueueDialog(false)}>
      <DialogTitle>Add E-queue Info</DialogTitle>
      <Divider />

      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle2">
          Choose Date for which you want to show E-queue
        </Typography>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            disablePast
            sx={{ margin: 0 }}
            onChange={(newDate) => {
              console.log(newDate, "new date"); // The selected date
              setDate(newDate);
            }}
          />
        </LocalizationProvider>
        <Box
          sx={{
            mb: 1,
          }}
        >
          <Typography variant="subtitle2">
            How many appointment would you like to take*
          </Typography>
          <CustomTextField
            type="number"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            placeholder="Enter limit"
          />
        </Box>
        <Box
          sx={{
            mb: 1,
          }}
        >
          <Typography variant="subtitle2">
            Average Patient Time (in min)*
          </Typography>
          <CustomTextField
            type="number"
            value={averageTime}
            onChange={(e) => setAverageTime(e.target.value)}
            placeholder="Enter Average Time"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Doctor Visiting Time*</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker", "TimePicker"]}>
              <TimePicker
                // label="Controlled picker"
                value={visitingTime}
                onChange={(newValue) => {
                  console.log(newValue, "newValue");
                  setVisitingTime(newValue);
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        <button
          className="th-18 th-btn-primary px-3 mx-1 my-1 py-2 th-br-5 h-100 border-0"
          onClick={createEQueue}
        >
          Save
        </button>
      </Box>
    </Dialog>
  );
}

export default EQueueInfoForm;
