import {
  AppBar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Steps,
  Row,
  Col,
  Divider,
  Space,
  Input,
} from "antd";
import { useNavigate } from "react-router-dom";
// import Authorize from "../../../global/Authorize";
const { Header, Content, Footer } = Layout;

const RegisterDoctor = () => {
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const menuItems = [<div></div>];
  return (
    // <Authorize>
    <Layout sx={{ backgroundColor: "#FFD3D3" }} className="layout">
      <Box sx={{ flexGrow: 1, backgroundColor: "#FFD3D3" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 40,
            p: 3,
            backgroundColor: "#BFEBFB",
          }}
        >
          <Box>
            <img
              role={"button"}
              onClick={() => {
                navigate("/home");
              }}
              alt="Welldone Logo"
              className="my-auto"
              src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
              style={{
                height: 40,
                // width: 20,
              }}
            />
          </Box>
          <Typography variant="caption">
            Profile of Register Medical Practionar (RMP)
          </Typography>
        </Box>
      </Box>
      <Content
        style={{
          padding: "0 5px",
        }}
      >
        <div
          className="site-layout-content"
          style={{
            background: colorBgContainer,
            // height: "80vh",
            padding: "5px",
          }}
        >
          <div style={{ height: "100%" }} className="row">
            <div span={18} className="col-sm-8">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  p: 1,
                }}
              >
                <Space direction="vertical" align="start">
                  <Typography variant="caption1">
                    Dear user, Share Following?
                  </Typography>
                  <Typography variant="h6">
                    Register, Create Professional Profile and Connect
                  </Typography>
                  <Typography variant="body1">
                    Please have the following ready before you begin:
                  </Typography>
                  <Typography variant="body1">
                    Your bank account details for receiving payments from
                    Welldone • Tax (GST/PAN) details of your Your Practice.
                  </Typography>
                  <Typography variant="body1">
                    Please ensure that all the information you submit is
                    accurate.
                  </Typography>
                  <Divider />
                  <Typography variant="body1">
                    Enter details below to continue registration
                  </Typography>
                  <Typography variant="body1">
                    Practice/Office/Clinic/Hospital Name
                  </Typography>
                  <Input placeholder="" size="large" />
                  <Typography variant="caption1">
                    Enter the company/business name as registered in GST/PAN
                  </Typography>
                  <Button variant="contained">Continue</Button>
                </Space>
                <Divider />
                <Box display="flex">
                  <Typography>Like to create new account?</Typography>
                  <Typography
                    color="#6170FF"
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/branch-doctor")}
                  >
                    Click Here
                  </Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body1">
                    <b>Practice Agreement</b>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox />
                    <Typography variant="caption1">
                      I have read and agree to comply with and/or be bound by
                      the terms and conditions of Welldone Services Business
                      Solutions Agreement, Easy Appointment Service and pathway
                      Terms and Conditions and Welldone Business (B2B) Terms &
                      Conditions
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </div>
            <div span={6} className="col-sm-4">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 1,
                }}
              >
                <Divider />
                <Button
                  variant="contained"
                  style={{ textTransform: "none" }}
                  onClick={() => navigate("/branch-doctor")}
                >
                  For Professional and Practice Both
                </Button>
              </Box>
            </div>
          </div>
        </div>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Privacy Notice
        </Footer>
      </Content>
    </Layout>
  );
};
export default RegisterDoctor;
