import React, { useEffect, useState } from "react";
import Placeholder from "../../assest/img/placeholder-img.jpg";
import DocAvatar from "../../assest/img/DocAvatar.png";
import LunchIcon from "../../assest/img/lunch.png";
import Speciality from "../../assest/img/speciality.png";
import Contact from "../../assest/img/contact.png";
import GraduatedIcon from "../../assest/img/graduatedIcon.png";

import {
  CallTwoTone,
  LanguageTwoTone,
  BusinessTwoTone,
  ApartmentTwoTone,
} from "@mui/icons-material";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { InsertCommentRounded } from "@mui/icons-material";
import { Box, Typography, Divider } from "@mui/material";

import { MedicationRounded, Apartment, Language } from "@mui/icons-material/";
import Footer from "../../component/Layout/Footer";

import axios from "axios";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import moment from "moment/moment";
import { Card, Image, Space } from "antd";

const HospitalPage = () => {
  const location = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [hospitalDetail, setHospitalDetail] = useState([]);
  const [convenienceServicesAvailable, setConvenienceServicesAvailable] =
    useState([]);

  const [showData, setShowData] = useState(false);

  useEffect(() => {
    fetchHospitalDetails();
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchHospitalDetails = () => {
    setShowData(true);
    const slug = location?.pathname?.split("/")[2];
    axios
      .get(`/apiV1/hospital-profile/?slug=${slug}`)
      .then((res) => {
        console.log(res?.data, "res of hospital");
        setHospitalDetail(res?.data);
        console.log(
          moment(res?.data?.timing[0]?.end_time, "hh:mm A").format("hh:mm A"),
          "timing"
        );
        const inpatientTypes =
          res?.data?.type_of_establishment[0]?.inpatient_type;
        const outpatientTypes =
          res?.data?.type_of_establishment[0]?.outpatient_type;
        const laboratoryTypes =
          res?.data?.type_of_establishment[0]?.laboratory_type;
        const imagingCenterTypes =
          res?.data?.type_of_establishment[0]?.imaging_center_type;

        const allTypes = [
          ...inpatientTypes,
          ...outpatientTypes,
          ...laboratoryTypes,
          ...imagingCenterTypes,
        ];
        setConvenienceServicesAvailable(allTypes);
      })
      .catch((err) => {
        console.log(err, "err");
      })
      .finally(() => setShowData(false));
  };

  console.log(
    hospitalDetail?.page_setup ? hospitalDetail?.page_setup[0]?.contact : "",
    "hos"
  );
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showData}
        onClick={() => setShowData(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <img
          src={
            hospitalDetail?.page_setup
              ? hospitalDetail?.page_setup[0]?.banner_image
              : Placeholder
          }
          alt="Hospital-Cover-Img"
          style={{ width: "100vw", height: "40vh", objectFit: "fill" }}
        />
      </div>

      <div className="w-100 th-container d-flex flex-wrap th-hos-profile ">
        {hospitalDetail?.page_setup ? (
          <img
            src={hospitalDetail?.page_setup[0]?.page_logo}
            className="rounded-circle th-doc-img "
            style={{
              width: 150,
              height: 150,
              objectFit: "cover",
              border: "1px solid lightgray",
              marginTop: -35,
            }}
            alt="hos_logo"
          />
        ) : (
          <img
            src={Placeholder}
            className="iconStyle"
            style={{
              width: 150,
              height: 150,
              objectFit: "cover",
              border: "1px solid lightgray",
              marginTop: -35,
            }}
            alt="placeholder"
          />
        )}
        <div className="th-hos-name ml-4" style={{ marginTop: "10px" }}>
          <h3 className="th-text-gradient" style={{ marginLeft: "5px" }}>
            {hospitalDetail ? hospitalDetail?.establishment_name : null}
          </h3>
          <div
            style={{
              borderRadius: "5px",
              backgroundColor: "#E6FFFB",
              color: "#08A4C4",
              border: "1px solid #08A4C4",
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              padding: "5px",
            }}
          >
            <Typography>
              {hospitalDetail?.page_setup
                ? hospitalDetail?.page_setup[0]?.tagline
                : null}
            </Typography>
          </div>
        </div>
      </div>
      <Box
        className="th-container-1 my-5"
        sx={
          {
            // border: "1px solid red",
          }
        }
      >
        <Box className="my-3">
          {/* <Typography variant="caption">365 Recommendation</Typography> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 50,
              height: 50,
              backgroundColor: "#1A73E8",
              borderRadius: "50%",
            }}
          >
            <InsertCommentRounded
              sx={{
                color: "#FFFFFF",
              }}
            />
          </Box>
          <Box
            sx={{
              ml: 1,
            }}
          >
            <Typography>
              <b>New Office safety precautions</b>
            </Typography>

            <Typography variant="caption">
              Messege from the office of{" "}
              {hospitalDetail ? hospitalDetail?.establishment_name : null}
            </Typography>
            <br />
            <Typography variant="">
              {hospitalDetail?.page_setup
                ? hospitalDetail?.page_setup[0]?.display_message
                : null}{" "}
            </Typography>
          </Box>
        </Box>

        <Box
          className="my-2"
          sx={{
            lineHeight: 0,
          }}
        >
          <h4>Statement</h4>
          <Typography variant="caption">
            {hospitalDetail?.page_setup
              ? hospitalDetail?.page_setup[0]?.statement
              : null}
          </Typography>
          <br />
        </Box>
        <div
          style={
            {
              // background: "red"
            }
          }
        >
          <Typography variant="h6">
            <b>Specialites in {hospitalDetail?.establishment_name}</b>
          </Typography>
          {hospitalDetail?.clinical_services?.length > 0 ? (
            <div className="row d-flex card-body">
              {hospitalDetail?.clinical_services[0]?.specility
                ?.split(",")
                ?.map((each) => {
                  console.log(each, "each");
                  return (
                    <div className="col-md-3 p-1 col-sm-4 m-1 d-flex  justify-content-center align-items-center card">
                      <div className="card-body">
                        <Space align="center">
                          <Image
                            width="25px"
                            src={Speciality}
                            preview={false}
                          />

                          <Typography>{each}</Typography>
                        </Space>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          <Box>
            <Typography className="px-2" variant="h6">
              Hospital branches and Location
            </Typography>
            <Box className="row my-2">
              {hospitalDetail?.available_branches?.map((each) => (
                <Box
                  className="col-md-3 col-sm-12 d-flex flex-column justify-content-center align-items-center"
                  sx={{
                    border: "1px solid lightgrey",
                    borderRadius: 1,
                    mb: 1,
                    backgroundColor: "#F8E8EE",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/hospital-page/${each?.slug}`);
                  }}
                >
                  <Typography>
                    <b>{each?.establishment_name}</b>
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {`${each?.owner_details__address}, ${each?.owner_details__city}, ${each?.owner_details__district}, ${each?.owner_details__pincode}, ${each?.owner_details__state__name}`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <div className="row d-flex" style={{ mt: 2 }}>
            {hospitalDetail?.doctors?.map((each) => (
              <div
                className="col-md-3 col-sm-4 card"
                onClick={() => {
                  navigate({
                    pathname: `/book-appointment`,
                    search: `?${createSearchParams({
                      doctor: each?.slug,
                      branch: slug,
                    })}`,
                  });
                }}
              >
                <Box className="card-body d-flex">
                  <img
                    src={
                      each?.profile_picture?.includes("dev")
                        ? each?.profile_picture
                        : GraduatedIcon
                    }
                    width="80px"
                    height="80px"
                    className="rounded-circle"
                    alt="doc_img"
                  />

                  <div className="pl-2">
                    <Typography variant="subtitle1">
                      {/* <b>{each?.name}</b> */}
                      <b>{`${each?.suffix ?? ""} ${each?.first_name ?? ""} ${
                        each?.middle_name ?? ""
                      } ${each?.last_name ?? ""}`}</b>
                    </Typography>

                    <div className="d-flex justify-content-between">
                      <Typography variant="caption">
                        {each?.qualification}
                      </Typography>
                    </div>
                    <Typography variant="subtitle2">
                      <b>{each?.speciality}</b>
                    </Typography>
                    <Typography variant="caption">{each?.expi}</Typography>
                  </div>
                </Box>
                <Box className="pb-1">
                  <Typography variant="caption">{each?.moreInfo}​</Typography>
                </Box>
              </div>
            ))}
          </div>
        </div>

        <div className="p-4">
          <Typography variant="subtitle1">
            <b>Convenience services available​</b>
          </Typography>

          <div className="row d-flex">
            {convenienceServicesAvailable?.map((each) => (
              <div className="m-1  card">
                <div className="card-body ">
                  <Typography variant="caption" textTransform="capitalize">
                    {each.split("_")?.join(" ")}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row p-4">
          <div className="col-md-6">
            <div className="py-2 mb-3">
              <Typography variant="h6">Timings </Typography>
              {hospitalDetail?.timing?.length > 0 && (
                <div
                  className="d-flex mt-1 p-1"
                  style={{ overflowX: "scroll" }}
                >
                  {hospitalDetail?.timing?.map((each, index) => (
                    <Card.Grid
                      style={{ minWidth: "140px" }}
                      className="mr-2 mb-2 p-1"
                    >
                      <div className="text-center font-weight-bold">
                        {each?.day}
                      </div>
                      <div className="text-center" style={{ fontSize: "12px" }}>
                        {moment(each?.start_time, "hh:mm A").format("hh:mm A")}{" "}
                        - {moment(each?.end_time, "hh:mm A").format("hh:mm A")}
                      </div>
                      {each?.lunch_start_time ? (
                        <div className="d-flex justify-content-center align-items-center   ">
                          <Image width="25px" src={LunchIcon} preview={false} />
                          <div
                            className="text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {moment(each?.lunch_start_time, "hh:mm A").format(
                              "hh:mm A"
                            )}{" "}
                            -{" "}
                            {moment(each?.lunch_end_time, "hh:mm A").format(
                              "hh:mm A"
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card.Grid>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6">
            {/* <div style={{ p: 2, m: 1 }}> */}
            <div className="mr-3 shadow-sm py-2 card">
              <div className="card-body">
                <Space align="center">
                  <Image width="40px" src={Contact} preview={false} />
                  <Typography
                    className="font-weight-bold"
                    style={{ fontSize: "18px" }}
                  >
                    Contact Details
                  </Typography>
                </Space>
                <div className="pt-3">
                  {hospitalDetail?.owner_details && (
                    <Typography className="pb-2" variant="body2">
                      <CallTwoTone /> {hospitalDetail?.page_setup[0]?.contact}{" "}
                    </Typography>
                  )}
                  {hospitalDetail?.owner_details && (
                    <Typography className="pb-2" variant="body2">
                      <LanguageTwoTone />{" "}
                      {hospitalDetail?.page_setup[0]?.website}
                    </Typography>
                  )}
                  {hospitalDetail?.owner_details && (
                    <Typography className="pb-2" variant="body2">
                      <BusinessTwoTone />{" "}
                      {hospitalDetail?.owner_details[0]?.address}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </Box>
      <Footer />
    </Box>
  );
};

export default HospitalPage;
