import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import DocAvatar from "../../../assest/img/DocAvatar.png";
import HospitalAvatar from "../../../assest/img/hospitalAvatar.jpg";
import { Button, Modal, Tag } from "antd";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { getRandomColor } from "../../../component/UtilComponents/getRandomColor";
const HospitalCard = ({ details }) => {
  const navigate = useNavigate();
  const [iframe, setIframe] = useState();
  const [modal, setModal] = useState(false);
  return (
    <React.Fragment>
      <div className="p-3 bg-white rounded my-3 shadow-sm">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-wrap justify-content-between ">
            <div>
              <img
                className="th-br-5"
                src={
                  details?.page_setup__page_logo?.length > 0
                    ? details?.page_setup__page_logo
                    : HospitalAvatar
                }
                width="130px"
                height="130px"
                alt="hos_image"
              />
            </div>
            <div className="pl-3">
              <div>
                <h4 className="text-capitalize">
                  {details?.establishment_name}
                </h4>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-map-marker text-danger pr-1"></i>
                <div className="pr-1">
                  {details?.contact_address ?? details?.branch}
                  {", "}
                </div>{" "}
                <div>
                  {details?.branch__state__name ?? details?.state__name}
                </div>
              </div>
            </div>
          </div>
          <div className="h-100 d-flex flex-column">
            {details?.branch_map__iframe && (
              <Button
                className="mt-2"
                onClick={() => {
                  setIframe(details?.branch_map__iframe);
                  setModal(true);
                }}
                icon={<i className="fas fa-map-marker text-danger"></i>}
              >
                View on Map
              </Button>
            )}
            <Button
              className="mt-2"
              type="primary"
              onClick={(e) => {
                navigate(`/hospital-page/${details?.slug}`);
              }}
              icon={<i class="fas fa-info-circle"></i>}
            >
              View Details
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={modal}
        title={"Map"}
        onCancel={() => {
          setIframe(null);
          setModal(false);
        }}
        footer={null}
      >
        <div className="th-iframe-listing">
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: iframe,
            }}
          ></div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default HospitalCard;
