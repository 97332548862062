import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/auth-context";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";

const PublicRoutes = ({ authenticated, children, ...rest }) => {
  const authValue = useAuth();
  return <Outlet />;
  // return authValue?.token ? <Navigate to="/" /> : <Outlet />;
};

// PublicRoutes.propTypes = {
//   user: PropTypes.object,
// };

export default PublicRoutes;
