import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Spin, Tag } from "antd";
import PatientAvatar from "../../assest/img/patient.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
const PatientProfile = () => {
  const navigate = useNavigate();
  const [patientsData, setPatientsData] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log("patientsData", patientsData);
  const location = useLocation();
  console.log("locn state", location?.state);
  const getPatientsData = () => {
    setLoading(true);
    axios
      .get("/apiV1/patient/")
      .then((res) => {
        setPatientsData(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getPatientsData();
  }, []);

  const [htmlContent, setHtmlContent] = useState("");

  const handleAppointment = (patient) => {
    setLoading(true);
    console.log("handle payment");
    navigate("/checkout", {
      state: {
        payload: { ...location?.state?.payload, patient: patient?.id },
        doctorData: location?.state?.doctorData,
        patientData: patient,
        appointmentDetail: location?.state?.payload,
      },
    });
  };

  return (
    <>
      {htmlContent && (
        <iframe
          style={{ position: "fixed", zIndex: 1000 }}
          srcDoc={htmlContent}
          width="100%"
          height="100%"
          frameBorder="0"
          title="HTML Content"
        />
      )}

      <Spin size="large" spinning={loading}>
        <div className="th-container p-5">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Select Patient</h4>
            <Button
              onClick={() => {
                navigate("/patient-reg");
              }}
              icon={<i class="fas fa-plus-circle"></i>}
              type="primary"
            >
              Add New Patient
            </Button>
          </div>
          <div className="row   ">
            {patientsData?.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                  <div
                    onClick={() => {
                      handleAppointment(item);
                    }}
                    className="th-patient-card card  th-br-10 w-100 p-3 rounded  shadow-sm h-100 d-flex flex-column justify-content-between"
                  >
                    <div className="d-flex flex-wrap py-2 ">
                      <img
                        alt="pat_img"
                        src={item?.avatar ? item?.avatar : PatientAvatar}
                        width="50px"
                        height="50px"
                        className="th-br-10 bg-white shadow-sm rounded-circle"
                      />
                      <div
                        className="pl-3"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div className="d-flex align-items-center ">
                          <h5 className="m-0 pr-1">
                            {[
                              item?.first_name ?? "",
                              item?.last_name ?? "",
                            ]?.join(" ")}
                          </h5>
                          <div>
                            ({item?.age},
                            {item?.gender?.substring(0, 1)?.toUpperCase()})
                          </div>
                          <Badge
                            style={{
                              backgroundColor: "#01549a",
                            }}
                            className="ml-2"
                            count={item?.relation}
                          />
                        </div>
                        <div className="d-flex flex-wrap mt-2">
                          {item?.contact && (
                            <Tag icon={<i class="fas fa-phone-alt mr-1"></i>}>
                              {item?.contact}
                            </Tag>
                          )}
                          {item?.address && (
                            <Tag
                              icon={<i class="fas fa-map-marker-alt mr-1"></i>}
                            >
                              {item?.address}
                            </Tag>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex flex-wrap  mt-2">
                        {item?.weight && (
                          <Tag className="m-1">
                            <span>
                              <i className="th-grey fas fa-weight mr-2"></i>
                              {item?.weight}kg
                            </span>
                          </Tag>
                        )}
                        {item?.temperature && (
                          <Tag className="m-1">
                            <span>
                              <i className="th-grey fas fa-temperature-high mr-2"></i>
                              {item?.temperature}° C
                            </span>
                          </Tag>
                        )}
                        {item?.menstrual && (
                          <Tag className="m-1">
                            <span>
                              <img
                                src="https://cdn-icons-png.flaticon.com/128/4080/4080345.png"
                                height={16}
                                className="mr-2"
                                alt="periods"
                              />
                              {item?.menstrual}M/c
                            </span>
                          </Tag>
                        )}
                        {item?.low_bp && item?.high_bp && (
                          <Tag className="m-1">
                            <span>
                              <img
                                src="https://cdn-icons-png.flaticon.com/128/811/811932.png"
                                height={16}
                                className="mr-2"
                                alt="periods"
                              />
                              {item?.low_bp}/{item?.high_bp} mm/hg
                            </span>
                          </Tag>
                        )}
                        {item?.respiratory_rate && (
                          <Tag className="m-1">
                            <span>
                              <img
                                src="https://cdn-icons-png.flaticon.com/128/2833/2833035.png"
                                height={16}
                                className="mr-2"
                                alt="periods"
                              />
                              {item?.respiratory_rate}/min
                            </span>
                          </Tag>
                        )}
                        {item?.glucose_level && (
                          <Tag className="m-1">
                            <span>
                              <img
                                src="https://cdn-icons-png.flaticon.com/128/3209/3209174.png"
                                height={16}
                                className="mr-2"
                                alt="periods"
                              />
                              {item?.glucose_level} mg/DL
                            </span>
                          </Tag>
                        )}
                        {item?.pulse && (
                          <Tag className="m-1">
                            <span>
                              <img
                                src="https://cdn-icons-png.flaticon.com/128/8226/8226674.png"
                                height={16}
                                className="mr-2"
                                alt="periods"
                              />
                              {item?.pulse}/min
                            </span>
                          </Tag>
                        )}
                        {item?.height && item?.height_unit && (
                          <Tag className="m-1">
                            <span>
                              <img
                                src="https://cdn-icons-png.flaticon.com/128/2330/2330995.png"
                                height={16}
                                className="mr-2"
                                alt="periods"
                              />
                              {item?.height}
                              {item?.height_unit}
                            </span>
                          </Tag>
                        )}
                        {item?.blood_group && (
                          <Tag
                            icon={
                              <img
                                src="https://cdn-icons-png.flaticon.com/128/8879/8879089.png"
                                height={14}
                                className="mr-1"
                                alt="periods"
                              />
                            }
                            className=""
                          >
                            <span style={{ textTransform: "uppercase" }}>
                              {item?.blood_group}
                            </span>
                          </Tag>
                        )}
                        {/* {vitalSign?.refered_by && (
                <Tag
                  color="white"
                  style={{ color: "#747474" }}
                  className="mt-1 py-1"
                >
                  <span>Referred By : {vitalSign?.refered_by}</span>
                </Tag>
              )}
              {vitalSign?.medical_history?.length > 0 &&
                vitalSign?.medical_history?.split(",")?.map((item) => (
                  <Tooltip title="Medical History">
                    <Tag
                      color="white"
                      style={{ color: "#747474" }}
                      className="mt-1 py-1"
                    >
                      <HistoryOutlined className="" />
                      <span>{item}</span>
                    </Tag>
                  </Tooltip>
                ))}
              {vitalSign?.allrgy && (
                <Tooltip title="Allergy">
                  <Tag
                    color="white"
                    style={{ color: "#747474" }}
                    className="mt-1 py-1"
                  >
                    {vitalSign?.allrgy}
                  </Tag>
                </Tooltip>
              )} */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default PatientProfile;
