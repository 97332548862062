import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  Typography,
  List,
  Toolbar,
  Divider,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  FormControl,
  Select,
  TextField,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import MedicationIcon from "@mui/icons-material/Medication";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import {
  CalendarMonthRounded,
  FormatListNumberedRtlRounded,
} from "@mui/icons-material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EQueueInfoForm from "./EQueueInfoForm";
import EQueueThird from "./pages/EQueueThird";
import DashboardFirst from "./pages/DashboardFirst";
import Doctor from "./pages/Doctor";
import EQueueList from "./pages/EQueueList";
import NewAppointemet from "./NewAppointemet";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [date, setDate] = useState(dayjs());

  const [open, setOpen] = React.useState(false);
  const [showEQueueDialog, setShowEQueueDialog] = useState(false);

  const [showData, setShowData] = useState(false);

  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [docList, setDocList] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const [patientCount, setPatientCount] = useState(null);
  const [allPatients, setAllPatients] = useState({});

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchBranchList();
  }, []);

  useEffect(() => {
    if (selectedBranch) {
      fetchDocList();
    }
  }, [selectedBranch]);

  const fetchBranchList = () => {
    setShowData(true);

    axios
      .get("apiV1/user-branch/")
      .then((res) => {
        console.log(res?.data, "branchList");
        setBranchList(res.data);

        setSelectedBranch(JSON.parse(localStorage.getItem("current_branch")));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setShowData(false));
  };

  const fetchDocList = () => {
    setShowData(true);

    axios
      .get(`apiV1/doctors-in-branch/?branch_id=${selectedBranch}`)
      .then((res) => {
        setDocList(res?.data);
        console.log(res.data, "doctor list");
        let preExistingDoctor = JSON.parse(
          localStorage.getItem("current_doctor")
        );
        setSelectedDoc(preExistingDoctor);
        localStorage.setItem(
          "current_doctor_details",
          JSON.stringify(
            res?.data?.filter((e) => e.id === Number(preExistingDoctor))[0] ??
              null
          )
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setShowData(false));
  };

  useEffect(() => {
    if (selectedDoc) {
      fetchEqueueInfo();
      setTimeout(() => {
        setShowEQueueDialog(true);
      }, 1000);
      const interval = setInterval(() => {
        fetchEqueueInfo();
      }, 5 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [selectedDoc, date]);

  const fetchEqueueInfo = () => {
    console.log(dayjs(date).format("YYYY-MM-DD"), "date from fetchEqueIFO");
    setShowData(true);
    axios
      .get(
        `/apiV1/appointment-recieved/?date=${dayjs(date).format(
          "YYYY-MM-DD"
        )}&doctor=${selectedDoc}`
      )
      .then((res) => {
        console.log(res?.data, "patlient alllllll");
        setAllPatients(res.data);
        let totalPatientCount = 0;
        for (const key in res.data) {
          if (Array.isArray(res.data[key])) {
            totalPatientCount += res.data[key].length;
          }
        }
        setPatientCount(totalPatientCount);
      })
      .catch((error) => console.log(error))
      .finally(() => setShowData(false));
  };

  const editEqueueInfo = (PatientId, info) => {
    setShowData(true);
    const payload = {
      branch: selectedBranch,
      doctor: selectedDoc,
      id: PatientId,
      ...info,
    };
    console.log(PatientId, payload, "PatientId");

    axios
      .put(`/apiV1/appointment-recieved/`, { ...payload })
      .then((res) => {
        console.log(res?.data, "edited");

        fetchEqueueInfo();
      })
      .catch((error) => {
        console.log(error);
        setShowData(false);
      });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [show, setShow] = useState(0);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Backdrop
        sx={{
          color: "#fff",
          // zIndex: (theme) => theme.zIndex.drawer + 1
          zIndex: 2,
        }}
        open={showData}
        onClick={() => setShowData(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "#BFEBFB", zIndex: 1 }}
      >
        <Toolbar
          className="d-flex"
          sx={{
            width: "100%",
            // display: "flex",
            flexWrap: "wrap",
            backgroundColor: "#BFEBFB",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                mr: 2,

                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ ml: 2 }}>
              <img
                role={"button"}
                onClick={() => {
                  navigate("/");
                }}
                alt="Welldone Logo"
                className="my-auto"
                src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                style={{
                  height: 40,
                }}
              />
            </Box>
          </Box>
          <button
            onClick={() => setShow("new")}
            className="th-18 th-btn-primary px-1 py-1 th-br-5 h-100  border-0"
          >
            New Appointment
          </button>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#000000",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowDatePicker((value) => !value);
              }}
            >
              <CalendarMonthRounded sx={{}} />
              <Typography variant="caption">
                {dayjs(date)?.format("DD")} {dayjs(date).format("MMMM")}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "90%",
                display: "none",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  open={showDatePicker}
                  onOpen={() => {
                    setShowDatePicker(true);
                    console.log("open");
                  }}
                  onClose={() => {
                    setShowDatePicker(false);
                  }}
                  value={date}
                  onChange={(newValue) => {
                    console.log(newValue, "newValue");
                    setDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                lineHeight: 0,
                mx: 1,
              }}
            >
              <Typography variant="caption" color="#000000">
                Branch
              </Typography>
              <FormControl sx={{ width: 100 }} size="small">
                <Select
                  value={selectedBranch}
                  // label="Branch"
                  onChange={(event) => {
                    setSelectedBranch(event.target.value);
                    console.log(event.target, "event");
                    localStorage.setItem(
                      "current_branch",
                      JSON.stringify(event.target.value)
                    );
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    backgroundColor: "#ffffff",
                  }}
                >
                  {branchList?.map((each) => (
                    <MenuItem value={each?.id}>
                      {each?.establishment_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                lineHeight: 0,
              }}
            >
              <Typography variant="caption" color="#000000">
                Doctor
              </Typography>
              <FormControl sx={{ width: 100 }} size="small">
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={selectedDoc}
                  label="Branch"
                  onChange={(event, extraData) => {
                    setSelectedDoc(event.target.value);
                    localStorage.setItem(
                      "current_doctor",
                      JSON.stringify(event.target.value)
                    );
                    localStorage.setItem(
                      "current_doctor_details",
                      JSON.stringify(extraData?.props?.detail)
                    );
                  }}
                  sx={{
                    backgroundColor: "#ffffff",
                  }}
                >
                  {docList?.map((each) => (
                    <MenuItem value={each?.id} detail={each}>
                      {`${each?.first_name ?? ""} ${each?.middle_name ?? ""} ${
                        each?.last_name ?? ""
                      }`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          // backgroundColor: "#FFEE54",
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          sx={{
            backgroundColor: "#FFEE54",
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            backgroundColor: "#FFEE54",
          }}
        >
          {["Dashboard", "Doctor", "PauseEQueue", "Equeue-List"].map(
            (text, index) => (
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",

                    px: 2.5,
                  }}
                  onClick={() => setShow(index)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index === 0 ? (
                      <DashboardCustomizeIcon />
                    ) : index === 1 ? (
                      <MedicationIcon />
                    ) : index === 2 ? (
                      <SupervisorAccountIcon />
                    ) : (
                      <FormatListNumberedRtlRounded />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
        {/* <Divider /> */}
      </Drawer>
      <Main open={open} sx={{ px: 0, my: 2 }}>
        <DrawerHeader />
        {show === 0 ? (
          <DashboardFirst
            date={dayjs(date).format("YYYY-MM-DD")}
            selectedBranch={selectedBranch}
            setShowEQueueDialog={setShowEQueueDialog}
            docList={docList}
            patientCount={patientCount}
            allPatients={allPatients}
            fetchEqueueInfo={fetchEqueueInfo}
            editEqueueInfo={editEqueueInfo}
            setShow={setShow}
            showData={showData}
          />
        ) : show === 1 ? (
          <Doctor docList={docList} showData={showData} />
        ) : show === 2 ? (
          <EQueueThird
            selectedDoc={selectedDoc}
            selectedBranch={selectedBranch}
          />
        ) : show === 3 ? (
          <EQueueList
            allPatients={allPatients}
            fetchEqueueInfo={fetchEqueueInfo}
          />
        ) : show === "new" ? (
          <NewAppointemet
            setShow={setShow}
            selectedDoc={selectedDoc}
            selectedBranch={selectedBranch}
          />
        ) : (
          <>Nothing to show</>
        )}
      </Main>
      {showEQueueDialog && (
        <EQueueInfoForm
          showEQueueDialog={showEQueueDialog}
          setShowEQueueDialog={setShowEQueueDialog}
          setShow={setShow}
          setSelectedDoc={setSelectedDoc}
          selectedDoc={selectedDoc}
          selectedBranch={selectedBranch}
        />
      )}
    </Box>
  );
}
