import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import SearchIcon from "@mui/icons-material/Search";
import {
  GroupsOutlined,
  KeyboardArrowDownRounded,
  LocationOnOutlined,
  LocationOnSharp,
  LocationOnTwoTone,
  OutputOutlined,
} from "@mui/icons-material";
import { SearchOutlined } from "@ant-design/icons";
import { Box, Typography, Popover, Divider } from "@mui/material";
import { useAuth } from "../../../context/auth-context";
import { useData } from "../../../context/data-context";
import AddressDialog from "../../../page/AddressDialog";
import axios from "axios";
import { Button, Select, Spin, Tag } from "antd";
import debounce from "lodash/debounce";
import { useMemo, useRef } from "react";
import MyMobile from "../../UtilComponents/MyMobile";
import MobileHeader from "../MobileHeader";
import MyDesktop from "../../UtilComponents/MyDesktop";
export function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  className,
  value,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  // const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  console.log(options, "options");
  return (
    <Select
      showSearch
      labelInValue
      placeholder="Search Doctors"
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      className={className}
      bordered={false}
      suffixIcon={
        <SearchOutlined
          style={{ fontSize: "18px" }}
          size="large"
          onClick={() => {
            navigate("/search-listing", {
              state: value,
            });
          }}
        />
      }
      size="large"
    />
  );
}

function Header(props) {
  const navigate = useNavigate();
  const authValue = useAuth();
  const dataValue = useData();
  const [showAddressDialog, setShowAddressDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const address = dataValue?.dataState?.currentAddress;

  const [value, setValue] = useState(null);
  const [input, setInput] = useState("");

  async function fetchUserList(searchTest) {
    if (searchTest?.length > 0) {
      console.log("fetching user", searchTest);
      setInput(searchTest);
      return axios(`apiV1/home-search/?search=${searchTest}`).then((res) => {
        console.log(res, "res");
        return res.data?.map((each) => ({
          label:
            each?.establishment_name ||
            `${each?.suffix} ${each?.first_name ?? ""} ${
              each?.middle_name ?? ""
            } ${each?.last_name ?? ""}`,
          value: each?.establishment_name || `${each?.first_name ?? ""}`,
          // is_hospital: each?.is_hospital,
          // clickedSlug: each?.slug,
        }));
      });
    }
  }
  const [divHeight, setDivHeight] = useState(58); // Initial height of the div in pixels
  const expandedHeight = 100; // Height to expand the div in pixels
  const scrollTriggerPosition = 25; // Number of pixels to scroll before expanding the div
  const [isFixed, setIsFixed] = useState(false); // Track if the div is fixed to the top

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollTriggerPosition) {
        // setDivHeight(expandedHeight);
        setIsFixed(true);
      } else {
        // setDivHeight(58); // Reset div height when scrolling up
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* DESKTOP HEADER SECTION */}
      <MyDesktop>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            backgroundColor: "#ffffff",
            opacity: 1,
            flexWrap: "wrap",
          }}
          className={`shadow-sm py-1 expanding-div py-2 ${
            isFixed ? "fixed m-0" : "m-0"
          }`}
        >
          <div>
            <img
              role={"button"}
              onClick={() => {
                navigate("/home");
              }}
              alt="Welldone Logo"
              className="my-auto"
              src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
              style={{
                height: 50,
              }}
            />
          </div>

          <div style={{ width: "40%", display: "flex" }}>
            <DebounceSelect
              sx={{ maxWidth: 300 }}
              value={value}
              placeholder="Search Doctors"
              fetchOptions={fetchUserList}
              onChange={(newValue, value) => {
                console.log(newValue, "newValue");
                navigate("/search-listing", {
                  state: value,
                });
                setValue(newValue);
              }}
              style={{
                width: "100%",
              }}
            />
          </div>
          <Button
            onClick={() => setShowAddressDialog(true)}
            icon={<LocationOnOutlined />}
          >
            {address?.id
              ? `${address?.town} ${address?.pincode}`
              : "Add Address"}
          </Button>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {authValue?.currUser?.name?.length > 0 ? (
              <Box>
                <Box
                  sx={{ display: "flex", alignItems: "center," }}
                  onClick={handleClick}
                >
                  <Typography>👋</Typography>
                  <Typography>Hii, {authValue?.currUser?.name}</Typography>
                  <KeyboardArrowDownRounded />
                </Box>
                <Popover
                  id={Boolean(anchorEl) ? "simple-popover" : undefined}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: 3,
                    }}
                  >
                    {authValue?.currUser?.roles?.id === 4 ? (
                      <Box>
                        <Typography
                          variant="body1"
                          className="pb-2"
                          sx={{ cursor: "pointer" }}
                          onClick={() => navigate("/dashboard")}
                        >
                          Dashboard
                        </Typography>
                        <Typography
                          variant="body1"
                          className="pb-2"
                          onClick={() => navigate("/branch-doctor")}
                          sx={{ cursor: "pointer" }}
                        >
                          Available Branch
                        </Typography>
                        <Typography
                          variant="body1"
                          className="pb-2"
                          onClick={() => {
                            console.log("first");
                            navigate("/history");
                          }}
                          sx={{ textAlign: "center" }}
                        >
                          My appointments
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <Typography variant="body1" className="pb-2" sx={{}}>
                          Patient
                        </Typography>
                      </>
                    )}
                    <Typography variant="body1" className="pb-2" sx={{}}>
                      {authValue?.currUser?.email}
                    </Typography>
                    <Divider />
                    {authValue?.currUser?.roles?.id === 4 ? null : (
                      <Button
                        style={{ width: "100%", border: "none" }}
                        size="large"
                        icon={<GroupsOutlined />}
                        onClick={() => {
                          navigate("/members");
                        }}
                      >
                        Memebers
                      </Button>
                    )}

                    <Button
                      style={{ width: "100%", border: "none" }}
                      size="large"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        authValue?.token
                          ? authValue?.logoutHandler()
                          : navigate("/login");
                      }}
                      icon={<OutputOutlined />}
                    >
                      Log Out
                    </Button>
                  </Box>
                </Popover>
              </Box>
            ) : (
              <button
                className="w-100 th-18 th-btn-primary px-3 py-2 th-br-5 h-100  border-0"
                onClick={() => {
                  authValue?.token
                    ? authValue?.logoutHandler()
                    : navigate("/login");
                }}
              >
                {authValue?.token ? "Logout" : "Login"}
              </button>
            )}
          </Box>
        </div>
      </MyDesktop>

      <AddressDialog
        showAddressDialog={showAddressDialog}
        setShowAddressDialog={setShowAddressDialog}
      />
      <MyMobile>
        <MobileHeader
          handleLogout={() => {
            authValue?.token ? authValue?.logoutHandler() : navigate("/login");
          }}
          DebounceSelect={DebounceSelect}
          value={value}
          setValue={setValue}
          fetchUserList={fetchUserList}
          address={address}
          setShowAddressDialog={setShowAddressDialog}
        />
      </MyMobile>
    </>
  );
}
export default Header;
