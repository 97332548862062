import React from "react";

const ContactUs = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h4>Contact Us</h4>
      <p>(Corporate office)</p>
      <p>Welldone Healthcare Private Limited</p>
      <p>CIN: U85100MH2021PTC354141</p>
      <p>
        G.NO.91/1, PRASAD NAGAR, BHADANE TAL.SAKRI, Dhule, Dhule, Maharashtra,
        India, 424304
      </p>
      <p>Email address: cto@welldonehealth.in</p>
    </div>
  );
};

export default ContactUs;
