import React from "react";
import { Layout, Menu, Space, Drawer } from "antd";
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Logo from "../../../../assest/img/favIcon.png";
import MyDesktop from "../../../UtilComponents/MyDesktop";
import MyMobile from "../../../UtilComponents/MyMobile";
import "./index.css";
import { menu } from "../DashbordMenu";
const { Sider } = Layout;
const Sidebar = ({ collapsed, setCollapsed, colorBgContainer }) => {
  const navigate = useNavigate();
  let menus = [];
  for (let i = 0; i < menu?.length; i++) {
    let obj = menu[i];
    if (menu[i]?.route)
      menu[i].onClick = () => {
        navigate(menu[i].route);
      };
    menus.push(obj);
  }
  return (
    <React.Fragment>
      <MyDesktop>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
          style={{ background: "#222C3C" }}
        >
          <Space
            className="demo-logo-vertical p-2 py-3 text-center text-white d-flex justify-content-center"
            align="end"
            style={{ letterSpacing: "1px", fontSize: "16px", gap: 2.2 }}
          >
            <img src={Logo} height={30} />
            {!collapsed && "ellDone"}
          </Space>
          <Menu
            theme="dark"
            className=""
            style={{ background: "#222C3C" }}
            mode="inline"
            defaultSelectedKeys={["1"]}
            items={menus}
          />
        </Sider>
      </MyDesktop>
      <MyMobile>
        <Drawer
          style={{ width: "70vw", background: "#2a3649", padding: 0 }}
          placement="left"
          open={!collapsed}
          className="th-mobile-sidebar"
          onClose={() => {
            setCollapsed(!collapsed);
          }}
          closeIcon={null}
          title={
            <Space
              className="demo-logo-vertical p-2 text-center text-white d-flex justify-content-center"
              align="end"
              style={{ letterSpacing: "1px", fontSize: "16px", gap: 1 }}
            >
              <img src={Logo} height={30} />
              {"ellDone"}
            </Space>
          }
        >
          <Menu
            theme="dark"
            mode="inline"
            style={{ background: "#2a3649" }}
            defaultSelectedKeys={["1"]}
            items={menus}
          />
        </Drawer>
      </MyMobile>
    </React.Fragment>
  );
};

export default Sidebar;
