import {
  Box,
  Typography,
  Avatar,
  Divider,
  Badge,
  Tab,
  Tabs,
  Paper,
} from "@mui/material";
import NoData from "../../../../assest/img/nodata.svg";
import { RefreshRounded } from "@mui/icons-material";
import {
  ArrowCircleRightRounded,
  ArrowCircleLeftRounded,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import PropTypes from "prop-types";

import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const DashboardFirst = ({
  date,
  selectedBranch,
  docList,
  allPatients,
  patientCount,
  fetchEqueueInfo,
  setShowEQueueDialog,
  editEqueueInfo,
  setShow,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const StyledBadge = styled(Badge)(({ theme, isOnline }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: isOnline ? "#44b700" : "#f00",

      color: isOnline ? "#44b700" : "#f00",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      // children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  console.log(patientCount, "patientCount");
  return (
    <Box
      sx={{
        background: "#FAFBFD",
        //  border: "1px solid red"
      }}
    >
      <Box
        sx={{
          display: "flex",
          background: "#ffffff",
          flexDirection: "column",
          justifyContent: "center",
          // border: "1px solid green",
          p: 1,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 1 }}>
          <Typography variant="subtitle1" sx={{ pb: 1 }}>
            <b> Activities</b>
          </Typography>
          <Box
            display="flex"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <button
              className="th-16 th-btn-primary px-1 py-1 th-br-5 h-100 border-0"
              onClick={() => setShowEQueueDialog(true)}
            >
              Add E-Queue
            </button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: 120,
              height: 120,
              background: "#FFF4DE",
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              p: 2,
              mb: 1,
            }}
          >
            <Typography variant="h3" sx={{}}>
              <b>{patientCount > 0 ? patientCount : "0"}</b>
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ pb: 1 }}
            >
              Patients
            </Typography>
          </Box>
          <Box
            sx={{
              width: 120,
              height: 120,
              background: "#DCFCE7",
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              p: 2,
              mb: 1,
            }}
          >
            <Typography variant="h3" sx={{}}>
              <b>
                {allPatients?.accepted?.length > 0
                  ? allPatients?.accepted?.length
                  : "0"}
              </b>
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ pb: 1 }}
            >
              Attend
            </Typography>
          </Box>

          <Box
            sx={{
              width: 120,
              height: 120,
              background: "#FFE2E6",
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              mb: 1,
              p: 2,
            }}
          >
            <Typography variant="h3" sx={{}}>
              <b>
                {allPatients?.delayed?.length > 0
                  ? allPatients?.delayed?.length
                  : "0"}
              </b>
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ pb: 1 }}
            >
              Delay
            </Typography>
          </Box>
          <Box
            sx={{
              width: 120,
              height: 120,
              background: "#D2AEBC",
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              p: 2,
              mb: 1,
            }}
          >
            <Typography variant="h3" sx={{}}>
              <b>
                {allPatients?.equeue?.length > 0
                  ? allPatients?.equeue?.length
                  : "0"}
              </b>
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ pb: 1 }}
            >
              In Queue
            </Typography>
          </Box>
          <Box
            sx={{
              width: 120,
              height: 120,
              background: "#F4E8FF",
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              p: 2,
              mb: 1,
            }}
          >
            <Typography variant="h3" sx={{}}>
              <b>{docList?.length}</b>
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ pb: 1 }}
            >
              Doctors
            </Typography>
          </Box>
        </Box>
      </Box>
      <Paper sx={{ width: "100%", my: 3 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Delay" {...a11yProps(0)} />
          <Tab label="Queue" {...a11yProps(1)} />
          <Tab label="Done" {...a11yProps(2)} />
        </Tabs>

        <Box index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            {!allPatients?.delayed?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={NoData} alt="no-data" height="150px" width="100%" />
                <Typography variant="subtitle1">No Data</Typography>
              </Box>
            ) : (
              allPatients?.delayed?.map((each) => (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      lineHeight: 0,
                      justifyContent: "space-between",
                      py: 0.5,
                    }}
                  >
                    <Box display="flex">
                      <Badge
                        badgeContent={each?.equeue_no}
                        color="primary"
                        overlap="circular"
                        // style={badgeStyle}
                      >
                        <Avatar
                          sx={{ width: 14, height: 5 }}
                          {...stringAvatar(
                            `${each?.patient__first_name ?? ""}`
                          )}
                        />
                      </Badge>
                      <Box sx={{ ml: 1 }}>
                        <Typography variant="subtitle1">
                          <b>{`${each?.patient__first_name ?? ""} ${
                            each?.patient__middle_name ?? ""
                          }`}</b>
                        </Typography>
                        <Typography color="text.secondary" variant="caption">
                          {each?.reason}
                        </Typography>
                      </Box>
                    </Box>
                    <ArrowCircleRightRounded
                      sx={{ color: "#6fc276", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();

                        console.log("left");
                        editEqueueInfo(each?.id, {
                          date: date,
                          is_accepted: true,
                          is_delayed: false,
                        });
                      }}
                    />
                  </Box>
                  <Divider />
                </Box>
              ))
            )}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {!allPatients?.equeue?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={NoData} alt="no-data" height="150px" width="100%" />
                <Typography variant="subtitle1">No Data</Typography>
              </Box>
            ) : (
              allPatients?.equeue?.map((each, index) => (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      lineHeight: 0,
                      justifyContent: "space-between",
                      alignItems: "center",
                      py: 1,
                    }}
                  >
                    <ArrowCircleLeftRounded
                      sx={{ color: "#FFE2E6", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();

                        editEqueueInfo(each?.id, {
                          date: date,
                          is_accepted: false,
                          is_delayed: true,
                        });
                      }}
                    />
                    <Box display="flex">
                      <Badge
                        badgeContent={each?.equeue_no}
                        color="primary"
                        overlap="circular"
                        // style={badgeStyle}
                      >
                        <Avatar
                          sx={{ width: 14, height: 5 }}
                          {...stringAvatar(
                            `${each?.patient__first_name ?? ""}`
                          )}
                        />
                      </Badge>

                      <Box sx={{ ml: 1 }}>
                        <Typography variant="subtitle1">
                          <b>{`${each?.patient__first_name ?? ""} ${
                            each?.patient__middle_name ?? ""
                          }`}</b>
                        </Typography>
                        <Typography color="text.secondary" variant="caption">
                          {each?.reason}
                        </Typography>
                      </Box>
                    </Box>
                    <ArrowCircleRightRounded
                      sx={{ color: "#6fc276", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();

                        console.log("right");
                        editEqueueInfo(each?.id, {
                          date: date,
                          is_accepted: true,
                          is_delayed: false,
                        });
                      }}
                    />
                  </Box>
                  <Divider />
                </Box>
              ))
            )}
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            {!allPatients?.accepted?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={NoData} alt="no-data" height="150px" width="100%" />
                <Typography variant="subtitle1">No Data</Typography>
              </Box>
            ) : (
              allPatients?.accepted?.map((each) => (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      lineHeight: 0,
                      justifyContent: "space-between",
                      py: 0.5,
                    }}
                  >
                    <ArrowCircleLeftRounded
                      sx={{ color: "#FFE2E6", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();

                        console.log("left");
                        editEqueueInfo(each?.id, {
                          date: date,
                          // is_equeue: true,
                          is_delayed: false,
                          is_accepted: false,
                        });
                      }}
                    />
                    <Box display="flex">
                      <Badge
                        badgeContent={each?.equeue_no}
                        color="primary"
                        overlap="circular"
                      >
                        <Avatar
                          sx={{ width: 14, height: 5 }}
                          {...stringAvatar(
                            `${each?.patient__first_name ?? ""}`
                          )}
                        />
                      </Badge>
                      <Box sx={{ ml: 1 }}>
                        <Typography variant="subtitle1">
                          <b>{`${each?.patient__first_name ?? ""} ${
                            each?.patient__middle_name ?? ""
                          }`}</b>
                        </Typography>
                        <Typography color="text.secondary" variant="caption">
                          {each?.reason}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              ))
            )}
          </TabPanel>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              // m: 1,
            }}
          >
            <RefreshRounded
              sx={{
                cursor: "pointer",
                backgroundColor: "#1976D2",
                borderRadius: "50%",
                m: 1,
                p: 0.4,
                color: "#fff",
              }}
              onClick={() => fetchEqueueInfo()}
            />
          </Box>
        </Box>
      </Paper>{" "}
    </Box>
  );
};

export default DashboardFirst;
