import {
  Box,
  Backdrop,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

import DocAvatar from "../../assest/img/DocAvatar.png";
import HospitalAvatar from "../../assest/img/hospitalAvatar.jpg";
import { ArrowForwardIosRounded } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import { Badge, Button, Empty, Input, Spin } from "antd";
import HospitalCard from "./HospitalCard";
import DoctorCard from "./DoctorCard";

//
const SearchListingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState(location?.state?.value);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [showData, setShowData] = useState(false);

  const [clickOnCard, setClickOnCard] = useState({});
  useEffect(() => {
    console.log(location, "location");
    if (location?.state?.value?.length > 0) {
      handleSearch({ search: location?.state?.value });
    }
    if (location?.state?.home) {
      handleSearch({
        is_hospital: location?.state?.is_hospital,
      });
    }
  }, []);

  const handleSearch = (params = {}) => {
    setShowData(true);
    setLoading(true);
    axios
      .get(`apiV1/home-search/`, { params: { ...params } })
      .then((res) => {
        setSearchResult(res?.data);
        setClickOnCard(res?.data[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setShowData(false);
      });
  };
  return (
    <>
      <div className="th-container py-3">
        <div className="my-2">
          <h3 className="text-center">
            <i class="fas fa-search pr-2 th-24"></i>Search Doctors & Hospitals
            Near You
          </h3>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 my-3">
            <Input
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              size="large"
              placeholder="e.g: Dr. Rakesh, AIIMS Patna"
              suffix={
                <Button
                  className=""
                  icon={<i class="fas fa-search"></i>}
                  onClick={() => handleSearch({ search: search })}
                  type="primary"
                >
                  Search
                </Button>
              }
            />
          </div>
        </div>
        <div
          className="px-3"
          style={{
            height: 500,
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Spin className="h-100 w-100" spinning={loading}>
            {searchResult?.length > 0 ? (
              searchResult?.map((each) =>
                each.is_hospital ? (
                  <HospitalCard details={each} />
                ) : (
                  <DoctorCard details={each} />
                )
              )
            ) : (
              <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                <Empty description={"No Results"} />
              </div>
            )}
          </Spin>
        </div>
      </div>
      {/* <Box
          sx={
            {
              // border: "1px solid red",
              // marginTop: "58px",
            }
          }
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showData}
            onClick={() => setShowData(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 1,
              mb: 1,
            }}
          >
            <CustomTextField
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
              style={{ backgroundColor: "white" }}
            />
            <Button
              variant="contained"
              onClick={() => handleSearch(search)}
              sx={{ ml: 1, background: "#FFCD1E", color: "#000000" }}
            >
              Search
            </Button>
          </Box>
        </Box>
        <Box
          className="row "
          sx={{
            // border: "1px solid black",
            justifyContent: "center",
            m: 0,
          }}
        >
          <Box
            className="col-md-6 col-sm-12"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              height: 500,
              overflowY: "scroll",
              overflowX: "hidden",
              // border: "1px solid blue",
              p: 3,
            }}
          >
            {searchResult?.map((each) => (
              <Box
                className="col-md-12 col-sm-12 rounded"
                sx={{
                  m: 1,
                  boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.25)",
                  borderLeft: each?.is_hospital
                    ? "4px solid #7d3865"
                    : "4px solid #949217",
  
                  py: 2,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  each.is_hospital
                    ? navigate(`/hospital-page/${each?.slug}`)
                    : navigate({
                        pathname: `/book-appointment`,
                        search: `?${createSearchParams({
                          doctor: each?.slug,
                          branch: each?.branch__slug,
                        })}`,
                      });
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // alignItems: "center",
                    flexWrap: "wrap",
  
                    // border: "1px solid green",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box>
                      {each?.is_hospital ? (
                        <img
                          src={
                            each?.page_setup__page_logo?.length > 0
                              ? each?.page_setup__page_logo
                              : HospitalAvatar
                          }
                          width="140px"
                          height="140px"
                          class="rounded-circle"
                          alt="hos_image"
                        />
                      ) : (
                        <img
                          src={
                            each?.profile_picture?.length > 0
                              ? each?.profile_picture
                              : DocAvatar
                          }
                          width="140px"
                          height="140px"
                          class="rounded-circle"
                          alt="doc_avatar"
                        />
                      )}
                    </Box>
                    <Box className="pl-3">
                      {each?.is_hospital ? (
                        <Typography variant="subtitle1">
                          <b>{each?.establishment_name}</b>
                        </Typography>
                      ) : (
                        <Typography variant="subtitle1">
                          <b>{`${each?.suffix ? each?.suffix : ""} ${
                            each?.first_name ? each?.first_name : ""
                          } ${each?.middle_name ? each?.middle_name : ""} ${
                            each?.last_name ? each?.last_name : ""
                          }`}</b>
                        </Typography>
                      )}
  
                      <Box className="d-flex justify-content-between">
                        <Typography variant="caption">
                          {each?.qualification}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle2">
                        <b>{each?.practice_speciality__speciality}</b>
                      </Typography>
                      <Typography variant="caption">
                        <b>{each?.branch__establishment_name ?? ""}</b>
                      </Typography>
                      <Typography variant="caption">{each?.expi}</Typography>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="caption">
                          {each?.contact_address ?? each?.branch}{" "}
                        </Typography>
                        <Typography variant="caption">
                          {each?.branch__state__name ?? each?.state__name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // border: "1px solid green",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        my: 1,
                        // width: "100%",
                        height: "25px",
                        background: "#FFCD1E",
                        color: "#000000",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
  
                        console.log("clicked", each);
                        setClickOnCard(each);
  
                        const element = document.getElementById("mapDiv");
                        element.scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      Map
                    </Button>
                  </Box>
                </Box>
  
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <ArrowForwardIosRounded sx={{ color: "grey" }} />
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            className="col-md-6 col-sm-10"
            id="mapDiv"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // border: "1px solid red",
              height: 500,
              width: "100%",
              overflow: "hidden",
            }}
          >
            {clickOnCard?.is_hospital ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: clickOnCard?.branch_map__iframe,
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: clickOnCard?.branch__branch_map__iframe,
                }}
              />
            )}
          </Box>
        </Box> */}
    </>
  );
};

export default SearchListingPage;
