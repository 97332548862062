import { LocationOnTwoTone } from "@mui/icons-material";
import { Button, Tag } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getRandomColor } from "../../../../component/UtilComponents/getRandomColor";
const HospitalCard = ({ details }) => {
  const navigate = useNavigate();
  let hospitalImage = details?.page_setup__page_logo?.replace(/ /g, "%20");
  return (
    <React.Fragment>
      <div
        className="col-md-4 col-sm-6 p-3 mt-3"
        key={details?.id}
        onClick={() => {
          navigate(`/hospital-page/${details?.slug}`);
        }}
      >
        <div
          className="shadow-sm card p-3 bg-white h-100 d-flex flex-column justify-content-between"
          style={{
            cursor: "pointer",
            borderRadius: 10,
          }}
        >
          <div className="d-flex ">
            <div
              className="th-bg-img "
              style={{
                height: "100px",
                width: "100px",
                borderRadius: 10,
                backgroundImage: `url(${
                  hospitalImage ??
                  `https://imgs.search.brave.com/ko2cpRw52Ge8xOe5x75k6tkX2LKmpKoyHx6S3FuHCuA/rs:fit:500:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy83/Lzc5L1UuUy5fTWFy/aW5lX0hvc3BpdGFs/LF9Nb2JpbGUsX0Fs/YWJhbWEuanBn`
                })`,
              }}
            />
            <div className="pl-3">
              <h6 className="text-start m-0">{details?.establishment_name}</h6>
              <div className="pt-2" style={{ fontSize: "14px" }}>
                <i className="fas fa-map-marker text-danger"></i>{" "}
                {details?.owner_details__city},{" "}
                {details?.owner_details__state__name}
              </div>
              {details?.owner_details__phone && (
                <div className="pt-1" style={{ fontSize: "14px" }}>
                  <i className="fas fa-phone-alt text-info"></i>{" "}
                  {details?.owner_details__phone}
                </div>
              )}
              {details?.owner_details__email && (
                <div className="pt-1" style={{ fontSize: "14px" }}>
                  <i class="fas fa-envelope text-warning"></i>{" "}
                  {details?.owner_details__email}
                </div>
              )}
            </div>
            {/* <div className="">
              <div className=" th-24 text-center pt-3">
                <h6>{details?.establishment_name}</h6>
              </div>
              <p variant="caption" className="text-center">
                {details?.state__name}
              </p>
              <br />
              <p
                variant="caption"
                color="text.secondary"
                className="text-center"
              >
                {details?.branch}
              </p>
            </div> */}
          </div>
          <div className="pt-3">
            <div className="d-flex flex-wrap">
              {details?.clinic_service[0]?.specility
                ?.split(",")
                ?.slice(0, 3)
                ?.map((each, index) => (
                  <Tag
                    key={each}
                    className="mb-2"
                    color={getRandomColor(index + 1)}
                  >
                    <i className="fas fa-ribbon"></i> {each}
                  </Tag>
                ))}
            </div>
          </div>
          <div className="">
            <div className="">
              <div className="">
                <Tag>
                  <i className="fas fa-sort-amount-up text-primary"></i>{" "}
                  {details?.available_branches}+ Branches across India
                </Tag>
              </div>
              <div className="pt-2">
                <Tag>
                  <i className="fas fa-user-md text-primary"></i>{" "}
                  {details?.total_doctor}+ Doctors associated
                </Tag>
              </div>
            </div>
          </div>
          <div className="pt-2">
            <Button className="w-100 ">View Details</Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HospitalCard;
