import React, { useContext, useEffect, useState } from "react";
import MyDesktop from "../../component/UtilComponents/MyDesktop";
import Logo from "../../assest/img/logo.jfif";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// import { SnackbarContext } from "../../component/UtilComponents/SnackbarUtil/SnackbarContext";
// import setAuthToken from "../../../global/setAuthToken";
import { Box } from "@mui/material";
import { AuthContext, useAuth } from "../../context/auth-context";
import { SnackbarContext } from "../../component/UtilComponents/SnackbarUtil/SnackbarContext";
import MyMobile from "../../component/UtilComponents/MyMobile";

const LoginPage = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    contact: "",
    password: "",
  });
  const { openSnackbar } = useContext(SnackbarContext);
  const [contact, setContact] = useState("");
  const [errMsgPassword, setErrMsgPassword] = useState("");
  const [errMsgEmail, setErrMsgEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginOTP, setLoginOTP] = useState(false);
  const [otp, setOTP] = useState("");
  const [OTPRecieved, setOTPRecieved] = useState(false);
  const [errMsgContact, setErrMsgContact] = useState("");
  const [seconds, setSeconds] = useState(300);
  const [isActive, setIsActive] = useState(false);

  const authValue = useAuth();
  const navigate = useNavigate();

  const location = useLocation();

  const navigateTo = location?.state?.from?.pathname || "/";

  console.log("navigateTo", navigateTo);

  useEffect(() => {
    if (authValue?.token) {
      navigate(navigateTo);
    }
  }, [authValue?.token]);

  const { loginHandler, getCurrentUser, otpHandler } = authValue;

  useEffect(() => {
    let interval = null;
    if (isActive) {
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds((seconds) => seconds - 1);
        }, 1000);
      } else {
        setSeconds(0);
        setIsActive(false);
      }
    } else if (!isActive && seconds !== 300) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const handlePassword = (e) => {
    // var passwordPattern =
    //   /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,28}$/;
    var passwordPattern =
      /^(?=.*[0-9])(?=.*[!@#$%^&*_+=(){}/|:`~.,?<>₹])[a-zA-Z0-9!@#$%^&*_+=(){}/|:`~.,?<>₹]{8,28}$/;
    if (passwordPattern.test(e.target.value)) {
      setErrMsgPassword("");
    } else {
      setErrMsgPassword(
        "Minimum 8 characters, at least 1 letter, 1 number and 1 special character"
      );
    }
    setValues({ ...values, password: e.target.value });
  };

  const handleEmail = (e) => {
    // var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // if (emailPattern.test(e.target.value)) {
    //   setErrMsgEmail("");
    // } else {
    //   setErrMsgEmail("Please Enter Valid Email Id");
    // }
    setValues({ ...values, contact: e.target.value });
  };

  const handleContact = (e) => {
    var numberPattern =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (numberPattern.test(e.target.value)) {
      setErrMsgContact("");
    } else {
      setErrMsgContact("Please Enter Valid Contact Number");
    }
    setContact(e.target.value);
  };

  const resendOTP = () => {
    axios
      .post(`/apiV1/generate-otp/`, { contact: contact, method: "login" })
      .then((res) => {
        setOTPRecieved((prev) => true);
        setSeconds(300);
        setIsActive(true);
        // openSnackbar("OTP sent again!", "success");
      })
      .catch((err) => {
        // openSnackbar(
        //   `${err.response.data ?? "Something went wrong!"}`,
        //   "error"
        // );
      });
  };

  const onFinish = (e) => {
    const { dispatch } = props;
    e.preventDefault();
    setLoading(true);
    if (loginOTP) {
      if (OTPRecieved) {
        if (otp) {
          let authorized1 = false;

          axios
            .post(`apiV1/verify-otp/`, {
              contact: contact,
              otp: otp,
              method: "login",
            })
            .then((response) => {
              // openSnackbar("Logged in successfully!", "success");
              authorized1 = true;
              const token = response?.data?.token?.toString();
              const expirationDate = new Date(
                new Date().getTime() + 3600 * 10000
              );
              localStorage.setItem("token", token);
              localStorage.setItem("expirationDate", expirationDate);
              otpHandler(token);
              // authValue?.setToken(token);
              dispatch({
                type: "user/OTP_LOGIN",
                payload: {
                  authorized: authorized1,
                  token: response?.data?.token,
                },
              });
            })
            .catch((error) => {
              // openSnackbar(
              //   `${error.response.data ?? "Something went wrong!"}`,
              //   "error"
              // );
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        if (contact) {
          setOTPRecieved((prev) => true);
          setSeconds(300);
          setIsActive(true);
          openSnackbar("OTP sent!", "success");
          resendOTP();

          // axios
          //   .post(`/apiV1/generate-otp/`, { contact: contact, method: "login" })
          //   .then((res) => {
          //     setOTPRecieved((prev) => true);
          //     setSeconds(300);
          //     setIsActive(true);
          //     // openSnackbar("OTP sent!", "success");
          //   })
          //   .catch((err) => {
          //     // openSnackbar(
          //     //   `${err.response.data ?? "Something went wrong!"}`,
          //     //   "error"
          //     // );
          //   })
          //   .finally(() => {
          //     setLoading(false);
          //   });
        }
      }
    } else {
      let value = values;
      loginHandler(value);
      // return axios
      //   .post(`/token/`, value)
      //   .then((response) => {
      //     if (response.status === 200) {
      //       localStorage.setItem("token", response.data.access);
      //       // setAuthToken(response.data.access);
      //       // openSnackbar("Logged in successfully!", "success");
      //       dispatch({
      //         type: "user/LOGIN",
      //         payload: { token: response.data?.access },
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     // openSnackbar(
      //     //   `${error.response.data.detail ?? "Something went wrong!"}`,
      //     //   "error"
      //     // );
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //   });
    }
  };
  console.log(props, "props");
  if (props?.user?.authorized) {
    console.log("User is authorized");
    return <Navigate to={navigateTo} />;
  }
  let divisor_for_minutes = seconds % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);
  let divisor_for_seconds = divisor_for_minutes % 60;
  let second = Math.ceil(divisor_for_seconds);

  return (
    <Box>
      <div className="row">
        <MyDesktop>
          <div className="col-md-8">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "calc(90vh - 80px)" }}
            >
              <div className="text-center">
                <img
                  src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                  style={{ height: "100px" }}
                  alt="welldone logo"
                  onClick={() => navigate("/home")}
                />
                <h3 className="pt-4">One stop solution for your well being.</h3>
                <h4 className="th-primary">#getWelldone</h4>
              </div>
            </div>
          </div>
        </MyDesktop>
        <div className="col-md-4 col-12">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh", backgroundColor: "#F6F4EE" }}
          >
            <div className="" style={{ width: "100%" }}>
              <MyMobile>
                <div className="text-center">
                  <img
                    src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                    style={{ height: "100px" }}
                    alt="welldone logo"
                    onClick={() => navigate("/home")}
                  />
                  <h3 className="pt-4">
                    One stop solution for your well being.
                  </h3>
                  <h4 className="th-primary">#getWelldone</h4>
                </div>
              </MyMobile>
              <div className="th-24 th-fw-500 text-center px-3 py-3">
                Welcome to Welldone!
              </div>
              <form onSubmit={onFinish} className="pt-3 ">
                {!loginOTP && (
                  <div className="">
                    <div className="col-md-12 px-3 py-3">
                      {/* {this.props.user.wrong ? (
                      <div
                        className="th-13 px-3 py-2 th-br-5 th-white"
                        style={{ background: "#ff9800" }}
                      >
                        {" "}
                        Invalid Email or Password. Try Again
                      </div>
                    ) : null} */}
                      <input
                        placeholder="Enter Phone Number"
                        className="shadow-none w-100 th-form-control-1"
                        name="contact"
                        type="contact"
                        onChange={handleEmail}
                      />
                    </div>
                    {/* <div className="th-12 px-3 th-text-error"> {errMsgEmail}</div> */}
                    <div className="col-md-12 px-3 py-3">
                      <input
                        className="shadow-none w-100 th-form-control-1"
                        name="password"
                        placeholder="Enter password"
                        type={showPassword ? "text" : "password"}
                        onChange={handlePassword}
                      />
                      <i
                        className={
                          showPassword
                            ? "fas fa-eye eye"
                            : "fas fa-eye-slash eye"
                        }
                        onClick={() => setShowPassword((prev) => !prev)}
                      ></i>
                    </div>
                    {/* <div className=" th-12 px-3 th-text-error">{errMsgPassword}</div> */}
                  </div>
                )}
                {loginOTP && (
                  <div className="">
                    <div className="col-md-12 px-3 py-3">
                      <input
                        placeholder="Enter Contact"
                        className="shadow-none w-100 th-form-control-1"
                        name="contact"
                        type="text"
                        onChange={handleContact}
                      />
                    </div>
                    <div className="th-12 px-3"> {errMsgContact}</div>
                    {OTPRecieved && (
                      <div className="col-md-12 px-3 py-3">
                        <input
                          placeholder="Enter OTP"
                          className="shadow-none w-100 th-form-control-1"
                          name="otp"
                          type="text"
                          onChange={(e) => setOTP(e.target.value)}
                        />
                      </div>
                    )}
                    {/* <div className="th-12 px-3 th-text-error"> {errMsgEmail}</div> */}
                    {/* <div className="col-md-12 px-3 py-3">
                      <input
                        className="shadow-none w-100 th-form-control-1"
                        name="password"
                        placeholder="Enter password"
                        type={showPassword ? "text" : "password"}
                        onChange={handlePassword}
                      />
                      <i
                        className={
                          showPassword
                            ? "fas fa-eye eye"
                            : "fas fa-eye-slash eye"
                        }
                        onClick={() => setShowPassword((prev) => !prev)}
                      ></i>
                    </div> */}
                    {/* <div className=" th-12 px-3 th-text-error">{errMsgPassword}</div> */}
                  </div>
                )}
                <div className="col-md-12 px-3 py-2 pt-4">
                  <button
                    className="w-100 th-18 th-bg-secondary text-white px-3 py-2 th-br-5 h-100  border-0"
                    disabled={authValue?.loginLoading}
                  >
                    <span className="pr-2">
                      {loginOTP
                        ? OTPRecieved
                          ? "Login"
                          : "Get OTP"
                        : !authValue?.loginLoading
                        ? "Login"
                        : "Logging in..."}
                    </span>
                    {authValue?.loginLoading && (
                      <i className=" fas fa-spinner fa-spin"></i>
                    )}
                  </button>
                </div>
                {OTPRecieved && loginOTP && (
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center pl-0 mt-3">
                      <button
                        disabled={seconds > 0}
                        onClick={resendOTP}
                        className=" th-18 th-btn-primary px-3 py-1 th-br-5 h-100  border-0"
                      >
                        Resend OTP
                      </button>
                      {seconds > 0 && (
                        <div className="font-size-16">
                          {minutes}:{second < 10 ? "0" + second : second}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className="col-md-12 th-primary th-14 d-flex justify-content-between flex-column gap-2"
                  style={{ gap: "6px", marginTop: "6px" }}
                >
                  {/* <div className="">
                    <Link to="/auth/forgot-password">Forgot Password</Link>
                  </div>
                  <div className=""></div> */}
                  <div
                    className="th-pointer"
                    onClick={() => {
                      setLoginOTP((prevState) => !prevState);
                      setOTPRecieved(false);
                    }}
                  >
                    Want to sign in with{" "}
                    <span>{!loginOTP ? "OTP" : "password"}</span> ?
                  </div>
                  <Link className="th-primary" to="/register">
                    Create New Account
                  </Link>
                  <a
                    className="th-primary"
                    target="_blank"
                    href="https://clinic.welldonehealth.in/"
                  >
                    Doctor's Login<i className="fas fa-share mx-1"></i>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default LoginPage;
