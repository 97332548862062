import { useNavigate } from "react-router-dom";
import { AppBar, Box, Button, Chip, Toolbar, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Layout, theme, Steps, Row, Col, Space, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
const { Header, Content, Footer } = Layout;
const BranchCard = ({ bgColor, data }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "240px",
        minHeight: "240px",
        border: "2px solid lightgrey",
        borderRadius: "10px",
        display: "flex",
        // alignItems: "space-between",
        justifyContent: "center",
        flexDirection: "column",
        m: 1,
        p: 1,
        py: 1,
        backgroundColor: bgColor ?? "#fff",
        gap: 2,
      }}
    >
      <Typography>
        Branch - <b>{data?.branch}</b>
      </Typography>
      <Box>
        <Typography>
          <b>{data?.establishment_name}</b>
        </Typography>
        <Typography sx={{ color: "#00A410" }}>
          <b>{data?.certificate_number}</b>
        </Typography>
        <Typography variant="caption">
          {data?.owner_details__address}{" "}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {data?.clinic_setup ? (
          <Chip label="Already Setup" color="success" size="small" />
        ) : (
          <Chip
            label="+ More Info"
            color="primary"
            size="small"
            onClick={() =>
              navigate("/doctor-in-hospital", {
                state: {
                  branch: { ...data },
                },
              })
            }
          />
        )}
        <Chip
          label="Add Doctor"
          color="primary"
          size="small"
          onClick={() =>
            navigate("/doctor-reg/one", {
              state: {
                branch: { ...data },
              },
            })
          }
        />
      </Box>
    </Box>
  );
};

const AddBranchCard = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "240px",
        // height: "240px",
        border: "2px dashed lightgrey",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        m: 1,
        p: 3,
        cursor: "pointer",
      }}
      onClick={() => navigate("/hospital-reg")}
    >
      <Typography sx={{ fontSize: "100px" }}>+</Typography>
      <Typography>
        <b>Add Branch</b>
      </Typography>
    </Box>
  );
};

const ProfileStatus = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "70vh",
        border: "2px solid black",
        display: "flex",
        flexDirection: "column",
        m: 1,
        p: 5,
      }}
    >
      <Space direction="vertical" align="start" size="large">
        <Box sx={{ display: "flex" }}>
          {/* <WarningRoundedIcon sx={{ mx: 1 }} /> */}
          <Typography>Profile is under verification!</Typography>
        </Box>
        <Typography>
          As our team is facing heavy surge in requests due to covid-19, kindly
          give us 7 working days to verify the information which you have
          provided.
        </Typography>
        <Steps
          direction="vertical"
          current={1}
          items={[
            {
              title: "Profile details and verification",
              description:
                "Doctor’s basic details, medical registration, education qualification, Doctor identity proof etc",
            },
            {
              title: "Establishment details And verification",
              description:
                "Proof of owner, registration proof, establishment details,establishment ownership proof etc.",
            },
            {
              title: "Start getting patients",
              description: "Location, Timings, Fees",
            },
          ]}
        />
      </Space>
    </Box>
  );
};

const Branch = () => {
  const navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [loading, setLoading] = useState(false);
  const [branchData, setBranchData] = useState([]);

  const getBranchList = () => {
    setLoading(true);
    axios
      .get(`apiV1/user-branch/`)
      .then((res) => {
        console.log("ress", res?.data);
        setBranchData(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBranchList();
  }, []);

  return (
    <Layout className="layout">
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 40,
            p: 3,
            backgroundColor: "#BFEBFB",
          }}
        >
          <Box>
            <img
              role={"button"}
              onClick={() => {
                navigate("/home");
              }}
              alt="Welldone Logo"
              className="my-auto"
              src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
              style={{
                height: 40,
                // width: 20,
              }}
            />
          </Box>
          <Typography variant="caption">
            Profile of Register Medical Practionar (RMP)
          </Typography>
        </Box>

        {/* <Button color="inherit" startIcon={<NotificationsIcon />}></Button>
            <Button color="inherit" style={{ textTransform: "none" }}>
              <Typography>Settings</Typography>
            </Button> */}
      </Box>
      <Content
        style={{
          padding: "0 5px",
        }}
      >
        <Spin spinning={loading} size="large">
          <div
            className="site-layout-content"
            style={{
              // background: colorBgContainer,
              height: "100vh",
              padding: "5px",
            }}
          >
            <Row style={{ height: "100%" }}>
              <Col span={24}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 1,
                    width: "100%",
                    height: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 1,
                      flexWrap: "wrap",
                    }}
                  >
                    <AddBranchCard />
                    {branchData?.map((item, index) => {
                      return <BranchCard data={item} key={index} />;
                    })}
                  </Box>
                </Box>
              </Col>
              {/* <Col span={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 1,
                }}
              >
                <ProfileStatus />
              </Box>
            </Col> */}
            </Row>
          </div>
          {/* <Footer
          style={{
            textAlign: "center",
          }}
        >
          Privacy Notice
        </Footer> */}
        </Spin>
      </Content>
    </Layout>
  );
};
export default Branch;
