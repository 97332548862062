import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, Typography } from "@mui/material";
import fileIcon from "../../../assest/img/PDFplaceholder.png";
import { UploadOutlined } from "@ant-design/icons";

import { Button, Layout, Steps, Input, Form, Image, Spin, Upload } from "antd";
import axios from "axios";
const { Content } = Layout;

const DoctorRegFormFour = () => {
  const navigate = useNavigate();
  const location = useLocation();

  console.log("state val", location?.state);

  const { formOneData, formTwoData, formThreeData, registrationId } =
    location?.state ?? {
      formOneData: null,
      formTwoData: null,
      formThreeData: null,
      registrationId: null,
    };

  const payload = { ...formOneData, ...formTwoData, ...formThreeData };

  console.log("payload", payload);

  const [imgFileData, setImgFileData] = useState(null);

  const [spinning, setSpinning] = useState(false);

  const [imgInput, setImgInput] = useState(null);

  const getImgFiles = () => {
    setSpinning(true);
    axios
      .get(`apiV1/image-gallery/?registration=${registrationId}`)
      .then((res) => {
        console.log("ress img ", res);
        setImgFileData(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setSpinning(false);
      });
  };

  const handleImgUpload = () => {
    setSpinning(true);
    const value = new FormData();
    value.append("registration", registrationId);
    value.append("file_type", "image");
    value.append("image", imgInput);
    axios
      .post(`/apiV1/image-gallery/`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setImgInput(null);
        getImgFiles();
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setSpinning(false);
      });
  };

  console.log("imgFileData", imgFileData);

  console.log("imgInput", imgInput);

  // console.log;

  useEffect(() => {
    getImgFiles();
  }, []);

  // education

  const [eduFileList, setEduFileList] = useState([]);
  const [eduUploading, setEduUploading] = useState(false);

  const educationUploadProps = {
    onRemove: (file) => {
      setEduFileList([]);
    },
    beforeUpload: (file) => {
      setEduFileList(file);
      return false;
    },
    eduFileList,
    maxCount: 1,
  };

  const handleEduUpload = () => {
    setEduUploading(true);
    setSpinning(true);
    const formData = new FormData();
    console.log("on click upload, edu file is", eduFileList);
    const value = new FormData();
    value.append("registration", registrationId);
    // value.append("file_type", "file");
    value.append("file", eduFileList);
    value.append("file_type", "education");
    axios
      .post(`/apiV1/image-gallery/`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // setImgFileData(res?.data);
        // setImgInput(null);
        getImgFiles();
        setEduFileList([]);
        console.log("edu res", res);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setSpinning(false);
        setEduUploading(false);
      });
  };

  // training

  const [trainingFileList, setTrainingFileList] = useState([]);
  const [trainingUploading, setTrainingUploading] = useState(false);

  const trainingUploadProps = {
    onRemove: (file) => {
      setTrainingFileList([]);
    },
    beforeUpload: (file) => {
      setTrainingFileList(file);
      return false;
    },
    setTrainingFileList,
    maxCount: 1,
  };

  const handleTrainingUpload = () => {
    setTrainingUploading(true);
    setSpinning(true);
    const formData = new FormData();
    console.log("on click upload, edu file is", trainingFileList);
    const value = new FormData();
    value.append("registration", registrationId);
    // value.append("file_type", "file");
    value.append("file", trainingFileList);
    value.append("file_type", "training");
    axios
      .post(`/apiV1/image-gallery/`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // setImgFileData(res?.data);
        // setImgInput(null);
        getImgFiles();
        setTrainingFileList([]);
        console.log("edu res", res);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setSpinning(false);
        setTrainingUploading(false);
      });
  };

  //  experience Experience

  const [experienceFileList, setExperienceFileList] = useState([]);
  const [experienceUploading, setExperienceUploading] = useState(false);

  const experienceUploadProps = {
    onRemove: (file) => {
      setExperienceFileList([]);
    },
    beforeUpload: (file) => {
      setExperienceFileList(file);
      return false;
    },
    setExperienceFileList,
    maxCount: 1,
  };

  const handleExperienceUpload = () => {
    setExperienceUploading(true);
    setSpinning(true);
    const formData = new FormData();
    console.log("on click upload, edu file is", experienceFileList);
    const value = new FormData();
    value.append("registration", registrationId);
    // value.append("file_type", "file");
    value.append("file", experienceFileList);
    value.append("file_type", "experience");
    axios
      .post(`/apiV1/image-gallery/`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // setImgFileData(res?.data);
        // setImgInput(null);
        getImgFiles();
        setExperienceFileList([]);
        console.log("edu res", res);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setSpinning(false);
        setExperienceUploading(false);
      });
  };

  // Research

  const [ResearchFileList, setResearchFileList] = useState([]);
  const [ResearchUploading, setResearchUploading] = useState(false);

  const ResearchUploadProps = {
    onRemove: (file) => {
      setResearchFileList([]);
    },
    beforeUpload: (file) => {
      setResearchFileList(file);
      return false;
    },
    setResearchFileList,
    maxCount: 1,
  };

  const handleResearchUpload = () => {
    setResearchUploading(true);
    setSpinning(true);
    const formData = new FormData();
    console.log("on click upload, edu file is", ResearchFileList);
    const value = new FormData();
    value.append("registration", registrationId);
    // value.append("file_type", "file");
    value.append("file", ResearchFileList);
    value.append("file_type", "research");
    axios
      .post(`/apiV1/image-gallery/`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // setImgFileData(res?.data);
        // setImgInput(null);
        getImgFiles();
        setResearchFileList([]);

        console.log("edu res", res);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setSpinning(false);
        setResearchUploading(false);
      });
  };

  // Social

  const [SocialFileList, setSocialFileList] = useState([]);
  const [SocialUploading, setSocialUploading] = useState(false);

  const SocialUploadProps = {
    onRemove: (file) => {
      setSocialFileList([]);
    },
    beforeUpload: (file) => {
      setSocialFileList(file);
      return false;
    },
    setSocialFileList,
    maxCount: 1,
  };

  const handleSocialUpload = () => {
    setSocialUploading(true);
    setSpinning(true);
    const formData = new FormData();
    console.log("on click upload, edu file is", SocialFileList);
    const value = new FormData();
    value.append("registration", registrationId);
    // value.append("file_type", "file");
    value.append("file", SocialFileList);
    value.append("file_type", "social");
    axios
      .post(`/apiV1/image-gallery/`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // setImgFileData(res?.data);
        // setImgInput(null);

        console.log("edu res", res);
        getImgFiles();
        setSocialFileList([]);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setSpinning(false);
        setSocialUploading(false);
      });
  };

  const [profilePic, setProfilePic] = useState("");
  const [educationFile, setEducationFile] = useState("");
  const [trainingFile, setTrainingFile] = useState("");
  const [experienceFile, setExperienceFile] = useState("");
  const [researchFile, setResearchFile] = useState("");
  const [socialFile, setSocialFile] = useState("");

  useEffect(() => {
    setProfilePic(imgFileData?.find((i) => i?.file_type === "image")?.image);
    setEducationFile(
      imgFileData?.find((i) => i?.file_type === "education")?.file
    );
    setTrainingFile(
      imgFileData?.find((i) => i?.file_type === "training")?.file
    );
    setExperienceFile(
      imgFileData?.find((i) => i?.file_type === "experience")?.file
    );
    setResearchFile(
      imgFileData?.find((i) => i?.file_type === "research")?.file
    );
    setSocialFile(imgFileData?.find((i) => i?.file_type === "social")?.file);
  }, [imgFileData]);

  return (
    <Layout className="layout">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              p: 3,
              backgroundColor: "#BFEBFB",
            }}
          >
            <Box>
              <img
                role={"button"}
                onClick={() => {
                  navigate("/home");
                }}
                alt="Welldone Logo"
                className="my-auto"
                src="https://dev-welldone.s3.ap-south-1.amazonaws.com/welldone/images/logo.png"
                style={{
                  height: 40,
                  // width: 20,
                }}
              />
            </Box>
            {/* <Typography variant="caption">
            Profile of Register Medical Practionar (RMP)
          </Typography> */}
          </Box>
        </AppBar>
      </Box>
      <Content
        style={{
          padding: "0 5px",
        }}
      >
        <Spin size="large" spinning={spinning}>
          <Box sx={{ p: 2 }}>
            <Steps
              size="small"
              current={0}
              items={[
                {
                  title: "Account Verification",
                },
                {
                  title: "Register Medical Practionar (RMP) Details",
                },
                {
                  title: "Practice Details",
                },
                {
                  title: "Dashboard",
                },
              ]}
            />
          </Box>
          <div
            className="site-layout-content"
            style={{
              // background: "orange",
              height: "80vh",
              padding: "2px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ p: 2 }}>
                <Typography>Profile pictures*</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: "40px",
                    p: 2,
                  }}
                >
                  {profilePic && (
                    <Box>
                      <Image width={200} src={profilePic} />
                    </Box>
                  )}

                  <Box
                    sx={{
                      width: "100%",
                      height: 150,
                      // border: "2px solid gray",
                      // background: "lightgray",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <Form.Item
                      name="image"
                      label={"Upload Image"}
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      // onChange={(e) => {

                      // }}
                    >
                      <Input
                        // hidden
                        // style={{ display: "hidden", height: 150 }}
                        accept="image/*"
                        type={"file"}
                        onChange={(e) => {
                          setImgInput(e.target.files[0]);
                          // console.log(e.target.value);
                          // e.target.value = null;
                          // console.log(e.target.value);
                        }}
                        color="primary"
                      ></Input>
                    </Form.Item>
                    {imgInput && (
                      <Button
                        style={{ marginLeft: "8em" }}
                        type="primary"
                        onClick={() => {
                          handleImgUpload();
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography>
                  Register Medical Practionar (RMP) Details*
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "40px",
                    rowGap: "20px",
                    flexWrap: "wrap",
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: 200,
                      padding: "2em",
                    }}
                  >
                    <Image
                      preview={false}
                      width={150}
                      src={fileIcon}
                      onClick={() => {
                        if (!educationFile) {
                          return;
                        }
                        let link = document.createElement("a");
                        link.setAttribute("href", educationFile);
                        link.setAttribute("target", "_bkd");
                        link.click();
                        link.remove();
                      }}
                    />
                    <Typography>
                      Proof Of Education and Background Master in Medicine
                    </Typography>
                    {/* <Button>Delete</Button> */}
                    <>
                      <Upload {...educationUploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                      <Button
                        type="primary"
                        onClick={handleEduUpload}
                        disabled={eduFileList.length === 0}
                        loading={eduUploading}
                        style={{
                          marginTop: 16,
                        }}
                      >
                        {eduUploading ? "Uploading" : "Start Upload"}
                      </Button>
                    </>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: 200,
                      padding: "2em",
                    }}
                  >
                    <Image
                      preview={false}
                      width={150}
                      src={fileIcon}
                      onClick={() => {
                        if (!trainingFile) {
                          return;
                        }
                        let link = document.createElement("a");
                        link.setAttribute("href", trainingFile);
                        link.setAttribute("target", "_bkd");
                        link.click();
                        link.remove();
                      }}
                    />
                    <Typography>Proof Of Trainning</Typography>
                    {/* <Button>Delete</Button> */}
                    <>
                      <Upload {...trainingUploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                      <Button
                        type="primary"
                        onClick={handleTrainingUpload}
                        disabled={trainingFileList.length === 0}
                        loading={trainingUploading}
                        style={{
                          marginTop: 16,
                        }}
                      >
                        {trainingUploading ? "Uploading" : "Start Upload"}
                      </Button>
                    </>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: 200,
                      padding: "2em",
                    }}
                  >
                    <Image
                      preview={false}
                      width={150}
                      src={fileIcon}
                      onClick={() => {
                        if (!experienceFile) {
                          return;
                        }
                        let link = document.createElement("a");
                        link.setAttribute("href", experienceFile);
                        link.setAttribute("target", "_bkd");
                        link.click();
                        link.remove();
                      }}
                    />
                    <Typography>Proof of Experience</Typography>
                    {/* <Button>Delete</Button> */}
                    <>
                      <Upload {...experienceUploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                      <Button
                        type="primary"
                        onClick={handleExperienceUpload}
                        disabled={experienceFileList.length === 0}
                        loading={experienceUploading}
                        style={{
                          marginTop: 16,
                        }}
                      >
                        {experienceUploading ? "Uploading" : "Start Upload"}
                      </Button>
                    </>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: 200,
                      padding: "2em",
                    }}
                  >
                    <Image
                      preview={false}
                      width={150}
                      src={fileIcon}
                      onClick={() => {
                        if (!researchFile) {
                          return;
                        }
                        let link = document.createElement("a");
                        link.setAttribute("href", researchFile);
                        link.setAttribute("target", "_bkd");
                        link.click();
                        link.remove();
                      }}
                    />
                    <Typography>Research Paper</Typography>
                    {/* <Button>Delete</Button> */}
                    <>
                      <Upload {...ResearchUploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                      <Button
                        type="primary"
                        onClick={handleResearchUpload}
                        disabled={ResearchFileList.length === 0}
                        loading={ResearchUploading}
                        style={{
                          marginTop: 16,
                        }}
                      >
                        {ResearchUploading ? "Uploading" : "Start Upload"}
                      </Button>
                    </>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: 200,
                      padding: "2em",
                    }}
                  >
                    <Image
                      preview={false}
                      width={150}
                      src={fileIcon}
                      onClick={() => {
                        if (!socialFile) {
                          return;
                        }
                        let link = document.createElement("a");
                        link.setAttribute("href", socialFile);
                        link.setAttribute("target", "_blank");
                        link.click();
                        link.remove();
                      }}
                    />
                    <Typography>Proof of Social cultural Activites</Typography>
                    {/* <Button>Delete</Button> */}
                    <>
                      <Upload {...SocialUploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                      <Button
                        type="primary"
                        onClick={handleSocialUpload}
                        disabled={SocialFileList.length === 0}
                        loading={SocialUploading}
                        style={{
                          marginTop: 16,
                        }}
                      >
                        {SocialUploading ? "Uploading" : "Start Upload"}
                      </Button>
                    </>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  navigate("/doctor-reg/five");
                }}
                disabled={
                  eduFileList?.length !== 0 ||
                  trainingFileList?.length !== 0 ||
                  experienceFileList?.length !== 0 ||
                  ResearchFileList?.length !== 0 ||
                  SocialFileList?.length !== 0 ||
                  imgInput
                }
              >
                Continue
              </Button>
              <br />
              <Typography variant="caption">
                Agree to the Welldone Terms of Use by clicking on the book
                appointment.
              </Typography>
            </Box>
          </div>
        </Spin>
      </Content>
    </Layout>
  );
};

export default DoctorRegFormFour;
