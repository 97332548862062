import {
  DashboardOutlined,
  PauseCircleOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
export const menu = [
  {
    key: "1",
    icon: <DashboardOutlined />,
    label: "Dashboard",
    route: "/dashboard2",
  },
  {
    key: "2",
    icon: <PauseCircleOutlined />,
    label: "Pause Equeue",
    route: "/",
  },
  {
    key: "3",
    icon: <OrderedListOutlined />,
    label: "Equeue List",
    route: "/dashboard3",
  },
];
