import React from "react";
import GoldenCoin from "../../assest/img/golden_icon.png";
import announce from "../../assest/img/announce.png";
import cart from "../../assest/img/cart.png";
import lock from "../../assest/img/lock.png";
import chat from "../../assest/img/chat.png";
import background from "../../assest/img/background.png";
import referral from "../../assest/img/referral.png";
import { Button, Space, Table, Tag } from "antd";
import {
  apiData,
  automationData,
  commerceData,
  integrationData,
  ownerShipData,
  planTypes,
  supportData,
  treatmentData,
  usersAndChannels,
} from "./pricings";
function PricingPage() {
  const planColumns = [
    {
      title: "feature",
      key: "feature",
      dataIndex: "title",
      className: "th-16 th-fw-500",
      width: "20%",
    },
    {
      title: "gp",
      key: "gp",
      dataIndex: "gp",
      render: (data) => (
        <div className="d-flex flex-column justify-content-between text-center">
          <h5 className="text-success ">{data?.plan}</h5>
          <h6 className="">{data?.price}</h6>
          <div className="th-grey">{data?.span}</div>
          <div className="th-fw-500">
            {data?.yearly} <span className="text-success">{data?.off}</span>
          </div>
          <div>
            <Button className="mt-2">Try for free</Button>
          </div>
        </div>
      ),
      width: "20%",
    },
    {
      title: "sp",
      key: "sp",
      dataIndex: "sp",
      render: (data) => (
        <div className="d-flex flex-column justify-content-between text-center">
          <h5 className="text-success ">{data?.plan}</h5>
          <h6 className="">{data?.price}</h6>
          <div className="th-grey">{data?.span}</div>
          <div className="th-fw-500">
            {data?.yearly} <span className="text-success">{data?.off}</span>
          </div>
          <div>
            <Button className="mt-2">Try for free</Button>
          </div>
        </div>
      ),
      width: "20%",
    },
    {
      title: "poly",
      key: "poly",
      dataIndex: "poly",
      render: (data) => (
        <div className="d-flex flex-column justify-content-between text-center">
          <h5 className="text-success ">{data?.plan}</h5>
          <h6 className="">{data?.price}</h6>
          <div className="th-grey">{data?.span}</div>
          <div className="th-fw-500">
            {data?.yearly} <span className="text-success">{data?.off}</span>
          </div>
          <div>
            <Button className="mt-2">Try for free</Button>
          </div>
        </div>
      ),
      width: "20%",
    },
    {
      title: "special",
      key: "special",
      dataIndex: "special",
      render: (data) => (
        <div className="d-flex flex-column justify-content-between text-center">
          <h5 className="text-success ">{data?.plan}</h5>
          <h6 className="">{data?.price}</h6>
          <div className="th-grey">{data?.span}</div>
          <div className="th-fw-500">
            {data?.yearly} <span className="text-success">{data?.off}</span>
          </div>
          <div>
            <Button className="mt-2">Try for free</Button>
          </div>
        </div>
      ),
      width: "20%",
    },
  ];
  const featureColumns = [
    {
      title: "feature",
      key: "feature",
      width: "20%",
      render: (data) => (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="">{data?.feature}</div>
            {data?.golden && <img src={GoldenCoin} alt="golden" height={30} />}
            {data?.premium && <Tag color="blue">Only for premium</Tag>}
          </div>
          <div className="th-grey th-12">{data?.feature_extra}</div>
        </>
      ),
    },
    {
      title: "gp",
      key: "gp",
      width: "20%",
      render: (data) => (
        <div className="text-center">
          {typeof data?.gp === "boolean" ? (
            <div className="th-18">
              {data?.gp ? (
                <i class="fas fa-check text-success"></i>
              ) : (
                <i class="fas fa-times text-danger"></i>
              )}
            </div>
          ) : (
            <div className=" ">
              <div className="">{data?.gp}</div>
              <small className="th-grey">{data?.gp_extra}</small>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "ss",
      key: "ss",
      width: "20%",
      render: (data) => (
        <div className="text-center">
          {typeof data?.ss === "boolean" ? (
            <div className="th-18">
              {data?.ss ? (
                <i class="fas fa-check text-success"></i>
              ) : (
                <i class="fas fa-times text-danger"></i>
              )}
            </div>
          ) : (
            <div className=" ">
              <div className="">{data?.ss}</div>
              <small className="th-grey">{data?.ss_extra}</small>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "poly",
      key: "poly",
      width: "20%",
      render: (data) => (
        <div className="text-center">
          {typeof data?.poly === "boolean" ? (
            <div className="th-18">
              {data?.poly ? (
                <i class="fas fa-check text-success"></i>
              ) : (
                <i class="fas fa-times text-danger"></i>
              )}
            </div>
          ) : (
            <div className=" ">
              <div className="">{data?.poly}</div>
              <small className="th-grey">{data?.poly_extra}</small>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "special",
      key: "special",
      width: "20%",
      render: (data) => (
        <div className="text-center">
          {typeof data?.special === "boolean" ? (
            <div className="th-18">
              {data?.special ? (
                <i class="fas fa-check text-success"></i>
              ) : (
                <i class="fas fa-times text-danger"></i>
              )}
            </div>
          ) : (
            <div className=" ">
              <div className="">{data?.special}</div>
              <small className="th-grey">{data?.special_extra}</small>
            </div>
          )}
        </div>
      ),
    },
  ];
  const features = [
    {
      title: "Marketing Conversations",
      desc: "Practice updates, promotional and offer messages",
      image: announce,
    },
    {
      title: "Utility Conversations",
      desc: "Transactional and Pri/post-Procedure notifications",
      image: cart,
    },
    {
      title: "Authentication Conversation",
      desc: "OTPs for account verification and account recovery",
      image: lock,
    },
    {
      title: "Service Conversations",
      desc: "All user-initiated conversations with free-form messages",
      image: chat,
    },
  ];
  return (
    <div>
      <div className="th-bg-secondary th-container py-5 text-center  th-fw-600 ">
        <h2 className="text-white">
          Simple & Transparent pricing tailored for the{" "}
          <span style={{ color: "#FDE400" }}>Growth of your Practice</span>
        </h2>
        <h2 className="my-3 text-white">With</h2>
        <div className="d-flex justify-content-center align-items-center">
          <img src={GoldenCoin} height={80} alt="gold" />
          <h2 className="m-0" style={{ color: "#FDE400" }}>
            Golden Services
          </h2>
        </div>
      </div>
      <div className="th-container">
        <div className="my-3 th-br-10 py-2">
          <Table
            columns={planColumns}
            dataSource={planTypes}
            pagination={false}
            showHeader={false}
            className="shadow-sm th-br-10 bg-white"
            bordered
            scroll={{ x: "max-width" }}
          />
        </div>
        <div className="my-3 ">
          <div className="bg-white py-3 th-br-10 shadow-sm">
            <div className="th-16 th-fw-500 px-3">Users & Channels</div>
            <Table
              columns={featureColumns}
              dataSource={usersAndChannels}
              pagination={false}
              showHeader={false}
              className="bg-white"
              bordered
              scroll={{ x: "max-width" }}
            />
          </div>
        </div>
        <div className="my-3 ">
          <div className="bg-white py-3 th-br-10 shadow-sm">
            <div className="th-16 th-fw-500 px-3">Automation</div>
            <Table
              columns={featureColumns}
              dataSource={automationData}
              pagination={false}
              showHeader={false}
              className="bg-white"
              bordered
              scroll={{ x: "max-width" }}
            />
          </div>
        </div>
        <div className="my-3 ">
          <div className="bg-white py-3 th-br-10 shadow-sm">
            <div className="th-16 th-fw-500 px-3">Commerce</div>
            <Table
              columns={featureColumns}
              dataSource={commerceData}
              pagination={false}
              showHeader={false}
              className="bg-white"
              bordered
              scroll={{ x: "max-width" }}
            />
          </div>
        </div>
        <div className="my-3 ">
          <div className="bg-white py-3 th-br-10 shadow-sm">
            <div className="th-16 th-fw-500 px-3">Support</div>
            <Table
              columns={featureColumns}
              dataSource={supportData}
              pagination={false}
              showHeader={false}
              className="bg-white"
              bordered
              scroll={{ x: "max-width" }}
            />
          </div>
        </div>
        <div className="my-3 ">
          <div className="bg-white py-3 th-br-10 shadow-sm">
            <div className="th-16 th-fw-500 px-3">Welldone Integrations</div>
            <Table
              columns={featureColumns}
              dataSource={integrationData}
              pagination={false}
              showHeader={false}
              className="bg-white"
              bordered
              scroll={{ x: "max-width" }}
            />
          </div>
        </div>
        <div className="my-3 ">
          <div className="bg-white py-3 th-br-10 shadow-sm">
            <div className="th-16 th-fw-500 px-3">Treatment communications</div>
            <Table
              columns={featureColumns}
              dataSource={treatmentData}
              pagination={false}
              showHeader={false}
              className="bg-white"
              bordered
              scroll={{ x: "max-width" }}
            />
          </div>
        </div>
        <div className="my-3 ">
          <div className="bg-white py-3 th-br-10 shadow-sm">
            <div className="th-16 th-fw-500 px-3">
              API & Webhooks
              <Tag className="mx-3" color="blue">
                Only for premium
              </Tag>
            </div>
            <Table
              columns={featureColumns}
              dataSource={apiData}
              pagination={false}
              showHeader={false}
              className="bg-white"
              bordered
              scroll={{ x: "max-width" }}
            />
          </div>
        </div>
        <div className="my-3 ">
          <div
            className=" py-3 th-br-10 shadow-sm bg-white"
            //   style={{ background: "#ECFFEE" }}
          >
            <div className="th-16 th-fw-500 px-3">
              Ownership
              <Tag className="mx-3" color="blue">
                Only for premium
              </Tag>
            </div>
            <Table
              columns={featureColumns}
              dataSource={ownerShipData}
              pagination={false}
              showHeader={false}
              className=""
              bordered
              scroll={{ x: "max-width" }}
            />
          </div>
        </div>
        <div className="my-4">
          <div className="text-center">
            <h2 className="th-fw-600 th-black">
              WhatsApp conversation by conversation category
            </h2>
          </div>
          <div className="row my-4">
            {features?.map((feature) => (
              <div className="col-md-3 col-sm-6 col-12 p-3 text-center">
                <img src={feature.image} height={50} alt="feature" />
                <div className="pt-3 th-18 th-fw-600">{feature.title}</div>
                <div className="th-14">{feature.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="th-container py-3 d-flex justify-content-between align-items-center"
        style={{ background: "#FDF398" }}
      >
        <h56 className="th-fw-600">Start Your 7-Day Free Trial Now!</h56>
        <Button type="primary">Start Free Trial</Button>
      </div>
      <div
        className="th-container  py-3 d-flex  justify-content-between align-items-center"
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="">
          <h3 className="th-black m-0">
            A free premium version is provided for doctors.
          </h3>
          <div className="">
            <div className="">
              Request a medical, pharmaceutical company marketing
              representative.
            </div>
            <Space className="pt-3">
              <Button type="primary" style={{ borderRadius: "20px" }}>
                Ask free<i className="fas fa-arrow-right mx-2"></i>
              </Button>
              <Button
                className="th-br-20 bg-success text-white"
                style={{ borderRadius: "20px" }}
              >
                Buy Now<i className="fas fa-arrow-right mx-2"></i>
              </Button>
            </Space>
          </div>
          <div className="mt-5">
            <div className="">
              Ask More than 2000 Welldone Payback Points (WPP)
            </div>
            <Space className="pt-3">
              <Button type="primary" style={{ borderRadius: "20px" }}>
                Ask free<i className="fas fa-arrow-right mx-2"></i>
              </Button>
              <Button
                className="th-br-20 bg-success text-white"
                style={{ borderRadius: "20px" }}
              >
                Shop Now<i className="fas fa-arrow-right mx-2"></i>
              </Button>
            </Space>
          </div>
        </div>
        <div className="">
          <img src={referral} alt="referral" height={200} />
        </div>
      </div>
    </div>
  );
}

export default PricingPage;
