// eslint-disable-next-line
import React, { lazy, Suspense, useEffect } from "react";
import "./assest/css/general.css";
import "./assest/css/home.css";
import "./assest/css/form.css";
import "./assest/css/style.scss";
import "./assest/css/antd/button.scss";
import Header from "./component/Layout/Header";
import PostHeader from "./component/Layout/PostHeader";
import {
  Navigate,
  Outlet,
  Route,
  Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import HomePage2 from "./page/HomePage2";
import LoginPage from "./page/LoginPage";
import RegisterPage from "./page/Register";
import RegisterDoctor from "./page/Doctor/RegisterDoctor";
import Branch from "./page/Doctor/Branch";
import HospitalRegPage from "./page/Doctor/HospitalRegPage";
import DoctorRegFormOne from "./page/Doctor/DoctorRegForms/DoctorRegFormOne";
import DoctorRegFormTwo from "./page/Doctor/DoctorRegForms/DoctorRegFormTwo";
import Footer from "./component/Layout/Footer";
import DoctorRegFormThree from "./page/Doctor/DoctorRegForms/DoctorRegFormThree";
import DoctorRegFormFour from "./page/Doctor/DoctorRegForms/DoctorRegFormFour";
import DoctorRegFormFive from "./page/Doctor/DoctorRegForms/DoctorRegFormFive";
import BookAppointment from "./page/Doctor/BookAppointment";

//Dashboard
// import Dashboard from "./page/Doctor/Dashboard";
import Dashboard from "./page/Doctor/Dashboard";
import PublicRoutes from "./utils/PublicRoutes";
import PrivateRoutes from "./utils/PrivateRoutes";
import HospitalPage from "./page/Hospital";
import PatientProfile from "./page/Patient/PatientProfile";
import PatientRegistration from "./page/Patient/PatientRegistration";
import HistoryPage from "./page/Patient/HistoryPage";

// import Header from "./component/Layout/Header";

import Axios from "axios";
import { useAuth } from "./context/auth-context";
import DoctorRoutes from "./utils/DoctorRoutes";
import Snackbar from "./component/UtilComponents/SnackbarUtil/Snackbar";
import getBaseUrl from "./utils/config";
import DoctorInHospitalForm from "./page/Doctor/HospitalRegPage/DoctorInHospitalForm";
import AboutUs from "./page/static/AboutUs";
import ContactUs from "./page/static/ContactUs";
import PrivacyPolicy from "./page/static/PrivacyPolicy";
import TermsAndCondtions from "./page/static/Terms&Conditions";
import PaymentPolicy from "./page/static/PaymentPolicy";
import ShippingAndReturnPolicy from "./page/static/Shipping&ReturnPolicy";
import PageNotFound from "./page/Doctor/PageNotFound";
import ProductAndCheckoutProcess from "./page/static/ProductAndCheckoutProcess.js";
import Checkout from "./page/Checkout/checkout";
import Members from "./page/Patient/Members";
import { Spin } from "antd";
import DashboardLayout from "./component/Layout/DashboardLayout";
import NewHome from "./page/NewHome";
import NewHeader from "./component/Layout/NewHeader";
import SearchListingPage from "./page/SearchListingPage";
import PaymentStatus from "./page/PaymentStatus";
import PricingPage from "./page/PricingPage";
import ForDoctors from "./page/ForDoctors";
import ForHospital from "./page/ForHospital";

Axios.defaults.baseURL = getBaseUrl();
Axios.defaults.headers.common.Authorization = localStorage["token"]
  ? `Bearer ${localStorage.getItem("token")}`
  : "";
// Axios.defaults.headers.post["Content-Type"] = "application/json";

const dashboardRoutes = [
  {
    path: "/dashboard2",
    exact: true,
    Component: lazy(() => import("./page/Dashboard")),
  },
  {
    path: "/dashboard3",
    exact: true,
    Component: lazy(() => import("./page/NewPageDashboard")),
  },
];

function App() {
  const location = useLocation();
  let dashboardLinks = dashboardRoutes?.map((each) => each?.path);
  return (
    <React.Fragment>
      {/* <Snackbar /> */}

      {/* <Router basename="/"> */}
      <DashboardLayout
        visible={dashboardLinks?.includes(window.location.pathname)}
      >
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <NewHeader />
                <NewHome />
                {/* <Footer /> */}
              </>
            }
          />
          {/* <Route
            exact
            path="/home2"
            element={
              <>
                <NewHome />
                <Footer />
              </>
            }
          /> */}
          <Route
            exact
            path="/home"
            element={
              <>
                <NewHeader />
                <NewHome />
                {/* <Footer /> */}
              </>
            }
          />
          <Route
            exact
            path="/history"
            element={
              <>
                <NewHeader />
                <HistoryPage />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/search-listing"
            element={
              <>
                <NewHeader />
                <SearchListingPage />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/for-doctors"
            element={
              <>
                <NewHeader />
                <ForDoctors />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/for-hospital"
            element={
              <>
                <NewHeader />
                <ForHospital />
                <Footer />
              </>
            }
          />
          <Route element={<PublicRoutes />}>
            <Route exact path="login" element={<LoginPage />} />
            <Route exact path="register" element={<RegisterPage />} />
            <Route
              exact
              path="hospital-page/:slug"
              element={
                <>
                  <NewHeader />
                  <HospitalPage />
                </>
              }
            />
            <Route
              exact
              path="book-appointment"
              element={
                <>
                  <NewHeader />
                  <BookAppointment />
                </>
              }
            />
            <Route
              exact
              path="pricing"
              element={
                <>
                  <NewHeader />
                  <PricingPage />
                </>
              }
            />
          </Route>

          <Route
            element={
              <>
                <NewHeader />
                <Outlet />
                <Footer />
              </>
            }
          >
            <Route exact path="about-us" element={<AboutUs />} />
            <Route exact path="contact-us" element={<ContactUs />} />
            <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="t-and-c" element={<TermsAndCondtions />} />
            <Route exact path="payment-policy" element={<PaymentPolicy />} />
            <Route
              exact
              path="shipping-policy"
              element={<ShippingAndReturnPolicy />}
            />
            <Route
              exact
              path="product-Checkout-Process"
              element={<ProductAndCheckoutProcess />}
            />
          </Route>

          <Route
            element={
              <>
                <NewHeader />
                <PrivateRoutes />
                <Footer />
              </>
            }
          >
            <Route element={<DoctorRoutes />}>
              <Route
                exact
                path="register-doctor"
                element={<RegisterDoctor />}
              />
              <Route exact path="hospital-reg" element={<HospitalRegPage />} />
              <Route
                exact
                path="doctor-reg/one"
                element={<DoctorRegFormOne />}
              />
              <Route
                exact
                path="doctor-reg/two"
                element={<DoctorRegFormTwo />}
              />
              <Route
                exact
                path="doctor-reg/three"
                element={<DoctorRegFormThree />}
              />
              <Route
                exact
                path="doctor-reg/four"
                element={<DoctorRegFormFour />}
              />
              <Route
                exact
                path="doctor-reg/five"
                element={<DoctorRegFormFive />}
              />
              <Route exact path="branch-doctor" element={<Branch />} />
              <Route exact path="dashboard" element={<Dashboard />} />
            </Route>

            <Route exact path="patient-profile" element={<PatientProfile />} />
            <Route exact path="patient-reg" element={<PatientRegistration />} />
            <Route exact path="payment-status" element={<PaymentStatus />} />

            <Route exact path="members" element={<Members />} />

            <Route exact path="checkout" element={<Checkout />} />

            <Route
              exact
              path="doctor-in-hospital"
              element={<DoctorInHospitalForm />}
            />

            {/* <Route exact path="search-listing" element={<SearchListingPage />} /> */}

            <Route path="*" element={<PageNotFound />} />
          </Route>
          {dashboardRoutes.map(({ path, Component, exact }, index) => (
            <Route
              path={path}
              key={path}
              exact={exact}
              element={
                <Suspense fallback={<Spin />}>
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Routes>
      </DashboardLayout>
      {(location.pathname === "/" || location.pathname === "/home") && (
        <Footer />
      )}
    </React.Fragment>
  );
}

export default App;
