import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightArrow from "../../../component/UtilComponents/RightArrow";
import LeftArrow from "../../../component/UtilComponents/LeftArrow";
import { Avatar, Typography } from "antd";
import { useData } from "../../../context/data-context";
import axios from "axios";
import HospitalCard from "./HospitalCard";
const Hospitals = ({ hospitalData }) => {
  const navigate = useNavigate();
  const settings = {
    slidesToShow: hospitalData?.length > 1 ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    infinite: true,
    nextArrow: <RightArrow to="next" />,
    prevArrow: <LeftArrow to="prev" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: hospitalData?.length > 1 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 535,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="py-5">
        <div className="pb-3 text-center">
          <h2>
            Hospitals associated with us <i class="fas fa-hands-helping"></i>
          </h2>
        </div>
        <div className=" row">
          {hospitalData?.slice(0, 3)?.map((each) => {
            return <HospitalCard details={each} />;
          })}
        </div>
        <div
          className="text-center pt-2 th-pointer"
          onClick={() => {
            navigate(`/search-listing`, {
              state: {
                is_hospital: true,
                home: true,
              },
            });
          }}
        >
          See More
        </div>
      </div>
    </React.Fragment>
  );
};

export default Hospitals;
