import React from "react";
import PageNotFoundImg from "../../assest/img/page_not_found.svg";
function PageNotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img width="100vw" height="100vh" src={PageNotFoundImg} />
      <br />
      <h1>Page Not Found</h1>
    </div>
  );
}

export default PageNotFound;
