import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Tag,
} from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";

const PatientRegistration = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const authValue = useAuth();

  const userId = authValue?.currUser?.id;

  const [medicalHistory, setMedicalHistory] = useState([]);
  const [medicalHistoryInput, setMedicalHistoryInput] = useState("");

  const [imgInput, setImgInput] = useState(null);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };

  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          p: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          width: "100%",
          flexWrap: "wrap",
          // border: "2px solid red",
          height: "1000px",
          overflowY: "scroll",
          gap: 2,
        }}
      >
        <Box
          sx={{
            // border: "2px solid green",
            flexBasis: "20vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
            flexDirection: "column",
          }}
        >
          <Typography> Add Display picture</Typography>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              setImgInput(e.target.files[0]);
            }}
          ></input>
        </Box>
        <Box
          sx={{
            // border: "2px solid blue",
            flexBasis: "75vw",
            height: "1000px",
            p: 2,
          }}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Typography variant="h6">New Patient Registration</Typography>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", p: 1 }}>
            <Form
              //   ref={educationFormRef}
              layout="vertical"
              onFinish={(value) => {
                const payload = { ...value, medical_history: medicalHistory };
                var form_data = new FormData();

                for (var key in payload) {
                  if (!!payload[key]) {
                    form_data.append(key, payload[key]);
                  }
                }
                if (!!imgInput) {
                  form_data.append("avatar", imgInput);
                }
                form_data.append("user", userId);
                console.log("value", {
                  ...value,
                  medical_history: medicalHistory,
                });
                console.log("img inp", imgInput);
                axios
                  .post("/apiV1/patient/", form_data, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then(() => {
                    navigate(-1);
                  })
                  .catch((err) => {
                    console.log("error", err);
                  });
              }}
              //     let v = value;
              //     v.id = Math.random();
              //     setEducationDetail([...educationDetail, v]);
              //     educationFormRef.current.resetFields();
              //   }}
            >
              <Row gutter={24} align="middle">
                <Col md={6}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please enter First Name",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter Last Name",
                    //   },
                    // ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item
                    label="Middle Name(Optional)"
                    name="middle_name"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24} align="middle">
                <Col md={6}>
                  <Form.Item
                    label="Referred By"
                    name="refered_by"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>

                <Col md={6} style={{ marginBottom: "1em" }}>
                  <Form.Item
                    label="Whatsapp Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mobile Number",
                      },
                    ]}
                    name="contact"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <Input placeholder="" />
                  </Form.Item>

                  {/* <Form.Item
                    name="myCheckbox"
                    valuePropName="checked"
                    style={{ margin: 0, padding: 0 }}
                  >
                    <Checkbox>SMS Notifications</Checkbox>
                  </Form.Item> */}
                </Col>

                <Col md={6} style={{ marginBottom: "1em" }}>
                  <Form.Item
                    label="Email Address"
                    name="emailAddress"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  {/* <Form.Item
                    name="emailNotification"
                    valuePropName="checked"
                    style={{ margin: 0, padding: 0 }}
                  >
                    <Checkbox defaultChecked={false}>
                      Email Notifications
                    </Checkbox>
                  </Form.Item> */}
                </Col>
              </Row>

              <Row gutter={24} align="middle">
                <Col md={6}>
                  <Form.Item
                    label="Age"
                    name="age"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Age",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select Gender",
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                      <Select.Option value="others">
                        Prefer not to say
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item
                    label="Blood Group"
                    name="blood_group"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select a Blood Group",
                    //   },
                    // ]}
                  >
                    <Select>
                      <Select.Option value="a+">A+</Select.Option>
                      <Select.Option value="a-">A-</Select.Option>
                      <Select.Option value="b+">B+</Select.Option>
                      <Select.Option value="b-">B-</Select.Option>
                      <Select.Option value="ab+">AB+</Select.Option>
                      <Select.Option value="ab-">AB-</Select.Option>
                      <Select.Option value="o+">O+</Select.Option>
                      <Select.Option value="o-">O-</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item label="Primary Language">
                    <Select>
                      <Select.Option value="hindi">Hindi</Select.Option>
                      <Select.Option value="english">English</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24} align="middle">
                <Col md={8}>
                  <Form.Item
                    label="Address of Patient"
                    name="address"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item
                    label="Pincode"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Pincode",
                      },
                    ]}
                    name="pincode"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item
                    label="City"
                    name="city"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Pincode",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24} align="middle">
                <Col md={8}>
                  <Form.Item
                    label="Your Relationship"
                    name="relation"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                  >
                    {/* Myself
                      Daughter
                      Son
                      Sister
                      Brother
                      Relative
                      Friend 
                    */}

                    <Select>
                      {[
                        "Myself",
                        "Daughter",
                        "Son",
                        "Sister",
                        "Brother",
                        "Relative",
                        "Friend",
                      ]?.map((item, index) => {
                        return (
                          <Select.Option value={item}>{item}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item
                    label="Member Name"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter Member Name",
                    //   },
                    // ]}
                    name="memberName"
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 8 }}
                    colon={false}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
              </Row>
              <Box sx={{ width: "100%" }}>
                <Form.Item
                  label={<b>Insurance Holder</b>}
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter Insurance Holder",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="yes">Yes </Radio>
                    <Radio value="no"> No </Radio>
                  </Radio.Group>
                </Form.Item>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography variant="body1">
                  <b>Medical Profile</b>
                </Typography>

                <Box
                  sx={{ p: 2, display: "flex", justifyContent: "space-around" }}
                >
                  <Box>
                    <Typography>Medical History</Typography>
                    <Box sx={{ display: "flex" }}>
                      {/* <Form.Item name="medicalHistory"> */}
                      <Input
                        size="small"
                        value={medicalHistoryInput}
                        onChange={(e) => {
                          setMedicalHistoryInput(e.target.value);
                        }}
                      />
                      {/* </Form.Item> */}
                      <Button
                        type="primary"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setMedicalHistory((p) => [...p, medicalHistoryInput]);
                          setMedicalHistoryInput("");
                        }}
                      >
                        Add
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        p: 1,
                        display: "flex",
                        maxWidth: "20vw",
                        flexWrap: "wrap",
                        gap: 1,
                      }}
                    >
                      {medicalHistory?.map((item, index) => {
                        return (
                          <Tag
                            key={item}
                            color="#7594D1"
                            closable
                            onClose={(e) => {
                              console.log(e);
                              setMedicalHistory((a) =>
                                a.filter((i) => i !== item)
                              );
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {item}
                          </Tag>
                        );
                      })}
                    </Box>
                  </Box>

                  {/* <Box> */}

                  {/* </Box> */}

                  <Box>
                    <Typography>Paient is allergic ?</Typography>
                    <Form.Item
                      name="allrgy"
                      // labelCol={{ span: 24 }}
                      // wrapperCol={{ span: 8 }}
                      colon={false}
                    >
                      <Input placeholder="Type of Allergy" />
                    </Form.Item>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button size="large" type="primary" htmlType="submit">
                  Add Member
                </Button>
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientRegistration;
