import React, { useEffect, useState } from "react";
import SuccessImg from "../../assest/img/success.png";
import FailureImg from "../../assest/img/payment_fail_icon.png";
import PendingImg from "../../assest/img/pending_status.png";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";
function PaymentStatus() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");

  useEffect(() => {
    verifyPayment();
  }, []);

  const verifyPayment = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "merchantTransactionId",
      window?.location?.search?.split("=")[1]
    );
    axios
      .post("apiV1/payment-verify/", formData)
      .then((res) => {
        console.log(res, "res of verified payment");
        if (res?.data?.code === "PAYMENT_SUCCESS") {
          setPaymentStatus(res?.data?.code);
        } else if (res?.data?.code === "PAYMENT_ERROR") {
          setPaymentStatus(res?.data?.code);
        } else if (res?.data?.code === "PAYMENT_PENDING") {
          setPaymentStatus(res?.data?.code);
        }
        setTimeout(() => {
          navigate("/history");
        }, 3000);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      {loading ? (
        <div className="d-flex flex-column justify-content-center align-items-center my-5">
          <Spin className="my-2" />
          <h1 style={{ color: "0CBE2D" }}>Verifying Payment...</h1>
          <h4>Don's Press Back Button</h4>
        </div>
      ) : paymentStatus === "PAYMENT_SUCCESS" ? (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center">
          <h2>Payment Successful</h2>
          <img src={SuccessImg} />
        </div>
      ) : paymentStatus === "PAYMENT_ERROR" ? (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center">
          <h2>Payment Failed</h2>
          <img src={FailureImg} />
        </div>
      ) : paymentStatus === "PAYMENT_ERROR" ? (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center">
          <h2>Payment Pending</h2>
          <img src={PendingImg} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default PaymentStatus;
