import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import DocAvatar from "../../../assest/img/DocAvatar.png";
import HospitalAvatar from "../../../assest/img/hospitalAvatar.jpg";
import { Button, Modal, Tag } from "antd";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { getRandomColor } from "../../../component/UtilComponents/getRandomColor";
const DoctorCard = ({ details }) => {
  const navigate = useNavigate();
  const [iframe, setIframe] = useState();
  const [modal, setModal] = useState(false);
  return (
    <React.Fragment>
      <div className="p-3 bg-white rounded my-3 shadow-sm" onClick={(e) => {}}>
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-wrap justify-content-between ">
            <div>
              <img
                src={
                  details?.profile_picture?.length > 0
                    ? details?.profile_picture
                    : DocAvatar
                }
                onClick={() => {
                  navigate({
                    pathname: `/book-appointment`,
                    search: `?${createSearchParams({
                      doctor: details?.slug,
                      branch: details?.branch__slug,
                    })}`,
                  });
                }}
                className="th-br-5"
                width="130px"
                height="130px"
                alt="doc_avatar"
              />
            </div>
            <div className="pl-3">
              <div>
                <h4
                  onClick={() => {
                    navigate({
                      pathname: `/book-appointment`,
                      search: `?${createSearchParams({
                        doctor: details?.slug,
                        branch: details?.branch__slug,
                      })}`,
                    });
                  }}
                  className="text-capitalize"
                >{`${details?.suffix ? details?.suffix : ""} ${
                  details?.first_name ? details?.first_name : ""
                } ${details?.middle_name ? details?.middle_name : ""} ${
                  details?.last_name ? details?.last_name : ""
                }`}</h4>
              </div>

              <div className="d-flex flex-wrap">
                {details?.qualification?.split(",")?.map((each, ind) => (
                  <Tag
                    key={ind}
                    className="mb-1"
                    color={getRandomColor(ind + 1)}
                  >
                    <i className="fas fa-graduation-cap"></i> {each}
                  </Tag>
                ))}
                <Tag
                  className="mb-1"
                  color={getRandomColor(
                    details?.qualification?.split(",")?.length + 2
                  )}
                >
                  <i class="fas fa-star"></i>{" "}
                  {details?.practice_speciality__speciality}
                </Tag>
                <Tag className="mb-1">
                  <i class="fas fa-briefcase text-primary"></i> 8 Years Overall
                  Experience
                </Tag>
              </div>
              <div className=" d-flex flex-wrap th-14">
                <div className="pt-1">
                  <i class="fas fa-hospital th-secondary pr-1"></i>{" "}
                  {details?.branch__establishment_name ?? ""}
                </div>
                ,
              </div>
              {details?.branch__state__name && (
                <div className=" pt-1">
                  <i className="fas fa-map-marker text-danger pr-1"></i>{" "}
                  {details?.branch__state__name}
                </div>
              )}
            </div>
          </div>
          <div className="h-100 d-flex flex-column">
            {details?.branch__branch_map__iframe && (
              <Button
                className="mt-2"
                onClick={() => {
                  setIframe(details?.branch__branch_map__iframe);
                  setModal(true);
                }}
                icon={<i className="fas fa-map-marker text-danger"></i>}
              >
                View on Map
              </Button>
            )}
            <Button
              className="mt-2"
              type="primary"
              onClick={() => {
                navigate({
                  pathname: `/book-appointment`,
                  search: `?${createSearchParams({
                    doctor: details?.slug,
                    branch: details?.branch__slug,
                  })}`,
                });
              }}
              icon={<i class="far fa-calendar-check"></i>}
            >
              Book Appointment
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={modal}
        title={"Map"}
        onCancel={() => {
          setIframe(null);
          setModal(false);
        }}
        footer={null}
      >
        <div className="th-iframe-listing">
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: iframe,
            }}
          ></div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default DoctorCard;
