import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MyDesktop from "../../UtilComponents/MyDesktop";
import MyMobile from "../../UtilComponents/MyMobile";
import MobileHeader from "../MobileHeader";

import BarsLine from "../../../assest/img/barsLine.svg";
import Doctor from "../../../assest/img/doctor.svg";
import Parmacy from "../../../assest/img/parmacy.svg";
import LabTest from "../../../assest/img/labTest.svg";
import Wellness from "../../../assest/img/wellness.svg";
import Preventive from "../../../assest/img/preventaive.svg";
import MyNetwork from "../../../assest/img/myNetwork.svg";
import Ambulance from "../../../assest/img/ambulance.svg";
import Notification from "../../../assest/img/notification.svg";
import Women from "../../../assest/img/women.svg";
import Childcare from "../../../assest/img/childcare.svg";

// import { connect } from "react-redux";
import { Box, Typography } from "@mui/material";

function PostHeader(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleLogout = () => {
    props.dispatch({
      type: "user/LOGOUT",
    });
  };
  console.log(props, "props");
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {/* DESKTOP HEADER SECTION */}
      <MyDesktop>
        <Box
          className={" shadow-sm d-flex justify-content-around "}
          sx={{
            backgroundColor: "#fff",
            opacity: 1,
          }}
        >
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={BarsLine} />
            <Typography variant="caption">All</Typography>
          </Box>
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={Doctor} />
            <Typography variant="caption">Doctor</Typography>
          </Box>
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={Parmacy} />
            <Typography variant="caption">Pharmacy</Typography>
          </Box>{" "}
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={LabTest} />
            <Typography variant="caption">Lab Test</Typography>
          </Box>{" "}
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={Wellness} />
            <Typography variant="caption" textAlign="center">
              Wellness
              <br /> & fitness
            </Typography>
          </Box>{" "}
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={Preventive} />
            <Typography variant="caption">Preventive Care</Typography>
          </Box>{" "}
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={MyNetwork} />
            <Typography variant="caption">My network</Typography>
          </Box>
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={Ambulance} />
            <Typography variant="caption">Ambulance</Typography>
          </Box>
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={Notification} />
            <Typography variant="caption">Notification</Typography>
          </Box>
          <Box
            className="d-flex flex-column justify-content-center align-items-center"
            sx={{ lineHeight: 0 }}
          >
            <img width="35rem" src={Women} />
            <Typography variant="caption" textAlign="center">
              Women's <br />
              Lifecycle & Care
            </Typography>
          </Box>
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <img width="35rem" src={Childcare} />
            <Typography variant="caption">Child Care</Typography>
          </Box>
        </Box>
      </MyDesktop>

      {/* MONILE HEADER SECTION */}
      <MyMobile>{/* <MobileHeader handleLogout={handleLogout} /> */}</MyMobile>
    </React.Fragment>
  );
}
// export default connect(mapStateToProps)(PostHeader);
// export default Header;

export default PostHeader;
