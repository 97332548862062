import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { getUserLevel } from "../../../utils/getUserLevel";
import { useAuth } from "../../../context/auth-context";
import Linkedin from "../../../assest/img/linkedin.svg";
import Instagram from "../../../assest/img/instagram.svg";
import Twitter from "../../../assest/img/twitter.svg";
import Facebook from "../../../assest/img/facebook.svg";
import WhiteLogo from "../../../assest/img/welldone_white.png";
import { Space } from "antd";

const Footer = () => {
  const navigate = useNavigate();
  const authValue = useAuth();

  const isUserDoctor =
    authValue.currUser?.roles?.id == getUserLevel("IS_DOCTOR");

  return (
    <React.Fragment>
      <div className="px-2 py-5 th-home th-bg-secondary">
        <div className="row text-white">
          <div className="col-md-3 text-center mb-4">
            <img src={WhiteLogo} height={70} />
          </div>
          <div className="col-md-9 row">
            <div className="col-md-3 col-sm-6 col-12">
              <h4 className="th-fw-600 text-white">Policies</h4>
              <div className="" style={{ color: "#ACACAC" }}>
                <div
                  className="my-2 th-pointer"
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                >
                  Privacy
                </div>
                <div
                  className="my-2 th-pointer"
                  onClick={() => {
                    navigate("/shipping-policy");
                  }}
                >
                  Shipping & Return
                </div>
                <div
                  className="my-2 th-pointer"
                  onClick={() => {
                    navigate("/payment-policy");
                  }}
                >
                  Refund & Cancellation
                </div>
                <div
                  className="my-2 th-pointer"
                  onClick={() => {
                    navigate("/product-Checkout-Process");
                  }}
                >
                  Product & Checkout
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <h4 className="th-fw-600 text-white">Company</h4>
              <div className="" style={{ color: "#ACACAC" }}>
                <div
                  className="my-2 th-pointer"
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  About
                </div>
                <div
                  className="my-2 th-pointer"
                  onClick={() => navigate("/t-and-c")}
                >
                  Terms & Conditions
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <h4 className="th-fw-600 w-100 text-white">Contact Us</h4>

              <div className=" th-fw-300 th-18" style={{ color: "#ACACAC" }}>
                <p>Welldone Healthcare Private Limited</p>
                <p>CIN: U85100MH2021PTC354141</p>
                <p>
                  G.NO.91/1, PRASAD NAGAR, BHADANE TAL.SAKRI, Dhule, Dhule,
                  Maharashtra, India, 424304 7757080066
                </p>
                <p>
                  <a
                    style={{ color: "#ACACAC" }}
                    href="mailto:welldonehealth499@gmail.com"
                  >
                    welldonehealth499@gmail.com
                  </a>
                </p>
                <p>For Daily Health Tips Follow Us </p>
              </div>
              <Space wrap>
                <a
                  href="https://www.facebook.com/profile.php?id=100092906130450"
                  target="__blank"
                >
                  <img
                    className="pr-2"
                    src={Facebook}
                    height={35}
                    alt="facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/welldone_health/"
                  target="__blank"
                >
                  <img
                    className="pr-2"
                    src={Instagram}
                    height={35}
                    alt="instagram"
                  />
                </a>

                <a href="https://twitter.com/health_welldone" target="__blank">
                  <img className="" src={Twitter} height={35} alt="twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/welldone-health/"
                  target="__blank"
                >
                  <img
                    className="pr-2"
                    src={Linkedin}
                    height={35}
                    alt="linkedin"
                  />
                </a>
              </Space>
            </div>
          </div>
        </div>
        <div
          className="text-center pt-5 text-white"
          style={{ fontSize: "14px" }}
        >
          copyright©️WELLDONE HEALTHCARE PRIVATE LIMITED. All rights reserved
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
